import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { T } from "../../config";
import { AreaSelect } from "../AreaSelect";
import { UserMenu } from "../User/UserMenu";

const routes = [
  { path: "/", breadcrumb: null },
  { path: "/admin", breadcrumb: null },
  { path: "/admin/home", breadcrumb: null },
  { path: "/admin/area/tracks/:id", breadcrumb: T("edit") },
  { path: "/admin/area/tracks/:id/:view", breadcrumb: null },
  { path: "/admin/area/tracks/:id/:view/:open", breadcrumb: null },
  { path: "/admin/area/transports/:id", breadcrumb: T("edit") },
  { path: "/admin/area/pois/:id", breadcrumb: T("edit") },
];

const Navbar = () => {
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <>
      <nav
        style={{ zIndex: 999 }}
        className="absolute hidden md:flex top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start items-center py-4 px-2"
      >
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-2">
          <div className="text-sm text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 border-0">
            {breadcrumbs.map(({ match, breadcrumb }) => (
              <span className={"breadcumb "} key={match.url}>
                <Link className={"hover:text-purple-500"} to={match.url}>
                  {breadcrumb}
                </Link>{" "}
              </span>
            ))}
          </div>

          <ul className="flex flex-row list-none items-center justify-center">
            <AreaSelect />
            <UserMenu />
          </ul>
        </div>
      </nav>
    </>
  );
};

export { Navbar };
