import { setContext } from '@apollo/client/link/context';

import { ACTUAL_AUTH } from '../../stores/db/auth';

const authLink = setContext((_, { headers }) => {
  const token = ACTUAL_AUTH.accessToken;
  return {
    headers: {
      ...headers,
      authentication: token || '',
    },
  };
});

export { authLink };
