import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

import { theme } from "../../config";

interface PieChartProps {
  labels: string[];
  data: string[] | number[];
  id: string;
  height?: number;
}

const PieChart = (props: PieChartProps) => {
  const CHART = useRef<Chart<"pie", any[], string>>();
  const CANVAS = useRef<any>();

  const initChart = async () => {
    if (!props.data) {
      return;
    }

    CANVAS.current = (document.getElementById(props.id) as any).getContext(
      "2d"
    );

    var gradient =
      CANVAS.current && CANVAS.current.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, theme.vars.colors.blueLight);
    gradient.addColorStop(1, "rgba(50,250,50,0.1)");

    CHART.current = new Chart(CANVAS.current, {
      type: "pie",

      data: {
        labels: props.labels,
        datasets: [
          {
            label: "",
            data: props.data,
            borderColor: "#fff",
            //borderSkipped: true,
            //backgroundColor: "rgba(0, 0, 0, 0.1)",
            //backgroundColor: gradient,

            backgroundColor: [
              theme.vars.colors.blueLight,
              theme.vars.colors.redLight,
              theme.vars.colors.yellow,
              theme.vars.colors.greenLight,
              theme.vars.colors.violet,
              theme.vars.colors.greyLight,
              theme.vars.colors.cyan,
            ],

            //fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum = sum + parseInt(data as any);
                return sum;
              });

              let percentage = (value * 100) / sum;

              if (percentage < 10) {
                return "";
              }

              return percentage.toFixed(2) + "%";
            },
            color: "#fff",
            labels: {
              title: {
                font: {
                  weight: "bold",
                },
              },
              value: {
                color: "#fff",
              },
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (CHART.current) {
      CHART.current.destroy();
      initChart();
    } else {
      initChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div
      style={{
        height: "100%",
      }}
      className="relative h-full"
    >
      <canvas id={props.id || "pie-chart"}></canvas>
    </div>
  );
};

PieChart.defaultProps = {
  from: "2020-01-01",
  to: "today",
  title: "Values",
  subTitle: "Overview",
};

export { PieChart };
