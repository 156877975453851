import { useQuery } from "@apollo/client";
import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { HeatMap } from "../../../components";
import { theme } from "../../../config";
import { useGlobalState } from "../../../stores/appState";
import { ACTUAL_AUTH } from "../../../stores/db/auth";
import {
  USER_TRACKED_EVENTS,
  TrackedEvent,
} from "../../../stores/queries/users";
import { Loading } from "../../../ui";
import { Icon } from "../../../ui/Icon";

const ProfilationIcon = ({ profilation }: { profilation: string }) => {
  let icon = "tutorial_ski";

  switch (profilation) {
    case "ski":
      icon = "tutorial_ski";
      break;
    case "snow":
      icon = "tutorial_outdoor";
      break;
    case "gravity":
      icon = "tutorial_bike_park";
      break;
    case "pedaled":
      icon = "tutorial_cross_country";
      break;
    case "family":
      if (ACTUAL_AUTH.app === "bike") {
        icon = "tutorial_family";
      } else {
        icon = "tutorial_family_snow";
      }
      break;
  }

  if (!icon) {
    icon = "tourist_attraction";
  }

  return (
    <div
      className="rounded-full bg-white flex justify-center items-center m-1 shadow"
      style={{ width: 60, height: 60 }}
    >
      <Icon name={icon as any} size={48} color={"#000"} />
    </div>
  );
};

const PlatformIcon = (props: { platform: string }) => {
  let icon = "fas fa-circle";
  switch (props.platform) {
    case "ios":
      icon = "fab fa-apple";
      break;
    case "android":
      icon = "fab fa-android";
      break;
    case "web":
      icon = "fas fa-globe";
      break;
  }
  return (
    <div
      className="rounded-full bg-white flex justify-center items-center m-1 shadow"
      style={{ width: 60, height: 60 }}
    >
      <i className={icon} style={{ fontSize: 30, color: "#666" }}></i>
    </div>
  );
};

const UserItem = ({ user }: { user: UserTracked }) => {
  const [showEvents, setShowEvents] = useState(false);
  return (
    <CSSTransition key={user.deviceRef} timeout={500} classNames="item">
      <div
        className="rounded-2xl m-2 shadow bg-gray-100 p-2"
        style={{ lineHeight: 1 }}
      >
        <h1
          className="text-gray-500 font-bold uppercase flex-1 truncate"
          style={{
            textAlign: "left",
            padding: 3,
            fontSize: 14,
            wordBreak: "break-all",
          }}
        >
          {/*   {user.deviceRef}
          <br /> */}

          <div className="flex flex-row" style={{ marginTop: 0 }}>
            <PlatformIcon platform={user.events[0].platform} />
            {user.events[0].isInArea && (
              <div
                className="rounded-full bg-white flex justify-center items-center m-1 shadow"
                style={{ width: 60, height: 60 }}
              >
                <i
                  className={"fas fa-map-marker-alt"}
                  style={{ fontSize: 30, color: "#666" }}
                ></i>
              </div>
            )}
            <ProfilationIcon profilation={user.events[0].profilation} />
          </div>
        </h1>
        <div
          className="text-gray-500 text-xs uppercase flex-1 opacity-80 truncate"
          style={{ fontSize: 10, padding: 3 }}
        >
          <b>IN AREA:</b> {user.events[0].isInArea ? "Si" : "No"}
          <br />
          <b>PIATTAFORMA:</b> {user.events[0].platform}
          <br />
          <b>PROFILAZIONE:</b> {user.events[0].profilation}
          <br />
          {showEvents ? (
            <div style={{ marginTop: 8 }}>
              {user.events.map((event: TrackedEvent, index) => {
                return (
                  <p
                    key={"events_" + user.deviceRef + "_" + index}
                    className="truncate"
                  >
                    - <b>{new Date(event.timestamp).toLocaleTimeString()}</b>:{" "}
                    {event.event}
                    <br />
                  </p>
                );
              })}
            </div>
          ) : (
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowEvents(true);
              }}
              style={{ marginTop: 8 }}
            >
              <big>
                <b>MOSTRA EVENTI</b>
              </big>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

interface UserTracked {
  deviceRef: string;
  events: TrackedEvent[];
}

interface EventsViewProps {}

const EventsView = (props: EventsViewProps) => {
  const { loading, data, startPolling, stopPolling } = useQuery<{
    userTrackedEventsByArea: TrackedEvent[];
  }>(USER_TRACKED_EVENTS, {
    fetchPolicy: "network-only",
    variables: {
      limit: 3000,
    },
  });

  const [actualArea] = useGlobalState("actualArea");

  const [range, setRange] = useState("live");

  const initInArea = [
    { id: "In Area", label: "In Area", value: 0, color: "hsl(39, 70%, 50%)" },
    {
      id: "Fuori",
      label: "Fuori",
      value: 0,
      color: "hsl(211, 70%, 50%)",
    },
  ];

  const initPlatform = [
    { id: "Web", label: "WEB", value: 0, color: "hsl(39, 70%, 50%)" },
    { id: "Android", label: "ANDROID", value: 0, color: "hsl(331, 70%, 50%)" },
    { id: "iOS", label: "IOS", value: 0, color: "hsl(211, 70%, 50%)" },
  ];

  const profilationBike = [
    { id: "Gravity", label: "Ski", value: 0, color: "hsl(39, 70%, 50%)" },
    { id: "Pedaled", label: "Snow", value: 0, color: "hsl(331, 70%, 50%)" },
    { id: "Family", label: "Family", value: 0, color: "hsl(211, 70%, 50%)" },
  ];

  const profilationSnow = [
    { id: "Ski", label: "Ski", value: 0, color: "hsl(39, 70%, 50%)" },
    { id: "Snow", label: "Snow", value: 0, color: "hsl(331, 70%, 50%)" },
    { id: "Family", label: "Family", value: 0, color: "hsl(211, 70%, 50%)" },
  ];

  const initProfilation =
    ACTUAL_AUTH.app === "bike" ? profilationBike : profilationSnow;

  const [platforms, setPlatforms] = useState<
    {
      id: string;
      label: string;
      value: number;
      color: string;
    }[]
  >(initPlatform);
  const [inArea, setInArea] = useState<
    {
      id: string;
      label: string;
      value: number;
      color: string;
    }[]
  >(initInArea);
  const [profilations, setProfilations] = useState<
    {
      id: string;
      label: string;
      value: number;
      color: string;
    }[]
  >(initProfilation);
  const [points, setPoints] = useState<number[][]>([]);

  const [users, setUsers] = useState<{
    [key: string]: UserTracked;
  }>({});

  const [interactions, setInteractions] = useState<number>(0);

  useEffect(() => {
    if (range === "live") {
      startPolling(30000);
    } else {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  useEffect(() => {
    if (data?.userTrackedEventsByArea) {
      let eventsArr = [...data.userTrackedEventsByArea];
      let minDate = 0;

      if (range === "live") {
        minDate = new Date().setHours(0, 0, 0, 0);
      } else if (range === "week") {
        minDate = new Date().setHours(0, 0, 0, 0) - 7 * 60 * 60 * 1000 * 5;
      }

      let usersArr: any = {};
      let pointsArr = [];
      let platformsCount = [0, 0, 0];
      let profilationsCount = [0, 0, 0];
      let inAreaCount = [0, 0];

      let count = 0;

      for (let i = 0; i < eventsArr.length; i++) {
        const event: TrackedEvent = eventsArr[i];

        if (new Date(event.timestamp).getTime() < minDate) {
          break;
        }

        count++;

        if (usersArr[event.deviceRef]) {
          usersArr[event.deviceRef].events.push(event);
        } else {
          usersArr[event.deviceRef] = {
            deviceRef: event.deviceRef,
            events: [event],
          };

          if (event?.geoLocation?.coords?.latitude) {
            pointsArr.push([
              event.geoLocation.coords.latitude,
              event.geoLocation.coords.longitude,
              1,
            ]);
          }

          switch (event.platform) {
            case "web":
              platformsCount[0]++;
              break;
            case "android":
              platformsCount[1]++;
              break;
            case "ios":
              platformsCount[2]++;
              break;
          }

          switch (event.profilation) {
            case "ski":
              profilationsCount[0]++;
              break;
            case "gravity":
              profilationsCount[0]++;
              break;
            case "snow":
              profilationsCount[1]++;
              break;
            case "pedaled":
              profilationsCount[1]++;
              break;
            case "family":
              profilationsCount[2]++;
              break;
          }

          if (event.isInArea) {
            inAreaCount[0]++;
          } else {
            inAreaCount[1]++;
          }
        }
      }

      setInteractions(count);

      let userCount = Object.keys(usersArr).length;

      let percPlatform = [
        Math.round((platformsCount[0] / userCount) * 100),
        Math.round((platformsCount[1] / userCount) * 100),
        Math.round((platformsCount[2] / userCount) * 100),
      ];

      let percInArea = [
        Math.round((inAreaCount[0] / userCount) * 100),
        Math.round((inAreaCount[1] / userCount) * 100),
      ];

      let percProfilation = [
        Math.round((profilationsCount[0] / userCount) * 100),
        Math.round((profilationsCount[1] / userCount) * 100),
        Math.round((profilationsCount[2] / userCount) * 100),
      ];

      setPlatforms([
        { ...platforms[0], value: percPlatform[0] },
        { ...platforms[1], value: percPlatform[1] },
        { ...platforms[2], value: percPlatform[2] },
      ]);
      setInArea([
        { ...inArea[0], value: percInArea[0] },
        { ...inArea[1], value: percInArea[1] },
      ]);
      setProfilations([
        { ...profilations[0], value: percProfilation[0] },
        { ...profilations[1], value: percProfilation[1] },
        { ...profilations[2], value: percProfilation[2] },
      ]);
      setPoints(pointsArr);

      setUsers(usersArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.userTrackedEventsByArea, range]);

  const items = Object.keys(users).map((userKey: string, index: number) => {
    let user: UserTracked = users[userKey];
    return <UserItem key={"user_event_" + index} user={user} />;
  });

  return (
    <>
      <div className="flex flex-wrap bg-gray-100 rounded-2xl shadow">
        <div
          style={{ height: "160px", scrollbarWidth: "thin" }}
          className="w-full lg:w-3/12 p-1 mt-2 relative flex flex-col min-w-0 break-words"
        >
          <div className="w-full h-full relative flex flex-col min-w-0  border-0 overflow-y-auto overflow-x-hidden justify-around">
            <div className="flex flex-row justify-center items-center">
              <div
                onClick={() => {
                  setRange("live");
                }}
                style={{ width: "100%" }}
                className={
                  "flex flex-row rounded-2xl m-2 shadow p-1 px-2 justify-center items-center cursor-pointer " +
                  (range === "live" ? "bg-purple-400" : "bg-white")
                }
              >
                <h1
                  className={
                    "flex flex-row text-md font-bold uppercase text-center justify-center items-center truncate"
                  }
                  style={{
                    textAlign: "center",
                    wordBreak: "break-all",
                    color: range === "live" ? "#fff" : "#666",
                  }}
                >
                  LIVE OGGI
                </h1>
                {range === "live" ? (
                  <div style={{ marginLeft: 8 }}>
                    <Loading
                      width={18}
                      height={18}
                      color={range === "live" ? "#fff" : "#666"}
                    />
                  </div>
                ) : null}
              </div>
              {/* <div
                onClick={() => {
                  setRange("week");
                }}
                style={{ width: "46%" }}
                className={
                  "flex flex-row rounded-2xl m-2 shadow p-1 px-2 justify-center items-center cursor-pointer " +
                  (range === "week" ? "bg-purple-400" : "bg-white")
                }
              >
                <h1
                  className={
                    "flex flex-row text-md font-bold uppercase text-center justify-center items-center truncate"
                  }
                  style={{
                    textAlign: "center",
                    wordBreak: "break-all",
                    color: range === "week" ? "#fff" : "#666",
                  }}
                >
                  Settimana
                </h1>
              </div> */}
            </div>

            {/* <div className=" rounded-2xl mx-2 shadow bg-gray-400 p-1 px-2 justify-center items-center">
              <h1
                className="text-white text-sm font-bold uppercase text-center"
                style={{
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
              >
                INTERAZIONI IN AREA: {interactions}
              </h1>
            </div> */}

            <div className=" rounded-2xl mx-2 shadow bg-gray-400 p-1 px-2 justify-center items-center">
              <h1
                className="text-white text-sm font-bold uppercase text-center"
                style={{
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
              >
                UTENTI ATTIVI: {Object.keys(users).length}
              </h1>
            </div>
          </div>
        </div>
        <div
          style={{ height: "160px", scrollbarWidth: "thin" }}
          className="w-full lg:w-3/12 p-2 mt-2 relative flex flex-col min-w-0 break-words"
        >
          <div className="w-full h-full relative flex flex-col min-w-0 border-0 overflow-y-auto overflow-x-hidden">
            <ResponsivePie
              data={platforms}
              margin={{ top: 20, right: 60, bottom: 20, left: 60 }}
              colors={{ scheme: "nivo" }}
              innerRadius={0.2}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={2}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0]],
              }}
              valueFormat={(v) => `${v}%`}
              arcLinkLabelsSkipAngle={2}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLinkLabelsTextOffset={2}
            />
          </div>
        </div>
        <div
          style={{ height: "160px", scrollbarWidth: "thin" }}
          className="w-full lg:w-3/12 p-1 mt-2 relative flex flex-col min-w-0 break-words"
        >
          <div className="w-full h-full relative flex flex-col min-w-0 border-0 overflow-y-auto overflow-x-hidden">
            <ResponsivePie
              data={inArea}
              margin={{ top: 20, right: 60, bottom: 20, left: 60 }}
              colors={{ scheme: "paired" }}
              innerRadius={0.2}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={2}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0]],
              }}
              valueFormat={(v) => `${v}%`}
              arcLinkLabelsSkipAngle={2}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLinkLabelsTextOffset={2}
            />
          </div>
        </div>
        <div
          style={{ height: "160px", scrollbarWidth: "thin" }}
          className="w-full lg:w-3/12 p-1 mt-2 relative flex flex-col min-w-0 break-words"
        >
          <div className="w-full h-full relative flex flex-col min-w-0 border-0 overflow-y-auto overflow-x-hidden">
            <ResponsivePie
              data={profilations}
              margin={{ top: 20, right: 60, bottom: 20, left: 60 }}
              colors={{ scheme: "set2" }}
              innerRadius={0.2}
              padAngle={0}
              cornerRadius={3}
              activeOuterRadiusOffset={2}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0]],
              }}
              valueFormat={(v) => `${v}%`}
              arcLinkLabelsSkipAngle={2}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              arcLinkLabelsTextOffset={2}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div
          style={{ height: "calc(100vh - 280px)", scrollbarWidth: "thin" }}
          className="w-full lg:w-4/12 p-1 mt-2 relative flex flex-col min-w-0 break-words shadow-lg rounded-2xl bg-gray-50 border-0 overflow-y-auto overflow-x-hidden"
        >
          <TransitionGroup>{items}</TransitionGroup>
          {loading && (
            <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
              <Loading color={theme.vars.colors.greyDark} />
            </div>
          )}
        </div>
        <div className="w-full lg:w-8/12 pl-2 mt-2">
          <HeatMap
            center={
              actualArea?.map?.coordsCenter
                ? [
                    actualArea.map.coordsCenter[1],
                    actualArea.map.coordsCenter[0],
                  ]
                : null
            }
            points={points}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export { EventsView };
