const AVAILABLE_LOCALE = ['en', 'it', 'de'];

const getLanguage = () =>
  // @ts-ignore
  navigator.userLanguage ||
  (navigator.languages &&
    navigator.languages.length &&
    navigator.languages[0]) ||
  navigator.language ||
  // @ts-ignore
  navigator.browserLanguage ||
  // @ts-ignore
  navigator.systemLanguage ||
  'en';

const LOCALE = getLanguage().substring(0, 2).toLowerCase();

let translation: any = null;

const translations: any = {
  en: require('./en.ts'),
  it: require('./it.ts'),
  de: require('./de.ts'),
};

const getTransaltion = (locale: string) => {
  if (!AVAILABLE_LOCALE.includes(locale)) {
    locale = 'en';
  }
  translation = translations[locale];
  return translation;
};

export { getTransaltion, LOCALE };
