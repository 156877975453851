import React from "react";
import { useQuery } from "@apollo/client";

import { ACTUAL_AUTH } from "../../stores/db/auth";
import { Transport, TRANSPORTS } from "../../stores/queries/transports";
import { Button, Table } from "../../ui";
import { LOG, T, theme } from "../../config";
import { useHistory } from "react-router";

const WarnBtn = ({ transport }: { transport: Transport }) => {
  const history = useHistory();
  let color: any = "white";
  if (transport.warnings && transport.warnings.length > 0) {
    color = "yellow";
    for (let index = 0; index < transport.warnings.length; index++) {
      if (transport.warnings[index].ifClosed) {
        color = "red";
      }
    }
  }
  return (
    <Button
      style={{ width: "85%", fontSize: 14 }}
      color={color}
      onClick={() => {
        history.push("/admin/area/transports/" + transport.id + "/warnings");
      }}
    >
      {transport.warnings && transport.warnings.length > 0
        ? transport.warnings.length
        : "+"}
    </Button>
  );
};

const columns: any = [
  {
    name: T("name"),
    selector: "name",
    sortable: true,
    minWidth: "20%",
    style: { fontWeight: 600 },
  },
  {
    name: T("Services"),
    selector: "detailedInfo.primaryService",
    cell: (row: Transport) => T(row.primaryService),
    sortable: true,
    hide: "md",
  },
  /*   {
    name: T('category'),
    selector: 'category',
    sortable: true,
    cell: (row: Transport) => T(row.category),
    center: true,
    hide: 'md',
  }, */
  {
    name: T("typology"),
    selector: "typology",
    sortable: true,
    cell: (row: Transport) => T(row.typology),
    hide: "md",
  },
  {
    hide: "md",
    name: (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <h1>STATO</h1>
        <h4 style={{ fontSize: "80%" }}>Sorgente</h4>
      </div>
    ),
    cell: (row: Transport) => (
      <div style={{ fontSize: 9, fontWeight: "bold" }}>
        {typeof row?.statusFromExternalSource === "boolean" ? (
          <>
            <i
              className="fa fa-circle"
              style={{
                color: row?.statusFromExternalSource
                  ? theme.vars.colors.greenLight
                  : theme.vars.colors.redLight,
                opacity: 0.5,
              }}
            />{" "}
            A
          </>
        ) : (
          <>
            {typeof row?.originalStatus === "boolean" ? (
              <>
                <i
                  className="fa fa-circle"
                  style={{
                    color: row?.originalStatus
                      ? theme.vars.colors.greenLight
                      : theme.vars.colors.redLight,
                    opacity: 0.5,
                  }}
                />{" "}
                M
              </>
            ) : (
              <i
                className="fa fa-minus"
                style={{
                  color: theme.vars.colors.greyLight,
                }}
              />
            )}
          </>
        )}
      </div>
    ),
    width: "60px",
    sortable: false,
    center: true,
    style: { borderLeft: "1px solid #eee" },
  },
  {
    name: (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <h1>STATO</h1>
        <h4 style={{ fontSize: "80%" }}>Attuale</h4>
      </div>
    ),
    cell: (row: Transport) => (
      <div>
        {typeof row?.status === "boolean" ? (
          <i
            className="fa fa-circle"
            style={{
              color: row?.status
                ? theme.vars.colors.greenLight
                : theme.vars.colors.redLight,
            }}
          />
        ) : (
          <i
            className="fa fa-minus"
            style={{
              color: theme.vars.colors.greyLight,
            }}
          />
        )}
      </div>
    ),
    width: "60px",
    sortable: false,
    center: true,
    style: { borderLeft: "1px solid #eee" },
  },
  {
    name: "AVVISI",
    cell: (row: Transport) => <WarnBtn transport={row} />,
    width: "110px",
    sortable: false,
    center: true,
    hide: "md",
    style: { borderLeft: "1px solid #eee" },
  },
];

/*
  originalStatus: true
status: true
statusClosedUntil: null
statusFromExternalSource: null
  */

const TransportsList = () => {
  const history = useHistory();
  const { error, data } = useQuery<{
    userTransports: Transport[];
  }>(TRANSPORTS, {
    variables: {
      area: ACTUAL_AUTH.area,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    LOG.error(error);
  }

  return (
    <>
      <div
        className={
          "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white"
        }
      >
        <Table
          columns={columns}
          defaultSortField={"category"}
          data={data?.userTransports || []}
          height={"calc(100vh - 240px)"}
          title={"Lista Trasporti"}
          loading={data ? false : true}
          filterSelector={"name"}
          onRowClick={(row: Transport, event) => {
            history.push("/admin/area/transports/" + row.id);
          }}
        />
      </div>
    </>
  );
};

export { TransportsList };
