import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import { LOG, T, theme } from "../../config";
import { Button, EditBox, Loading } from "../../ui";
import { useGlobalState } from "../../stores/appState";
import {
  AREA_CREATE_NOTIFICATION,
  AREA_CREATE_TEST_NOTIFICATION,
  AREA_NOTIFICATIONS,
  Notification,
} from "../../stores/queries/notifications";

const NotificationAdd = () => {
  const [areaCreateNotification, { error, loading }] = useMutation(
    AREA_CREATE_NOTIFICATION,
    {
      refetchQueries: [
        {
          query: AREA_NOTIFICATIONS,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (res: any) => {
        if (res?.areaCreateNotification) {
          setSuccess(true);
        }
      },
    }
  );

  const [
    areaCreateTestNotification,
    { error: testError, loading: testLoading },
  ] = useMutation(AREA_CREATE_TEST_NOTIFICATION, {
    refetchQueries: [
      {
        query: AREA_NOTIFICATIONS,
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (res: any) => {
      if (res?.areaCreateTestNotification) {
        setSuccessTest(true);
      }
    },
  });

  const [locale, setLocale] = useGlobalState("locale");
  const [recipients, setRecipients] = useState<
    "devicesInArea" | "devicesNowInArea"
  >("devicesNowInArea");
  const [success, setSuccess] = useState<boolean>(false);
  const [successTest, setSuccessTest] = useState<boolean>(false);

  useEffect(() => {
    setLocale("en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actualNotification = useRef<{
    head: Notification["head"];
    content: Notification["content"];
    description: Notification["description"];
  }>({
    head: {
      it: "",
      en: "",
      de: "",
    },
    content: {
      it: "",
      en: "",
      de: "",
    },
    description: {
      it: "",
      en: "",
      de: "",
    },
  });

  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  const create = () => {
    if (actualNotification.current?.head?.en) {
      if (!actualNotification.current.head.it) {
        actualNotification.current.head.it = actualNotification.current.head.en;
      }
      if (!actualNotification.current.head.de) {
        actualNotification.current.head.de = actualNotification.current.head.en;
      }
    } else {
      return false;
    }

    if (actualNotification.current?.content?.en) {
      if (!actualNotification.current.content.it) {
        actualNotification.current.content.it =
          actualNotification.current.content.en;
      }
      if (!actualNotification.current.content.de) {
        actualNotification.current.content.de =
          actualNotification.current.content.en;
      }
    }
    if (actualNotification.current?.description?.en) {
      if (!actualNotification.current.description.it) {
        actualNotification.current.description.it =
          actualNotification.current.description.en;
      }
      if (!actualNotification.current.description.de) {
        actualNotification.current.description.de =
          actualNotification.current.description.en;
      }
    }

    areaCreateNotification({
      variables: { recipients, ...actualNotification.current },
    });
  };

  const createTest = () => {
    if (testLoading) return false;

    if (actualNotification.current?.head?.en) {
      if (!actualNotification.current.head.it) {
        actualNotification.current.head.it = actualNotification.current.head.en;
      }
      if (!actualNotification.current.head.de) {
        actualNotification.current.head.de = actualNotification.current.head.en;
      }
    } else {
      return false;
    }

    if (actualNotification.current?.content?.en) {
      if (!actualNotification.current.content.it) {
        actualNotification.current.content.it =
          actualNotification.current.content.en;
      }
      if (!actualNotification.current.content.de) {
        actualNotification.current.content.de =
          actualNotification.current.content.en;
      }
    }
    if (actualNotification.current?.description?.en) {
      if (!actualNotification.current.description.it) {
        actualNotification.current.description.it =
          actualNotification.current.description.en;
      }
      if (!actualNotification.current.description.de) {
        actualNotification.current.description.de =
          actualNotification.current.description.en;
      }
    }

    areaCreateTestNotification({
      variables: { ...actualNotification.current },
    });
  };

  if (error) {
    LOG.error(error);
  }

  if (testError) {
    LOG.error(testError);
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 108px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
      >
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Attenzione!</strong>
          <span className="block sm:inline">
            Invio notifiche temporaneamente disabilitato, sarà riattivato a
            breve.
          </span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
        <div className="rounded-t-2xl w-full my-1 px-6 py-4 flex flex-row justify-between bg-white">
          <h3 className={"font-semibold text-2xl text-gray-600"}>Nuova</h3>
          <div>
            <Button
              onClick={() => {
                createTest();
              }}
              disabled={
                actualNotification.current?.head?.en &&
                actualNotification.current.head.en !== ""
                  ? false
                  : true
              }
              color="blue"
              style={{ marginRight: 5 }}
            >
              Test <i className="fa fa-chevron-up"></i>
            </Button>
            <Button
              onClick={() => {
                create();
              }}
              color="gray"
              disabled={
                true
                /* actualNotification.current?.head?.en &&
                actualNotification.current.head.en !== ""
                  ? false
                  : true */
              }
            >
              Invia <i className="fa fa-chevron-right"></i>
            </Button>
          </div>
        </div>
        <div
          className="flex-auto px-4 lg:px-4 py-4 pt-0"
          style={{ overflow: "auto" }}
        >
          <form>
            {error?.message && (
              <div className="p-2 mt-2 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
                {error.message}
              </div>
            )}
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("pubblico")}
            </h6>
            <div className="flex flex-1 flex-row w-full">
              <div
                onClick={() => {
                  setRecipients("devicesInArea");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (recipients === "devicesInArea"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-map"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Tutti gli utenti
                </h6>
              </div>
              <div
                onClick={() => {
                  setRecipients("devicesNowInArea");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (recipients === "devicesNowInArea"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-map-marked-alt"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Utenti nell'area
                </h6>
              </div>
            </div>

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("lingue")}
            </h6>
            <div className="text-gray-400 text-base mt-3 mb-4 font-bold uppercase flex flex-1 flex-row ">
              <Button
                color={locale === "en" ? "purple" : "white"}
                onClick={() => {
                  setLocale("en");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                EN*
              </Button>{" "}
              <Button
                color={locale === "it" ? "purple" : "white"}
                onClick={() => {
                  setLocale("it");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                IT
              </Button>{" "}
              <Button
                color={locale === "de" ? "purple" : "white"}
                onClick={() => {
                  setLocale("de");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                DE
              </Button>
            </div>

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("contenuto")}
            </h6>

            <div className="flex flex-wrap">
              <EditBox
                editable={true}
                value={actualNotification.current.head[locale]}
                label={T("title") + (locale === "en" ? "*" : "")}
                maxChar={25}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualNotification.current.head[locale] = value || "";
                }}
              />
              <EditBox
                editable={true}
                value={actualNotification.current.content[locale]}
                label={T("Contenuto")}
                maxChar={200}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualNotification.current.content[locale] = value || "";
                }}
              />
            </div>
          </form>
          {activeEdit && (
            <div
              className="w-full h-full absolute top-0 left-0 rounded-2xl"
              style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
            ></div>
          )}
          {loading || testLoading ? (
            <div
              className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 5000,
              }}
            >
              <Loading color={theme.vars.colors.light} />
            </div>
          ) : null}
          {successTest ? (
            <div
              className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center bg-white"
              style={{
                zIndex: 5000,
              }}
            >
              <div
                className="flex flex-1 flex-col w-full justify-center items-center p-4"
                style={{
                  width: "100%",
                  height: 500,
                  borderTopWidth: 2,
                  borderColor: theme.vars.colors.light,
                }}
              >
                <i
                  style={{
                    fontSize: 80,
                    color: theme.vars.colors.greenLight,
                  }}
                  className="icon-check_encircled_thin"
                />
                <p className="text-lg text-gray-800 text-center">
                  Test inviato!
                </p>
                <p className="text-base text-gray-600 mt-2 text-center">
                  E' stata inviata una notifica di prova al dispositivo al quale
                  è loggato il tuo account.
                </p>

                <Button
                  style={{ marginTop: 20 }}
                  color="blue"
                  onClick={() => {
                    create();
                    setSuccessTest(false);
                  }}
                >
                  Invia al pubblico <i className="fa fa-chevron-right"></i>
                </Button>
                <Button
                  style={{ marginTop: 20 }}
                  color="grayLight"
                  onClick={() => {
                    setSuccessTest(false);
                  }}
                >
                  Indietro
                </Button>
              </div>
            </div>
          ) : null}
          {success ? (
            <div
              className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center bg-white"
              style={{
                zIndex: 5000,
              }}
            >
              <div
                className="flex flex-1 flex-col w-full justify-center items-center p-4"
                style={{
                  width: "100%",
                  height: 500,
                  borderTopWidth: 2,
                  borderColor: theme.vars.colors.light,
                }}
              >
                <i
                  style={{
                    fontSize: 80,
                    color: theme.vars.colors.greenLight,
                  }}
                  className="icon-check_encircled_thin"
                />
                <p className="text-lg text-gray-800 text-center">
                  Notifica creata!
                </p>
                <p className="text-base text-gray-600 mt-2 text-center">
                  La tua notifica è in attesa di approvazione dopo di che verrà
                  inviata ai destiantari, puoi controllarne lo stato nella
                  tabella a destra.
                </p>
                <Button
                  style={{ marginTop: 20 }}
                  color="gray"
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  Indietro
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { NotificationAdd };
