import React from 'react';
import { NotificationAdd, NotificationsList } from '../../../components';

interface NotificationsViewProps {}

const NotificationsView = (props: NotificationsViewProps) => {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12 pr-2">
          <NotificationAdd />
        </div>
        <div className="w-full lg:w-8/12 pl-2">
          <NotificationsList />
        </div>
      </div>
    </>
  );
};

export { NotificationsView };
