//import { gql } from '@apollo/client';
import gql from "graphql-tag";
import { ENV, ENV_PATH, LOG } from "../../config";
const log = LOG.extend("AREAS");

interface AppArea {
  bundle: { version: string };
  id: string;
  name: string;
  description: string;
  nameRef: string;
  locality: string;
  isDisabled: boolean;
  isCoomingSoon: string;
  assets: {
    coverUrl: string;
    logoUrl: string;
    tailsPath: string;
  };
  app: "ski" | "bike";
}

interface Filter {
  name: string;
  isEnabled?: boolean;
  color?: string;
}

interface FilterCategory {
  name: string;
  localities?: Filter[];
  typologies?: Filter[];
  tagFilters?: Filter[];
  booleans?: Filter[];
}

interface AreaData {
  app: "bike" | "ski";
  admins: any;
  id: string;
  name: string;
  description: string;
  nameRef: string;
  locality: string;
  isDisabled: boolean;
  isCoomingSoon: string;
  assets: {
    coverUrl: string;
    logoUrl: string;
    tailsPath: string;
    backgroundUrl: string;
  };
  webcams: {
    url: string;
    name: {
      it: string;
      en: string;
      de: string;
    };
  }[];
  weatherForecast: {
    name: string;
    latitude: string;
    longitude: string;
  }[];
  bundle: {
    version: string;
    updatedAt: string;
    description: string;
    track: FilterCategory[];
    poi: FilterCategory[];
    transport: FilterCategory[];
  };
  map: {
    version: string;
    limits: {
      geo: [number, number, number, number];
      height: [number, number];
    };
    coordsCenter: [number, number];
    /*textures: string[];
    texturesLow: string[]; 
    resolution: [number, number, number, number];
    topology: [
      {
        type: 'Feature';
        properties: {
          name: string;
          importance: number; // 1-3
        };
        geometry: {
          type: 'Point';
          coordinates: [number, number]; // [11.0670971, 46.1949624],
        };
      }
    ];
    modelData: {
      heightMatrix: [[number]];
      precision: [number, number];
      subdivisions: [number, number];
    };*/
  };
  info: {
    description: {
      short: {
        it: string;
        en: string;
        de: string;
      };
      long: {
        it: string;
        en: string;
        de: string;
      };
      media: {
        coverImage: string;
        trackImage: string;
      };
    };
    suggestedPeriod: number[];
    features: string[];
    location: {
      state: string;
      region: string;
      locality: string;
      howToGet: {
        street: string;
        coords: string[];
        description: {
          it: string;
          en: string;
          de: string;
        };
      };
    };
    map: {
      extension: number;
      maxHeight: number;
      minHeight: number;
      totalTracks: number;
      totalTransports: number;
      totalTracksExtension: number;
    };
    contacts: {
      phoneNumber: string;
      webSite: string;
      email: string;
    };
    sponsors: {
      name: string;
      image: string;
    }[];
  };
}

const getSkiAreas = async (): Promise<AreaData[] | null> => {
  try {
    let response = await fetch(ENV_PATH.ski[ENV.mode].backend.areas);
    let responseJson = await response.json();
    let areas: AreaData[] = responseJson.areas;
    if (areas && areas.length > 0) {
      return areas;
    } else {
      log.warn("getSkiAreas error: zero areas receved");
      return null;
    }
  } catch (error: any) {
    log.error("getSkiAreas error: " + error.message);
    return null;
  }
};

const getBikeAreas = async (): Promise<AreaData[] | null> => {
  try {
    let response = await fetch(ENV_PATH.bike[ENV.mode].backend.areas);
    let responseJson = await response.json();
    let areas: AreaData[] = responseJson.areas;
    if (areas && areas.length > 0) {
      return areas;
    } else {
      log.warn("getBikeAreas error: zero areas receved");
      return null;
    }
  } catch (error: any) {
    log.error("getBikeAreas error: " + error.message);
    return null;
  }
};

const getAreas = async (): Promise<AreaData[] | null> => {
  let ski = null;
  let bike = null;
  let areas = [];
  try {
    [ski, bike] = await Promise.all([getSkiAreas(), getBikeAreas()]);
    if (bike) {
      for (let index = 0; index < bike.length; index++) {
        (bike as any)[index]["app"] = "bike";
        areas.push(bike[index]);
      }
    }
    if (ski) {
      for (let index = 0; index < ski.length; index++) {
        (ski as any)[index]["app"] = "ski";
        areas.push(ski[index]);
      }
    }
    return areas;
  } catch (e: any) {
    log.error(e.message);
    return null;
  }
};

const AREA_COMPLETE = gql`
  query AREA_COMPLETE($id: ID!) {
    Area(id: $id) {
      id
      name
      nameRef
      locality
      description
      isDisabled
      isCoomingSoon
      admins {
        user {
          _id
          displayName
        }
        role
      }
      assets {
        coverUrl
        logoUrl
        tailsPath
      }
      bundle {
        version
        updatedAt
        track {
          name
          localities {
            name
            isEnabled
            color
          }
          typologies {
            name
            isEnabled
            color
          }
          tagFilters {
            name
            isEnabled
            color
          }
          booleans {
            name
            isEnabled
            color
          }
        }
        poi {
          name
          localities {
            name
            isEnabled
            color
          }
          typologies {
            name
            isEnabled
            color
          }
          tagFilters {
            name
            isEnabled
            color
          }
          booleans {
            name
            isEnabled
            color
          }
        }
        transport {
          name
          localities {
            name
            isEnabled
            color
          }
          typologies {
            name
            isEnabled
            color
          }
          tagFilters {
            name
            isEnabled
            color
          }
          booleans {
            name
            isEnabled
            color
          }
        }
      }
      map {
        version
        limits {
          geo
          height
        }
      }
    }
  }
`;

const PROMOTE_PATROL = gql`
  mutation PROMOTE_PATROL($areaId: ID!, $userId: ID!) {
    promoteUserToPatrolInArea(areaId: $areaId, userId: $userId)
  }
`;

const DEMOTE_PATROL = gql`
  mutation DEMOTE_PATROL($areaId: ID!, $userId: ID!) {
    demoteUserFromPatrolInArea(areaId: $areaId, userId: $userId)
  }
`;

export { getAreas, AREA_COMPLETE, PROMOTE_PATROL, DEMOTE_PATROL };
export type { AppArea, AreaData };
