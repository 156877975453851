import React from 'react';

interface BrandViewProps {}

const BrandView = (props: BrandViewProps) => {
  return (
    <>
      <div className="flex flex-wrap justify-center items-center">
        <h1 className="text-3xl font-bold text-gray-500">COMING SOON</h1>
      </div>
    </>
  );
};

export { BrandView };
