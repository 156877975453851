import { gql } from '@apollo/client';
import { Warning } from './warnings';

interface Transport {
  id: string;
  name: string;
  area: string;
  category: string;
  typology: string;
  localities: string[];
  tagFilters: string[];
  primaryService: string;
  status: boolean;
  originalStatus: boolean;
  statusFromExternalSource: boolean;
  statusClosedUntil: boolean;
  description: {
    short: {
      it: string;
      en: string;
      de: string;
    };
    long: {
      it: string;
      en: string;
      de: string;
    };
  };
  media: {
    pictures: [
      {
        _id: string;
        url: string;
      }
    ];
    videos: [
      {
        _id: string;
        url: string;
      }
    ];
    cover: {
      _id: string;
      url: string;
    };
  };
  geoLocation: {
    coordinatesMultiLineString?: number[][][];
    coordinatesLineString?: number[][];
    coordinatesPoint?: number[];
  };
  specialPOIs: {
    name: string;
    primaryService: String;
    description: {
      short: {
        it: string;
        en: string;
        de: string;
      };
      long: {
        it: string;
        en: string;
        de: string;
      };
    };
    altimetry: {
      highestPoint: number;
    };
    geoLocation: {
      coordinatesMultiLineString?: number[][][];
      coordinatesLineString?: number[][];
      coordinatesPoint?: number[];
    };
    detailedInfo: {
      tagServices: [string];
      isForDisabled: boolean;
    };
  }[];
  detailedInfo: {
    locality: {
      it: string;
      en: string;
      de: string;
    };
    stations: {
      start: {
        name: {
          it: string;
          en: string;
          de: string;
        };
        altitude: number;
      };
      end: {
        name: {
          it: string;
          en: string;
          de: string;
        };
        altitude: number;
      };
    };
    isForPedestrians: boolean;
    isForDisabled: boolean;
    isForSnowscoot: boolean;
    importance: number;
    tagServices: [string];
  };
  warnings: Warning[];
}

const TRANSPORT_FRAGMENT = gql`
  fragment transportFragment on UserTransport {
    id
    name
    area
    category
    typology
    primaryService
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    specialPOIs {
      name
      primaryService
      description {
        short {
          it
          en
          de
        }
        long {
          it
          en
          de
        }
      }
      altimetry {
        highestPoint
      }
      geoLocation {
        coordinatesMultiLineString
        coordinatesLineString
        coordinatesPoint
      }
      detailedInfo {
        tagServices
        isForDisabled
      }
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      isForPedestrians
      isForDisabled
      isForSnowscoot
      importance
      tagServices
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const TRANSPORT_MEDIA_FRAGMENT = gql`
  fragment transportMediaFragment on UserTransport {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const TRANSPORTS = gql`
  query TRANSPORTS($area: ID!) {
    userTransports(area: $area) {
      ...transportFragment
    }
  }
  ${TRANSPORT_FRAGMENT}
`;

const TRANSPORT = gql`
  query TRANSPORT($id: ID!) {
    userTransport(id: $id) {
      ...transportFragment
      ...transportMediaFragment
    }
  }
  ${TRANSPORT_FRAGMENT}
  ${TRANSPORT_MEDIA_FRAGMENT}
`;

const TRANSPORT_UPDATE = gql`
  mutation TRANSPORT_UPDATE($id: ID!, $transportInput: TransportInput) {
    userUpdateTransport(id: $id, transportInput: $transportInput) {
      ...transportFragment
    }
  }
  ${TRANSPORT_FRAGMENT}
`;

const TRANSPORT_ADD_MEDIA = gql`
  mutation TRANSPORT_ADD_MEDIA(
    $id: ID!
    $medias: [Upload]
    $type: TransportMediaType
  ) {
    userAddMediasToTransport(id: $id, medias: $medias, type: $type) {
      id
      ...transportMediaFragment
    }
  }
  ${TRANSPORT_MEDIA_FRAGMENT}
`;

const TRANSPORT_REMOVE_MEDIA = gql`
  mutation TRANSPORT_REMOVE_MEDIA(
    $id: ID!
    $mediaId: ID!
    $type: TransportMediaType
  ) {
    userRemoveMediaFromTransport(id: $id, mediaId: $mediaId, type: $type) {
      id
      ...transportMediaFragment
    }
  }
  ${TRANSPORT_MEDIA_FRAGMENT}
`;

const TRANSPORT_UPDATE_OSTATUS = gql`
  mutation TRANSPORT_UPDATE_OSTATUS($ids: [ID]!, $newStatus: Boolean) {
    patrolTransportChangeOriginalStatus(ids: $ids, newStatus: $newStatus)
  }
`;

export {
  TRANSPORT,
  TRANSPORTS,
  TRANSPORT_UPDATE,
  TRANSPORT_UPDATE_OSTATUS,
  TRANSPORT_ADD_MEDIA,
  TRANSPORT_REMOVE_MEDIA,
};
export type { Transport };
