import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { LOG, T, theme } from "../../config";
import {
  POI,
  POI_UPDATE,
  Poi,
  POI_REMOVE_MEDIA,
  POI_ADD_MEDIA,
} from "../../stores/queries/pois";
import { Button, EditBox, EditImage, Loading } from "../../ui";
import { transformApolloData } from "../../libs/utils";
import { useGlobalState } from "../../stores/appState";
import { ACTUAL_AUTH } from "../../stores/db/auth";
import { checkScope } from "../../libs/apollo/auth";

interface TagServicesProps {
  tagServices: string[];
}
const TagServices = (props: TagServicesProps) => {
  if (!props.tagServices) return null;
  const list = props.tagServices.map((tag: any, index: number) => {
    return (
      <div
        className="flex flex-col justify-center items-center mx-2"
        key={index}
      >
        <i className={"text-3xl icon-" + tag} />
        <span className="uppercase" style={{ fontSize: 9 }}>
          {T(tag)}
        </span>
      </div>
    );
  });
  return <>{list}</>;
};

interface PoiDetailsProps {
  id: string;
}

const PoiDetails = (props: PoiDetailsProps) => {
  const { error, data } = useQuery<{
    userPoi: Poi;
  }>(POI, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [poiUpdate, { error: updateError }] = useMutation(POI_UPDATE);

  const isRoot = useRef<boolean>(checkScope(ACTUAL_AUTH.scopes, "root"));

  const editPermission = useRef<boolean>(
    isRoot.current || checkScope(ACTUAL_AUTH.scopes, "area:edit")
  );

  const [locale, setLocale] = useGlobalState("locale");

  const actualPoi = useRef<{
    name: Poi["name"];
    description: Poi["description"];
  }>({
    name: "",
    description: {
      long: {
        it: "",
        en: "",
        de: "",
      },
      short: {
        it: "",
        en: "",
        de: "",
      },
    },
  });

  const [poiRemoveCover, { loading: removeLoading, error: removeError }] =
    useMutation(POI_REMOVE_MEDIA);

  const [poiAddCover, { error: uploadError, loading: uploadLoading }] =
    useMutation(POI_ADD_MEDIA);

  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  useEffect(() => {
    if (data?.userPoi) {
      actualPoi.current = transformApolloData({
        name: data.userPoi.name,
        description: data.userPoi.description,
      });
    }
  }, [data]);

  const update = () => {
    poiUpdate({
      variables: {
        id: props.id,
        poiInput: actualPoi.current,
      },
    });
  };

  const addCover = (files: any) => {
    poiAddCover({
      variables: {
        id: props.id,
        medias: files,
        type: "cover",
      },
    });
  };

  const removeCover = (id: string) => {
    poiRemoveCover({
      variables: {
        id: props.id,
        mediaId: id,
        type: "cover",
      },
    });
  };

  if (updateError) {
    LOG.error(updateError);
  }

  if (error || uploadError || removeError) {
    LOG.error(error || uploadError || removeError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error?.message || uploadError?.message || removeError?.message}
        </div>
      </div>
    );
  }

  if (!data?.userPoi) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  if (!isRoot) {
    if (!data?.userPoi?.permission?.isPrivate && editPermission.current) {
      editPermission.current = false;
    }
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
      >
        <div
          className="rounded-t-2xl w-full bg-white mb-0 px-6 py-4"
          style={{
            backgroundColor: theme.helpers.getTagServiceColor(
              data?.userPoi.primaryService
            ),
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center"
                style={{ width: 30, height: 30 }}
              >
                <i
                  className={"text-2xl icon-" + data?.userPoi.primaryService}
                />
              </span>
              <h6 className="text-white text-xl font-bold my-2">
                {data?.userPoi.name}
              </h6>
            </div>
            <Button
              //className={'mr-2'}
              color={"purple"}
              onClick={() => {
                //open();
              }}
            >
              <i className="fa fa-edit" /> COVER
            </Button>
            {/* {typeof data?.userPoi.status !== 'undefined' ? (
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
                style={{ fontSize: 11 }}
              >
                <span
                  className={
                    'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
                  }
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: data?.userPoi.status
                      ? theme.vars.colors.greenLight
                      : theme.vars.colors.redLight,
                  }}
                ></span>
                {T(data?.userPoi.status ? 'open' : 'closed')}
              </span>
            ) : null} */}
          </div>
        </div>
        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ overflow: "auto" }}
        >
          <form>
            {updateError && (
              <div className="p-2 mt-2 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
                {updateError.message}
              </div>
            )}
            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-base mt-3 mb-4 font-bold uppercase flex flex-row justify-between">
              {T("panoramica")}
              <div>
                <Button
                  color={locale === "it" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("it");
                  }}
                >
                  IT
                </Button>{" "}
                <Button
                  color={locale === "en" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("en");
                  }}
                >
                  EN
                </Button>{" "}
                <Button
                  color={locale === "de" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("de");
                  }}
                >
                  DE
                </Button>
              </div>
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={editPermission.current}
                value={data?.userPoi.name}
                label={T("name")}
                maxChar={30}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualPoi.current.name = value || "";
                  update();
                }}
              />
              <EditBox
                editable={false}
                value={T(data?.userPoi.primaryService)}
                label={T("typology")}
                halfCol={false}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userPoi.description.short[locale]}
                label={"Sottotitolo"}
                maxChar={80}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualPoi.current.description.short[locale] = value || "";
                  update();
                }}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userPoi.description.long[locale]}
                label={"Descrizione"}
                maxChar={800}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualPoi.current.description.long[locale] = value || "";
                  update();
                }}
              />
              <EditImage
                value={data.userPoi.media.cover.url}
                id={data.userPoi.media.cover._id}
                loading={uploadLoading || removeLoading}
                onChange={(file) => {
                  addCover(file);
                }}
                onPressRemove={(id) => {
                  removeCover(id);
                }}
                label={"Cover"}
                editable={editPermission.current}
              />
            </div>

            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("dettagli")}
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={false}
                value={data?.userPoi.detailedInfo.locality[locale]}
                label={T("locality")}
                halfCol={true}
              />
            </div>

            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("services")}
            </h6>
            <div className="flex flex-wrap px-4">
              <TagServices
                tagServices={data?.userPoi.detailedInfo?.tagServices}
              />
            </div>
          </form>
          {activeEdit && (
            <div
              className="w-full h-full absolute top-0 left-0 rounded-2xl"
              style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export { PoiDetails };
