import React, { useState } from "react";
import { ElementViews } from "../../../components/Analytics/ElementViews";
import { ACTUAL_AUTH } from "../../../stores/db/auth";
import { DateRange } from "../../../ui/DateRange";

import ImgStats from "../../../assets/img/stats.jpg";

interface BehaviorViewProps {
  route?: any;
}

const BehaviorView = (props: BehaviorViewProps) => {
  const [selRange, setSelRange] = useState({
    startDate: "2022-06-01",
    endDate: undefined,
  });

  return (
    <>
      <div className="relative pt-5">
        <div className="mx-auto w-full">
          <div className="pr-2 pl-2">
            <DateRange
              initStartDate={selRange.startDate}
              initEndDate={selRange.endDate}
              onChange={(range) => {
                let startDate = range.startDate.toISOString().split("T")[0];
                let endDate = range.endDate.toISOString().split("T")[0];
                setSelRange({ startDate, endDate });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            lineHeight={16}
            height={530}
            type="track"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Tracciati più visti"
            id={"tracks_list"}
          />
        </div>
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            lineHeight={16}
            height={530}
            type="poi"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Poi più visti"
            id={"pois_list"}
          />
        </div>
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            lineHeight={30}
            height={530}
            type="transport"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Trasporti più visti"
            id={"transports_list"}
          />
        </div>
      </div>
    </>
  );
};

export { BehaviorView };
