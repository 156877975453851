import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Notice, PATROL_NOTICES } from "../../stores/queries/notices";

const NoticeIcon = ({ status }: any) => {
  let icon: any = "patrol_management_section_standby";
  let color: any = theme.vars.colors.redLight;
  if (status === "takenOver") {
    icon = "patrol_top_bar_tacking_on";
    color = theme.vars.colors.cyan;
  }
  return (
    <div
      className="flex justify-center items-center"
      style={{ ...styles.noticeLeft, backgroundColor: color }}
    >
      <i className={"icon-" + icon} style={{ fontSize: 30 }} />
    </div>
  );
};

const NoticeItem = ({ notice }: { notice: Notice }) => {
  return (
    <div className="flex w-full flex-row py-1 px-2 justify-center items-center rounded-2xl bg-gray-100 text-white">
      <NoticeIcon status={notice.status} />
      <div style={{ alignItems: "start", justifyContent: "start" }}></div>
      <div style={{ flex: 1, justifyContent: "start" }}>
        <div className="text-sm text-gray-600 font-bold text-left">
          {notice.objectName}
        </div>
        <div className="text-sm text-gray-600 text-left font-normal">
          {notice.description[ENV.locale as "it" | "en" | "de"]}
        </div>
      </div>
    </div>
  );
};

const CardNotices = () => {
  const { loading, data } = useQuery<{
    patrolActiveNotices: Notice[];
  }>(PATROL_NOTICES, {
    fetchPolicy: "cache-and-network",
    pollInterval: 10000,
  });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl pb-2">
        <div className="rounded-t mb-0 px-1 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h2 className="text-xl font-semibold">Segnalazioni</h2>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-purple-400 text-white hover:bg-purple-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                <Link to={"/admin/area/notices"}>Gestisci</Link>
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <tbody>
              {data?.patrolActiveNotices?.map((notice, index) => {
                if (index < 4) {
                  return (
                    <tr key={"notice_" + index}>
                      <th className="border-t-0 px-2 align-middle border-l-0 border-r-0">
                        <NoticeItem notice={notice} />
                      </th>
                    </tr>
                  );
                } else return null;
              })}
            </tbody>
          </table>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  noticeLeft: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: theme.vars.colors.blue,
    marginRight: 10,
  },
  noticeIcon: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: 3,
  },
};

export { CardNotices };
