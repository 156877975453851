import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../../ui";
import { ENV, theme } from "../../config";

type baseMetric = {
  name: string;
  value: number;
};

interface CardStatsProps {
  metric: string;
  areaId?: string | null;
  areaRef?: string | null;
  areaApp?: string | null;
  platform?: string | null;
  from?: string;
  to?: string;
  title?: string;
  statDescripiron?: string;
  statIconName?: string;
  statIconColor?: string;
}

const CardStats = (props: CardStatsProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(0);

  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await axios.post<baseMetric>(
          ENV.analytics + "/base/metric",
          {
            metric: props.metric,
            area_app: props.areaApp,
            area_id: props.areaId,
            area_ref: props.areaRef,
            platform: props.platform,
            from: props.from,
            to: props.to,
          }
        );

        if (response?.data) {
          setData(response.data.value);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          return error.message;
        } else {
          console.log("unexpected error: ", error);
          return "An unexpected error occurred";
        }
      }
    };
    setLoading(true);
    getReport();
  }, [props]);

  if (!props.metric) return null;

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                {props.title}
              </h5>
              <span className="font-semibold text-xl text-blueGray-700">
                {data}
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  props.statIconColor
                }
              >
                <i className={props.statIconName}></i>
              </div>
            </div>
          </div>
          <p className="text-xs text-blueGray-400 mt-2">
            <span className="whitespace-nowrap">{props.statDescripiron}</span>
          </p>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

CardStats.defaultProps = {
  from: "2022-01-01",
  to: "today",
  title: "Statistic",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

export { CardStats };
