import React, {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Button } from './Button';

interface EditBoxProps {
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  label?: string;
  halfCol?: boolean;
  editable: boolean;
  value?: string | number;
  maxChar?: number;
  hideCharCounter?: boolean;
  unit?: string;
  customView?: ReactNode;
  customEdit?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onEnterEdit?: () => void;
  onExitEdit?: () => void;
  onPressSave?: (value?: string) => void;
  onPressCancel?: () => void;
}

const EditBox = (props: EditBoxProps) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(props.value);
  const [chartersCont, setChartersCount] = useState(
    String(props.value || '').length || 0
  );

  useEffect(() => {
    setValue(props.value ? String(props.value) : undefined);
    setChartersCount(String(props.value || '').length || 0);
  }, [props.value]);

  useEffect(() => {
    if (edit) {
      props.onEnterEdit && props.onEnterEdit();
    } else {
      props.onExitEdit && props.onExitEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <div
      className={
        'w-full lg:w-12/12 px-4 py-2 rounded-2xl ' +
        (props.editable && !edit ? 'hover:bg-white ' : '') +
        (props.halfCol ? 'lg:w-6/12' : '')
      }
      onClick={(e) => {
        if (props.editable && !edit) {
          setEdit(true);
        }
      }}
      style={{ zIndex: edit ? 99 : undefined }}
    >
      <div className={'relative w-full ' + (props.editable && 'edit-icon')}>
        {props.label && (
          <label className="block uppercase text-gray-500 text-xs font-bold mb-2">
            {props.label}
          </label>
        )}
        {props.customView ? (
          <div className="flex flex-1" hidden={edit}>
            {props.customView}
          </div>
        ) : (
          <>
            {value ? (
              <p
                className="text-gray-500 text-xs"
                style={{ whiteSpace: 'break-spaces' }}
                hidden={edit}
              >
                {value}
                {props.unit && ' ' + props.unit}
              </p>
            ) : (
              <p className="text-gray-400 text-xs" hidden={edit}>
                -
              </p>
            )}
          </>
        )}

        {props.editable && (
          <div className="flex flex-row w-full" hidden={!edit}>
            {props.customEdit ? (
              <div className="flex flex-1">{props.customEdit}</div>
            ) : (
              <textarea
                className="flex flex-1 shadow px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
                rows={1}
                value={value || ''}
                maxLength={props.maxChar}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setValue(event.target.value);
                  setChartersCount(event.target.value.length);
                  props.onChange && props.onChange(event);
                }}
              />
            )}

            <div className="flex flex-col p-2">
              <Button
                color={'purple'}
                onClick={() => {
                  setEdit(false);
                  props.onPressSave && props.onPressSave(String(value || ''));
                }}
              >
                <i className="fa fa-check" />
              </Button>{' '}
              <Button
                color={'white'}
                onClick={() => {
                  setEdit(false);
                  setValue(String(props.value || ''));
                  props.onPressCancel && props.onPressCancel();
                }}
                style={{ marginTop: 4 }}
              >
                <i className="fa fa-times" />
              </Button>
              {!props.hideCharCounter && (
                <div
                  className="w-full font-semibold text-gray-600 bg-white rounded-lg text-center"
                  style={{
                    marginTop: 8,
                    padding: 2,
                    width: 55,
                    fontSize: 9,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {chartersCont}/{props.maxChar || '-'}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditBox };
