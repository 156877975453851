/* eslint-disable no-useless-concat */
import React, { CSSProperties } from 'react';
import { T, theme } from '../../config';
import { Poi } from '../../stores/queries/pois';

interface PoiCardProps {
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  poi: Poi;
  locale: 'it' | 'en' | 'de';
}

const PoiCard = (props: PoiCardProps) => {
  if (!props.poi)
    return <div style={{ ...styles.container, ...props.style }}></div>;

  let poiColor: string = theme.helpers.getTagServiceColor(
    props.poi.primaryService
  );
  let pService: any = props.poi.primaryService;

  return (
    <div
      style={{
        ...styles.container,
        ...props.style,
        ...{ backgroundColor: poiColor },
      }}
    >
      {props.poi.media && props.poi.media.cover && props.poi.media.cover.url ? (
        <>
          <img
            alt={'Cover'}
            src={props.poi.media.cover.url}
            style={{
              ...{
                width: '100%',
                height: '100%',
                position: 'absolute',
                borderRadius: 20,
                bottom: 0,
                right: 0,
                left: 0,
                objectFit: 'cover',
                zIndex: 0,
              },
              ...props.headerStyle,
              ...props.imageStyle,
            }}
          />
          <div
            style={{
              ...{
                background:
                  'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                height: '50%',
                width: '100%',
                borderRadius: 20,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                zIndex: 0,
              },
              ...props.headerStyle,
            }}
          />
        </>
      ) : (
        <>
          {props.poi.description?.short[props.locale] ? (
            <div
              style={{
                opacity: 1,
                paddingTop: 10,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 14,
                  lineHeight: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  lineClamp: 2,
                }}
              >
                {props.poi.description.short[props.locale]}
              </div>
            </div>
          ) : (
            <div
              style={{
                opacity: 0.2,
                paddingTop: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <i className={'icon-' + pService} style={{ fontSize: 120 }} />
            </div>
          )}
        </>
      )}
      <div style={{ ...styles.header, ...props.headerStyle }}>
        <div style={styles.number}>
          <i
            className={'text-2xl icon-' + pService}
            style={{ color: poiColor }}
          />
        </div>
        <h1
          className="text-white font-black"
          style={{
            maxWidth: '70%',
            fontSize: 19,
            fontWeight: 1100,
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.poi.name || T(props.poi.primaryService)}
        </h1>
        <div style={{ flexDirection: 'row' }}></div>
      </div>
      <div style={styles.body}>
        {typeof props.poi.status === 'boolean' ? (
          <div
            style={styles.statusCont}
            className="bg-gray-600 text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
          >
            <span
              className={
                'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
              }
              style={{
                width: 10,
                height: 10,
                backgroundColor: props.poi.status
                  ? theme.vars.colors.greenLight
                  : theme.vars.colors.redLight,
              }}
            ></span>
            {T(props.poi.status ? 'open' : 'closed')}
          </div>
        ) : null}
      </div>
      {props.poi.detailedInfo?.tagServices &&
      (props.poi.detailedInfo.tagServices.length > 1 ||
        props.poi.detailedInfo.tagServices[0] !== props.poi.primaryService) ? (
        <div style={{ ...styles.footer, ...props.footerStyle }}>
          {props.poi.detailedInfo.tagServices.map(
            (service: any, index: number) => {
              if (service !== props.poi.primaryService) {
                return <i key={index} className={'text-xl icon-' + service} />;
              }
              return null;
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    borderRadius: 20,
  },
  header: {
    display: 'flex',
    flex: 4,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 1,
  },
  body: {
    display: 'flex',
    flex: 5,
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
    borderRadius: 20,
    zIndex: 1,
  },
  footer: {
    display: 'flex',
    flex: 3,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: theme.vars.colors.darkOpacity,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1,
  },
  block: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  blockLabel: {
    width: 100,
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 3,
  },
  number: {
    display: 'flex',
    borderRadius: 30 / 2,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    position: 'absolute',
    left: 5,
  },
  statusCont: {
    height: 14,
    fontSize: 9,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.vars.colors.greyDark,
    borderRadius: 5,
    paddingRight: 5,
    marginTop: -2,
  },
  statusWarn: {
    display: 'flex',
    height: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 5,
    paddingRight: 5,
  },
  status: {
    borderRadius: 5,
    width: 8,
    height: 8,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: theme.vars.colors.greenLight,
  },
};

export { PoiCard };
