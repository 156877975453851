import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";

import { LOG, T, theme } from "../../config";
import { Transport, TRANSPORT } from "../../stores/queries/transports";
import {
  getLocaleDescription,
  PATROL_REMOVE_WARNINGS,
  Warning,
} from "../../stores/queries/warnings";
import { Button, Loading } from "../../ui";
import { WarningCreate } from "..";

interface TransportWarningsProps {
  id: string;
}

const TransportWarnings = (props: TransportWarningsProps) => {
  const [openWarning, setOpenWarning] = useState<Warning | null>(null);
  const [openCreateWarning, setOpenCreateWarning] = useState<boolean>(false);

  const { error, data } = useQuery<{
    userTransport: Transport;
  }>(TRANSPORT, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    patrolTransportRemoveWarnings,
    { loading: rwLoading, error: rwError },
  ] = useMutation(PATROL_REMOVE_WARNINGS.transport, {
    refetchQueries: [
      {
        query: TRANSPORT,
        variables: {
          id: props.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const removeWarning = (warningId: string) => {
    patrolTransportRemoveWarnings({
      variables: {
        list: [
          {
            transportId: props.id,
            warningId,
          },
        ],
      },
    });
  };

  if (rwError) LOG.error(rwError);
  if (error) LOG.error(error);

  if (error || rwError) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error?.message || rwError?.message}
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 180px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  return (
    <>
      <div
        className="relative flex flex-col min-w-0 break-words w-full h-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0 overflow-hidden"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div
          className="rounded-t-2xl w-full bg-white mb-0 px-6 py-4"
          style={{
            backgroundColor: theme.helpers.getTagServiceColor(
              data?.userTransport.primaryService
            ),
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center"
                style={{ width: 30, height: 30 }}
              >
                <i
                  className={
                    "text-2xl icon-" + data?.userTransport.primaryService
                  }
                />
              </span>
              <h6 className="text-white text-xl font-bold my-2">
                {data?.userTransport.name}
              </h6>
            </div>
            <Button
              color={"purple"}
              onClick={() => {
                setOpenCreateWarning(true);
              }}
            >
              <i className="fa fa-plus" /> Crea Avviso
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-full px-4 py-4 items-center justify-between overflow-auto">
          {!data?.userTransport?.warnings ||
          data?.userTransport?.warnings?.length < 1 ? (
            <div className="font-bold w-full text-center text-gray-500">
              Non sono presenti avvisi su questo elemento
            </div>
          ) : null}
          {data?.userTransport?.warnings.map((warning, index) => {
            let desc = getLocaleDescription(warning);
            //if (!desc) return null;
            return (
              <div key={index} className={"flex flex-row w-full"}>
                <div
                  className={`               
                  ${warning.ifClosed ? " bg-red-400 " : " bg-yellow-300 "}
                  ${warning.ifClosed ? " text-white " : " text-gray-500 "}
                  relative rounded-lg shadow-md px-2 py-2 flex flex-1 flex-row items-center focus:outline-none text-white m-2
                `}
                >
                  <div>
                    <i
                      className={`text-3xl ${
                        warning.ifClosed
                          ? "icon-patrol_top_bar_trail_status"
                          : "icon-attention"
                      }`}
                    />
                  </div>
                  <div>
                    {desc} - {warning.location}
                    <br />
                    <small>
                      {T("createdBy")} {warning?.creator?.displayName}
                    </small>
                  </div>
                </div>
                <Button
                  color="white"
                  className={"m-2"}
                  onClick={() => {
                    setOpenWarning(warning);
                  }}
                >
                  <i className={`text-2xl icon-close_thin`} />
                </Button>
              </div>
            );
          })}
          {rwLoading ? (
            <div
              className="absolute top-0 left-0 flex w-full h-full items-center justify-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 5000,
              }}
            >
              <Loading color={theme.vars.colors.light} />
            </div>
          ) : null}
        </div>
      </div>
      <WarningCreate
        open={openCreateWarning}
        type={"transport"}
        elemId={props.id}
        name={data.userTransport.name}
        onCancel={() => setOpenCreateWarning(false)}
        onClose={() => setOpenCreateWarning(false)}
      />
      <AskDialog
        open={openWarning ? true : false}
        title={"Elimina l'avviso?"}
        desc={
          openWarning?.ifClosed && !data.userTransport.statusFromExternalSource
            ? "Eliminando questo avviso, tra poco, verrà riaperto il tracciato e sarà inviata una notifica a tutti gli utenti di quest'area"
            : "Nell'app, l'avviso sarà rimosso tra poco"
        }
        onCancel={() => {
          setOpenWarning(null);
        }}
        onYes={() => {
          openWarning?._id && removeWarning(openWarning._id);
          setOpenWarning(null);
        }}
      />
    </>
  );
};

const AskDialog = ({
  open,
  onCancel,
  onYes,
  title,
  desc,
}: {
  open: boolean;
  onCancel?: Function;
  onYes?: Function;
  title?: string;
  desc?: string;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex: 99999 }}
        open={open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"></div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-600"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-400">{desc}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 flex justify-around">
                <Button
                  size="md"
                  color="white"
                  className={"lg:min-w-140-px"}
                  onClick={() => {
                    onCancel && onCancel();
                  }}
                >
                  {" "}
                  {T("cancel")}
                </Button>
                <Button
                  color="red"
                  size="md"
                  className={"lg:min-w-140-px"}
                  onClick={() => {
                    onYes && onYes();
                  }}
                >
                  {" "}
                  {T("yes")}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { TransportWarnings };
