import React, { CSSProperties, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Css
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "leaflet/dist/leaflet.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/mowi-icons.css";
import "./assets/styles/tailwind.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// Layouts
import { Admin } from "./layouts/Admin";
import { Auth } from "./layouts/Auth";

// Libs
import { DB_AUTH } from "./stores/db/auth";
import { ENV, theme } from "./config";
import { Global } from "./layouts/Global";

const Root = () => {
  useEffect(() => {
    DB_AUTH.get();
  }, []);
  return (
    <BrowserRouter basename={ENV.app.basename}>
      <div
        className="absolute top-0 w-full h-full bg-gray-700 bg-no-repeat bg-full"
        style={styles.container}
      ></div>
      <Switch>
        {/* Routes root */}
        <Route path="/global" component={Global} />

        {/* Routes admin */}
        <Route path="/admin" component={Admin} />

        {/* Routes autenticazione */}
        <Route path="/auth" component={Auth} />

        {/* Redirect alla prima pagina */}
        <Redirect from="*" to="/auth" />
      </Switch>
    </BrowserRouter>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: theme.vars.colors.greyDark,
  },
};

ReactDOM.render(<Root />, document.getElementById("root"));
