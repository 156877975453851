/* eslint-disable no-useless-concat */
import React, { CSSProperties } from 'react';
import { T, theme } from '../../config';
import { Transport } from '../../stores/queries/transports';

interface TransportCardProps {
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  transport: Transport;
  locale: 'it' | 'en' | 'de';
}

const TransportCard = (props: TransportCardProps) => {
  if (!props.transport)
    return <div style={{ ...styles.container, ...props.style }}></div>;

  let transportColor: string = theme.helpers.getTagServiceColor(
    props.transport.primaryService
  );
  let pService: any = props.transport.primaryService;

  return (
    <div
      style={{
        ...styles.container,
        ...props.style,
        ...{ backgroundColor: transportColor },
      }}
    >
      {props.transport.media &&
      props.transport.media.cover &&
      props.transport.media.cover.url ? (
        <>
          <img
            alt={'Cover'}
            src={props.transport.media.cover.url}
            style={{
              ...{
                width: '100%',
                height: '100%',
                position: 'absolute',
                borderRadius: 20,
                bottom: 0,
                right: 0,
                left: 0,
                objectFit: 'cover',
                zIndex: 0,
              },
              ...props.headerStyle,
              ...props.imageStyle,
            }}
          />
          <div
            style={{
              ...{
                background:
                  'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                height: '50%',
                width: '100%',
                borderRadius: 20,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                zIndex: 0,
              },
              ...props.headerStyle,
            }}
          />
        </>
      ) : (
        <>
          <div
            style={{
              opacity: 0.2,
              paddingTop: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <i className={'icon-' + pService} style={{ fontSize: 120 }} />
          </div>
          {props.transport.description?.short[props.locale] ? (
            <div
              style={{
                opacity: 1,
                paddingTop: 10,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 12,
                  lineHeight: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  lineClamp: 2,
                  paddingTop: 10,
                }}
              >
                {props.transport.description.short[props.locale]}
              </div>
            </div>
          ) : null}
        </>
      )}
      <div style={{ ...styles.header, ...props.headerStyle }}>
        <div style={styles.number}>
          <i
            className={'text-2xl icon-' + pService}
            style={{ color: transportColor }}
          />
        </div>
        <h1
          className="text-white font-black"
          style={{
            maxWidth: '70%',
            fontSize: 19,
            fontWeight: 1100,
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.transport.name || T(props.transport.primaryService)}
        </h1>
        <div style={{ flexDirection: 'row' }}></div>
      </div>
      <div style={styles.body}>
        {typeof props.transport.status === 'boolean' ? (
          <div
            style={styles.statusCont}
            className="bg-gray-600 text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
          >
            <span
              className={
                'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
              }
              style={{
                width: 8,
                height: 8,
                backgroundColor: props.transport.status
                  ? theme.vars.colors.greenLight
                  : theme.vars.colors.redLight,
              }}
            ></span>
            {T(props.transport.status ? 'open' : 'closed')}
          </div>
        ) : (
          <>
            {typeof props.transport.originalStatus === 'boolean' ? (
              <div
                style={styles.statusCont}
                className="bg-gray-600 text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
              >
                <span
                  className={
                    'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
                  }
                  style={{
                    width: 8,
                    height: 8,
                    backgroundColor: props.transport.originalStatus
                      ? theme.vars.colors.greenLight
                      : theme.vars.colors.redLight,
                  }}
                ></span>
                {T(props.transport.originalStatus ? 'open' : 'closed')}
              </div>
            ) : null}
          </>
        )}
      </div>
      {props.transport.detailedInfo?.tagServices &&
      (props.transport.detailedInfo.tagServices.length > 1 ||
        props.transport.detailedInfo.tagServices[0] !==
          props.transport.primaryService) ? (
        <div style={{ ...styles.footer, ...props.footerStyle }}>
          {props.transport.detailedInfo.isForPedestrians ? (
            <i className={'text-xl icon-pedestrians_yes'} color={'light'} />
          ) : (
            <i className={'text-xl icon-pedestrians_no'} color={'light'} />
          )}
          {props.transport.detailedInfo.isForDisabled && (
            <i className={'text-xl icon-disabled_instructor'} color={'light'} />
          )}
          {props.transport.detailedInfo.isForSnowscoot && (
            <i className={'text-xl icon-snowscoot'} color={'light'} />
          )}
          {props.transport.detailedInfo.tagServices.map(
            (service: any, index: number) => {
              if (service !== props.transport.primaryService) {
                return <i key={index} className={'text-xl icon-' + service} />;
              }
              return null;
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    borderRadius: 20,
  },
  header: {
    display: 'flex',
    flex: 4,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 1,
  },
  body: {
    display: 'flex',
    flex: 5,
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
    borderRadius: 20,
    zIndex: 1,
  },
  footer: {
    display: 'flex',
    flex: 3,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: theme.vars.colors.darkOpacity,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    zIndex: 1,
  },
  block: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  blockLabel: {
    width: 100,
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 3,
  },
  number: {
    display: 'flex',
    borderRadius: 30 / 2,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    position: 'absolute',
    left: 5,
  },
  statusCont: {
    height: 14,
    fontSize: 9,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.vars.colors.greyDark,
    borderRadius: 5,
    paddingRight: 5,
    marginTop: -2,
  },
  statusWarn: {
    display: 'flex',
    height: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    marginLeft: 2,
    marginRight: 2,
    borderRadius: 5,
    paddingRight: 5,
  },
  status: {
    borderRadius: 5,
    width: 8,
    height: 8,
    marginLeft: 5,
    marginRight: 5,
    backgroundColor: theme.vars.colors.greenLight,
  },
};

export { TransportCard };
