import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, Tooltip } from "react-leaflet";

import { TrackCard } from "..";
import { theme } from "../../config";
import { Track, TRACK } from "../../stores/queries/tracks";
import { Loading } from "../../ui";

interface TrackMapProps {
  id: string;
}

const TrackMap = (props: TrackMapProps) => {
  const { error, data } = useQuery<{
    userTrack: Track;
  }>(TRACK, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [trackPath, setTrackPath] = useState<any>(null);

  const gjObject = useRef<any>(null);

  useEffect(() => {
    let path = null;
    if (data?.userTrack?.geoLocation?.coordinatesLineString?.length) {
      path = [
        {
          type: "LineString",
          coordinates: data?.userTrack.geoLocation.coordinatesLineString,
        },
      ];
    } else if (
      data?.userTrack?.geoLocation?.coordinatesMultiLineString &&
      data?.userTrack?.geoLocation?.coordinatesMultiLineString[0].length
    ) {
      path = [
        {
          type: "LineString",
          coordinates:
            data?.userTrack.geoLocation.coordinatesMultiLineString[0],
        },
      ];
    }
    //console.log(data?.userTrack.geoLocation);
    setTrackPath(path);
  }, [data?.userTrack.geoLocation]);

  if (error) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error.message}
        </div>
      </div>
    );
  }

  if (!trackPath) {
    return (
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 180px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  return (
    <>
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 overflow-hidden"
        style={{ height: "calc(100vh - 170px)", transform: "translateZ(0)" }}
      >
        <MapContainer
          center={[46.169820718028596, 11.008665122087717]}
          zoom={13}
          scrollWheelZoom={false}
          className="relative break-words w-full rounded-2xl border-0"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          whenCreated={(map) => {
            setTimeout(() => {
              if (gjObject.current && gjObject.current.getBounds) {
                let bounds = gjObject.current.getBounds();
                if (bounds._northEast) {
                  map.fitBounds(bounds);
                }
              }
            }, 200);
          }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON
            ref={gjObject}
            data={trackPath}
            style={(geoJsonFeature) => {
              return {
                color: theme.helpers.getTypologyColor(
                  data?.userTrack.typology || "default"
                ),
                weight: 5,
              };
            }}
          >
            <Tooltip sticky>
              <b>{data?.userTrack.name}</b>
            </Tooltip>
          </GeoJSON>
          <div
            className="absolute shadow-xl"
            style={{
              position: "absolute",
              bottom: 18,
              left: 0,
              marginLeft: "calc(50% - 140px)",
              marginRight: "auto",
              width: 280,
              height: 110,
              backgroundColor: theme.vars.colors.greyDark,
              borderRadius: 20,
              zIndex: 999,
              alignSelf: "center",
            }}
          >
            <TrackCard track={data?.userTrack as Track} />
          </div>
        </MapContainer>
      </div>
    </>
  );
};

export { TrackMap };
