/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import ImgLogo from "../../assets/img/loghi/MowiBusinessWhite.png";
import { ENV, __DEV__ } from "../../config";
import { checkScope } from "../../libs/apollo/auth";
import { ACTUAL_AUTH } from "../../stores/db/auth";

const LinkItem = ({ uri, title }: { uri: string; title: string }) => {
  return (
    <Link
      className={
        "text-xs uppercase py-3 font-bold block " +
        (window.location.href.indexOf(uri) !== -1
          ? "text-purple-500 hover:text-purple-700"
          : "text-gray-500 hover:text-gray-700")
      }
      to={uri}
    >
      <i
        className={
          "fas fa-minus mr-2 text-xs " +
          (window.location.href.indexOf(uri) !== -1
            ? "opacity-75"
            : "text-gray-400")
        }
      ></i>{" "}
      {title}
    </Link>
  );
};

const Sidebar = () => {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav
        style={{ backgroundColor: "#fff" }}
        className=" md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-lg bg-white flex flex-wrap items-center justify-between relative md:w-60 z-10 py-4 px-6"
      >
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="flex flex-col items-center justify-center">
            <img
              alt="Mowi Business"
              src={ImgLogo}
              style={{
                filter: "invert(0.7)",
                width: 100,
                marginRight: 10,
                marginBottom: 4,
              }}
              className="h-auto align-middle border-none opacity-90"
            />
            <small className="hidden md:block text-gray-500">
              {ENV.app.version}
              {__DEV__ && "-test"}
            </small>
          </div>

          {/* Buttons */}
          <ul className="md:hidden items-center flex flex-wrap list-none"></ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <h6 className="md:block text-left md:pb-2 text-gray-500 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">
                    Mowi Business
                  </h6>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <hr className="mb-4 md:min-w-full" />

            <h6
              onClick={() => setCollapseShow("hidden")}
              className="md:min-w-full text-gray-400 text-xs uppercase font-bold block no-underline"
            >
              <LinkItem title="Dashboard" uri={"/admin/home/dashboard"} />
            </h6>

            <h6
              onClick={() => setCollapseShow("hidden")}
              className="md:min-w-full text-gray-400 text-xs uppercase font-bold block no-underline"
            >
              <LinkItem title="WebApp e Links" uri={"/admin/home/app"} />
            </h6>

            <hr className="my-4 md:min-w-full" />

            <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Analytics
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <LinkItem
                  title="Panoramica Live"
                  uri={"/admin/analytics/events"}
                />
              </li>
              <li className="items-center">
                <LinkItem
                  title="Comportamento"
                  uri={"/admin/analytics/behavior"}
                />
              </li>
              <li className="items-center">
                <LinkItem title="Pubblico" uri={"/admin/analytics/public"} />
              </li>
              <li className="items-center">
                <LinkItem
                  title="Acquisizione"
                  uri={"/admin/analytics/acquisition"}
                />
              </li>
            </ul>

            <hr className="my-4 md:min-w-full" />

            {checkScope(ACTUAL_AUTH.scopes, "area:edit") && (
              <>
                <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  Messages
                </h6>
                <ul
                  onClick={() => setCollapseShow("hidden")}
                  className="md:flex-col md:min-w-full flex flex-col list-none"
                >
                  <li className="items-center">
                    <LinkItem
                      title="Notifiche Push"
                      uri={"/admin/messages/notifications"}
                    />
                    <LinkItem
                      title="Messaggi inapp"
                      uri={"/admin/messages/inappmessages"}
                    />
                  </li>
                </ul>
                <hr className="my-4 md:min-w-full" />
              </>
            )}

            <h6 className="md:min-w-full text-gray-400 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Area
            </h6>
            <ul
              onClick={() => setCollapseShow("hidden")}
              className="md:flex-col md:min-w-full flex flex-col list-none"
            >
              <li className="items-center">
                <LinkItem title="tracciati" uri={"/admin/area/tracks"} />
                <LinkItem title="trasporti" uri={"/admin/area/transports"} />
                <LinkItem title="servizi" uri={"/admin/area/pois"} />
                {ACTUAL_AUTH.app !== "ski" ? (
                  <>
                    <LinkItem
                      title="segnalazioni"
                      uri={"/admin/area/notices"}
                    />
                    {checkScope(ACTUAL_AUTH.scopes, "area:edit") && (
                      <LinkItem title="patrols" uri={"/admin/area/patrols"} />
                    )}
                  </>
                ) : null}
                {/* {checkScope(ACTUAL_AUTH.scopes, 'area:edit') && (
                  <LinkItem title="brand" uri={'/admin/area/brand'} />
                )} */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export { Sidebar };
