const Platform = {
  OS: 'ios',
};

const app = 'mowi';
const appName = 'mowi';
const trackName = 'Track';
const tracksName = 'Tracks';

type lang = { [key: string]: string };

const general: lang = {
  yes: 'Ja',
  no: 'Nein',
  back: 'Zurück',
  go: 'Start',
  from: 'von',
  fromDay: 'Von',
  minutes: 'Min',
  hours: 'Stunden',
  hoursAgo: 'Std zuvor',
  minAgo: 'Min zuvor',
  to: 'zu',
  and: 'und',
  sureAsk: 'Sind Sie sicher?',
  cancel: 'Abbrechen',
  accept: 'Zustimmen',
  acceptInvite: 'Einladung annehmen',
  remove: 'Entfernen',
  delete: 'Löschen',
  restore: 'Aktualisieren',
  requestSent: 'Anfrage gesendet',
  createdBy: 'Erstellt von',
  list: 'Liste',
  requests: 'Anfragen',
  archive: 'Datei',
  outOf: 'nach oben',
  now: 'jetzt',
  start: 'starten',
  stop: 'stoppen',
  track: trackName,
  tracks: tracksName,
  services: 'Dienstleistungen',
  Services: 'Dienstleistungen',
  Typologies: 'Typen',
  group: 'Gruppe',
  groups: 'Gruppen',
  transports: 'Seilbahn',
  filters: 'Filter',
  type: 'Typ',
  unknown: 'andres',
  refresh: 'Aktualisieren',
  next: 'Weiter',
  name: 'Name',
  welcome: 'Willkommen bei ' + appName + ' ;)',
  readyToGo: 'Bereit loszufahren?',
  insertDisplayName:
    'Geben Sie Ihren Namen oder Nickname ein, damit andere Mowi-Benutzer Sie finden können',
  otherInfo: 'Weitere Informationen',
  otherInfoHint:
    'Diese Angaben sind nicht verpflichtend, können uns aber helfen, Ihr Erlebnis zu personalisieren!',
  displayName: 'Angezeigter Name',
  birthYear: 'Geburtsjahr',
  privacyAd: 'Mowi kümmert sich um die Privatsphäre seiner Nutzer.',
  privacyShow: 'Lesen Sie unsere Datenschutzrichtlinien',
  admin: 'Admin',
  waitAgain: 'Bitte warten Sie eine kleine Weile…',
  outOfMap: 'AAußerhalb Karte',
  outOfMapHint:
    'Sie müssen sich physisch auf Altopiano della Paganella befinden, um alle ' +
    appName +
    '-Funktionen nutzen zu können!',
  open: 'Geöffnet',
  closed: 'Geschlossen',
  offline: 'Sie sind offline',
  close: 'Schließen',
  continue: 'Weiter',
  rememberLater: 'Später erinnern',
  edit: 'Bearbeiten',
  create: 'Erstellen',
  add: 'Hinzufügen',
  save: 'Speichern',
  position: 'Standort',
  active: 'aktiv',
  set: 'Einstellen',
  motionAndFitness: 'Bewegung und Fitness',
  energySaver: 'Energiesparen',
  backgroundUpdate: 'Update im Hintergrund',
  maintenance: 'App wird gerade gewartet!',
  maintenanceHint:
    'Sorry, wir reparieren einige Funktionen der App, versuchen Sie es in ein paar Minuten erneut!',
  disabled: 'deaktiviert',
  continueAnyway: 'Trotzdem fortfahren',
};

const genericErrors: lang = {
  OPS: 'HUUCH!',
  ops: 'Huch! Unerwarteter Fehler!',
  opsServer: 'Huch! Server-Fehler',
  goBackSorry: 'Tut uns leid, gehen Sie zurück und versuchen Sie es erneut :(',
  tryLaterSorry: 'Tut uns leid, versuchen Sie es später noch einmal :(',
  checkConnection: 'Prüfen Sie Ihre Verbindung',
  retry: 'Versuchen Sie es erneut',
};

const localities: lang = {
  andalo: 'Andalo',
  molveno: 'Molveno',
  faiDellaPaganella: 'Fai della Paganella',
  cavedago: 'Cavedago',
  spormaggiore: 'Spormaggiore',
  ValDiSole: 'Val di Sole',
  ValDiPejo: 'Val di Pejo',
  ValDiPeio: 'Val di Peio',
  ValDiRabbi: 'Val di Rabbi',
  TonalePonteDiLegno: 'Tonale - Ponte di Legno',
};

const categories: lang = {
  bike_trails: 'Rad-Routen',
  bike_tours: 'Fahrrad-Touren',
  bike_gravity: 'Bike Gravity',
  alpine_skiing: 'Skifahren',
  skimo: 'Ski-Bergsteigen',
  crosscountry_skiing: 'Skilanglauf',
  snow_shoes: 'Schneeschuhwandern',
  sleeding: 'Rodeln',
  trekking: 'Trekking',
};

const trackTypologies: lang = {
  ut: 'Enduro-Tour',
  fa: 'Familie',
  xc: 'Cross-Country',
  en: 'Gemeinsame Strecke',
  dh: 'Bike Park',
  ut_min: 'ET',
  fa_min: 'FA',
  xc_min: 'XC',
  en_min: 'ST',
  dh_min: 'BP',
  blue: 'Blaue Pisten',
  red: 'Rote Pisten',
  black: 'Schwarze Pisten',
  snowPark: 'Schneepark',
  firstSteps: 'Erste Schritte',
  cross_green: 'Grüne Pisten',
  cross_blue: 'Blaue Pisten',
  cross_red: 'Rote Pisten',
  cross_black: 'Schwarze Pisten',
};

const difficulties: lang = {
  peaceful: 'sehr leicht',
  easygoing: 'leicht',
  halfway: 'mittelschwer',
  challenging: 'anspruchsvoll',
  rocky: 'schwierig',
  adrenaline: 'extrem',
  beginner: 'Anfänger',
  intermediate: 'Zwischenstufe',
  advanced: 'Experte',
  beginner_level: 'Anfänger',
  intermediate_level: 'Zwischenstufe',
  advanced_level: 'Experte',
  blue_level: 'Anfänger',
  red_level: 'Zwischenstufe',
  black_level: 'Experte',
  snowPark_level: 'Schneepark',
  firstSteps_level: 'Einstieg',
  cross_green_level: 'Einstieg',
  cross_blue_level: 'Anfänger',
  cross_red_level: 'Zwischenstufe',
  cross_black_level: 'Experte',
};

const tagServices: lang = {
  aerialway_chair_lift: 'Sessellift',
  bicycle_bus_stop: 'Bushaltestelle',
  parking: 'Parkplatz',
  ski_bus_station: 'Skibus-Haltestelle',
  bicycle_rent: 'Fahrradverleih',
  fountain: 'Springbrunnen',
  shower: 'Duschen',
  toilette: 'Toilette',
  point_danger: 'Gefahr',
  point_forbidden: 'Betreten/Überqueren verboten',
  point_warning: 'Warnung',
  sleep: 'Sleep',
  charging_station: 'Ladestationen',
  kids_trail: 'Kids trail',
  kinderclub: 'Kinderclub',
  pharmacy: 'Apotheke',
  track_pump: 'Track-Pumpe',
  outdoor_school: 'Schule',
  school: 'Schule',
  aerialway_gondola: 'Seilbahn',
  aerialway_lift: 'Seilbahn',
  aerialway_station: 'Seilbahnstation',
  historical_point: 'Historischer Ort',
  panorama: 'Aussichtspunkt',
  alpine_hut: 'Berghütte',
  alpine_hut_sponsored: 'Prima Hütte',
  food: 'Essen',
  restaurant: 'Gaststätte',
  my_hotel: 'Mein Hotel',
  bicycle_repair: 'Fahrrad-Werkstatt',
  bicycle_wash: 'Fahrrad waschen',
  rent: 'Verleih',
  sell: 'Geschäft',
  shop: 'Geschäft',
  shop_bicycle: 'Fahrradladen',
  doctor: 'Arzt',
  tourist_attraction: 'Attraktion',
  atm: 'GELDAUTOMAT',
  bicycle_sell: 'Fahrradladen',
  repair_kit: 'Reparaturwerkstatt',
  shop_ticket: 'Ticketschalter',
  info_point: 'Info-Punkt',
  info_point_outdoor: 'Info-Punkt',
  info_point_panel: 'Infotafel',
  info_point_panel_ar: 'Infotafel AR',
  info_point_sponsored: 'Info-Punkt',
  alpineskiing_gear: 'Ski-Ausrüstung',
  andalo_life: 'Andalo Life',
  animals_no: 'Haustiere verboten',
  animals_yes: 'Haustiere willkommen',
  baby_backpack: 'Babytrage',
  bus: 'Bus',
  crampons: 'Steigeisen',
  crosscountry_skiing_gear: 'Langlauf-Ausrüstung',
  disabled_instructor: 'Behinderten-Skilehrer',
  googles_and_mask: 'Masken und Schutzbrillen',
  gym: 'Fitnessstudio',
  handicap_yes: 'Geeignet für Behindertensportler',
  helmet: 'Helme',
  ice_skating: 'Schlittschuhlaufen',
  instructor_meeting_point: 'Lehrer-Treffpunkt',
  kart: 'Kart',
  party: 'Party',
  pedestrians_no: 'Verbot für Fußgänger',
  pedestrians_yes: 'Fußgänger erlaubt',
  riding_school: 'Reitschule',
  ski_boots: 'Skischuhe',
  ski_instructor: 'Skilehrer',
  ski_storage: 'Ski-Depot',
  skimo_gear: 'Bergsportausrüstung',
  snow_clothing: 'Schneebekleidung',
  snow_shoes_gear: 'Schneeschuh-Ausrüstung',
  snow_tubing: 'Snowtubing',
  snowboard_gear: 'Snowboard-Ausrüstung',
  snowboard_instructor: 'Snowboard-Lehrer',
  snowscoot: 'Schneescooter',
  snowscoot_gear: 'Schneescooter-Ausrüstung',
  spa: 'Wellness-Zentrum',
  swimming_pool: 'Pool',
  train: 'Zug',
};

const tagFilters = {
  aerialway: 'Seilbahnen',
  bicycle_info: 'Info-Punkt',
  bike_shops: 'Fahrradläden',
  bicycle_facilities: 'Fahrrad-Services',
  discover: 'Discover',
  eat_sleep: 'Berghütten',
  public_utility: 'Dienste',
  trails_alert: 'Info-Alarm',
  skill_area: 'Skill-Bereich',
  ski_shops: 'Skiläden',
  ski_info: 'Info-Punkt',
  my_hotel: 'Mein Hotel',
  ski_school: 'Skischule',
};

const appInit = {
  loadInit: appName + 'Starten',
  loadFonts: 'Schriftarten initialisieren',
  loadFontsErr: 'Schriftarten können nicht geladen werden',
  loadCache: 'Cache-Daten initialisieren',
  loadAuth: 'Authentifizierungsprüfung',
  loadAuthErr: 'Gerät kann nicht authentifiziert werden',
  loadClient: 'Client erstellen', // device not customer
  loadClientErr: 'Client kann nicht angelegt werden',
  loadCheckBundle: 'Datenbündel-Prüfung',
  loadCheckBundleErr: 'Update erforderlich',
  installUpdate: 'App aktualisieren',
  loadCheckBundleAsk:
    'Sie benötigen die neue Version von ' + appName + ' um fortzufahren!',
  loadDownloadBundle: 'Neues Datenpaket herunterladen',
  loadCacheBundle: 'Datenpaket aus Cache laden',
  loadBundleErr: 'Datenpaket kann nicht geladen werden',
  loadFilters: 'Kartenfilter setzen',
  loadFiltersErr: 'Fehler beim Filternladen',
  loadFileServe: 'Statischen Dateiserver starten',
  loadFileServeErr: 'Statischer Dateiserver kann nicht gestartet werden',
  loadModel: 'Kartenvorlage erstellen',
  insertTrails: 'Routen auf Karte hochladen',
  loadComplete: 'Laden abgeschlossen',
  loadMapError: 'Sorry, Fehler beim Karteladen',
};

const login: lang = {
  login: 'Einloggen',
  logout: 'Abmelden',
  logoutExt: 'Benutzer abmelden',
  signup: 'Anmelden',
  fbBtn: 'Weiter mit Facebook',
  mailBtn: 'Weiter mit E-Mail',
  googleBtn: 'Weiter mit Google',
  newAccount: 'Erstellen Sie ein neues Mowi Space-Konto!',
  loginAccount: 'Melden Sie sich mit Ihrem Mowi Space-Konto an!',
  passwordHint: 'Ihr Passwort muss mindestens 8 Zeichen enthalten',
  passwordForgot: 'Passwort vergessen?',
  acceptAndregister: 'Zustimmen und registrieren',
  userPassNull: 'Benutzername und Passwort erforderlich!',
  userPassErr: 'Falscher Benutzername oder Passwort',
  passShort: 'Ihr Passwort ist zu kurz!',
  userInfoNull: 'Entschuldigung, Ihr Anzeigename ist obligatorisch!',
  wrongYear: 'Falsches Geburtsjahr!',
  forbiddenName: 'Netter Versuch! Name ändern! ;)',
  moreThan13: 'Sie müssen 13+ sein, um sich anzumelden!',
  emailNotVerified: 'E-Mail nicht verifiziert!',
  userDataNotComplete: 'Benutzerdaten nicht vollständig!',
  loginSuccess: 'Anmeldung erfolgreich',
  verifyEmail: 'Email prüfen',
  completeProfile: 'Vervollständigen Sie Ihr Profil',
  recoverPassword: 'Passwort zurücksetzen',
  checkEmail: 'Prüfen Sie Ihre E-Mail!',
  mailLink:
    'Wir haben Ihnen den Link zum Zurücksetzen Ihres Passworts geschickt!',
  recoverPasswordHint:
    'Geben Sie die E-Mail ein, mit der Sie sich angemeldet haben',
  recoverYourPassword: 'Passwort zurücksetzen',
  resendVerify: 'Haben Sie Ihren Code nicht erhalten? Fordern Sie ihn hier an',
};

const privacyRequest: lang = {
  privacy: 'Datenschutz',
  canContact: 'Dürfen wir Sie kontaktieren?',
  weUpdateYouOn: 'Wir würden Sie gerne über informieren:',
  reason1: 'Status neuer und bestehender Routen',
  reason2: 'Neue App-Funktionen',
  reason3: 'Neuigkeiten und Veranstaltungen in der Region',
  reason4: 'Einige Tipps, um das Beste aus ' + appName + ' herauszuholen',
};

const profile: lang = {
  bornWhen: 'Geboren in',
  male: 'männlich',
  female: 'weiblich',
  country: 'Land',
  sex: 'Geschlecht',
  myProfile: 'Mein Profil',
  profilePhoto: 'Profilbild',
  photoPermissions:
    appName +
    ' kann nicht auf Ihre Fotos zugreifen; gehen Sie zu den App-Einstellungen, um die Berechtigungen zu ändern',
};

const friends: lang = {
  friend: 'Freund',
  myFirends: 'Meine Freunde',
  isFriend: 'Sie sind bereits befreundet',
  revokeFriendship: 'Freundschaft entfernen',
  requestFriendship: 'Freundschaft anfragen',
  searchHereFriend: 'Hier einen neuen Freund finden',
  addFriend: 'Freund hinzufügen',
  friendSearchNull: 'Kein Benutzer mit diesem Namen gefunden',
  friendsRequestsNull: 'Keine Freundschaftsanfrage erhalten',
  friendsNull: 'Sie haben noch keine Freunde,\nsuchen Sie sie nach Namen!',
  friendsNullGroup:
    'Sie haben noch keine Freunde, fügen Sie einige hinzu, um Gruppen zu erstellen!',
  friendIsInGroup: 'Sie sind bereits Mitglied in dieser Gruppe',
};

const group: lang = {
  groupDetail: 'Details zur Gruppe',
  groupNew: 'Mitglieder hinzufügen',
  groupNoActives: 'Keine aktiven Gruppen',
  partecipate: 'Mitglied werden',
  ongoing: 'Läuft gerade',
  scheduled: 'Geplant',
  partecipatingNow: 'Sie sind jetzt dabei',
  partecipatingNowHe: 'nimmt daran teil',
  partecipatingNot: 'Sie nehmen nicht teil',
  partecipatingNotHe: 'nimmt nicht daran teil',
  partecipatingBefore: 'Du hast an diesem Gruppen teilgenommen',
  onlyOnePartecipation:
    'SIE KÖNNEN NUR IN EINER GRUPPE GLEICHZEITIG TEILNEHMEN',
  components: 'Mitglieder',
  startAt: 'Start um',
  activeFor: 'Aktiv für',
  overAt: 'Abgeschlossen am',
  inactive: 'Inaktiv',
  moving: 'umziehend',
  still: 'stationär',
  partecipants: 'Teilnehmer',
  groupList: 'Gruppenliste',
  createNew: 'Neue Gruppe erstellen',
  createHang: 'Gruppe erstellen',
  groupNoLogin:
    'Melden Sie sich bei ' +
    appName +
    ' an, um Gruppen zu erstellen und an ihnen teilzunehmen!',
  noGroups:
    'Sie sind in keiner Gruppe; erstellen Sie jetzt eine und fügen Sie Ihre Freunde hinzu!',
  groupNamePlaceholder: 'Gruppe unter Freunden, Familie, etc.',
  groupDescPlaceholder: 'Treffpunkt, Gruppenzweck, Zeiten, etc.',
  groupFriendSelect: 'Suche unter Ihren Freunden',
  removeUser: 'Mitglied entfernen',
  groupDelete: 'Gruppe löschen',
  groupDismiss: 'Gruppe verlassen',
  groupPhoto: 'Gruppenbild',
  title: 'Titel',
  description: 'Beschreibung',
  promote: 'Admin machen',
  addComponents: 'Mitglieder hinzufügen',
  editGroup: 'Gruppe bearbeiten',
  groupPermissionsTitle:
    'Damit Gruppen ordnungsgemäß funktionieren, müssen Sie ' +
    appName +
    ' diese Berechtigungen gewähren',
  groupPermissionsPosition:
    'Um den Standort mit Gruppenmitgliedern zu teilen, auch wenn die App nicht verwendet wird, stellen Sie ihn auf „Immer“.',
  groupPermissionsMotion:
    'Verfolgen Sie Ihre Bewegungen und sparen Sie den Akku, indem Sie das Senden vermeiden, wenn Sie stillstehen',
  groupPermissionsBattery:
    Platform.OS === 'ios'
      ? 'Deaktivieren Sie den Energiesparmodus, damit ' +
        appName +
        ' auch dann kommunizieren kann, wenn Sie die App nicht verwenden: Wir kümmern uns darum, Ihren Akku zu schützen.'
      : 'Wir empfehlen, die Akkuoptimierung zu deaktivieren, um sicherzustellen, dass die Gruppen ordnungsgemäß arbeiten können: Wir kümmern uns darum, Ihren Akku zu schützen.',
  groupPermissionsBackground:
    'Aktivieren Sie die Hintergrundaktualisierung der App, damit ' +
    appName +
    ' auch dann kommunizieren kann, wenn Sie die App nicht nutzen',
  preciseLocation: 'Genauer Standort',
  groupPermissionsPreciseLocation:
    'Teilen Sie Ihren genauen Standort mit, damit er in einer Gruppe angezeigt wird: Klicken Sie auf  „Aktivieren“ , um dies vorübergehend anzufordern oder ändern Sie Ihre Einstellungen, um es dauerhaft zu aktivieren',
  partecipating: 'Sie nehmen an einer Gruppe teil',
};

const userMenu: lang = {
  yourFriends: 'Ihre Freunde',
  newRequests: 'Neue Anfragen',
  yourGroups: 'Ihre Gruppen',
  showTutorialAgain: 'Tutotial noch mal schauen',
  credits: 'Credits',
  settings: 'Einstellungen',
  advancedSettings: 'Erweiterte Einstellungen',
};

const elements: lang = {
  infoView: 'Übersicht',
  details: 'Details',
  locality: 'Standort',
  info: 'Info',
  media: 'Medien',
  reviews: 'Kommentare',
  notice: 'Warnungen',
  cardPressDetails: 'Klicken Sie auf die Karte, um Details zu erfahren',
};

const tracks: lang = {
  trackInfoErr: 'Huch, keine Daten für diese ' + trackName + ' vorhanden',
  trackMediaErr:
    'Noch keine Fotos oder Videos für diese ' + trackName + ' vorhanden!',
  IMBA: 'IMBA',
  artificial: 'künstlich',
  natural: 'natürlich',
  startStation: 'Start',
  endStation: 'Ziel',
  difficulty: 'Schwierigkeitsgrad',
  length: 'Länge',
  duration: 'Dauer',
  altimetryPlus: 'Aufstieg',
  altimetryMinus: 'Abstieg',
  maxAltitude: 'Max. Höhe',
  minAltitude: 'Min. Höhe',
  technicalAbility: 'Technische Schwierigkeit',
  physicalEfford: 'Körperliche Anstrengung',
  emotion: 'Emotion',
  landscape: 'Landschaft',
  suggestedPeriod: 'Beste Zeit',
  isCircular: 'Rundroute',
  isFamily: 'Geeignet für Familien und Kinder',
  howToArrive: 'Wie man dorthin kommt:',
  trackFilterNull: 'Keine Route passend zu den eingestellten Filtern!',
  maxSlope: 'Max Steigung',
  loginTrackHint:
    'Loggen Sie sich ein, um Live-Warnungen zu bekommen oder erstellen Sie jetzt ein kostenloses Mowi Space-Konto!',
};

const pois: lang = {
  poiInfoErr: 'Huch! Keine Daten für diesen Service verfügbar',
  poiMediaErr:
    'Es sind noch keine Fotos oder Videos für diesen Dienst verfügbar!',
  altitude: 'Höhenlage',
  poiFilterNull:
    'Keine Dienste, die den von Ihnen gesetzten Filtern entsprechen!',
};

const transports: lang = {
  pedestrians_yes: 'Fußgänger erlaubt',
  pedestrians_no: 'Verbot für Fußgänger',
  isForDisabled: 'Zugänglichkeit für Behinderte',
  isForSnowscoot: 'Schneescooter erlaubt',
  transportInfoErr: 'Huch! Keine Daten für diese Anlage vorhanden',
  transportMediaErr: 'Noch keine Fotos oder Videos für diese Anlage!',
  transportFilterNull:
    'Keine Skilifts, die den von Ihnen gesetzten Filtern entsprechen!',
};

const intro: lang = {
  begin: 'Starten',
  nextSlide: 'Weiter',
  slideToBegin: 'Zum Starten an das Ende scrollen',
  useTerm: 'Nutzungsbedingungen',
  privacyPolicy: 'Datenschutzrichtlinie',
  iAgree: 'Ich stimme zu',
  iDoNotAgree: 'Ich stimme nicht zu',
  maybeLater: 'Vielleicht später',
  pressConsent: 'Drücken Sie "Ich stimme zu" um unsere zu akzeptieren',
  sureDeny: 'Zustimmung verweigern',
  abortDeny: 'Nein, zurückgehen',
  denyAlert: 'Auf diese Weise können Sie ' + appName + ' nicht nutzen',
  welcomeTitle: 'Welcome\nbei ' + appName,
  welcomeP1:
    'Schöpfen Sie das Potenzial unserer App voll aus und genießen Sie jeden Moment',
  welcomeP2:
    'Wir hoffen, dass Sie eine tolle Erfahrung mit uns haben werden ;)',
  privacyLockTitle: 'Ihre Privatsphäre\nist wichtig',
  privacyLockP1:
    'Der Schutz Ihrer Privatsphäre ist für Mowi sehr wichtig. Deshalb möchten wir Sie darüber informieren, welche Daten wir verwenden und wie wir sie nutzen.',
  privacyUserTitle: 'Persönliche Daten',
  privacyUserP1:
    'Während der Nutzung der App fragen wir Sie möglicherweise nach einigen Ihrer Angaben, z. B. nach Ihrem Standort, damit Sie sich auf der Karte in der Umgebung lokalisieren können, oder nach anderen Informationen, wenn Sie sich anmelden.',
  privacyStarTitle: 'Um Ihr Erlebnis\nzu verbessern',
  privacyStarP1:
    'Wir sind bestrebt, unsere Dienste zu verbessern, um Ihnen immer die bestmögliche Erfahrung anzubieten. Zu diesem Zweck sammeln wir in völlig anonymer Weise einige statistische und Nutzungsdaten der App.',
  privacyAlertTitle: 'Ich möchte Updates bekommen',
  privacyAlertP1:
    'Wir werden Sie über großartige neue Mowi-Funktionen, etwas Nützliches oder einfach nur Cooles mit Ihnen teilen.',
  privacyAlertP2:
    Platform.OS === 'ios'
      ? 'Sie werden um Ihre Zustimmung zum Erhalt von Benachrichtigungen gebeten: Sie können Ihre Benachrichtigungen immer anpassen, indem Sie auf die kleine Glocke am oberen Rand klicken'
      : 'Sie können Ihre Benachrichtigungen immer anpassen, indem Sie auf die kleine Glocke am oberen Rand klicken',
  privacyGroupTitle: 'Live-Gruppen',
  privacyGroupP1:
    'Teilen Sie Ihren Standort mit einer Gruppe von Freunden oder Familienmitgliedern. Sie können entscheiden, wann Sie die Standortfreigabe aktivieren oder deaktivieren möchten, indem Sie auf die Schaltfläche „Teilnehmen/Stop“ klicken',
  privacyGroupP2:
    'Aus diesem Grund benötigen wir Ihre Zustimmung zur Nutzung des Standorts auch dann, wenn Sie die App nicht verwenden (im Hintergrund) sowie Bewegungsdaten: Sie teilen Ihren Standort nur mit Nutzern in der Gruppe, in der Sie Mitglied sind',
  privacyMarketingTitle: 'Neuigkeiten aus Ihrem\n' + app + ' Gebiet',
  privacyMarketingP1:
    'Ich möchte über Skilift- und Pistenöffnungen und -schließungen informiert werden',
  privacyMarketingP2:
    'Erhalten Sie Benachrichtigungen, Neuigkeiten, Aktionen und Veranstaltungen aus Ihrem ' +
    app +
    ' Gebiet',
  privacyMarketingP3:
    'Um Ihnen nützliche und relevante Mitteilungen zu senden, müssen wir möglicherweise Ihren ' +
    app +
    ' Gebiet anhand Ihres Standorts erkennen',
  privacyMarketingP4:
    'Sie können Ihre Benachrichtigungseinstellungen jederzeit über Ihr Profil ändern',
  selectAreaTitle: 'Wählen Sie den Bereich\nfür den Sie sich interessieren',
  selectAreaP1:
    '*' +
    appName +
    ' ist derzeit für das Gebiet Paganella ' +
    app +
    ' verfügbar\nNeue fantastische Ziele kommen bald!',
  smartNoti: 'Smart Selection',
  smartNotiP:
    'Erlauben Sie Mowi, Ihre Benachrichtigungen so auszuwählen, dass Sie nur die erhalten, an denen Sie wirklich interessiert sind, z. B. das Senden von Warnungen, Ereignissen und Track-/Anlagenstatus nur dann, wenn Sie sich tatsächlich in dem Gebiet befinden',
};

const find: lang = {
  whatNeed: 'Wonach\nsuchen Sie?',
  trailsProgression: 'Routen Liste',
  aerialway_lifts: 'Seilbahnen',
  alpine_huts: 'Berghütten',
  charging_stations: 'Ladestationen',
  rent_and_shops: 'Fahrrad-Verleihe und -Läden',
  repairs_mtb: 'Fahrrad-Werkstätten',
  find: 'Suchen',
  trailDetail: 'Route Detail',
  serviceDetail: 'Service-Detail',
  rentAndSchoolSki: 'Verleih und Skischulen',
};

const notifications: lang = {
  notifications: 'Benachrichtigungen',
  readAll: 'Alles lesen',
  notiCallToLoginTitle:
    'Fühlen Sie sich frustriert, wenn Sie an einem Lift oder einer ' +
    trackName +
    ' ankommen und feststellen, dass sie geschlossen sind?',
  notiCallToLoginDesc:
    'Wenn Sie noch keines haben, erstellen Sie Ihr Mowi Space-Konto, um Benachrichtigungen über den Status von Skipisten, Anlagen und Skilifts in Echtzeit zu erhalten!',
  noNotifications: 'Keine Benachrichtigungen zu lesen...',
  notiSettings: 'Einstellungen für Benachrichtigungen',
  notiSettingsTitle:
    'Wählen Sie, welche Benachrichtigungen Sie erhalten möchten',
  notiSettingsRT: 'Informationen in Echtzeit',
  trailsStatus: 'Pisten- und Routenstatus',
  poisStatus: 'Anlagen-Status',
  transportsStatus: 'Lift- und Transportstatus',
  notiExpire: 'Eine Woche lang',
  notiForever: 'Immer',
  notiNever: 'Nie',
  notiInitTitle:
    'Möchten Sie Push-Benachrichtigungen über den Status von Pisten und Liften in Echtzeit erhalten?',
  notiInitHint:
    'Sie können diese Wahl jederzeit in Ihren Profileinstellungen ändern',
  notiPermission:
    'Sie haben keine Benachrichtigungen aktiviert! Um Warnungen und Benachrichtigungen zu erhalten, müssen Sie die Benachrichtigungsberechtigungen in den App-Einstellungen ändern',
  rtStatus: 'Echtzeit-Status',
  rtStatusP:
    'Erhalten Sie Benachrichtigungen über neue Warnungen oder Updates zum Status von Routen, Anlagen oder Aktivitäten in der Umgebung.',
  newsAndAlerts: 'Nachrichten und Warnungen',
  newsAndAlertsP:
    'Erhalten Sie Benachrichtigungen über neue nützliche oder sicherheitsrelevante Maßnahmen und Warnungen aus dem Gebiet oder von Mowi',
  promoAndEvents: 'Veranstaltungen und Promo',
  promoAndEventsP:
    'Erhalten Sie Benachrichtigungen über Veranstaltungen, Mitteilungen und Promos aus dem Gebiet, Aktivitäten oder Mowi',
  notiFromArea: 'Sie erhalten Benachrichtigungen aus diesem Gebiet',
  yourActualArea: 'Ihr aktuelles Gebiet',
  smartNoti: 'Smart Auswahl',
  smartNotiP:
    'Erlauben Sie Mowi, Ihre Benachrichtigungen so auszuwählen, dass Sie nur die erhalten, die Sie wirklich interessieren, z. B. indem Sie nur Benachrichtigungen, Ereignisse und den Status von Fahrradrouten/Pisten/Skilifts erhalten, wenn Sie sich tatsächlich in dem Gebiet befinden',
};

const chat: lang = {
  chatCallToLoginTitle:
    'Suchen Sie Freunde oder jemanden, der Ihnen Ratschläge gibt?',
  chatCallToLoginDesc:
    'Erstellen Sie Ihr ' +
    appName +
    '-Konto, um auf den Gebiets-Chat zuzugreifen und sich mit unserer Community zu verbinden!',
};

const location: lang = {
  locationDisactive: 'Standort aus',
  locationPermissions:
    'Aktivieren Sie Ihren Standort in den Einstellungen, um zu sehen, wo Sie sich auf der Karte befinden',
};

const other: lang = {
  debug: 'DEBUG',
  ComingSoon: 'Bald verfügbar',
  avalancheRisk: 'Lawinengefahr',
  openLifts: 'Lifte geöffnet',
  openTracks: 'Routen geöffnet',
};

const promots: lang = {
  remain: 'Hier bleiben',
  changeArea: 'Bereich ändern',
  chooseArea: 'Wählen Sie diesen Area',
};

const notices: lang = {
  newNotice: 'Neuer Bericht',
  noticesList: 'Notices List',
  newWarning: 'Warnung erstellen',
  noticeProblemsTracks:
    'Melden Sie uns Probleme, die auf diesem Track aufgetreten sind',
  noticeProblemHinweis:
    ' Helfen Sie anderen Benutzern, indem Sie uns über das Problem informieren! Wir werden versuchen, das Problem so schnell wie möglich zu lösen, vielen Dank!',
  noticeProblemAnmelden:
    'Melden Sie sich an oder erstellen Sie ein Mowi Space account, um Berichte einzureichen!',
  senden: 'Senden',
  sendNotice: 'Bericht senden',
  thanksForNotice: 'Vielen Dank für Ihren Bericht!',
  weResolveSoon:
    'Wir werden unser Bestes tun, um das Problem so schnell wie möglich zu beheben!',
  noticeProblem: 'Ein Problem melden',
  compileCamps: 'Bitte füllen Sie die folgenden Felder aus',
  wichProblem: 'Was ist das Problem?',
  selectRightProblem:
    'Wählen Sie den Problemtyp aus, der Ihrem Problem am nächsten kommt',
  whereProblem: 'Wo befindet sich das Problem?',
  problem_all: 'Auf dem gesamten Pfad',
  problem_start: 'Am Anfang',
  problem_center: 'Mittendrin',
  problem_end: 'Am Ende',
  selectRightWhere:
    'Wählen Sie den Punkt auf der Route, der dem aufgetretenen Problem am nächsten liegt',
  inOtherArea: 'Aus einem anderen Bereich',
};

export const t: lang = {
  ...general,
  ...genericErrors,
  ...localities,
  ...categories,
  ...trackTypologies,
  ...difficulties,
  ...tagServices,
  ...tagFilters,
  ...appInit,
  ...login,
  ...privacyRequest,
  ...profile,
  ...friends,
  ...group,
  ...userMenu,
  ...elements,
  ...tracks,
  ...pois,
  ...transports,
  ...intro,
  ...find,
  ...notifications,
  ...chat,
  ...location,
  ...other,
  ...promots,
  ...notices,
  ...other,
};
