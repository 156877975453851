import React, { useState, MouseEvent, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";

import { ENV, LOG, theme } from "../../config";
import {
  changeArea,
  getAvailableAreas,
  getPermittedAreas,
} from "../../libs/apollo/auth";
import { ACTUAL_AUTH } from "../../stores/db/auth";
import { AreaData, getAreas } from "../../stores/queries/areas";
import { useGlobalState } from "../../stores/appState";
import { User, USER_ME } from "../../stores/queries/users";
import { Loading } from "../../ui";
import ImgLogoBike from "../../assets/img/loghi/bike.png";
import ImgLogoSki from "../../assets/img/loghi/ski.png";
import { useHistory } from "react-router";

const ImgLoghi = {
  ski: ImgLogoSki,
  bike: ImgLogoBike,
};

interface AreaBtnProps {
  area: AreaData;
}

const AreaBtn = (props: AreaBtnProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const changeAreaRes = (res: any) => {
    if (res.success) {
      LOG.info("Change Area Succesfully");
      LOG.info(res.auth);
      if (res.tokens?.atDecoded?.auth?.permission?.area) {
        window.location.reload();
        setLoading(false);
      }
    } else {
      setLoading(false);
      LOG.warn("Login fallito: " + res.msg);
    }
  };

  const handleAreaPress = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loading || ACTUAL_AUTH.area === props.area.id) return null;
    setLoading(true);
    setTimeout(() => {
      if (ACTUAL_AUTH.refreshToken) {
        changeArea(
          ACTUAL_AUTH.refreshToken,
          props.area.id,
          props.area.app
        ).then((res) => {
          changeAreaRes(res);
        });
      } else {
        LOG.error("Try to select area without refresh token");
      }
    }, 100);
  };

  return (
    <button
      onClick={handleAreaPress}
      className="flex flex-row w-full p-4 shadow-md rounded-lg justify-between items-center hover:shadow-xl outline-none focus:outline-none ease-linear transition-all duration-150"
      style={{
        backgroundColor: "#515151",
        marginTop: 10,
        marginBottom: 10,
        opacity: loading ? 0.5 : 1,
      }}
    >
      <img
        alt="Mowi Logo"
        src={ImgLoghi[props.area.app]}
        className="border-none"
        style={{
          width: 50,
          height: 40,
          paddingRight: 10,
          borderRight: "1px solid #666",
        }}
      />
      <h1
        className="text-white text-lg font-bold uppercase flex-1 m-2 opacity-80"
        style={{ textAlign: "center" }}
      >
        {props.area.name}
      </h1>
      {loading && (
        <div className="absolute flex items-center justify-center">
          <Loading color={theme.vars.colors.light} />
        </div>
      )}
    </button>
  );
};

const AreaSelect = () => {
  const [auth] = useGlobalState("auth");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [actualArea, setActualArea] = useGlobalState("actualArea");
  const [currentArea, setCurrentArea] = useState<AreaData>();
  const [userAreas, setUserAreas] = useState<AreaData[]>();

  const history = useHistory();

  const { data } = useQuery<{
    userMe: User;
  }>(USER_ME[ACTUAL_AUTH.app as "bike" | "ski"], {
    fetchPolicy: "network-only",
    skip: !auth.user,
  });

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 0],
        },
      },
    ],
  });

  useEffect(() => {
    if (data?.userMe?.auth?.permissions[0] && ACTUAL_AUTH.area) {
      getAreas().then((areas) => {
        if (areas) {
          let myAreas = getAvailableAreas(
            getPermittedAreas(data.userMe.auth.permissions),
            areas
          );
          let currentArea = myAreas.find((area: AreaData) => {
            return area.id === ACTUAL_AUTH.area;
          });
          setCurrentArea(currentArea);
          let nameRef = currentArea?.nameRef?.toLowerCase() || null;
          setActualArea({ ...currentArea, nameRef } as any);
          setUserAreas(myAreas);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!userAreas) {
    return (
      <div className={"bg-white p-2 rounded-2xl"} style={{ marginRight: 20 }}>
        <Loading color={theme.vars.colors.dark} />
      </div>
    );
  }

  return (
    <>
      <Popover>
        <Popover.Button
          ref={setReferenceElement as any}
          className="flex mr-2 flex-row bg-white w-full rounded-2xl justify-between items-center hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
        >
          <div
            className="border-none rounded-2xl"
            style={{
              width: 47,
              height: 47,
              marginRight: 10,
              backgroundColor: "#515151",
              padding: 11,
            }}
          >
            <img
              alt="Mowi Logo"
              src={ImgLoghi[currentArea?.app || "bike"]}
              className="border-none"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>

          <h1
            className="text-gray-500 text-sm font-bold uppercase flex-1 opacity-80"
            style={{
              textAlign: "left",
              padding: 3,
              paddingRight: 10,
              wordBreak: "break-all",
            }}
          >
            {currentArea?.name}
            <br />
            <small>{currentArea?.locality}</small>
          </h1>
        </Popover.Button>

        <Popover.Panel
          ref={setPopperElement as any}
          style={styles.popper}
          {...attributes.popper}
        >
          <div
            className={
              "bg-white text-base z-50 float-left px-2 mt-1 list-none rounded-lg shadow-lg min-w-48 text-right"
            }
            style={{
              border: "1px solid #ddd",
              zIndex: 99999,
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            {data?.userMe?.email && ENV.rootEmails.includes(data.userMe.email) && (
              <button
                onClick={() => {
                  history.push("/global");
                }}
                className="flex flex-row w-full p-4 shadow-md rounded-lg justify-between items-center hover:shadow-xl outline-none focus:outline-none ease-linear transition-all duration-150"
                style={{
                  backgroundColor: "#515151",
                  marginTop: 10,
                  marginBottom: 10,
                  opacity: 1,
                }}
              >
                <img
                  alt="Mowi Logo"
                  src={"https://mowi.space/favicon.png"}
                  className="border-none"
                  style={{
                    width: 50,
                    height: 40,
                    paddingRight: 10,
                    borderRight: "1px solid #666",
                  }}
                />
                <h1
                  className="text-white text-lg font-bold uppercase flex-1 m-2 opacity-80"
                  style={{ textAlign: "center" }}
                >
                  Global Analytics
                </h1>
              </button>
            )}
            {userAreas &&
              userAreas?.map((area, index) => (
                /* if (area.id === currentArea.id) return null; */
                <AreaBtn key={index} area={area} />
              ))}
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
};

export { AreaBtn, AreaSelect };
