import React, { useMemo } from "react";
import DataTable from "react-data-table-component";

import { theme } from "../config";
import { useFormFields } from "../libs/hooks/forms";
import { TextBox } from "./TextBox";
import { Loading } from "./Loading";

interface TableProps {
  columns: any;
  data: any[];
  height: string;
  title: string;
  loading: boolean;
  onRowClick?: (row: any, e: MouseEvent) => void;
  selectableRows?: boolean;
  filterSelector?: string;
  defaultSortField?: string;
  defaultSortInvert?: boolean;
}

const Table = (props: TableProps) => {
  const [inputs, handleInputChange] = useFormFields<{
    filter: string;
  }>({
    filter: "",
  });

  const filteredItems = props.filterSelector
    ? props.data?.filter(
        (item) =>
          item[props.filterSelector as any] &&
          item[props.filterSelector as any]
            .toLowerCase()
            .includes(inputs.filter.toLowerCase())
      )
    : props.data;

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="flex flex-row justify-between items-center w-full py-2">
        <h3 className={"font-semibold text-2xl text-gray-600"}>
          {props.title}
        </h3>
        <div className={"flex flex-row"} style={{ width: "200px" }}>
          {props.filterSelector && (
            <TextBox
              id="filter"
              type="text"
              placeholder={"Cerca per " + props.filterSelector + "..."}
              required={true}
              onChange={handleInputChange}
            />
          )}
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.filter]);

  return (
    <DataTable
      noHeader
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      fixedHeader
      highlightOnHover
      striped={true}
      fixedHeaderScrollHeight={props.height}
      title={props.title}
      responsive={true}
      columns={props.columns}
      data={filteredItems || []}
      style={styles.container}
      customStyles={styles.table}
      pointerOnHover
      progressPending={props.loading}
      selectableRows={props.selectableRows}
      defaultSortField={props.defaultSortField}
      defaultSortAsc={props.defaultSortInvert ? false : true}
      progressComponent={
        <div className="w-full flex items-center justify-center py-8">
          <Loading color={theme.vars.colors.greyDark} />
        </div>
      }
      onRowClicked={props.onRowClick}
    />
  );
};

const styles = {
  container: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  table: {
    subHeader: {
      style: {
        borderRadius: 30,
      },
    },
    headRow: {
      style: {
        textTransform: "uppercase",
        backgroundColor: "#e9e9e9",
        borderBottomWidth: "0px",
        borderBottomColor: "#eee",
        borderBottomStyle: "solid",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        fontWeight: 600,
        color: theme.vars.colors.grey,
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderBottomStyle: "solid",
          borderBottomWidth: "0px",
          borderBottomColor: "#eee",
        },
      },
    },
    cells: {
      style: {
        color: theme.vars.colors.greyDark,
      },
    },
    table: {
      style: {
        overflow: "hidden",
        borderBottomLeftRadius: "1rem",
        borderBottomRightRadius: "1rem",
      },
    },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
  },
};

export { Table };
