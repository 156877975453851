import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { theme } from "../../config";

interface HBarChartProps {
  labels: string[];
  data: string[] | number[];
  id: string;
  height?: number;
  lineHeight?: number;
}

const HBarChart = (props: HBarChartProps) => {
  const CHART = useRef<Chart<"bar", any[], string>>();
  const CANVAS = useRef<any>();

  useEffect(() => {
    const initChart = () => {
      if (!props.data) {
        return;
      }

      CANVAS.current = (document.getElementById(props.id) as any).getContext(
        "2d"
      );

      var gradient =
        CANVAS.current && CANVAS.current.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, theme.vars.colors.blueLight);
      gradient.addColorStop(1, "rgba(50,250,50,0.1)");

      if (props.data.length > 0) {
        var lineHeight = 12;

        if (props.lineHeight) {
          lineHeight = props.lineHeight;
        }

        var chartHeight = props.data.length * lineHeight;
        CANVAS.current.canvas.height = chartHeight;
      } else {
        CANVAS.current.canvas.height = props.height || 600;
      }

      CHART.current = new Chart(CANVAS.current, {
        type: "bar",
        data: {
          labels: props.labels,
          datasets: [
            {
              label: "",

              data: props.data,
              borderColor: theme.vars.colors.blueLight,
              //borderSkipped: true,
              //backgroundColor: "rgba(0, 0, 0, 0.1)",
              backgroundColor: gradient,

              //fill: true,
            },
          ],
        },
        options: {
          indexAxis: "y" as const,

          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          //maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
              color: "#666",
              anchor: "end",
              align: "end",
              offset: -2,
              formatter: Math.round,
              font: {
                weight: "bold",
              },
            },
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: false,
              },
            },
            y: {
              display: true,

              grid: {
                display: false,
                drawBorder: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                autoSkip: false,
              },
            },
          },
        },
      });
    };

    if (CHART.current) {
      CHART.current.destroy();
      initChart();
    } else {
      initChart();
    }
  }, [props]);

  return (
    <div
      style={{
        height: props.height || 600,
      }}
      className="relative"
    >
      <canvas id={props.id || "hbar-chart"}></canvas>
    </div>
  );
};

export { HBarChart };
