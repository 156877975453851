import React, { CSSProperties } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { theme } from "../config";

// views
import { AnalyticsView } from "../views/global/AnalyticsView";

interface GlobalProps {}

const Global = (props: GlobalProps) => {
  return (
    <>
      <div
        className="absolute top-0 w-full h-full bg-gray-700 bg-no-repeat bg-full"
        style={styles.container}
      ></div>
      <div
        className="relative min-h-screen"
        style={{ backgroundColor: "#e4e6e9" }}
      >
        <div className="px-4 md:px-10 mx-auto w-full pt-4">
          <Switch>
            <Route path="/global/analytics" exact component={AnalyticsView} />
            <Redirect from="/global" to="/global/analytics" />
          </Switch>
        </div>
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: theme.vars.colors.grey,
  },
};

export { Global };
