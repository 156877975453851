import { CardStats } from "../../components/Analytics/CardStats";
import { theme } from "../../config";
import { DateRange } from "../../ui/DateRange";

import { Listbox } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ElementViews } from "../../components/Analytics/ElementViews";
import { LineMetric } from "../../components/Analytics/LineMetric";
import { PieDimension } from "../../components/Analytics/PieDimension";
import { PieAreas } from "../../components/Analytics/PieAreas";
import { useHistory } from "react-router";
import { DownViews } from "../../components/Analytics/DownViews";
import { ACTUAL_AUTH } from "../../stores/db/auth";

interface AnalyticsViewProps {
  route?: any;
  area?: string;
  from?: string;
  to?: string;
}

const areasBike = [
  { name: "Tutte le Aree", value: null },
  { name: "DPB", value: "DPB" },
  { name: "VDS", value: "VDS" },
  { name: "VDN", value: "VDN" },
  { name: "ETNA", value: "ETNA" },
  { name: "SIBIL", value: "SIBIL" },
  { name: "BOMBA", value: "BOMBA" },
  { name: "KPZ", value: "KPZ" },
];

const areasSnow = [
  { name: "Tutte le Aree", value: null },
  { name: "PSA", value: "PSA" },
  { name: "MDC", value: "MDC" },
  { name: "TON", value: "TON" },
  { name: "PEJO", value: "PEJO" },
  { name: "ACFL", value: "ACFL" },
  { name: "BOND", value: "BOND" },
];

const platforms = [
  { name: "Tutte le Piattaforme", value: null },
  { name: "Android", value: "Android" },
  { name: "iOS", value: "iOS" },
  { name: "WebApp", value: "web" },
];

const apps = [
  { name: "Mowi Snow", value: "snow" },
  { name: "Mowi Bike", value: "bike" },
];

const AnalyticsView = (props: AnalyticsViewProps) => {
  const history = useHistory();
  const [selArea, setSelArea] = useState(areasSnow[0]);
  const [selPlatform, setSelPlatform] = useState(platforms[0]);
  const [selApp, setSelApp] = useState<any>(apps[0]);
  const [selRange, setSelRange] = useState({
    startDate: "2022-06-01",
    endDate: undefined,
  });

  const [areas, setAreas] = useState(areasSnow);

  useEffect(() => {
    if (selApp.value === "bike") {
      setAreas(areasBike);
      setSelArea(areasBike[0]);
    } else {
      setAreas(areasSnow);
      setSelArea(areasSnow[0]);
    }
  }, [selApp]);

  return (
    <>
      <div
        className="mx-auto shadow-lg"
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          marginLeft: " -2.5rem",
          marginRight: "-2.5rem",
          zIndex: 99999,
          backgroundColor: "#f7f7f7",
        }}
      >
        <div
          style={{ height: 70 }}
          className="flex flex-1 flex-row justify-center items-center"
        >
          <div
            onClick={() => {
              history.push("/");
            }}
            style={{ position: "absolute", left: 0 }}
            className={
              "rounded-2xl mx-2 p-2 hover:shadow-lg justify-center items-center cursor-pointer bg-white"
            }
          >
            <h1
              className={
                "flex flex-row text-lg font-bold text-center justify-center items-center truncate"
              }
              style={{
                textAlign: "center",
                wordBreak: "break-all",
                color: theme.vars.colors.greyDark,
              }}
            >
              HOME
            </h1>
          </div>

          <div className="flex flex-1 flex-row justify-center items-center invisible lg:visible">
            <Listbox value={selApp} onChange={setSelApp}>
              <div className="relative">
                <Listbox.Button
                  style={{ minWidth: 150 }}
                  className="rounded-2xl mx-2 p-2 hover:shadow-lg transition-all justify-center items-center cursor-pointer bg-white"
                >
                  <span className="block text-lg font-bold truncate">
                    {selApp.name}
                  </span>
                </Listbox.Button>

                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {apps.map((app, index) => (
                    <Listbox.Option
                      key={"platforms_" + index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={app}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {app.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 fa fa-check"></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>

            <div className="pr-2 pl-2">
              <DateRange
                initStartDate={selRange.startDate}
                initEndDate={selRange.endDate}
                onChange={(range) => {
                  let startDate = range.startDate.toISOString().split("T")[0];
                  let endDate = range.endDate.toISOString().split("T")[0];
                  setSelRange({ startDate, endDate });
                }}
              />
            </div>
            <Listbox value={selArea} onChange={setSelArea}>
              <div className="relative">
                <Listbox.Button
                  style={{ minWidth: 150 }}
                  className="rounded-2xl mx-2 p-2 hover:shadow-lg transition-all justify-center items-center cursor-pointer bg-white"
                >
                  <span className="block text-lg font-bold truncate">
                    {selArea.name}
                  </span>
                </Listbox.Button>

                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {areas.map((area, index) => (
                    <Listbox.Option
                      key={"areas_" + index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={area}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {area.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 fa fa-check"></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>

            <Listbox value={selPlatform} onChange={setSelPlatform}>
              <div className="relative">
                <Listbox.Button
                  style={{ minWidth: 150 }}
                  className="rounded-2xl mx-2 p-2 hover:shadow-lg transition-all justify-center items-center cursor-pointer bg-white"
                >
                  <span className="block text-lg font-bold truncate">
                    {selPlatform.name}
                  </span>
                </Listbox.Button>

                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {platforms.map((platform, index) => (
                    <Listbox.Option
                      key={"platforms_" + index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-amber-900"
                            : "text-gray-900"
                        }`
                      }
                      value={platform}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {platform.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 fa fa-check"></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: 80 }}
        className="text-sm w-full text-center text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 mt-2 border-0"
      >
        Globali
        <br />
        <small>
          <i>Root - Tutte le aree</i>
        </small>
      </div>

      <div className="flex mt-4 flex-wrap">
        <div className="w-full xl:w-3/12 px-3">
          <PieAreas
            event="open_card_area"
            platform={selPlatform.value}
            from={selRange.startDate}
            to={selRange.endDate}
            areaApp={selApp.value}
            title="Visualizzazioni card area"
            id="pie-chart_area_card21432"
          />
        </div>
        <div className="w-full xl:w-3/12 px-3">
          <PieAreas
            event="area_start"
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Entrate in area"
            id="pie-chart_area213412"
          />
        </div>
        <div className="w-full xl:w-3/12 px-3">
          <PieAreas
            event="user_activity"
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Interazioni utente"
            id="pie-chart_area_activity"
          />
        </div>
        <div className="w-full xl:w-3/12 px-3">
          <PieAreas
            event="get_app"
            from={selRange.startDate}
            areaApp={selApp.value}
            to={selRange.endDate}
            title="Download app link"
            subTitle="Tutte le piattaforme"
            id="pie-chart_area_link32412"
          />
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
          <PieAreas
            event="gps_area"
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Download GPS"
            id="pie-chart_area7864335"
          />
        </div>
        <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
          <PieAreas
            event="first_area"
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Prima area visitata"
            id="pie-chart_areaa234522345"
          />
        </div>
      </div>

      <div className="text-sm w-full text-center text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 mt-2 border-0">
        Base
        <br />
        <small>
          <i>Statistiche Dashboard</i>
        </small>
      </div>
      <div className="relative md:pt-5 pb-5 pt-5">
        <div className="mx-auto w-full">
          <div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 xl:w-4s/12 px-4">
                <CardStats
                  areaRef={selArea.value}
                  from={selRange.startDate}
                  to={selRange.endDate}
                  platform={selPlatform.value}
                  areaApp={selApp.value}
                  metric={"totalUsers"}
                  title="Totale Utenti"
                  statDescripiron="Utenti unici"
                  statIconName="fas fa-user"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                <CardStats
                  areaRef={selArea.value}
                  from={selRange.startDate}
                  to={selRange.endDate}
                  platform={selPlatform.value}
                  areaApp={selApp.value}
                  metric={"sessions"}
                  title="Accessi totali"
                  statDescripiron="Totale accessi in area"
                  statIconName="fas fa-users"
                  statIconColor="bg-blue-400"
                />
              </div>
              <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                <CardStats
                  areaRef={selArea.value}
                  from={selRange.startDate}
                  to={selRange.endDate}
                  platform={selPlatform.value}
                  areaApp={selApp.value}
                  metric={"activeUsers"}
                  title="Nuovi accessi"
                  statDescripiron="Accessi questa settimana"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-purple-500"
                />
              </div>
              {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  metric={"activeUsers"}
                  title="Utenti area"
                  statDescripiron="Utenti loggati nell'area"
                  statIconName="fas fa-street-view"
                  statIconColor="bg-pink-500"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <LineMetric
            areaRef={selArea.value}
            from={selRange.startDate}
            to={selRange.endDate}
            platform={selPlatform.value}
            areaApp={selApp.value}
            id="line-metric-base-access123412"
            metric="sessions"
            title="Andamento accessi"
            unit="Accessi Totali"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="customUser:USER_PROFILATION"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={"2022-05-01"}
            to={"today"}
            title="Profilazione utenti"
            id="pie-chart_profilation12341"
          />
        </div>
      </div>

      <div className="text-sm w-full text-center text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 mt-2 border-0">
        Comportamento
        <br />
        <small>
          <i>Elementi d'interesse e azioni utente</i>
        </small>
      </div>

      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaRef={selArea.value}
            areaApp={selApp.value}
            lineHeight={16}
            height={530}
            type="track"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Tracciati più visti"
            id={"tracks_list12341234"}
          />
        </div>
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaRef={selArea.value}
            areaApp={selApp.value}
            lineHeight={16}
            height={530}
            type="poi"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Poi più visti"
            id={"pois_list123412"}
          />
        </div>
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ElementViews
            areaRef={selArea.value}
            areaApp={selApp.value}
            lineHeight={30}
            height={530}
            type="transport"
            from={selRange.startDate}
            to={selRange.endDate}
            title="Trasporti più visti"
            id={"transports_list4312412"}
          />
        </div>
      </div>

      <div className="text-sm w-full text-center text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 mt-2 border-0 mb-4">
        Acquisizione
        <br />
        <small>
          <i>Accessi e referrals</i>
        </small>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full mb-2 px-4">
          <LineMetric
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            id="line-metric-base-access143214"
            metric="totalUsers"
            title="Andamento utenti unici"
            //unit="Accessi Totali"
          />
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full mb-2 px-4">
          <LineMetric
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            id="line-metric-base-access123412342"
            metric="sessions"
            title="Andamento aperture area"
            //unit="Accessi Totali"
          />
        </div>
      </div>

      <div className="text-sm w-full text-center text-gray-500 uppercase hidden lg:inline-block font-extrabold rounded-2xl bg-white p-3 mt-2 border-0 mb-4">
        Pubblico
        <br />
        <small>
          <i>Profilazione utente</i>
        </small>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="customUser:USER_PROFILATION"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Profilazione utenti"
            id="pie-chart_profilation1231421"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="deviceCategory"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Tipo dispositivo"
            id="pie-chart_devices3214312"
          />
        </div>

        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="platform"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Piattaforma"
            id="pie-chart_platform12341234"
          />
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="language"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Lingua"
            id="pie-chart_language13412345"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="country"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Paese"
            id="pie-chart_country4352342678"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="region"
            areaRef={selArea.value}
            platform={selPlatform.value}
            areaApp={selApp.value}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Regione"
            id="pie-chart_regionf3452"
          />
        </div>
      </div>
    </>
  );
};

export { AnalyticsView };
