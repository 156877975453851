import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import { divIcon } from "leaflet";

import { theme } from "../../config";
import { Poi, POI } from "../../stores/queries/pois";
import { PoiCard } from "..";
import { useGlobalState } from "../../stores/appState";
import { Loading } from "../../ui";

interface PoiMapProps {
  id: string;
}

const PoiMap = (props: PoiMapProps) => {
  const { error, data } = useQuery<{
    userPoi: Poi;
  }>(POI, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [locale] = useGlobalState("locale");
  const [poiPoint, setPoiPoint] = useState<any>(null);

  const gjObject = useRef<any>(null);

  useEffect(() => {
    let point = null;
    if (data?.userPoi.geoLocation.coordinatesPoint) {
      point = [
        data.userPoi.geoLocation.coordinatesPoint[1],
        data.userPoi.geoLocation.coordinatesPoint[0],
      ];
    }
    setPoiPoint(point);
  }, [data?.userPoi.geoLocation]);

  const iconMarkup = renderToStaticMarkup(
    <div
      className="rounded-full flex justify-center items-center"
      style={{
        backgroundColor: theme.helpers.getTagServiceColor(
          data?.userPoi.primaryService
        ),
        color: "#fff",
        width: 35,
        height: 35,
        fontSize: 30,
        borderWidth: 2,
        borderColor: "#fff",
      }}
    >
      <i className={"icon-" + data?.userPoi.primaryService} />
    </div>
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  if (error) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error.message}
        </div>
      </div>
    );
  }

  if (!poiPoint) {
    return (
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 180px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  return (
    <>
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0"
        style={{ height: "calc(100vh - 170px)", transform: "translateZ(0)" }}
      >
        <MapContainer
          center={poiPoint || [46.169820718028596, 11.008665122087717]}
          zoom={13}
          scrollWheelZoom={false}
          className="relative break-words w-full rounded-2xl border-0"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          whenCreated={(map) => {
            setTimeout(() => {
              if (gjObject.current && gjObject.current.getBounds) {
                let bounds = gjObject.current.getBounds();
                if (bounds._northEast) {
                  map.fitBounds(bounds);
                }
              }
            }, 200);
          }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={poiPoint} icon={customMarkerIcon}>
            <Tooltip sticky>
              <b>{data?.userPoi.name}</b>
            </Tooltip>
          </Marker>
          <div
            className="absolute shadow-xl"
            style={{
              position: "absolute",
              bottom: 18,
              left: 0,
              marginLeft: "calc(50% - 140px)",
              marginRight: "auto",
              width: 280,
              height: 100,
              backgroundColor: theme.vars.colors.greyDark,
              borderRadius: 20,
              zIndex: 999,
              alignSelf: "center",
            }}
          >
            <PoiCard poi={data?.userPoi as Poi} locale={locale} />
          </div>
        </MapContainer>
      </div>
    </>
  );
};

export { PoiMap };
