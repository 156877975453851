// @ts-ignore
import omitDeep from 'omit-deep';
import { LOG } from '../../config';

const transformApolloData = (data: Object) => {
  if (!data) return null;
  try {
    let tData = JSON.parse(JSON.stringify(data));
    return omitDeep(tData, '__typename');
  } catch (error) {
    LOG.error(error);
    return null;
  }
};

const copyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0px';
  textArea.style.left = '0px';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0px';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  document.body.appendChild(textArea);
  textArea.value = text;
  textArea.focus();
  textArea.select();
  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    LOG.info('Copying text command was ' + msg);
  } catch (err) {
    LOG.error('Oops, unable to copy');
  }
  document.body.removeChild(textArea);
};

export { transformApolloData, copyTextToClipboard };
