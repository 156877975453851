import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import { PieChart } from "../Charts/PieChart";

type baseDimensionDate = {
  name: string;
  rows: [{ date: string; value: number }];
  count: number;
};

interface PieAreasProps {
  event: string;
  id: string;
  platform?: string | null;
  areaApp: "bike" | "ski" | null;
  from?: string;
  to?: string;
  title?: string;
  subTitle?: string;
  height?: number;
}

const PieAreas = (props: PieAreasProps) => {
  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState<{
    labels: string[];
    data: string[] | number[];
  }>({ labels: [], data: [] });

  let app = "ski";
  if (props.areaApp === "bike") {
    app = "bike";
  }

  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await axios.post<baseDimensionDate>(
          ENV.analytics + "/base/area_event",
          {
            event: props.event,
            platform: props.platform,
            area_app: app,
            from: props.from,
            to: props.to,
          }
        );

        if (response?.data) {
          let data = [];
          let labels = [];

          response.data.rows.forEach((row: any, index: number) => {
            if (index < 5) {
              data.push(row.value);
              labels.push(row.name);
            }
          });

          if (response.data.count > 5) {
            let otherData = 0;
            let otherLabel = "Altro";
            response.data.rows.forEach((row: any, index: number) => {
              if (index > 4) {
                otherData = otherData + parseInt(row.value);
              }
            });
            data.push(otherData);
            labels.push(otherLabel);
          }

          setChartData({
            labels: labels,
            data: data,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    setLoading(true);
    getReport();
  }, [props]);

  if (!props.event) return null;

  return (
    <>
      <div className="overflow-hidden relative flex flex-col min-w-0 break-words w-full mb-6 rounded-xl">
        <div className="rounded-xl mb-0 px-4 py-3 ">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1 text-center">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold text-center">
                {props.subTitle}
              </h6>
              <h2 className="text-xl font-semibold">{props.title}</h2>
            </div>
          </div>
        </div>
        <div
          className="flex-auto"
          style={{ height: 390, padding: 10, paddingTop: 0 }}
        >
          <div
            style={{
              height: "100%",
              /* marginLeft: -25,
              marginRight: -25, */
              marginTop: 0,
            }}
            className="relative h-full"
          >
            {chartData.data.length > 0 && !loading && (
              <PieChart
                id={props.id}
                data={chartData.data}
                labels={chartData.labels}
              />
            )}
          </div>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

PieAreas.defaultProps = {
  from: "2020-01-01",
  to: "today",
  title: "Values",
  subTitle: "Overview",
};

export { PieAreas };
