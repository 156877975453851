import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { LOG, T, theme } from "../../config";
import {
  TRANSPORT,
  TRANSPORT_UPDATE,
  Transport,
  TRANSPORT_ADD_MEDIA,
  TRANSPORT_REMOVE_MEDIA,
  TRANSPORT_UPDATE_OSTATUS,
} from "../../stores/queries/transports";
import { Button, EditBox, EditImage, StatusLabel, Loading } from "../../ui";
import { transformApolloData } from "../../libs/utils";
import { useGlobalState } from "../../stores/appState";
import { checkScope } from "../../libs/apollo/auth";
import { ACTUAL_AUTH } from "../../stores/db/auth";

interface TagServicesProps {
  tagServices: string[];
}
const TagServices = (props: TagServicesProps) => {
  if (!props.tagServices) return null;
  const list = props.tagServices.map((tag: any, index: number) => {
    return (
      <div
        className="flex flex-col justify-center items-center mx-2"
        key={index}
      >
        <i className={"text-3xl icon-" + tag} />
        <span className="uppercase" style={{ fontSize: 9 }}>
          {T(tag)}
        </span>
      </div>
    );
  });
  return <>{list}</>;
};

interface TransportDetailsProps {
  id: string;
}

const TransportDetails = (props: TransportDetailsProps) => {
  const { error, data } = useQuery<{
    userTransport: Transport;
  }>(TRANSPORT, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [transportUpdate, { error: updateError }] =
    useMutation(TRANSPORT_UPDATE);

  const [transportUpdateOStatus, { error: tuosError }] = useMutation(
    TRANSPORT_UPDATE_OSTATUS,
    {
      refetchQueries: [
        {
          query: TRANSPORT,
          variables: {
            id: props.id,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const isRoot = useRef<boolean>(checkScope(ACTUAL_AUTH.scopes, "root"));

  const editPermission = useRef<boolean>(
    isRoot.current || checkScope(ACTUAL_AUTH.scopes, "area:edit")
  );

  const [locale, setLocale] = useGlobalState("locale");

  const actualTransport = useRef<{
    name: Transport["name"];
    description: Transport["description"];
  }>({
    name: "",
    description: {
      long: {
        it: "",
        en: "",
        de: "",
      },
      short: {
        it: "",
        en: "",
        de: "",
      },
    },
  });

  const [transportRemoveCover, { loading: removeLoading, error: removeError }] =
    useMutation(TRANSPORT_REMOVE_MEDIA);

  const [transportAddCover, { error: uploadError, loading: uploadLoading }] =
    useMutation(TRANSPORT_ADD_MEDIA);

  const [activeEdit, setActiveEdit] = useState<boolean>(false);
  const [manualState, setManualState] = useState<boolean | null | undefined>(
    null
  );

  useEffect(() => {
    if (data?.userTransport) {
      actualTransport.current = transformApolloData({
        name: data.userTransport.name,
        description: data.userTransport.description,
      });
      setManualState(data.userTransport.originalStatus);
    }
  }, [data]);

  const update = () => {
    transportUpdate({
      variables: {
        id: props.id,
        transportInput: actualTransport.current,
      },
    });
  };

  const updateOStatus = () => {
    transportUpdateOStatus({
      variables: {
        ids: [props.id],
        newStatus: manualState,
      },
    });
  };

  const addCover = (files: any) => {
    transportAddCover({
      variables: {
        id: props.id,
        medias: files,
        type: "cover",
      },
    });
  };

  const removeCover = (id: string) => {
    transportRemoveCover({
      variables: {
        id: props.id,
        mediaId: id,
        type: "cover",
      },
    });
  };

  if (updateError) {
    LOG.error(updateError);
  }

  if (tuosError) {
    LOG.error(tuosError);
  }

  if (error || uploadError || removeError) {
    LOG.error(error || uploadError || removeError);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error?.message || uploadError?.message || removeError?.message}
        </div>
      </div>
    );
  }

  if (!data?.userTransport) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  let statusText = "nessun valore";
  let statusAuto = false;
  if (typeof data?.userTransport.statusFromExternalSource === "boolean") {
    statusAuto = true;
    statusText = data.userTransport.statusFromExternalSource
      ? T("open")
      : T("closed");
  } else {
    if (typeof data?.userTransport.originalStatus === "boolean") {
      statusText = data.userTransport.originalStatus ? T("open") : T("closed");
    }
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
      >
        <div
          className="rounded-t-2xl w-full bg-white mb-0 px-6 py-4"
          style={{
            backgroundColor: theme.helpers.getTagServiceColor(
              data?.userTransport.primaryService
            ),
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center"
                style={{ width: 30, height: 30 }}
              >
                <i
                  className={
                    "text-2xl icon-" + data?.userTransport.primaryService
                  }
                />
              </span>
              <h6 className="text-white text-xl font-bold my-2">
                {data?.userTransport.name}
              </h6>
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="flex flex-col items-center justify-center mx-2">
                <span
                  className={
                    "rounded-full text-white font-bold text-xs flex items-center justify-center"
                  }
                >
                  Stato Attuale
                </span>
                {typeof data?.userTransport.status === "boolean" ? (
                  <StatusLabel status={data?.userTransport.status} />
                ) : (
                  <StatusLabel status={data?.userTransport.originalStatus} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ overflow: "auto" }}
        >
          <form>
            {updateError && (
              <div className="p-2 mt-2 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
                {updateError.message}
              </div>
            )}
            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-base mt-3 mb-4 font-bold uppercase flex flex-row justify-between">
              {T("panoramica")}
              <div>
                <Button
                  color={locale === "it" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("it");
                  }}
                >
                  IT
                </Button>{" "}
                <Button
                  color={locale === "en" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("en");
                  }}
                >
                  EN
                </Button>{" "}
                <Button
                  color={locale === "de" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("de");
                  }}
                >
                  DE
                </Button>
              </div>
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={editPermission.current}
                value={data?.userTransport.name}
                label={T("name")}
                maxChar={35}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTransport.current.name = value || "";
                  update();
                }}
              />
              <EditBox
                editable={false}
                value={T(data?.userTransport.primaryService)}
                label={T("typology")}
                halfCol={false}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userTransport.description.short[locale]}
                label={"Sottotitolo"}
                maxChar={80}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTransport.current.description.short[locale] =
                    value || "";
                  update();
                }}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userTransport.description.long[locale]}
                label={"Descrizione"}
                maxChar={800}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTransport.current.description.long[locale] =
                    value || "";
                  update();
                }}
              />
              <EditImage
                value={data.userTransport.media.cover.url}
                id={data.userTransport.media.cover._id}
                loading={uploadLoading || removeLoading}
                onChange={(file) => {
                  addCover(file);
                }}
                onPressRemove={(id) => {
                  removeCover(id);
                }}
                label={"Cover"}
                editable={editPermission.current}
              />

              <EditBox
                editable={statusAuto ? false : true}
                value={statusText}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                customView={
                  <StatusLabel
                    status={
                      statusAuto
                        ? data?.userTransport.statusFromExternalSource
                        : data?.userTransport.originalStatus
                    }
                  />
                }
                onPressCancel={() => {
                  setManualState(data?.userTransport.originalStatus);
                }}
                onPressSave={() => {
                  updateOStatus();
                }}
                customEdit={
                  <div className="flex flex-1 flex-row justify-start items-center">
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={manualState ? "purple" : "white"}
                      onClick={() => {
                        setManualState(true);
                      }}
                    >
                      {T("open")}
                    </Button>{" "}
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={
                        typeof manualState !== "boolean" ? "purple" : "white"
                      }
                      onClick={() => {
                        setManualState(null);
                      }}
                    >
                      Nessuno
                    </Button>{" "}
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={
                        typeof manualState === "boolean" && !manualState
                          ? "purple"
                          : "white"
                      }
                      onClick={() => {
                        setManualState(false);
                      }}
                    >
                      {T("closed")}
                    </Button>
                  </div>
                }
                label={
                  statusAuto ? "Stato da sorgente esterna" : "Stato manuale"
                }
                halfCol={false}
                hideCharCounter={true}
              />
            </div>

            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("dettagli")}
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={false}
                value={
                  data?.userTransport.detailedInfo.isForDisabled
                    ? T("yes")
                    : T("no")
                }
                label={T("isForDisabled")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTransport.detailedInfo.isForSnowscoot
                    ? T("yes")
                    : T("no")
                }
                label={T("isForSnowscoot")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTransport.detailedInfo.isForPedestrians
                    ? T("yes")
                    : T("no")
                }
                label={T("pedestrians_yes")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTransport.detailedInfo.locality[locale]}
                label={T("locality")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTransport.detailedInfo.stations.start.name[locale]
                }
                label={T("startStation")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTransport.detailedInfo.stations.end.name[locale]
                }
                label={T("endStation")}
                halfCol={true}
              />
            </div>

            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("services")}
            </h6>
            <div className="flex flex-wrap px-4">
              <TagServices
                tagServices={data?.userTransport.detailedInfo?.tagServices}
              />
              {data?.userTransport.detailedInfo.isForPedestrians ? (
                <div className="flex flex-col justify-center items-center mx-2">
                  <i className={"text-3xl icon-pedestrians_yes"} />
                  <span className="uppercase" style={{ fontSize: 9 }}>
                    {T("pedestrians_yes")}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center mx-2">
                  <i className={"text-3xl icon-pedestrians_no"} />
                  <span className="uppercase" style={{ fontSize: 9 }}>
                    {T("pedestrians_no")}
                  </span>
                </div>
              )}
              {data?.userTransport.detailedInfo.isForDisabled && (
                <div className="flex flex-col justify-center items-center mx-2">
                  <i className={"text-3xl icon-disabled_instructor"} />
                  <span className="uppercase" style={{ fontSize: 9 }}>
                    {T("isForDisabled")}
                  </span>
                </div>
              )}
              {data?.userTransport.detailedInfo.isForSnowscoot && (
                <div className="flex flex-col justify-center items-center mx-2">
                  <i className={"text-3xl icon-snowscoot"} />
                  <span className="uppercase" style={{ fontSize: 9 }}>
                    {T("isForSnowscoot")}
                  </span>
                </div>
              )}
            </div>
          </form>
          {activeEdit && (
            <div
              className="w-full h-full absolute top-0 left-0 rounded-2xl"
              style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export { TransportDetails };
