import { useMutation, useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";

import { ENV, LOG, T, theme } from "../../config";
import { TRACK } from "../../stores/queries/tracks";
import {
  NOTICE_TO_WARNING,
  PATROL_ADD_WARNINGS,
  PROBLEM_LIST_WARNING,
  Warning,
} from "../../stores/queries/warnings";
import { Button, EditBox, Select, Loading } from "../../ui";
import { PATROL_NOTICES, Problem } from "../../stores/queries/notices";
import { TRANSPORT } from "../../stores/queries/transports";

const warningsLocations = [
  {
    label: "Su tutto il percorso",
    value: "all",
  },
  {
    label: "All'inizio",
    value: "start",
  },
  {
    label: "A metà",
    value: "center",
  },
  {
    label: "Alla fine",
    value: "end",
  },
];

const WarningCreate = ({
  open,
  onCancel,
  onYes,
  onClose,
  noticeId,
  type,
  elemId,
  name,
  problem,
  location,
}: {
  open: boolean;
  onCancel?: Function;
  onYes?: Function;
  onClose?: Function;
  noticeId?: string;
  type: "track" | "poi" | "transport";
  elemId: string;
  name: string;
  problem?: string;
  location?: Warning["location"];
}) => {
  //const [auth] = useGlobalState('auth');

  const [actualWarning, setActualWarning] = useState<Warning>({
    ifClosed: false,
    location: undefined,
    problem: undefined,
    description: {
      it: undefined,
      en: undefined,
      de: undefined,
    },
  });
  //const [sendNoti, setSendNoti] = useState<boolean>(false);
  const [problems, setProblems] = useState<Array<any>>([]);
  const [success, setSuccess] = useState<boolean>(false);

  /* useEffect(() => {
    setActualWarning({
      ...actualWarning,
      problem: problem,
      location: location,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problem, location]); */

  const { loading, error, data } = useQuery<{
    [key: string]: [Problem];
  }>(PROBLEM_LIST_WARNING[type], {
    variables: {
      language: ENV.locale,
    },
    fetchPolicy: "cache-and-network",
  });

  const [noticeToWarning, { loading: nwLoading, error: nwError }] = useMutation(
    NOTICE_TO_WARNING,
    {
      refetchQueries: [
        { query: PATROL_NOTICES },
        {
          query: type === "transport" ? TRANSPORT : TRACK,
          variables: {
            id: elemId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (res: any) => {
        if (res?.patrolTransformNoticeInWarning) {
          setSuccess(true);
        }
      },
    }
  );

  const [warningCreate, { loading: wcLoading, error: wcError }] = useMutation(
    PATROL_ADD_WARNINGS[type],
    {
      refetchQueries: [
        {
          query: type === "transport" ? TRANSPORT : TRACK,
          variables: {
            id: elemId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (res: any) => {
        if (
          res?.patrolTrackAddWarnings ||
          res?.patrolPoiAddWarnings ||
          res?.patrolTransportAddWarnings
        ) {
          setSuccess(true);
        }
      },
    }
  );

  useEffect(() => {
    let problemsEnum = null;
    if (data?.configProblemListTrackWarning) {
      problemsEnum = data?.configProblemListTrackWarning;
    } else if (data?.configProblemListTransportWarning) {
      problemsEnum = data?.configProblemListTransportWarning;
    } else if (data?.configProblemListPoiWarning) {
      problemsEnum = data?.configProblemListPoiWarning;
    }
    if (problemsEnum) {
      let problemsList = [];
      for (let i = 0; i < problemsEnum.length; i++) {
        let problem = {
          label: problemsEnum[i].description,
          value: problemsEnum[i].problem,
        };
        problemsList.push(problem);
      }
      setProblems(problemsList);
    }
  }, [data]);

  const create = () => {
    if (!type || !actualWarning) return false;
    let variables: any = {};

    if (noticeId) {
      variables["id"] = noticeId;
      variables[type + "Warning"] = {
        location: actualWarning.location,
        problem: actualWarning.problem,
        ifClosed: actualWarning.ifClosed,
        description: actualWarning.description,
      };
      /* variables['notification'] = {
        ifSendToArea: actualWarning?.ifClosed ? true : sendNoti,
      }; */
      noticeToWarning({
        variables,
      });
    } else {
      variables = {
        ids: [elemId],
        warning: {
          location: actualWarning.location,
          problem: actualWarning.problem,
          ifClosed: actualWarning.ifClosed,
          description: actualWarning.description,
        },
        /* notification: {
          ifSendToArea: actualWarning?.ifClosed ? true : sendNoti,
        }, */
      };
      setSuccess(true);
      warningCreate({
        variables,
      });
    }
  };

  if (error) LOG.error(error);
  if (nwError) LOG.error(nwError);
  if (wcError) LOG.error(wcError);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex: 99999 }}
        open={open}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-12 sm:align-middle sm:max-w-3xl w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-auto">
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-2xl leading-6 font-medium text-gray-600 mb-4"
                    >
                      {name}
                      <small>
                        <br />
                        Crea avviso
                      </small>
                    </Dialog.Title>
                    {success ? (
                      <div
                        className="flex flex-1 flex-col w-full justify-center items-center p-4"
                        style={{
                          width: "100%",
                          height: 500,
                          borderTopWidth: 2,
                          borderColor: theme.vars.colors.light,
                        }}
                      >
                        <i
                          style={{
                            fontSize: 80,
                            color: theme.vars.colors.greenLight,
                          }}
                          className="icon-check_encircled_thin"
                        />
                        <p className="text-lg text-gray-800 text-center">
                          Avviso aggiunto con successo!
                        </p>
                        {actualWarning.ifClosed && (
                          <p className="text-base text-gray-800 mt-2 text-center">
                            Tra poco l'elemento verrà chiuso e verrà inviata una
                            notifica a tutti gli utenti di quest'area
                          </p>
                        )}
                        <p className="text-base text-gray-600 mt-2 text-center">
                          Quando il problema è stato risolto ricordati di
                          eliminare l'avviso nella sezione apposita.
                        </p>
                      </div>
                    ) : (
                      <div
                        className="w-full"
                        style={{
                          width: "100%",
                          height: 500,
                          borderTopWidth: 2,
                          borderColor: theme.vars.colors.light,
                        }}
                      >
                        {problems && (
                          <Select
                            zIndex={999}
                            items={problems}
                            placeholder="Seleziona il problema che desideri segnalare..."
                            onChange={(item) => {
                              setActualWarning({
                                ...actualWarning,
                                problem: item.value,
                              });
                            }}
                          />
                        )}
                        {actualWarning.problem === "other" ? (
                          <>
                            <EditBox
                              editable={true}
                              value={undefined}
                              label={"EN *"}
                              halfCol={false}
                              onPressSave={(value) => {
                                setActualWarning({
                                  ...actualWarning,
                                  description: {
                                    ...actualWarning.description,
                                    en: value,
                                  },
                                });
                              }}
                            />
                            <EditBox
                              editable={true}
                              value={
                                actualWarning?.description &&
                                actualWarning.description["it"]
                              }
                              label={"IT"}
                              halfCol={false}
                              onPressSave={(value) => {
                                setActualWarning({
                                  ...actualWarning,
                                  description: {
                                    ...actualWarning.description,
                                    it: value,
                                  },
                                });
                              }}
                            />
                            <EditBox
                              editable={true}
                              value={
                                actualWarning?.description &&
                                actualWarning.description["de"]
                              }
                              label={"DE"}
                              halfCol={false}
                              onPressSave={(value) => {
                                setActualWarning({
                                  ...actualWarning,
                                  description: {
                                    ...actualWarning.description,
                                    de: value,
                                  },
                                });
                              }}
                            />
                          </>
                        ) : null}
                        <Select
                          zIndex={998}
                          placeholder="Seleziona la posizione del problema..."
                          items={warningsLocations}
                          onChange={(value) => {
                            setActualWarning({
                              ...actualWarning,
                              location: value.value as Warning["location"],
                            });
                          }}
                        />
                        {/* <Switch.Group>
                          <div
                            className="flex items-center relative w-full px-3 text-left "
                            style={{ marginTop: 25 }}
                          >
                            <Switch.Label className="flex flex-1 mr-4 text-sm text-gray-600 text-left">
                              Invia una notifica a tutti gli utenti della tua
                              area per segnalare il problema
                            </Switch.Label>
                            <Switch
                              checked={
                                actualWarning?.ifClosed ? true : sendNoti
                              }
                              onChange={(value) => {
                                if (actualWarning?.ifClosed) {
                                  setSendNoti(true);
                                } else {
                                  setSendNoti(value);
                                }
                              }}
                              style={{ width: 45 }}
                              className={`${
                                actualWarning?.ifClosed || sendNoti
                                  ? 'bg-purple-400'
                                  : 'bg-gray-300'
                              } relative inline-flex items-center h-6 rounded-full transition-colors focus:outline-none`}
                            >
                              <span
                                className={`${
                                  actualWarning?.ifClosed || sendNoti
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group> */}
                        <Switch.Group>
                          <div
                            className="flex items-center relative w-full px-3 text-left "
                            style={{ marginTop: 25 }}
                          >
                            <Switch.Label className="flex flex-1 mr-4 text-sm text-gray-600 text-left">
                              Chiudi il tracciato fino a quando non eliminerai
                              questo avviso
                            </Switch.Label>
                            <Switch
                              checked={actualWarning.ifClosed}
                              onChange={(value) => {
                                setActualWarning({
                                  ...actualWarning,
                                  ifClosed: value,
                                });
                              }}
                              style={{ width: 45 }}
                              className={`${
                                actualWarning.ifClosed
                                  ? "bg-purple-400"
                                  : "bg-gray-300"
                              } relative inline-flex items-center h-6 rounded-full transition-colors focus:outline-none`}
                            >
                              <span
                                className={`${
                                  actualWarning.ifClosed
                                    ? "translate-x-6"
                                    : "translate-x-1"
                                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group>
                        {actualWarning.ifClosed ? (
                          <div
                            style={{ marginTop: 30 }}
                            className="p-2 text-md text-gray-600 text-center w-full bg-yellow-200 rounded-lg"
                          >
                            Attenzione, chiudendo il tracciato verrà inviata una
                            notifica push a tutti gli utenti che sono
                            attualmente nella tua area!
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 px-4 py-3 flex justify-between">
                {success ? (
                  <>
                    <div></div>
                    <Button
                      color="purple"
                      size="md"
                      className={"lg:min-w-140-px"}
                      onClick={() => {
                        setActualWarning({
                          ifClosed: false,
                          location: location,
                          problem: problem,
                          description: {
                            it: undefined,
                            en: undefined,
                            de: undefined,
                          },
                        });
                        setSuccess(false);
                        onClose && onClose();
                      }}
                    >
                      {" "}
                      {T("close")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      size="md"
                      color="white"
                      className={"lg:min-w-140-px"}
                      onClick={() => {
                        setActualWarning({
                          ifClosed: false,
                          location: location,
                          problem: problem,
                          description: {
                            it: undefined,
                            en: undefined,
                            de: undefined,
                          },
                        });
                        onCancel && onCancel();
                      }}
                    >
                      {" "}
                      {T("cancel")}
                    </Button>
                    <Button
                      color="purple"
                      disabled={
                        !actualWarning.problem ||
                        !actualWarning.location ||
                        (actualWarning.problem === "other" &&
                          !actualWarning.description?.en)
                          ? true
                          : false
                      }
                      size="md"
                      className={"lg:min-w-140-px"}
                      onClick={() => {
                        onYes && onYes();
                        create();
                      }}
                    >
                      {" "}
                      {T("send")}
                    </Button>
                  </>
                )}
              </div>
              {loading || nwLoading || wcLoading ? (
                <div
                  className="absolute top-0 left-0 flex w-full h-full items-center justify-center"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.3)",
                    zIndex: 5000,
                  }}
                >
                  <Loading color={theme.vars.colors.light} />
                </div>
              ) : null}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export { WarningCreate };
