const Platform = {
  OS: 'ios',
};

const app = 'mowi';
const appName = 'mowi';
const trackName = 'Track';
const tracksName = 'Tracks';

type lang = { [key: string]: string };

const general: lang = {
  yes: 'Si',
  no: 'No',
  back: 'Indietro',
  go: 'Inizia',
  from: 'da',
  fromDay: 'Dal',
  minutes: 'min',
  hours: 'ore',
  hoursAgo: 'ore fa',
  minAgo: 'min fa',
  to: 'a',
  and: 'e',
  sureAsk: 'Sei sicuro?',
  cancel: 'Annulla',
  accept: 'Accetta',
  acceptInvite: 'Accetta invito',
  remove: 'Rimuovi',
  delete: 'Elimina',
  restore: 'Ripristina',
  requestSent: 'Richiesta inviata',
  createdBy: 'Creato da',
  list: 'Lista',
  requests: 'Richieste',
  archive: 'Archivio',
  outOf: 'su',
  now: 'adesso',
  start: 'start',
  stop: 'stop',
  track: trackName,
  tracks: tracksName,
  services: 'servizi',
  Services: 'Servizi',
  Typologies: 'Tipologie',
  group: 'gruppo',
  groups: 'gruppi',
  transports: 'impianti',
  filters: 'filtri',
  type: 'tipo',
  unknown: 'altro',
  refresh: 'Aggiorna',
  next: 'Avanti',
  name: 'Nome',
  welcome: 'Benvenuto in ' + appName + ' ;)',
  readyToGo: 'Pronto a Partire?',
  insertDisplayName:
    'Inserisci il tuo nome o nickname per farti riconoscere dagli altri utenti Mowi',
  otherInfo: 'Altre informazioni',
  otherInfoHint:
    'Queste informazioni non sono obbligatorie ma possono aiutarci a personalizzare la tua esperienza!',
  displayName: 'Nome Visualizzato',
  birthYear: 'Anno di nascita',
  privacyAd: 'Mowi ci tiene alla privacy dei sui utenti.',
  privacyShow: 'Visualizza la nostra Informativa sulla Privacy',
  admin: 'Amministratore',
  waitAgain: 'Attendi ancora un po...',
  outOfMap: 'Fuori Mappa',
  outOfMapHint:
    "Devi essere sull'Altopiano della Paganella per poter utilizzare tutte le funzioni di  " +
    appName +
    '!',
  open: 'Aperto',
  closed: 'Chiuso',
  offline: 'sei offline',
  close: 'Chiudi',
  continue: 'Continua',
  rememberLater: 'Ricordamelo più tardi',
  edit: 'Modifica',
  create: 'Crea',
  add: 'Aggiungi',
  save: 'Salva',
  position: 'Posizione',
  active: 'Attiva',
  set: 'Imposta',
  motionAndFitness: 'Movimento e Fitness',
  energySaver: 'Risparmio Energetico',
  backgroundUpdate: 'Aggiornamento in Background',
  maintenance: 'App in manutenzione!',
  maintenanceHint:
    'Scusaci, stiamo sistemando alcune cose, riprova tra qualche minuto!',
  disabled: 'disabilitate',
  continueAnyway: 'Continua comunque',
};

const genericErrors: lang = {
  OPS: 'OPS!',
  ops: 'Ops! Errore imprevisto!',
  opsServer: 'Ops! Errore nel server',
  goBackSorry: 'Torna indietro e riprova, scusa... :(',
  tryLaterSorry: 'Riprova più tardi, scusa... :(',
  checkConnection: 'Controlla la tua connessione',
  retry: 'Riprova',
};

const localities: lang = {
  andalo: 'Andalo',
  molveno: 'Molveno',
  faiDellaPaganella: 'Fai della Paganella',
  cavedago: 'Cavedago',
  spormaggiore: 'Spormaggiore',
  ValDiSole: 'Val di Sole',
  ValDiPejo: 'Val di Pejo',
  ValDiPeio: 'Val di Peio',
  ValDiRabbi: 'Val di Rabbi',
  TonalePonteDiLegno: 'Tonale - Ponte di Legno',
};

const categories: lang = {
  bike_trails: 'Bike Trails',
  bike_tours: 'Bike Tours',
  bike_gravity: 'Bike Gravity',
  alpine_skiing: 'Sci Alpino',
  skimo: 'Sci Alpinismo',
  crosscountry_skiing: 'Sci Fondo',
  snow_shoes: 'Ciaspole',
  sleeding: 'Slittino',
  trekking: 'Trekking',
};

const trackTypologies: lang = {
  ut: 'Enduro Tour',
  fa: 'Family',
  xc: 'Crosscountry',
  en: 'Shared Trail',
  dh: 'Bike Park',
  ut_min: 'ET',
  fa_min: 'FA',
  xc_min: 'XC',
  en_min: 'ST',
  dh_min: 'BP',
  blue: 'Piste Azzurre',
  red: 'Piste Rosse',
  black: 'Piste Nere',
  snowPark: 'Snowpark',
  firstSteps: 'Primi Passi',
  cross_green: 'Piste Verdi',
  cross_blue: 'Piste Azzurre',
  cross_red: 'Piste Rosse',
  cross_black: 'Piste Nere',
};

const difficulties: lang = {
  peaceful: 'molto facile',
  easygoing: 'facile',
  halfway: 'intermedio',
  challenging: 'impegnativo',
  rocky: 'difficile',
  adrenaline: 'estremo',
  beginner: 'principiante',
  intermediate: 'intermedio',
  advanced: 'esperto',
  beginner_level: 'principiante',
  intermediate_level: 'intermedio',
  advanced_level: 'esperto',
  blue_level: 'principiante',
  red_level: 'intermedio',
  black_level: 'esperto',
  snowPark_level: 'snowpark',
  firstSteps_level: 'primi passi',
  cross_green_level: 'primi passi',
  cross_blue_level: 'principiante',
  cross_red_level: 'intermedio',
  cross_black_level: 'esperto',
};

const tagServices: lang = {
  aerialway_chair_lift: 'Seggiovia',
  bicycle_bus_stop: 'Fermata bus',
  parking: 'Parcheggio',
  ski_bus_station: 'Fermata SkiBus',
  bicycle_rent: 'Noleggio bike',
  fountain: 'Fontana',
  shower: 'Docce',
  toilette: 'Toilette',
  point_danger: 'Pericolo',
  point_forbidden: 'Divieto',
  point_warning: 'Attenzione',
  sleep: 'Letti',
  charging_station: 'Stazione ricarica',
  kids_trail: 'Kids trail',
  kinderclub: 'Kinderclub',
  pharmacy: 'Farmacia',
  track_pump: 'Track Pump',
  outdoor_school: 'Scuola',
  school: 'Scuola',
  aerialway_gondola: 'Cabinovia',
  aerialway_lift: 'Impianto risalita',
  aerialway_station: 'Stazione iompianto',
  historical_point: 'Punto storico',
  panorama: 'Punto panoramico',
  alpine_hut: 'Rifugio',
  alpine_hut_sponsored: 'Rifugio top',
  food: 'Bar',
  restaurant: 'Ristorante',
  my_hotel: 'MyHotel',
  bicycle_repair: 'Officina bike',
  bicycle_wash: 'Lavaggio bike',
  rent: 'Noleggio',
  sell: 'Negozio',
  shop: 'Negozio',
  shop_bicycle: 'Negozio bike',
  doctor: 'Medico',
  tourist_attraction: 'Attrazione',
  atm: 'ATM',
  bicycle_sell: 'Negozio bike',
  repair_kit: 'Kit riparazione',
  shop_ticket: 'Biglietteria',
  info_point: 'Info Point',
  info_point_outdoor: 'Info Point',
  info_point_panel: 'Info Panel',
  info_point_panel_ar: 'Info Panel AR',
  info_point_sponsored: 'Info Point',
  alpineskiing_gear: 'Attrezzatura sci',
  andalo_life: 'Andalo Life',
  animals_no: 'Animali vietati',
  animals_yes: 'Animali permessi',
  baby_backpack: 'Zaino per bambini',
  bus: 'Bus',
  crampons: 'Ramponi',
  crosscountry_skiing_gear: 'Attrezzatura fondo',
  disabled_instructor: 'Istruttore disabili',
  googles_and_mask: 'Maschere ed occhiali',
  gym: 'Palestra',
  handicap_yes: 'Adatto a disabili',
  helmet: 'Casco',
  ice_skating: 'Pattinaggio',
  instructor_meeting_point: 'Ritrovo istruttore',
  kart: 'Kart',
  party: 'Party',
  pedestrians_no: 'Pedoni non ammessi',
  pedestrians_yes: 'Pedoni ammessi',
  riding_school: 'Scuola equitazione',
  ski_boots: 'Scarponi da sci',
  ski_instructor: 'Istruttore sci',
  ski_storage: 'Deposito sci',
  skimo_gear: 'Attrezzatura alpinismo',
  snow_clothing: 'Abbigliamento da neve',
  snow_shoes_gear: 'Attrezzatura ciaspole',
  snow_tubing: 'Snow tubing',
  snowboard_gear: 'Attrezzatura snowboard',
  snowboard_instructor: 'Istruttore snowboard',
  snowscoot: 'Snowscoot',
  snowscoot_gear: 'Attrezzatura snowscoot',
  spa: 'Spa',
  swimming_pool: 'Piscina',
  train: 'Treno',
};

const tagFilters = {
  aerialway: 'Impianti',
  bicycle_info: 'Info Point',
  bike_shops: 'Negozi Bike',
  bicycle_facilities: 'Servizi Bike',
  discover: 'Discover',
  eat_sleep: 'Rifugi',
  public_utility: 'Utilità',
  trails_alert: 'Info Allerta',
  skill_area: 'Skill Area',
  ski_shops: 'Negozi Sci',
  ski_info: 'Info Point',
  my_hotel: 'Il mio Hotel',
  ski_school: 'Scuola sci',
};

const appInit = {
  loadInit: 'Start ' + appName,
  loadFonts: 'Inizializzazione fonts',
  loadFontsErr: 'Impossibile caricare fonts',
  loadCache: 'Inizializzazione dati cache',
  loadAuth: 'Controllo autenticazione',
  loadAuthErr: 'Impossibile autenticare dispositivo',
  loadClient: 'Creazione Client',
  loadClientErr: 'Impossibile creare client',
  loadCheckBundle: 'Controllo Bundle Dati',
  loadCheckBundleErr: 'Aggiornamento necessario',
  installUpdate: "Aggiorna l'app",
  loadCheckBundleAsk:
    'Ti serve la nuova versione di ' + appName + ' per continuare!',
  loadDownloadBundle: 'Download nuovo Bundle Dati',
  loadCacheBundle: 'Carico Bundle Dati da Cache',
  loadBundleErr: 'Impossibile caricare Bundle Dati',
  loadFilters: 'Imposto filtri mappa',
  loadFiltersErr: 'Errore nel caricamento filtri',
  loadFileServe: 'Avvio server file statici',
  loadFileServeErr: 'Impossibile avviare server file statici',
  loadModel: 'Creazione modello mappa',
  insertTrails: 'Disegno tracciati su mappa',
  loadComplete: 'Caricamento completato',
  loadMapError: 'Scusa, Errore nel caricamento mappa',
};

const login: lang = {
  login: 'Accedi',
  logout: 'Esci',
  logoutExt: 'Disconnetti utente',
  signup: 'Registrati',
  fbBtn: 'Continua con Facebook',
  mailBtn: "Continua con l'e-mail",
  googleBtn: 'Continua con Google',
  newAccount: 'Crea un nuovo account Mowi Space!',
  loginAccount: 'Accedi con il tuo account Mowi Space!',
  passwordHint: 'La password deve contenere almeno 8 caratteri',
  passwordForgot: 'Hai dimenticato la tua password?',
  acceptAndregister: 'Accetta e registrati',
  userPassNull: 'Username e Password obbligatori!',
  userPassErr: 'Username o Password errati',
  passShort: 'La tua password è troppo corta!',
  userInfoNull: 'Scusa, il tuo DisplayName è obbligatorio!',
  wrongYear: 'Anno di nascita errato!',
  forbiddenName: 'Bel tentativo! Cambia Nome! ;)',
  moreThan13: 'Devi avere più di 13 anni per poterti iscrivere!',
  emailNotVerified: 'Email non verificata!',
  userDataNotComplete: 'Dati utente non completi!',
  loginSuccess: 'Accesso effettuato con successo',
  verifyEmail: "Verifica l'e-mail",
  completeProfile: 'Completa il tuo Profilo',
  recoverPassword: 'Reimposta password',
  checkEmail: 'Controlla la tua Email!',
  mailLink: 'Ti abbiamo inviato il link per reimpostare la tua passowrd!',
  recoverPasswordHint: 'Inserisci la mail con la quale ti sei iscritto',
  recoverYourPassword: 'Reimposta la password',
  resendVerify: 'Non hai ricevuto il codice? richiedilo qui',
};

const privacyRequest: lang = {
  privacy: 'Informativa privacy',
  canContact: 'Possiamo Contattarti?',
  weUpdateYouOn: 'Vorremmo tenerti aggiornato su:',
  reason1: 'Stato ' + tracksName + ' e costruzione nuovi tracciati',
  reason2: 'Nuove funzionalità dell’App',
  reason3: "Novità ed eventi dell'Area",
  reason4: 'Alcuni suggerimenti per sfruttare al meglio ' + appName,
};

const profile: lang = {
  bornWhen: 'Nato/a nel',
  male: 'Maschio',
  female: 'Femmina',
  country: 'Paese',
  sex: 'Sesso',
  myProfile: 'Il mio profilo',
  profilePhoto: 'Foto profilo',
  photoPermissions:
    appName +
    " non può accedere alle tue foto, vai nelle impostazioni dell'app per modificare i permessi",
};

const friends: lang = {
  friend: 'Amico',
  myFirends: 'I miei amici',
  isFriend: 'Siete già amici',
  revokeFriendship: 'Rimuovi amicizia',
  requestFriendship: 'Richiedi amicizia',
  searchHereFriend: 'Cerca qui un nuovo amico',
  addFriend: 'Aggiungi amico',
  friendSearchNull: 'Nessun utente trovato con questo nome',
  friendsRequestsNull: "Nessuna richiesta d'amicizia ricevuta",
  friendsNull: 'Non hai ancora nessun amico,\ncercali ora per nome!',
  friendsNullGroup:
    'Non hai ancora nessun amico,\naggiungine qualcuno per poter creare dei gruppi!',
  friendIsInGroup: 'Fa già parte di questo gruppo',
};

const group: lang = {
  groupDetail: 'Dettagli gruppo',
  groupNew: 'Aggiungi componenti',
  groupNoActives: 'Nessun gruppo attivo',
  partecipate: 'Partecipa',
  ongoing: 'In progress',
  scheduled: 'Scheduled',
  partecipatingNow: 'Stai partecipando ora',
  partecipatingNowHe: 'Sta partecipando',
  partecipatingNot: 'Non stai partecipando',
  partecipatingNotHe: 'Non partecipa',
  partecipatingBefore: 'You joined this group',
  onlyOnePartecipation: 'PUOI PARTECIPARE SOLO AD UN GRUPPO ALLA VOLTA',
  components: 'Componenti',
  startAt: 'Started at',
  activeFor: 'Active for',
  overAt: 'Over at',
  inactive: 'Inattivo',
  moving: 'In movimento',
  still: 'Fermo',
  partecipants: 'Partecipanti',
  groupList: 'Lista gruppi',
  createNew: 'Crea nuovo',
  createHang: 'Crea gruppo',
  groupNoLogin: 'Accedi a ' + appName + ' per creare e partecipare ai gruppi!',
  noGroups:
    'Non sei in nessun gruppo, creane uno ora ed aggiungi i tuoi amici!',
  groupNamePlaceholder: 'Gruppo tra amici, famigliari, ecc...',
  groupDescPlaceholder: 'Luogo di ritrovo, scopo del gruppo, orari, ecc...',
  groupFriendSelect: 'Cerca tra i tuoi amici',
  removeUser: 'Rimuovi componente',
  groupDelete: 'Elimina gruppo',
  groupDismiss: 'Abbandona gruppo',
  groupPhoto: 'Immagine di copertina',
  title: 'Titolo',
  description: 'Descrizione',
  promote: 'Rendi Amministratore',
  addComponents: 'Aggiungi componenti',
  editGroup: 'Modifica gruppo',
  groupPermissionsTitle:
    'Per un corretto funzionamento dei gruppi devi concedere a ' +
    appName +
    ' questi permessi',
  groupPermissionsPosition:
    'Condividi la posizione con i componenti del gruppo anche quando l\'app non è in uso, devi impostarla su "Sempre"',
  groupPermissionsMotion:
    'Monitora i tuoi movimenti, risparmia batteria evitando invii quando sei fermo',
  groupPermissionsBattery:
    Platform.OS === 'ios'
      ? 'Disabilita il risparmio energetico cosi da permettere a ' +
        appName +
        "  di comunicare anche quando non usi l'app, penseremo noi a preservare la tua batteria"
      : 'Ti consigliamo di disabilitare l’ottimizazione della batteria per essere sicuro che i gruppi funzionino correttamente, penseremo noi a risparmiare la tua carica',
  groupPermissionsBackground:
    "Abilita l'aggiornamento in background dell'app cosi da permettere a " +
    appName +
    " di comunicare anche quando non usi l'app",
  preciseLocation: 'Posizione Esatta',
  groupPermissionsPreciseLocation:
    'Condividi la tua posizione esatta per poterla mostrare a te e ai tuoi amici in gruppo, clicca su "Attiva" per richiederla temporaneamente oppure vai nelle impostazioni per abilitarla permanentemente',
  partecipating: 'Stai partecipando ad un gruppo',
};

const userMenu: lang = {
  yourFriends: 'I tuoi amici',
  newRequests: 'nuove richieste',
  yourGroups: 'I tuoi gruppi',
  showTutorialAgain: 'Rivedi tutorial',
  credits: 'Credits',
  settings: 'Impostazioni',
  advancedSettings: 'Impostazioni avanzate',
};

const elements: lang = {
  infoView: 'Panoramica',
  details: 'Dettagli',
  locality: 'Località',
  info: 'Info',
  media: 'Media',
  reviews: 'Commenti',
  notice: 'Avvisi',
  cardPressDetails: 'Premi sulla card per scoprire i dettagli',
};

const tracks: lang = {
  trackInfoErr: 'Ops, nessun dato disponibile per questo ' + trackName,
  trackMediaErr:
    'Non sono ancora presenti foto o video per questo ' + trackName + '!',
  IMBA: 'IMBA',
  artificial: 'Artificiale',
  natural: 'Naturale',
  startStation: 'Partenza',
  endStation: 'Arrivo',
  difficulty: 'Difficoltà',
  length: 'Lunghezza',
  duration: 'Durata',
  altimetryPlus: 'Salita',
  altimetryMinus: 'Discesa',
  maxAltitude: 'Quota Max',
  minAltitude: 'Quota Min',
  technicalAbility: 'Difficoltà Tecnica',
  physicalEfford: 'Impegno Fisico',
  emotion: 'Emozione',
  landscape: 'Paesaggio',
  suggestedPeriod: 'Periodo Migliore',
  isCircular: 'Tracciato Circolare',
  isFamily: 'Adatto a famiglie e bambini',
  howToArrive: 'Come arrivare',
  trackFilterNull:
    'Non sono presenti tracciati che soddisfano i filtri da te impostati!',
  maxSlope: 'Pendenza Max',
  loginTrackHint:
    'Per vedere avvisi e alert in tempo reale sui tracciati, accedi o crea ora gratuitamente un account Mowi Space!',
};

const pois: lang = {
  poiInfoErr: 'Ops, nessun dato disponibile per questo servizio',
  poiMediaErr: 'Non sono ancora presenti foto o video per questo servizio!',
  altitude: 'Quota',
  poiFilterNull:
    'Non sono presenti servizi che soddisfano i filtri da te impostati!',
};

const transports: lang = {
  pedestrians_yes: 'Ammessi pedoni',
  pedestrians_no: 'Divieto pedoni',
  isForDisabled: 'Accesso disabili',
  isForSnowscoot: 'Ammesso snowscoot',
  transportInfoErr: 'Ops, nessun dato disponibile per questo impianto',
  transportMediaErr:
    'Non sono ancora presenti foto o video per questo impianto!',
  transportFilterNull:
    'Non sono presenti impianti che soddisfano i filtri da te impostati!',
};

const intro: lang = {
  begin: 'Inizia',
  nextSlide: 'Prosegui',
  slideToBegin: 'Scorri fino alla fine per iniziare',
  useTerm: "termini d'utilizzo",
  privacyPolicy: 'informativa sulla privacy',
  iAgree: "Sono d'accordo",
  iDoNotAgree: "Non sono d'accordo",
  maybeLater: 'Forse più tardi',
  pressConsent: 'Premi "Sono d\'accordo" per accettare i nostri',
  sureDeny: 'Si, nega consenso',
  abortDeny: 'No, torna indietro',
  denyAlert: 'In questo modo non potrai utilizzare ' + appName,
  welcomeTitle: 'Benvenuto\nin ' + appName,
  welcomeP1:
    'Sfrutta al meglio il potenziale della nostra App e goditi ogni istante in assoluta tranquillità',
  welcomeP2: 'Ci auguriamo che la tua esperienza con noi sia strepitosa ;)',
  privacyLockTitle: 'La tua privacy\nè importante',
  privacyLockP1:
    'La tutela della tua privacy è un aspetto molto importante per Mowi. Per questo motivo vogliamo informarti su quali dati utilizziamo e come li impieghiamo',
  privacyUserTitle: 'Informazioni\npersonali',
  privacyUserP1:
    'Durante l’uso dell’App potremmo richiedere alcuni tuoi dati, come ad es. la tua posizione per permetterti di visualizzare in mappa dove ti trovi all’interno dell’area, o altre informazioni in fase di registrazione',
  privacyStarTitle: 'Per migliorare la\ntua esperienza',
  privacyStarP1:
    'Vogliamo continuare a migliorare i nostri servizi per garantirti sempre la miglior esperienza possibile. A questo scopo raccogliamo, in modo totalmente anonimo, alcuni dati statistici e di utilizzo dell’app',
  privacyAlertTitle: 'Tieniti\ninformato',
  privacyAlertP1:
    'Ti aggiorneremo sulle nuove fantastiche funzionalità Mowi e quando avremo qualcosa di utile o semplicemente bello da condividere con te',
  privacyAlertP2:
    Platform.OS === 'ios'
      ? 'Verrà richiesto il tuo consenso a ricevere notifiche, potrai sempre personalizzare le tue notifiche cliccando sulla campanella in alto'
      : 'Potrai sempre personalizzare le tue notifiche cliccando sulla campanella in alto',
  privacyGroupTitle: 'Gruppi\nin tempo reale',
  privacyGroupP1:
    'Condividi la tua posizione con un gruppo di amici o familiari. Sei tu a decidere quando attivare o disattivare la posizione condivisa mediante il pulsante “Partecipa/Stop”',
  privacyGroupP2:
    'Per questo richiederemo il tuo consenso per utilizzare la localizzazione anche quando non utilizzi l’App (background) ed i dati di movimento, condividerai la tua posizione solo con gli utenti del gruppo al quale stai partecipando',
  privacyMarketingTitle: 'Notizie dalla tua\n' + app + 'Area',
  privacyMarketingP1:
    'Tieniti informato su aperture e chiusure degli impianti di risalita e piste',
  privacyMarketingP2:
    'Ricevi avvisi, notizie, promozioni ed eventi dalla tua ' + app + 'Area',
  privacyMarketingP3:
    'Per inviarti comunicazioni utili e pertinenti potremo aver bisogno di rilevare tua ' +
    app +
    'Area tramite la tua posizione',
  privacyMarketingP4:
    'Potrai sempre cambiare le impostazioni di notifica dal tuo profilo',
  selectAreaTitle: "Seleziona l'area\na cui sei interessato",
  selectAreaP1:
    '*' +
    appName +
    ' è attualmente disponibile per la ' +
    app +
    ' area Paganella\nPresto nuove fantastiche destinazioni!',
};

const find: lang = {
  whatNeed: 'Cosa\nstai cercando?',
  trailsProgression: 'Lista ' + tracksName,
  aerialway_lifts: 'Impianti di risalita',
  alpine_huts: 'Rifugi',
  charging_station: 'Stazioni di ricarica',
  rent_and_shops: 'Noleggi e negozi bike',
  repairs_mtb: 'Officine MTB',
  find: 'Cerca',
  trailDetail: 'Dettaglio ' + trackName,
  serviceDetail: 'Dettaglio Servizio',
  rentAndSchoolSki: 'Noleggi e scuole sci',
};

const notifications: lang = {
  notifications: 'Notifiche',
  readAll: 'Leggi tutte',
  notiCallToLoginTitle:
    'Ti senti frustrato quando arrivi ad un impianto di risalita o ' +
    trackName +
    ' e lo trovi chiuso?',
  notiCallToLoginDesc:
    'Se non lo hai già crea il tuo account Mowi Space per poter ricevere le notifche sullo stato dei tracciati, servizi ed impianti di risalita in tempo reale!',
  noNotifications: 'Nessuna notifica da leggere...',
  notiSettings: 'Impostazioni notifiche',
  notiSettingsTitle: 'Scegli quali notifiche ricevere',
  notiSettingsRT: 'Informazioni in Tempo Reale',
  trailsStatus: 'Stato piste e tracciati',
  poisStatus: 'Stato servizi',
  transportsStatus: 'Stato impianti e transporti',
  notiExpire: 'Per una settimana',
  notiForever: 'Sempre',
  notiNever: 'Mai',
  notiInitTitle:
    'Vuoi ricevere le notifiche push sullo stato in tempo reale di piste ed impianti di risalita?',
  notiInitHint:
    'Potrai sempre modificare questa scelta nelle impostazioni del tuo profilo',
  notiPermission:
    "Non hai attivato le notifiche!\nPer poter ricevere avvisi e comunicazioni devi modificare i permessi di notifica dalle impostazioni dell'app",
  rtStatus: 'Stato in tempo reale',
  rtStatusP:
    "Ricevi notifiche quando c'è un allerta o cambia lo stato di un tracciato, di un impianto o di un attività nell'area",
  newsAndAlerts: 'Notizie e Avvisi',
  newsAndAlertsP:
    "Ricevi notifiche quando ci sono nuove notizie e avvisi utili o di sicurezza dall'area o da Mowi",
  promoAndEvents: 'Eventi e Promo',
  promoAndEventsP:
    "Ricevi notifiche su eventi, comunicazioni e promo dall'area, dalle attività o da Mowi",
  notiFromArea: "Riceverai notifiche da quest'area",
  yourActualArea: 'La tua area attuale',
  smartNoti: 'Selezione Smart',
  smartNotiP:
    "Consenti a Mowi di selezionare le tue notifiche cosi da ricevere solo quelle a cui sei veramente interessato, ad esempio inviandoti avvisi, eventi e stato tracciati/impianti soltanto quando sei effettivamente all'interno dell'area",
};

const chat: lang = {
  chatCallToLoginTitle: 'Cerchi amici o qualcuno che ti dia dei consigli?',
  chatCallToLoginDesc:
    'Crea il tuo account ' +
    appName +
    " per poter accedere alla chat dell'area e connetterti alla nostra community!",
};

const location: lang = {
  locationDisactive: 'Posizione disattivata',
  locationPermissions:
    'Attiva la tua posizione dalle impostazioni per poter vedere dove sei sulla mappa',
};

const other: lang = {
  debug: 'DEBUG',
  ComingSoon: 'Presto disponibile',
  avalancheRisk: 'Rischio\nValanghe',
  openLifts: 'Impianti aperti',
  openTracks: 'Tracciati aperti',
};

const promots: lang = {
  remain: 'Rimani qui',
  changeArea: 'Cambia area',
  chooseArea: "Segli quest'area",
};

const notices: lang = {
  newNotice: 'Nuova segnalazione',
  noticesList: 'Lista Segnalazioni',
  newWarning: 'Crea Avviso',
  noticeProblemsTracks:
    'Segnalaci eventuali problemi che hai riscontrato su questo tracciato',
  noticeProblemHint:
    'Aiuta gli altri utenti mettendoci al corrente del problema! Noi cercheremo di risolverlo il prima possibile, grazie!',
  noticeProblemLogin:
    'Accedi o crea un account Mowi Space per poter inviare delle segnalazioni!',
  send: 'Invia',
  sendNotice: 'Invia segnalazione',
  thanksForNotice: 'Grazie per la tua segnalazione!',
  weResolveSoon:
    'Faremo il possibile per risolvere il problema il prima possibile!',
  noticeProblem: 'Segnala un problema',
  compileCamps: 'Compila i campi che seguono',
  wichProblem: 'Di che problema si tratta?',
  selectRightProblem:
    'Seleziona la tipologia di problema più vicina a quella da te riscontrata',
  whereProblem: 'Dove si trova il problema?',
  problem_all: 'Su tutto il percorso',
  problem_start: "All'inizio",
  problem_center: 'A metà',
  problem_end: 'Alla fine',
  selectRightWhere:
    'Seleziona il punto del tracciato più vicino al problema da te riscontrato',
  inOtherArea: 'Da un altra area',
};

export const t: lang = {
  ...general,
  ...genericErrors,
  ...localities,
  ...categories,
  ...trackTypologies,
  ...difficulties,
  ...tagServices,
  ...tagFilters,
  ...appInit,
  ...login,
  ...privacyRequest,
  ...profile,
  ...friends,
  ...group,
  ...userMenu,
  ...elements,
  ...tracks,
  ...pois,
  ...transports,
  ...intro,
  ...find,
  ...notifications,
  ...chat,
  ...location,
  ...promots,
  ...notices,
  ...other,
};
