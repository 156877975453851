import gql from "graphql-tag";

interface Notification {
  id: string;
  sentOnDate: string;
  createdAt: string;
  lastUpdate: string;
  occurrances: number;
  numberOfRecipients: number;
  status: "waiting" | "approved" | "sent" | "cancel" | "rejected";
  info: {
    it: string;
    en: string;
    de: string;
  };
  recipients:
    | "devices"
    | "devicesInArea"
    | "devicesNowInArea"
    | "devicesWithUser"
    | "devicesInAreaWithUser"
    | "devicesNowInAreaWithUser"
    | "noOne";
  creator: {
    _id: string;
    displayName: string;
  };
  area: string;
  notificationType:
    | "frienshipRequest"
    | "frienshipAccepted"
    | "trackWarning"
    | "poiWarning"
    | "transportWarning"
    | "realTimeTrackStatusOpen"
    | "realTimeTrackStatusClose"
    | "realTimePoiStatusOpen"
    | "realTimePoiStatusClose"
    | "realTimeTransportStatusOpen"
    | "realTimeTransportStatusClose"
    | "groupUserAdd"
    | "groupUserRemove"
    | "patrolUserNewNotice";
  involvedNames: string[];
  involvedIds: string[];
  head: {
    it: string;
    en: string;
    de: string;
  };
  content: {
    it: string;
    en: string;
    de: string;
  };
  description: {
    it: string;
    en: string;
    de: string;
  };
}

const NOTIFICATION_FRAGMENT = gql`
  fragment notificationFragment on Notification {
    id
    sentOnDate
    createdAt
    lastUpdate
    occurrances
    numberOfRecipients
    status
    info {
      it
      en
      de
    }
    recipients
    creator {
      _id
      displayName
    }
    area
    notificationType
    involvedNames
    involvedIds
    head {
      it
      en
      de
    }
    content {
      it
      en
      de
    }
    description {
      it
      en
      de
    }
  }
`;

const AREA_NOTIFICATIONS = gql`
  query AREA_NOTIFICATIONS {
    areaAllNotifications {
      ...notificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

const AREA_CREATE_NOTIFICATION = gql`
  mutation areaCreateNotification(
    $recipients: AreaNotificationRecipients!
    $head: NotificationInfoInput!
    $content: NotificationInfoInput
    $description: NotificationInfoInput
  ) {
    areaCreateNotification(
      recipients: $recipients
      head: $head
      content: $content
      description: $description
    ) {
      id
    }
  }
`;

const AREA_CREATE_TEST_NOTIFICATION = gql`
  mutation areaCreateTestNotification(
    $head: NotificationInfoInput!
    $content: NotificationInfoInput
    $description: NotificationInfoInput
  ) {
    areaCreateTestNotification(
      head: $head
      content: $content
      description: $description
    )
  }
`;

const AREA_CANCEL_NOTIFICATION = gql`
  mutation areaUpdateNotification($id: ID!) {
    areaUpdateNotification(id: $id, status: cancel) {
      ...notificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export {
  AREA_CREATE_NOTIFICATION,
  AREA_NOTIFICATIONS,
  AREA_CREATE_TEST_NOTIFICATION,
  AREA_CANCEL_NOTIFICATION,
};
export type { Notification };
