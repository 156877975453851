const screenHeight = '100vh';
const screenWidth = '100vw';

type Colors =
  | 'mowi'
  | 'green'
  | 'greenLight'
  | 'greenDark'
  | 'cyan'
  | 'blue'
  | 'blueLight'
  | 'blueDark'
  | 'yellow'
  | 'orange'
  | 'violet'
  | 'red'
  | 'redLight'
  | 'grey'
  | 'greyLight'
  | 'greyDark'
  | 'light'
  | 'dark'
  | 'white'
  | 'black'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'darkOpacity'
  | 'lightOpacity'
  | 'default';

let colors: { [Color in Colors]: string } = {
  mowi: '#268FCD',
  green: '#599B4E',
  greenLight: '#8CC37D',
  greenDark: '#649A5C',
  cyan: '#1875B2',
  blue: '#008BAC',
  blueLight: '#79B3D3',
  blueDark: '#345087',
  yellow: '#F4C84D',
  orange: '#B29C6D',
  violet: '#5A4282',
  red: '#bc3535',
  redLight: '#d15c5c',
  grey: '#5A5A5A',
  greyLight: '#989898',
  greyDark: '#353535',
  light: '#EAEAEA',
  dark: '#101111',
  white: '#FFFFFF',
  black: '#000000',
  primary: '#5A4282',
  secondary: '#8CC37D',
  info: '#008BAC',
  success: '#599B4E',
  error: '#D15D5D',
  warning: '#B29C6D',
  darkOpacity: 'rgba(16,17,17,0.8)',
  lightOpacity: 'rgba(255,255,255,0.8)',
  default: '#525251',
};

type Difficulties =
  | 'peaceful'
  | 'easygoing'
  | 'halfway'
  | 'challenging'
  | 'rocky'
  | 'adrenaline';
let difficulties = {
  peaceful: 'greyLight',
  easygoing: 'green',
  halfway: 'blueLight',
  challenging: 'orange',
  rocky: 'red',
  adrenaline: 'dark',
};

type TagServices =
  | 'aerialway_chair_lift'
  | 'bicycle_bus_stop'
  | 'parking'
  | 'ski_bus_station'
  | 'bicycle_rent'
  | 'fountain'
  | 'shower'
  | 'toilette'
  | 'point_danger'
  | 'point_forbidden'
  | 'point_warning'
  | 'sleep'
  | 'charging_station'
  | 'kids_trail'
  | 'kinderclub'
  | 'pharmacy'
  | 'track_pump'
  | 'outdoor_school'
  | 'school'
  | 'aerialway_gondola'
  | 'aerialway_lift'
  | 'aerialway_station'
  | 'historical_point'
  | 'panorama'
  | 'alpine_hut'
  | 'alpine_hut_sponsored'
  | 'food'
  | 'restaurant'
  | 'my_hotel'
  | 'bicycle_repair'
  | 'bicycle_wash'
  | 'rent'
  | 'sell'
  | 'shop'
  | 'shop_bicycle'
  | 'doctor'
  | 'tourist_attraction'
  | 'atm'
  | 'bicycle_sell'
  | 'repair_kit'
  | 'shop_ticket'
  | 'info_point'
  | 'info_point_outdoor'
  | 'info_point_panel'
  | 'info_point_panel_ar'
  | 'info_point_sponsored';

let tagServiceColor: { [Services in TagServices]: string } = {
  aerialway_chair_lift: '#8B7BBA',
  bicycle_bus_stop: '#3b6f91',
  parking: '#3b6f91',
  ski_bus_station: '#3b6f91',
  bicycle_rent: '#4190ce',
  fountain: '#4190ce',
  shower: '#4190ce',
  toilette: '#4190ce',
  point_danger: '#545353',
  point_forbidden: '#545353',
  point_warning: '#545353',
  sleep: '#55A9BC',
  charging_station: '#5dce1b',
  kids_trail: '#5dce1b',
  kinderclub: '#5dce1b',
  pharmacy: '#5dce1b',
  track_pump: '#5dce1b',
  outdoor_school: '#783c78',
  school: '#783c78',
  aerialway_gondola: '#8B7BBA',
  aerialway_lift: '#8B7BBA',
  aerialway_station: '#8B7BBA',
  historical_point: '#936F57',
  panorama: '#936F57',
  alpine_hut: '#b12a2a',
  alpine_hut_sponsored: '#b12a2a',
  food: '#b12a2a',
  restaurant: '#b12a2a',
  my_hotel: '#c18844',
  bicycle_repair: '#CC4484',
  bicycle_wash: '#CC4484',
  rent: '#CC4484',
  sell: '#CC4484',
  shop: '#CC4484',
  shop_bicycle: '#CC4484',
  doctor: '#d13a26',
  tourist_attraction: '#fbbf16',
  atm: '#FF9A15',
  bicycle_sell: '#FF9A15',
  repair_kit: '#FF9A15',
  shop_ticket: '#FF9A15',
  info_point: '#ffd00d',
  info_point_outdoor: '#ffd00d',
  info_point_panel: '#ffd00d',
  info_point_panel_ar: '#ffd00d',
  info_point_sponsored: '#ffd00d',
};

const typologyColor = {
  dh: '#008BAC',
  en: '#5A4282',
  xc: '#d65151',
  fa: '#649A5C',
  ut: '#e9752f',
};

const vars = {
  fontSizes: {
    xs: 6,
    s: 8,
    m: 10,
    l: 14,
    xl: 20,
    xxl: 28,
  },
  colors,
  screen: {
    width: screenWidth,
    height: screenHeight,
  },
  base: {
    margin: 5,
    radius: 10,
    border: 0,
    textColor: colors.white,
    textFont: 'kanit-regular',
    textFontBold: 'kanit-bold',
    iconFont: 'mowi-icons',
    bkgColor: colors.dark,
    difficulties,
  },
};

// Texts

const textDefault = {
  color: vars.base.textColor,
  fontFamily: vars.base.textFont,
  fontSize: vars.fontSizes.l,
};

const text = {
  xs: { ...textDefault, fontSize: vars.fontSizes.xs },
  s: { ...textDefault, fontSize: vars.fontSizes.s },
  m: { ...textDefault, fontSize: vars.fontSizes.m },
  l: { ...textDefault, fontSize: vars.fontSizes.l },
  xl: { ...textDefault, fontSize: vars.fontSizes.xl },
  bold: {
    fontFamily: 'kanit-bold',
  },
  blockCenter: {
    width: '100%',
    textAlign: 'center',
  },
};

// Generals

const general = {
  fullAbsulute: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: screenWidth,
    height: screenHeight,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  pageContainer: {
    width: '100%',
    height: '100%',
    maxWidth: 1000,
    borderColor: colors.greyDark,
    alignSelf: 'center',
  },
  pageContainerMin: {
    width: '100%',
    height: '100%',
    maxWidth: 600,
    alignSelf: 'center',
  },
};

const helpers = {
  difficultiesColor: (level: any) => {
    let color: any = vars.colors.default;
    let lvl: Difficulties = level;
    if (level && difficulties[lvl]) {
      color = difficulties[lvl];
    }
    return color;
  },
  getTagServiceColor: (tagService: any) => {
    let color: any = vars.colors.default;
    let tag: TagServices = tagService;
    if (tag && tagServiceColor[tag]) {
      color = tagServiceColor[tag];
    }
    return color;
  },
  getTypologyColor: (typology: string) => {
    let color: any = vars.colors.default;
    if ((typologyColor as any)[typology]) {
      color = (typologyColor as any)[typology];
    }
    return color;
  },
  checkColor: (color: any) => {
    let resColor: any = vars.colors.default;
    let cColor: Colors = color;
    if (color && colors[cColor]) {
      resColor = color;
    }
    return resColor;
  },
};

const theme = {
  vars,
  general,
  text,
  helpers,
};

export { theme };
export type { Difficulties, Colors };
