import React from 'react';
import { PoisList } from '../../../components';

interface PoisViewProps {}

const PoisView = (props: PoisViewProps) => {
  return (
    <>
      <PoisList />
    </>
  );
};

export { PoisView };
