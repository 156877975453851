import React from 'react';
import { PatrolsAdd, PatrolsList } from '../../../components';

interface PatrolsViewProps {}

const PatrolsView = (props: PatrolsViewProps) => {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-2">
            <h6 className="text-gray-500 text-xl font-bold">Aggiungi Patrol</h6>
          </div>
          <PatrolsAdd />
        </div>
        <div className="w-full lg:w-8/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-2">
            <h6 className="text-gray-500 text-xl font-bold">
              Lista Patrols Area
            </h6>
          </div>
          <PatrolsList />
        </div>
      </div>
    </>
  );
};

export { PatrolsView };
