import React, { Fragment, useState } from "react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useQuery } from "@apollo/client";

import { useGlobalState } from "../../stores/appState";
import { ENV } from "../../config";
import { logout } from "../../libs/apollo/auth";
import { User, USER_ME } from "../../stores/queries/users";
import { ACTUAL_AUTH } from "../../stores/db/auth";

const UserMenu = () => {
  const [auth] = useGlobalState("auth");

  const { data } = useQuery<{
    userMe: User;
  }>(USER_ME[ACTUAL_AUTH.app as "bike" | "ski"], {
    fetchPolicy: "network-only",
    skip: !auth.user,
  });

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  return (
    <>
      <Popover>
        <Popover.Button
          ref={setReferenceElement as any}
          className={"focus:outline-none outline-none"}
        >
          <div
            className="items-center flex"
            style={{ height: 48, marginTop: 5, marginLeft: 10 }}
          >
            <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-2xl hover:shadow-lg ease-linear transition-all duration-150">
              <i className="fa fa-user fa-lg" />
            </span>
          </div>
        </Popover.Button>

        <Popover.Panel
          ref={setPopperElement as any}
          style={styles.popper}
          {...attributes.popper}
        >
          <div
            className={
              "bg-white text-base z-50 float-left py-2 mt-1 list-none rounded-lg shadow-lg min-w-48 text-right"
            }
            style={{ border: "1px solid #ddd", zIndex: 99999 }}
          >
            <a
              href="null"
              className={
                "text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-800"
              }
              onClick={(e) => e.preventDefault()}
            >
              {data?.userMe.displayName}
            </a>
            <a
              href="null"
              className={
                "text-xs px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-600"
              }
              onClick={(e) => e.preventDefault()}
            >
              {data?.userMe.email}
            </a>
            <div className="h-0 my-2 border border-solid border-gray-200" />
            <a
              href="mailto:info@mowispace.com"
              className={
                "text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700"
              }
              //onClick={(e) => e.preventDefault()}
            >
              ASSISTENZA
            </a>
            <div className="h-0 my-2 border border-solid border-gray-200" />
            <a
              href="null"
              className={
                "text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-700"
              }
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
            >
              LOGOUT
            </a>
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );

  /* const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = useRef<any>(null);
  const popoverDropdownRef = useRef<any>(null);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <a
        className="text-gray-600 block"
        href="#null"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-2xl hover:shadow-lg ease-linear transition-all duration-150">
            {auth.user && ENV.assets.userAvatar.uri ? (
              <img
                alt="USER"
                className="w-full rounded-2xl align-middle border-none"
                src={
                  ENV.assets.userAvatar.uri +
                  auth.user +
                  '?t=' +
                  ENV.auth.sessionId
                }
              />
            ) : null}
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left py-2 mt-1 list-none rounded-lg shadow-lg min-w-48 text-right'
        }
        style={{ border: '1px solid #ddd', zIndex: 99999 }}
      >
        <a
          href="null"
          className={
            'text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-800'
          }
          onClick={(e) => e.preventDefault()}
        >
          {data?.userMe.displayName}
        </a>
        <a
          href="null"
          className={
            'text-xs px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-600'
          }
          onClick={(e) => e.preventDefault()}
        >
          {data?.userMe.email}
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          href="null"
          className={
            'text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700'
          }
          onClick={(e) => e.preventDefault()}
        >
          MODIFICA PROFILO
        </a>
        <a
          href="null"
          className={
            'text-xs py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700'
          }
          onClick={(e) => e.preventDefault()}
        >
          ASSISTENZA
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          href="null"
          className={
            'text-sm py-2 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-gray-700'
          }
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
        >
          LOGOUT
        </a>
      </div>
    </>
  );*/
};

export { UserMenu };
