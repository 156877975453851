import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
// @ts-ignore
import Moment from "moment/min/moment-with-locales";

import { Button, Table } from "../../ui";
import { ENV, LOG, T, theme } from "../../config";
import {
  AREA_CANCEL_NOTIFICATION,
  AREA_NOTIFICATIONS,
  Notification,
} from "../../stores/queries/notifications";

const StatusLabel = ({ status }: { status: Notification["status"] }) => {
  let color: any = "white";
  let icon: any = "";
  let text: string = "";

  switch (status) {
    case "sent":
      color = theme.vars.colors.greenLight;
      icon = "paper-plane";
      text = "inviata";
      break;
    case "waiting":
      icon = "clock";
      color = theme.vars.colors.grey;
      text = "attesa";
      break;
    case "approved":
      icon = "check-circle";
      color = theme.vars.colors.cyan;
      text = "approvata";
      break;
    case "rejected":
      icon = "times-circle";
      color = theme.vars.colors.redLight;
      text = "rifiutata";
      break;
    case "cancel":
      icon = "times";
      color = theme.vars.colors.greyLight;
      text = "annullata";
      break;
    default:
      break;
  }

  return (
    <div
      data-tag="allowRowEvents"
      style={{
        backgroundColor: color,
        wordBreak: "keep-all",
        wordWrap: "unset",
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 10,
      }}
      className={
        "w-full text-xs rounded-2xl text-white p-2 px-4 flex flex-row justify-center items-center uppercase font-bold"
      }
    >
      <span data-tag="allowRowEvents">{text}</span>
      <i
        data-tag="allowRowEvents"
        style={{ marginLeft: 4 }}
        className={"fa fa-" + icon}
      ></i>
    </div>
  );
};

const DateLabel = ({ date, onClick }: { date: string; onClick?: Function }) => {
  if (date) {
    return (
      <span
        data-tag="allowRowEvents"
        onClick={() => {
          onClick && onClick();
        }}
      >
        {Moment(new Date(date))
          .locale(ENV.locale, "en")
          .format("DD/MM/YY, HH:mm")}
      </span>
    );
  } else {
    return (
      <span
        data-tag="allowRowEvents"
        onClick={() => {
          onClick && onClick();
        }}
      >
        -
      </span>
    );
  }
};

const NotiStatus = ({ noti }: { noti: Notification }) => {
  if (!noti) return null;

  let color: any = "white";
  let icon: any = "";
  let text: string = "";
  let date: string = "";

  switch (noti.status) {
    case "sent":
      color = theme.vars.colors.greenLight;
      icon = "paper-plane";
      text = "inviata";
      date = Moment(new Date(noti.sentOnDate))
        .locale(ENV.locale, "en")
        .format("dddd, DD/MM/YY, HH:mm");
      break;
    case "waiting":
      icon = "clock";
      color = theme.vars.colors.grey;
      text = "in attesa di verifica";
      break;
    case "approved":
      icon = "check-circle";
      color = theme.vars.colors.cyan;
      text = "approvata";
      break;
    case "rejected":
      icon = "times-circle";
      color = theme.vars.colors.redLight;
      text = "rifiutata";
      break;
    case "cancel":
      icon = "times";
      color = theme.vars.colors.greyLight;
      text = "invio annullato";
      break;
    default:
      break;
  }

  return (
    <div className="flex flex-1 flex-col">
      <div
        className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center rounded-2xl bg-gray-100 text-white"
        style={{
          backgroundColor: color,
        }}
      >
        <div className="px-2">
          <i className={"text-2xl fa fa-" + icon} />
        </div>

        <div style={{ flexDirection: "column", flex: 1 }}>
          <span className="text-base mt-2 text-center font-bold uppercase">
            {text}
          </span>
          <br />

          <small className="text-sm mt-2">
            {noti.info[ENV.locale] || noti.info["en"]}
          </small>
          {date && <small className="text-sm mt-2">{date}</small>}
        </div>
      </div>
    </div>
  );
};

const textRecipients = (recipients: Notification["recipients"]) => {
  switch (recipients) {
    case "devicesInArea":
      return "Tutti gli utenti";
    case "devicesWithUser":
      return "Utenti nell'area";
    case "devicesNowInArea":
      return "Utenti nell'area";
    case "devicesNowInAreaWithUser":
      return "Utenti nell'area";

    default:
      return recipients;
  }
};

const NotiHead = ({ noti }: { noti: Notification }) => {
  let man = false;

  if (noti?.creator?._id) {
    man = true;
  }

  return (
    <div
      data-tag="allowRowEvents"
      className="flex flex-1 flex-row items-center"
    >
      <div
        data-tag="allowRowEvents"
        className="text-white text-xs font-bold flex justify-center items-center"
        style={{
          marginRight: 8,
          width: 20,
          height: 26,
          borderRadius: 13,
          backgroundColor: man
            ? theme.vars.colors.cyan
            : theme.vars.colors.greyLight,
        }}
      >
        {man ? "M" : "A"}
      </div>
      <div data-tag="allowRowEvents" className="flex-1 text-gray-800 font-bold">
        {noti.head[ENV.locale] || noti.head["en"]}
      </div>
    </div>
  );
};

const columns: any = [
  {
    name: T("title"),
    cell: (row: Notification) => (
      <div data-tag="allowRowEvents">
        <NotiHead noti={row} />
      </div>
    ),
    selector: "head." + ENV.locale.toLowerCase(),
    sortable: true,
    minWidth: "25%",
    style: { fontWeight: 600 },
  },
  {
    name: T("creata"),
    cell: (row: Notification) => (
      <div data-tag="allowRowEvents">
        <DateLabel date={row.createdAt} />
      </div>
    ),
    selector: "createdAt",
    sortable: true,
    //center: true,
    hide: "md",
  },

  {
    name: T("inviata"),
    cell: (row: Notification) => (
      <div data-tag="allowRowEvents">
        <DateLabel date={row.sentOnDate} />
      </div>
    ),
    selector: "sentOnDate",
    sortable: true,
    //center: true,
    hide: "md",
  },
  {
    name: (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <h1>STATO</h1>
      </div>
    ),
    cell: (row: Notification) => <StatusLabel status={row.status} />,
    sortable: true,
    selector: "status",
    maxWidth: "120px",
    center: true,
    style: {
      width: 5,
      borderLeft: "1px solid #eee",
    },
  },
];

const NotificationsList = () => {
  const { error, data } = useQuery<{
    areaAllNotifications: Notification[];
  }>(AREA_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 5000,
  });

  const [cancelNoti, { error: cnError, loading: cnLoading }] = useMutation(
    AREA_CANCEL_NOTIFICATION
  );

  const [openNoti, setOpenNoti] = useState<Notification | null>(null);

  if (error) {
    LOG.error(error);
  }

  if (cnError) {
    LOG.error(cnError);
  }

  const cancel = (id: string) => {
    cancelNoti({
      variables: { id },
    });
  };

  return (
    <>
      <div
        className={
          "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white"
        }
      >
        <Table
          columns={columns}
          defaultSortField={"createdAt"}
          defaultSortInvert={true}
          data={data?.areaAllNotifications || []}
          height={"calc(100vh - 220px)"}
          title={"Storico"}
          loading={data && !cnLoading ? false : true}
          //filterSelector={'title'}
          onRowClick={(row: Notification, event) => {
            setOpenNoti(row);
          }}
        />
        <Transition.Root show={openNoti ? true : false} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 overflow-y-auto"
            style={{ zIndex: 99999 }}
            open={openNoti ? true : false}
            onClose={() => {}}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-12 sm:align-middle sm:max-w-md w-full">
                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-auto">
                    <div className="sm:flex sm:items-start ">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                        <Dialog.Title
                          as="h3"
                          className="text-xl leading-6 font-medium text-gray-600 mb-2"
                        >
                          <p
                            style={{
                              borderBottom: "1px solid #ddd",
                              paddingBottom: 5,
                            }}
                            className="text-center"
                          >
                            {openNoti?.head[ENV.locale] || openNoti?.head["en"]}
                          </p>
                          <p
                            style={{
                              marginTop: 15,
                              fontSize: 10,
                              lineHeight: 1.3,
                              marginLeft: 10,
                              whiteSpace: "break-spaces",
                              fontWeight: "bold",
                            }}
                          >
                            CONTENUTO
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 10,
                              lineHeight: 1.3,
                              whiteSpace: "break-spaces",
                              //borderBottom: '1px solid #fff',
                              borderTop: "1px solid #fff",
                            }}
                            className="bg-gray-200 rounded-2xl p-2"
                          >
                            {openNoti?.content[ENV.locale] ||
                              openNoti?.content["en"]}
                          </p>

                          <p
                            style={{
                              marginTop: 15,
                              fontSize: 10,
                              lineHeight: 1.3,
                              marginLeft: 10,
                              whiteSpace: "break-spaces",
                              fontWeight: "bold",
                            }}
                          >
                            PUBBLICO
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 10,
                              lineHeight: 1.3,
                              whiteSpace: "break-spaces",
                              //borderBottom: '1px solid #fff',
                              borderTop: "1px solid #fff",
                            }}
                            className="bg-gray-200 rounded-2xl p-2"
                          >
                            {textRecipients(openNoti?.recipients as any)}
                          </p>

                          <p
                            style={{
                              marginTop: 15,
                              fontSize: 10,
                              lineHeight: 1.3,
                              marginLeft: 10,
                              whiteSpace: "break-spaces",
                              fontWeight: "bold",
                            }}
                          >
                            CREATA
                          </p>
                          <p
                            style={{
                              marginTop: 5,
                              fontSize: 10,
                              lineHeight: 1.3,
                              whiteSpace: "break-spaces",
                              //borderBottom: '1px solid #fff',
                              borderTop: "1px solid #fff",
                            }}
                            className="bg-gray-200 rounded-2xl p-2"
                          >
                            {openNoti?.createdAt &&
                              Moment(new Date(openNoti.createdAt))
                                .locale(ENV.locale, "en")
                                .format("dddd, DD/MM/YY, HH:mm")}
                          </p>
                        </Dialog.Title>
                        {openNoti && <NotiStatus noti={openNoti} />}
                      </div>
                    </div>
                  </div>

                  <div className="px-4 flex mb-4 justify-between">
                    {openNoti?.status === "waiting" ? (
                      <Button
                        size="md"
                        color="red"
                        className={"lg:min-w-140-px mb-4 m-2"}
                        onClick={() => {
                          cancel(openNoti.id);
                          if (openNoti) {
                            setOpenNoti(null);
                          }
                        }}
                      >
                        {" "}
                        {T("Annulla notifica")}
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <Button
                      size="md"
                      color="white"
                      className={"lg:min-w-140-px mb-4 m-2"}
                      onClick={() => {
                        if (openNoti) {
                          setOpenNoti(null);
                        }
                      }}
                    >
                      {" "}
                      {T("close")}
                    </Button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export { NotificationsList };
