/* eslint-disable no-useless-concat */
import React, { CSSProperties } from 'react';
import { ENV, T, theme } from '../../config';
import { Colors } from '../../config/theme';
import { Track } from '../../stores/queries/tracks';

const meterConversion = (meters: number) => {
  if (meters > 1000) {
    meters = meters / 1000;
    return Math.round(meters * 10) / 10 + ' km';
  } else {
    return meters + ' m';
  }
};

const timeConversion = (duration: number) => {
  let str = '';
  const hours = Math.trunc(duration / 60);
  duration = duration - hours * 60;
  str += hours + 'h:';
  const minutes = duration;
  if (minutes > 0) {
    str += minutes + 'm';
  } else {
    str += '0m';
  }
  return str;
};

interface TrackCardProps {
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  track: Track;
}

const TrackCard = (props: TrackCardProps) => {
  if (!props.track) return null;

  let trackColor: string = theme.helpers.getTypologyColor(
    props.track.typology || 'default'
  );

  return (
    <div style={{ ...styles.container, ...props.style }}>
      <div
        style={{
          ...styles.header,
          ...{ backgroundColor: trackColor },
        }}
      >
        {props.track.detailedInfo?.trackNumber && (
          <div style={styles.number}>
            <div className="text-white font-bold">
              {props.track.detailedInfo.trackNumber}
            </div>
          </div>
        )}
        <h1
          className="text-white font-bold"
          style={{
            maxWidth: '70%',
            fontSize: 19,
            fontWeight: 1100,
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.track.name}
        </h1>
      </div>

      <div style={{ ...styles.body, ...props.bodyStyle }}>
        <div style={{ ...styles.block, ...{ marginRight: 5 } }}>
          {typeof props.track.status === 'boolean' ? (
            <div
              style={styles.statusCont}
              className="bg-gray-600 text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
            >
              <span
                className={
                  'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
                }
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: props.track.status
                    ? theme.vars.colors.greenLight
                    : theme.vars.colors.redLight,
                }}
              ></span>
              {T(props.track.status ? 'open' : 'closed')}
            </div>
          ) : (
            <>
              {typeof props.track.originalStatus === 'boolean' ? (
                <div
                  style={styles.statusCont}
                  className="bg-gray-600 text-white font-bold p-1 px-2 flex items-center justify-center uppercase"
                >
                  <span
                    className={
                      'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
                    }
                    style={{
                      width: 12,
                      height: 12,
                      backgroundColor: props.track.originalStatus
                        ? theme.vars.colors.greenLight
                        : theme.vars.colors.redLight,
                    }}
                  ></span>
                  {T(props.track.originalStatus ? 'open' : 'closed')}
                </div>
              ) : null}
            </>
          )}

          {props.track.typology ? (
            <div
              style={{
                ...styles.statusCont,
                ...styles.blockLabel,
                backgroundColor: trackColor,
              }}
            >
              {T(
                ENV.app.key === 'mowiSki'
                  ? props.track.typology + '_level'
                  : props.track.typology
              )}
            </div>
          ) : null}

          {props.track.detailedInfo?.level ? (
            <div
              style={{
                ...styles.statusCont,
                ...styles.blockLabel,
                backgroundColor:
                  theme.vars.colors[
                    theme.helpers.difficultiesColor(
                      props.track.detailedInfo.level
                    ) as Colors
                  ],
              }}
            >
              {T(props.track.detailedInfo?.level)}
            </div>
          ) : null}
        </div>

        {/* LUNGHEZZA */}
        {props.track.technicalInfo?.length ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i
              className={'text-xl icon-' + 'lenght_card'}
              style={{ marginLeft: -10 }}
            />
            <div>{meterConversion(props.track.technicalInfo.length)}</div>
          </div>
        ) : null}

        {/* PEMDENZA MASSIMA */}
        {props.track.technicalInfo?.maxSlope ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i className={'text-xl icon-' + 'max_slope'} />
            <div>{props.track.technicalInfo?.maxSlope}°</div>
          </div>
        ) : null}

        {/* ALTITUDINE MASSIMA */}
        {props.track.technicalInfo?.altimetry?.highestPoint ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i className={'text-xl icon-' + 'top_trail'} />
            <div>{props.track.technicalInfo?.altimetry?.highestPoint} m</div>
          </div>
        ) : null}

        {/* DISLIVELLO DISCESA */}
        {props.track.technicalInfo?.altimetry?.differenceMinus ||
        props.track.technicalInfo?.altimetry?.differenceMinus === 0 ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i className={'text-xl icon-' + 'nhd_card'} />
            <div>
              {meterConversion(
                props.track.technicalInfo.altimetry.differenceMinus
              )}
            </div>
          </div>
        ) : null}

        {/* DISLIVELLO SALITA */}
        {(!props.track.technicalInfo?.maxSlope &&
          props.track.technicalInfo?.altimetry?.differencePlus) ||
        props.track.technicalInfo?.altimetry?.differencePlus === 0 ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i className={'text-xl icon-' + 'phd_card'} />
            <div>
              {meterConversion(
                props.track.technicalInfo.altimetry.differencePlus
              )}
            </div>
          </div>
        ) : null}

        {/* TEMPO MEDIO PERCORRENZA */}
        {props.track.detailedInfo?.timeAvarage ? (
          <div style={{ ...styles.block, paddingBottom: 5 }}>
            <i className={'text-xl icon-' + 'duration_trail'} />
            <div>{timeConversion(props.track.detailedInfo.timeAvarage)}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 20,
    color: '#fff',
  },
  header: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.vars.colors.default,
    width: '100%',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  body: {
    display: 'flex',
    width: '100%',
    flex: 4,
    justifyContent: 'space-around',
    flexDirection: 'row',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  block: {
    display: 'flex',
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontWeight: 'bold',
    fontSize: 9,
  },
  number: {
    display: 'flex',
    borderRadius: 30 / 2,
    backgroundColor: theme.vars.colors.grey,
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    position: 'absolute',
    left: 5,
    fontSize: 12,
  },
  blockLabel: {
    width: 100,
    height: 14,
    padding: 0,
    margin: 0,
    marginBottom: 4,
  },
  statusCont: {
    display: 'flex',
    width: 100,
    height: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 10,
    margin: 0,
    marginBottom: 4,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 9,
  },
  statusWarn: {
    display: 'flex',
    width: 100,
    height: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.vars.colors.yellow,
    borderRadius: 10,
    margin: 0,
    marginBottom: 4,
  },
  status: {
    borderRadius: 5,
    width: 8,
    height: 8,
    marginRight: 5,
    backgroundColor: theme.vars.colors.greenLight,
  },
};

export { TrackCard };
