import { useState } from "react";
import { LineMetric } from "../../../components/Analytics/LineMetric";
import { ACTUAL_AUTH } from "../../../stores/db/auth";
import { DateRange } from "../../../ui/DateRange";
import ImgStats from "../../../assets/img/stats.jpg";

interface AquisitionViewProps {
  route?: any;
}

const AquisitionView = (props: AquisitionViewProps) => {
  const [selRange, setSelRange] = useState({
    startDate: "2022-06-01",
    endDate: undefined,
  });

  return (
    <>
      <div className="relative pt-5 mb-4">
        <div className="mx-auto w-full">
          <div className="pr-2 pl-2">
            <DateRange
              initStartDate={selRange.startDate}
              initEndDate={selRange.endDate}
              onChange={(range) => {
                let startDate = range.startDate.toISOString().split("T")[0];
                let endDate = range.endDate.toISOString().split("T")[0];
                setSelRange({ startDate, endDate });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full mb-2 px-4">
          <LineMetric
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            id="line-metric-base-accessasdfa"
            metric="totalUsers"
            title="Andamento utenti unici"
            //unit="Accessi Totali"
          />
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full mb-2 px-4">
          <LineMetric
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            id="line-metric-base-accessSessionsasdf"
            metric="sessions"
            title="Andamento aperture area"
            //unit="Accessi Totali"
          />
        </div>
      </div>
    </>
  );
};

export { AquisitionView };
