import React from 'react';
import { TracksList } from '../../../components';

interface TracksViewProps {}

const TracksView = (props: TracksViewProps) => {
  return (
    <>
      <TracksList />
    </>
  );
};

export { TracksView };
