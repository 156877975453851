import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import ImgLogo from "../../assets/img/loghi/MowiBusinessWhite.png";

import ImgLinesUp from "../../assets/img/bkg/lines_color_up_right.png";
import ImgLinesDown from "../../assets/img/bkg/lines_color_down_left.png";

import { ENV, LOG, theme } from "../../config";
import {
  getAvailableAreas,
  getPermittedAreas,
  loginUser,
} from "../../libs/apollo/auth";
import { useFormFields } from "../../libs/hooks/forms";

import { Button, TextBox, Loading } from "../../ui";
import { AreaData, getAreas } from "../../stores/queries/areas";
import { AreaBtn } from "../../components";

const noAreasError =
  "Non hai i permessi di accedere a nessuna area, contatta Mowi all'indirizzo info@mowispace.com!";

const Login = () => {
  const history = useHistory();

  const [inputs, handleInputChange] = useFormFields<{
    username: string;
    password: string;
  }>({
    username: "",
    password: "",
  });

  const [error, setError] = useState<null | string>(null);
  const [opLoad, setOpLoad] = useState<boolean>(false);
  const [view, setView] = useState<"login" | "areas">("login");
  const [userAreas, setUserAreas] = useState<AreaData[]>([]);

  const listener = (event: any) => {
    if (
      event.code === "Enter" ||
      event.code === "NumpadEnter" ||
      event.keyCode === 13
    ) {
      event.preventDefault();
      if (!opLoad && view === "login") {
        const inputs = {
          //@ts-ignore
          username: document.getElementById("username").value,
          //@ts-ignore
          password: document.getElementById("password").value,
        };
        login(inputs.username, inputs.password);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginRes = (res: any) => {
    if (res.success) {
      LOG.info("Login email Success");
      LOG.info(res.auth);
      if (res.tokens?.atDecoded?.auth?.permission?.area) {
        history.push("/admin");
      } else {
        getAreas().then((areas) => {
          setOpLoad(false);
          if (areas && res?.auth?.permissions[0]) {
            let myAreas = getAvailableAreas(
              getPermittedAreas(res.auth.permissions),
              areas
            );
            if (!myAreas || myAreas.length < 1) {
              setError(noAreasError);
            } else {
              setUserAreas(myAreas);
            }
            setView("areas");
          } else {
            setError(noAreasError);
          }
        });
      }
    } else {
      setOpLoad(false);
      setError(res.msg);
      LOG.warn("Login fallito: " + res.msg);
    }
  };

  const login = (
    email: string,
    password: string,
    app?: "ski" | "bike",
    area?: string
  ) => {
    setOpLoad(true);
    setError(null);
    loginUser(email, password, app, area).then((res) => {
      loginRes(res);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: theme.vars.colors.greyDark,
      }}
    >
      <img
        alt="Mowi Business"
        src={ImgLinesUp}
        className="absolute top-0 right-0 border-none w-screen h-auto mb-10"
        style={{ maxWidth: "30%" }}
      />
      <img
        alt="Mowi Business"
        src={ImgLinesDown}
        className="absolute bottom-0 left-0 border-none w-screen h-auto"
        style={{ maxWidth: "30%" }}
      />
      <div className="container mx-auto px-4 h-full">
        <div className="flex justify-start items-center w-full flex-col px-4 h-full">
          <div className="relative flex content-center items-center justify-center">
            <img
              alt="Mowi Business"
              src={ImgLogo}
              className="h-auto align-middle border-none mb-8 max-w-180-px opacity-90"
            />
          </div>
          {view === "login" ? (
            <div className="w-full flex flex-col lg:w-4/12">
              <div className="relative w-full flex flex-col min-w-0 break-words mb-6 shadow-xl rounded-lg bg-gray-200 border-0">
                {/*<div className="rounded-t mb-0 px-6 py-4">
                  <div className="text-center mb-3">
                      <h6 className="text-gray-500 text-sm font-bold">
                        Accedi con
                      </h6>
                    </div>
                    <div className="btn-wrapper text-center">
                      <Button
                        onClick={() => null}
                        style={{ marginRight: 2, marginLeft: 2, width: 50 }}
                      >
                        <i className="fab fa-facebook-f text-gray-400 text-sm"></i>
                      </Button>
                      <Button
                        onClick={() => null}
                        style={{ marginRight: 2, marginLeft: 2, width: 50 }}
                      >
                        <i className="fab fa-google text-gray-400 text-sm"></i>
                      </Button>
                      <Button
                        onClick={() => null}
                        style={{ marginRight: 2, marginLeft: 2, width: 50 }}
                      >
                        <i className="fab fa-apple text-gray-400 text-sm"></i>
                      </Button>
                    </div> 
                      <hr className="mt-6 border-b-1 border-gray-300" />
                </div>*/}
                <div className="flex-auto px-4 lg:px-10 py-4 lg:py-8 pt-0">
                  {error ? (
                    <div className="bg-red-400 py-2 rounded-lg text-white text-center ml-2 mr-2 mb-3 font-bold">
                      <small>{error}</small>
                    </div>
                  ) : (
                    <div className="text-gray-400 text-center mb-3 font-bold">
                      <small>Accedi con la tua email</small>
                    </div>
                  )}
                  <form
                    onSubmit={() => login(inputs.username, inputs.password)}
                  >
                    <div className="relative w-full mb-3">
                      <TextBox
                        id="username"
                        type="email"
                        label="Email"
                        placeholder="Email"
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <TextBox
                        id="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="text-center mt-6">
                      <Button
                        size="md"
                        full
                        color="purple"
                        onClick={() => login(inputs.username, inputs.password)}
                        disabled={opLoad ? true : false}
                      >
                        {opLoad ? (
                          <div className="w-full flex items-center justify-center">
                            <Loading
                              color={theme.vars.colors.light}
                              height={20}
                              width={20}
                            />
                          </div>
                        ) : (
                          "Accedi"
                        )}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="flex flex-wrap self-end mt-6 relative">
                <div className="w-full text-right">
                  <Link to="/auth/recover" className="text-white">
                    <small>Password dimenticata?</small>
                  </Link>
                </div>
              </div> */}
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <div className="w-full h-full lg:w-4/12 text-center">
                <h1
                  className="text-white text-lg font-bold flex-1 m-2 opacity-80"
                  style={{ textAlign: "center", marginTop: 20 }}
                >
                  Selezeiona l'Area interessata
                </h1>
                <i className="fas fa-chevron-down mb-6 text-white"></i>
                {error && (
                  <div className="bg-red-400 py-2 rounded-lg text-white text-center ml-2 mr-2 mb-3 font-bold">
                    <small>{error}</small>
                  </div>
                )}
                <div style={{ overflow: "auto" }}>
                  {ENV.rootEmails.includes(inputs.username) && (
                    <button
                      onClick={() => {
                        history.push("/global");
                      }}
                      className="flex flex-row w-full p-4 shadow-md rounded-lg justify-between items-center hover:shadow-xl outline-none focus:outline-none ease-linear transition-all duration-150"
                      style={{
                        backgroundColor: "#515151",
                        marginTop: 10,
                        marginBottom: 10,
                        opacity: 1,
                      }}
                    >
                      <img
                        alt="Mowi Logo"
                        src={"https://mowi.space/favicon.png"}
                        className="border-none"
                        style={{
                          width: 50,
                          height: 40,
                          paddingRight: 10,
                          borderRight: "1px solid #666",
                        }}
                      />
                      <h1
                        className="text-white text-lg font-bold uppercase flex-1 m-2 opacity-80"
                        style={{ textAlign: "center" }}
                      >
                        Global Analytics
                      </h1>
                    </button>
                  )}
                  {userAreas.map((area, index) => (
                    <AreaBtn key={index} area={area} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {opLoad ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "rgba('0,0,0,0.5')",
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Login };
