import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import { useQuery } from "@apollo/client";

import { Link } from "react-router-dom";
import { AREA_IAMS, Message } from "../../stores/queries/iams";

const MessageItem = ({
  message,
  index,
}: {
  message: Message;
  index: number;
}) => {
  if (!message) return null;
  return (
    <div className="flex w-full flex-row py-1 my-1 px-2 justify-center items-center rounded-2xl bg-gray-100 text-white">
      <div
        className="flex justify-center items-center bg-blue-400 text-white font-bold text-lg"
        style={{ ...styles.iamsLeft }}
      >
        #{index + 1}
      </div>
      <div
        className="flex"
        style={{ alignItems: "center", justifyContent: "center" }}
      ></div>
      <div className="flex flex-1 ">
        <div className="flex flex-col flex-1 justify-center">
          {message.title && (
            <div className="flex text-base text-gray-600 font-bold text-2lines text-left">
              {message.title[ENV.locale] || message.title.en}
            </div>
          )}
          <div className="flex flex-row text-base text-gray-600 font-bold">
            <div
              className="flex justify-center items-center bg-purple-400 text-white font-bold"
              style={{ ...styles.iamsLabel }}
            >
              {message.scope === "alert" ? (
                <i className="fa fa-bell text-xs" />
              ) : null}
              {message.scope === "info" ? (
                <i className="fa fa-info-circle text-xs" />
              ) : null}
              {message.scope === "commercial" ? (
                <i className="fa fa-ad text-xs" />
              ) : null}
            </div>
            <div
              className="flex justify-center items-center bg-purple-400 text-white font-bold"
              style={{ ...styles.iamsLabel }}
            >
              {!message.repeat || message.repeat === null ? (
                <i className="fa fa-calendar-times text-xs" />
              ) : null}
              {message.repeat === "week" ? (
                <i className="fa fa-calendar-day text-xs" />
              ) : null}
              {message.repeat === "month" ? (
                <i className="fa fa-calendar-week text-xs" />
              ) : null}
            </div>
            {message.image?.it || message.image?.en || message.image?.de ? (
              <div
                className="flex justify-center items-center bg-purple-400 text-white font-bold"
                style={{ ...styles.iamsLabel }}
              >
                <i className="fa fa-image text-xs" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardMessages = () => {
  const { loading, data } = useQuery<{
    areaInAppMessages: Message[];
  }>(AREA_IAMS, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl pb-2">
        <div className="rounded-t mb-0 px-1 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h2 className="text-xl font-semibold">Messaggi</h2>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-purple-400 text-white hover:bg-purple-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                <Link to={"/admin/messages/inappmessages"}>Gestisci</Link>
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <tbody>
              {data?.areaInAppMessages?.map((message, index) => {
                if (index < 3) {
                  return (
                    <tr key={"message_" + index}>
                      <th className="border-t-0 px-2 align-middle border-l-0 border-r-0">
                        <MessageItem message={message} index={index} />
                      </th>
                    </tr>
                  );
                } else return null;
              })}
            </tbody>
          </table>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  iamsLeft: {
    width: 40,
    height: 50,
    borderRadius: 16,
    marginRight: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  iamsLabel: {
    height: 26,
    width: 26,
    borderRadius: 13,
    marginRight: 5,
  },
  iamsIcon: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: 3,
  },
  voidList: {
    width: "100%",
    padding: 20,
    alignItems: "center",
  },
  modalBtn: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    borderRadius: 20,
    paddingVertical: 10,
    marginVertical: 5,
    paddingRight: 10,
    minHeight: 70,
  },
};

export { CardMessages };
