import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L, { Map } from "leaflet";
import "leaflet.heat";
import { Loading } from "../../ui";
import { theme } from "../../config";

interface HeatMapProps {
  points?: number[][];
  center?: any;
  loading?: boolean;
}

const HeatMap = (props: HeatMapProps) => {
  const MapRef = useRef<Map>();
  const HeatMapRef = useRef<any>();
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    if (props?.points && MapRef.current && mapReady) {
      setTimeout(() => {
        if (HeatMapRef.current) {
          HeatMapRef.current.setLatLngs(props.points);
        } else {
          //@ts-ignore
          HeatMapRef.current = L.heatLayer(props.points, {
            radius: 20,
            minOpacity: 0.6,
          }).addTo(MapRef.current);
        }
      }, 200);
    }
  }, [props?.points, mapReady]);
  /* const { error, data } = useQuery<{
    userTrack: Track;
  }>(TRACK, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [trackPath, setTrackPath] = useState<any>(null);

  const gjObject = useRef<any>(null);

  useEffect(() => {
    let path = null;
    if (data?.userTrack.geoLocation.coordinatesLineString) {
      path = [
        {
          type: "LineString",
          coordinates: data?.userTrack.geoLocation.coordinatesLineString,
        },
      ];
    } else if (data?.userTrack.geoLocation.coordinatesMultiLineString) {
      path = [
        {
          type: "LineString",
          coordinates:
            data?.userTrack.geoLocation.coordinatesMultiLineString[0],
        },
      ];
    }
    setTrackPath(path);
  }, [data?.userTrack.geoLocation]);

  if (error) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error.message}
        </div>
      </div>
    );
  }

  if (!trackPath) {
    return (
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 180px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  } */

  return (
    <>
      <div
        className="relative break-words w-full shadow-lg rounded-2xl border-0 overflow-hidden"
        style={{ height: "calc(100vh - 280px)", transform: "translateZ(0)" }}
      >
        <MapContainer
          center={props.center || [46, 11]}
          zoom={12}
          maxZoom={18}
          //minZoom={10}
          scrollWheelZoom={false}
          className="relative break-words w-full rounded-2xl border-0"
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          whenCreated={(map) => {
            /* setTimeout(() => {
              if (gjObject.current && gjObject.current.getBounds) {
                let bounds = gjObject.current.getBounds();
                if (bounds._northEast) {
                  map.fitBounds(bounds);
                }
              }
            }, 200); */
            MapRef.current = map;
            setMapReady(true);
          }}
        >
          {/* {props?.events && (
            <HeatmapLayer
              points={[
                { lat: -11, lon: 46 },
                { lat: -11, lon: 46 },
              ]}
              longitudeExtractor={(m: any) => m.lat}
              latitudeExtractor={(m: any) => m.lon}
              intensityExtractor={(m: any) => m.lat}
              max={100}
              minOpacity={0.2}
            />
          )} */}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMaps</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMaps</a>, &copy; Stadia maps'
            url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          /> */}

          {/* <TileLayer
            attribution='&copy; <a href="https://www.opensnowmap.org/iframes/data.html">OpenSnowMap</a>'
            url="https://tiles.opensnowmap.org/pistes/{z}/{x}/{y}.png"
          /> */}
          {/* <GeoJSON
            ref={gjObject}
            data={trackPath}
            style={(geoJsonFeature) => {
              return {
                color: theme.helpers.getTypologyColor(
                  data?.userTrack.typology || "default"
                ),
                weight: 5,
              };
            }}
          >
            <Tooltip sticky>
              <b>{data?.userTrack.name}</b>
            </Tooltip>
          </GeoJSON> */}
          {/* <div
            className="absolute shadow-xl"
            style={{
              position: "absolute",
              bottom: 18,
              left: 0,
              marginLeft: "calc(50% - 140px)",
              marginRight: "auto",
              width: 280,
              height: 110,
              backgroundColor: theme.vars.colors.greyDark,
              borderRadius: 20,
              zIndex: 999,
              alignSelf: "center",
            }}
          >
            <TrackCard track={data?.userTrack as Track} />
          </div> */}
          {props.loading && (
            <div
              style={{ zIndex: 999999999 }}
              className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg"
            >
              <Loading color={theme.vars.colors.greyDark} />
            </div>
          )}
        </MapContainer>
      </div>
    </>
  );
};

export { HeatMap };
