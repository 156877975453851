import { gql } from "@apollo/client";
import { Warning } from "./warnings";

interface Poi {
  id: string;
  name: string;
  area: string;
  category: string;
  typology: string;
  localities: string[];
  tagFilters: string[];
  primaryService: string;
  status: boolean;
  originalStatus: boolean;
  statusFromExternalSource: boolean;
  statusClosedUntil: boolean;
  permission: {
    owner: string;
    isPrivate: boolean;
  };
  detailedInfo: {
    locality: {
      it: string;
      en: string;
      de: string;
    };
    importance: number;
    tagServices: [string];
  };
  technicalInfo: {
    altimetry: {
      highestPoint: number;
    };
  };
  description: {
    short: {
      it: string;
      en: string;
      de: string;
    };
    long: {
      it: string;
      en: string;
      de: string;
    };
  };
  media: {
    pictures: [
      {
        _id: string;
        url: string;
      }
    ];
    videos: [
      {
        _id: string;
        url: string;
      }
    ];
    cover: {
      _id: string;
      url: string;
    };
  };
  geoLocation: {
    coordinatesMultiLineString?: number[][][];
    coordinatesLineString?: number[][];
    coordinatesPoint?: number[];
  };
  warnings: Warning[];
}

const POI_FRAGMENT = gql`
  fragment poiFragment on UserPoi {
    id
    name
    area
    category
    localities
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    permission {
      owner
      isPrivate
    }
    detailedInfo {
      locality {
        it
        en
        de
      }
      importance
      tagServices
    }
    primaryService
    tagFilters
    technicalInfo {
      altimetry {
        highestPoint
      }
    }
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
    }
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const POI_MEDIA_FRAGMENT = gql`
  fragment poiMediaFragment on UserPoi {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const POIS = gql`
  query POIS($area: ID!) {
    userPois(area: $area) {
      ...poiFragment
    }
  }
  ${POI_FRAGMENT}
`;

const POI = gql`
  query POI($id: ID!) {
    userPoi(id: $id) {
      ...poiFragment
      ...poiMediaFragment
    }
  }
  ${POI_FRAGMENT}
  ${POI_MEDIA_FRAGMENT}
`;

const POI_UPDATE = gql`
  mutation POI_UPDATE($id: ID!, $poiInput: PoiInput) {
    userUpdatePoi(id: $id, poiInput: $poiInput) {
      ...poiFragment
    }
  }
  ${POI_FRAGMENT}
`;

const POI_ADD_MEDIA = gql`
  mutation POI_ADD_MEDIA($id: ID!, $medias: [Upload], $type: PoiMediaType) {
    userAddMediasToPoi(id: $id, medias: $medias, type: $type) {
      id
      ...poiMediaFragment
    }
  }
  ${POI_MEDIA_FRAGMENT}
`;

const POI_REMOVE_MEDIA = gql`
  mutation POI_REMOVE_MEDIA($id: ID!, $mediaId: ID!, $type: PoiMediaType) {
    userRemoveMediaFromPoi(id: $id, mediaId: $mediaId, type: $type) {
      id
      ...poiMediaFragment
    }
  }
  ${POI_MEDIA_FRAGMENT}
`;

export { POI, POIS, POI_UPDATE, POI_REMOVE_MEDIA, POI_ADD_MEDIA };
export type { Poi };
