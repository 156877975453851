import React, { useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { ACTUAL_AUTH } from "../../stores/db/auth";
import { Button, TextBox, Loading } from "../../ui";
import { ENV, LOG, T, theme } from "../../config";
import { AREA_COMPLETE, PROMOTE_PATROL } from "../../stores/queries/areas";
import { UserBasic, USER_SEARCHFRIENDS } from "../../stores/queries/users";

const UserItem = ({
  user,
  promote,
}: {
  user: {
    id: string;
    displayName: string;
  };
  promote: Function;
}) => {
  return (
    <div className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center shadow transition-all rounded-2xl bg-gray-100 hover:bg-gray-200 text-white">
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginRight: 10,
        }}
      >
        <img
          alt={"AvatarUser"}
          src={
            ENV.assets.userAvatar.uri + user.id + "_low?t=" + ENV.auth.sessionId
          }
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: theme.vars.colors.grey,
            borderColor: theme.vars.colors.grey,
            borderWidth: 1,
          }}
        />
      </div>
      <div style={{ flex: 1, justifyContent: "center" }}>
        <div style={{ flexDirection: "row", alignItems: "center" }}>
          <span className="text-base text-gray-600 mt-2 text-center font-bold">
            {user.displayName}
          </span>
        </div>
      </div>
      <Button
        onClick={() => {
          promote && promote(user.id);
        }}
        color="purple"
      >
        Promuovi <i className="fa fa-chevron-right"></i>
      </Button>
    </div>
  );
};

const PatrolsAdd = () => {
  const [userSearchFriends, { loading, data, error }] = useLazyQuery<{
    userSearchFriends: UserBasic[];
  }>(USER_SEARCHFRIENDS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const [promotePatrol, { error: ppError, loading: ppLoading }] = useMutation(
    PROMOTE_PATROL,
    {
      refetchQueries: [
        {
          query: AREA_COMPLETE,
          variables: {
            id: ACTUAL_AUTH.area,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (res: any) => {
        setSearch(" ");
      },
    }
  );

  const promote = (id: string) => {
    promotePatrol({
      variables: { areaId: ACTUAL_AUTH.area, userId: id },
    });
  };

  const [search, setSearch] = useState("");
  const searchTimer = useRef<any>();

  const handleSearchChange = (e: string) => {
    setSearch(e);
    if (e.length > 2) {
      if (searchTimer.current) clearTimeout(searchTimer.current);
      searchTimer.current = setTimeout(() => {
        userSearchFriends({ variables: { searchName: e } });
      }, 300);
    }
  };

  if (error) {
    LOG.error(error);
  }
  if (ppError) {
    LOG.error(ppError);
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className={
          "relative flex flex-1 flex-col items-center min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white p-2"
        }
      >
        <TextBox
          id="filter"
          type="text"
          placeholder="Cerca per nome..."
          required={true}
          onChange={(e) => {
            handleSearchChange(e.target.value);
          }}
        />
        <hr style={{ marginBottom: 10, marginTop: 10 }} />
        {loading ? (
          <div className="p-4">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        ) : (
          <div className="h-full w-full overflow-y-auto">
            {search.length < 2 ||
            !data?.userSearchFriends ||
            data.userSearchFriends.length < 1 ? (
              <h6 className="text-gray-500 text-xl font-bold text-center p-4">
                {T("friendSearchNull")}
              </h6>
            ) : (
              <>
                {data?.userSearchFriends.map((item, index) => {
                  return (
                    <UserItem
                      key={"list_" + item.id}
                      user={item}
                      promote={promote}
                    />
                  );
                })}
              </>
            )}
          </div>
        )}
        {ppLoading ? (
          <div
            className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              zIndex: 5000,
            }}
          >
            <Loading color={theme.vars.colors.light} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export { PatrolsAdd };
