import { gql } from '@apollo/client';
import { ENV } from '../../config';

interface Warning {
  _id?: string;
  location?: 'start' | 'center' | 'end' | 'all';
  creator?: {
    user: string;
    displayName: string;
  };
  problem?: string;
  ifClosed: boolean;
  description?: { it?: string; en?: string; de?: string };
  refId?: string;
}

const PROBLEM_LIST_WARNING = {
  track: gql`
    query PROBLEM_LIST_WARNING_TRACK($language: Language!) {
      configProblemListTrackWarning(language: $language) {
        problem
        description
      }
    }
  `,
  poi: gql`
    query PROBLEM_LIST_WARNING_POI($language: Language!) {
      configProblemListPoiWarning(language: $language) {
        problem
        description
      }
    }
  `,
  transport: gql`
    query PROBLEM_LIST_WARNING_TRANSPORT($language: Language!) {
      configProblemListTransportWarning(language: $language) {
        problem
        description
      }
    }
  `,
};

const NOTICE_TO_WARNING = gql`
  mutation patrolTransformNoticeInWarning(
    $id: ID!
    $trackWarning: TrackWarningInput
    $poiWarning: PoiWarningInput
    $transportWarning: TransportWarningInput
    $notification: WarningNotificationInput
  ) {
    patrolTransformNoticeInWarning(
      id: $id
      trackWarning: $trackWarning
      poiWarning: $poiWarning
      transportWarning: $transportWarning
      notification: $notification
    )
  }
`;

const PATROL_REMOVE_WARNINGS = {
  track: gql`
    mutation PATROL_REMOVE_WARNINGS_TRACK($list: [TrackRemoveWarningInput]) {
      patrolTrackRemoveWarnings(list: $list)
    }
  `,
  poi: gql`
    mutation PATROL_REMOVE_WARNINGS_POI($list: [PoiRemoveWarningInput]) {
      patrolPoiRemoveWarnings(list: $list)
    }
  `,
  transport: gql`
    mutation PATROL_REMOVE_WARNINGS_TRANSPORT(
      $list: [TransportRemoveWarningInput]
    ) {
      patrolTransportRemoveWarnings(list: $list)
    }
  `,
};

const PATROL_ADD_WARNINGS = {
  track: gql`
    mutation PATROL_ADD_WARNINGS_TRACK(
      $ids: [ID]!
      $warning: TrackWarningInput!
      $notification: TrackWarningNotificationInput
    ) {
      patrolTrackAddWarnings(
        ids: $ids
        warning: $warning
        notification: $notification
      )
    }
  `,
  poi: gql`
    mutation PATROL_ADD_WARNINGS_POI(
      $ids: [ID]!
      $warning: PoiWarningInput!
      $notification: PoiWarningNotificationInput
    ) {
      patrolPoiAddWarnings(
        ids: $ids
        warning: $warning
        notification: $notification
      )
    }
  `,
  transport: gql`
    mutation PATROL_ADD_WARNINGS_TRANSPORT(
      $ids: [ID]!
      $warning: TransportWarningInput!
      $notification: TransportWarningNotificationInput
    ) {
      patrolTransportAddWarnings(
        ids: $ids
        warning: $warning
        notification: $notification
      )
    }
  `,
};

const getLocaleDescription = (warning: Warning) => {
  if (warning?.description && warning.description[ENV.locale]) {
    return warning.description[ENV.locale];
  }
  if (warning?.description && warning.description['en']) {
    return warning.description['en'];
  }
  return null;
};

export {
  PROBLEM_LIST_WARNING,
  NOTICE_TO_WARNING,
  PATROL_ADD_WARNINGS,
  PATROL_REMOVE_WARNINGS,
  getLocaleDescription,
};
export type { Warning };
