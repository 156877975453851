const Platform = {
  OS: 'ios',
};

const app = 'mowi';
const appName = 'mowi';
const trackName = 'Track';
const tracksName = 'Tracks';

type lang = { [key: string]: string };

const general: lang = {
  yes: 'Yes',
  no: 'No',
  back: 'Back',
  go: 'Start',
  from: 'from',
  fromDay: 'From',
  minutes: 'min',
  hours: 'hours',
  hoursAgo: 'hours ago',
  minAgo: 'min ago',
  to: 'to',
  and: 'and',
  sureAsk: 'Are you sure?',
  cancel: 'Cancel',
  accept: 'Accept',
  acceptInvite: 'Accept invitation',
  remove: 'Remove',
  delete: 'Delete',
  restore: 'Refresh',
  requestSent: 'Request sent',
  createdBy: 'Created by',
  list: 'List',
  requests: 'Requests',
  archive: 'Archive',
  outOf: 'up',
  now: 'now',
  start: 'start',
  stop: 'stop',
  track: trackName,
  tracks: tracksName,
  services: 'services',
  Services: 'Services',
  Typologies: 'Types',
  group: 'group',
  groups: 'groups',
  transports: 'lifts',
  filters: 'filters',
  type: 'type',
  unknown: 'other',
  refresh: 'Update',
  next: 'Next',
  name: 'Name',
  welcome: 'Welcome to ' + appName + ' ;)',
  readyToGo: 'Ready to Go?',
  insertDisplayName:
    'Enter your name or nickname for other Mowi users to find you',
  otherInfo: 'Further information',
  otherInfoHint:
    'This information is not compulsory but can help us to personalise your experience!',
  displayName: 'Displayed name',
  birthYear: 'Year of birth',
  privacyAd: 'Mowi cares about the privacy of its users.',
  privacyShow: 'View our Privacy Policy',
  admin: 'Admin',
  waitAgain: 'Please wait a little longer…',
  outOfMap: 'Off Map',
  outOfMapHint:
    'You must be physically on Altopiano della Paganella to use all ' +
    appName +
    ' features!',
  open: 'Open',
  closed: 'Closed',
  offline: "you're offline",
  close: 'Close',
  continue: 'Next',
  rememberLater: 'Remind me later',
  edit: 'Edit',
  create: 'Create',
  add: 'Add',
  save: 'Save',
  position: 'Location',
  active: 'On',
  set: 'Set',
  motionAndFitness: 'Movement and Fitness',
  energySaver: 'Energy Saving',
  backgroundUpdate: 'Update in Background',
  maintenance: 'App under maintenance!',
  maintenanceHint:
    'Sorry, we’re fixing some app features, try again in a few minutes!',
  disabled: 'disabled',
  continueAnyway: 'Continue anyway',
};

const genericErrors: lang = {
  OPS: 'OOPS!',
  ops: 'Oops! Unexpected error!',
  opsServer: 'Oops! Server error',
  goBackSorry: 'Sorry, go back and try again :(',
  tryLaterSorry: 'Sorry, try again later :(',
  checkConnection: 'Check your connection',
  retry: 'Try again',
};

const localities: lang = {
  andalo: 'Andalo',
  molveno: 'Molveno',
  faiDellaPaganella: 'Fai della Paganella',
  cavedago: 'Cavedago',
  spormaggiore: 'Spormaggiore',
  ValDiSole: 'Val di Sole',
  ValDiPejo: 'Val di Pejo',
  ValDiPeio: 'Val di Peio',
  ValDiRabbi: 'Val di Rabbi',
  TonalePonteDiLegno: 'Tonale - Ponte di Legno',
};

const categories: lang = {
  bike_trails: 'Bike Trails',
  bike_tours: 'Bike Tours',
  bike_gravity: 'Bike Gravity',
  alpine_skiing: 'Alpine Skiing',
  skimo: 'Ski Mountaineering',
  crosscountry_skiing: 'Cross-country skiing',
  snow_shoes: 'Snowshoeing',
  sleeding: 'Tobogganing',
  trekking: 'Trekking',
};

const trackTypologies: lang = {
  ut: 'Enduro Tour',
  fa: 'Family',
  xc: 'Crosscountry',
  en: 'Shared Trail',
  dh: 'Bike Park',
  ut_min: 'ET',
  fa_min: 'FA',
  xc_min: 'XC',
  en_min: 'ST',
  dh_min: 'BP',
  blue: 'Blue Slopes',
  red: 'Red Slopes',
  black: 'Black Slopes',
  snowPark: 'Snowpark',
  firstSteps: 'Getting started',
  cross_green: 'Green Slopes',
  cross_blue: 'Blue Slopes',
  cross_red: 'Red Slopes',
  cross_black: 'Black Slopes',
};

const difficulties: lang = {
  peaceful: 'peaceful',
  easygoing: 'easygoing',
  halfway: 'halfway',
  challenging: 'challenging',
  rocky: 'rocky',
  adrenaline: 'adrenaline',
  beginner: 'beginner',
  intermediate: 'intermediate',
  advanced: 'expert',
  beginner_level: 'beginner',
  intermediate_level: 'intermediate',
  advanced_level: 'expert',
  blue_level: 'beginner',
  red_level: 'intermediate',
  black_level: 'expert',
  snowPark_level: 'snowpark',
  firstSteps_level: 'getting started',
  cross_green_level: 'getting started',
  cross_blue_level: 'beginner',
  cross_red_level: 'intermediate',
  cross_black_level: 'expert',
};

const tagServices: lang = {
  aerialway_chair_lift: 'Chairlift',
  bicycle_bus_stop: 'Bus stop',
  parking: 'Parking',
  ski_bus_station: 'SkiBus stop',
  bicycle_rent: 'Bike rental',
  fountain: 'Fountain',
  shower: 'Showers',
  toilette: 'Toilet',
  point_danger: 'Danger',
  point_forbidden: 'No entry/crossing',
  point_warning: 'Warning',
  sleep: 'Sleep',
  charging_station: 'Charging Station',
  kids_trail: 'Kids trail  ' + trackName,
  kinderclub: 'Kinderclub',
  pharmacy: 'Pharmacy',
  track_pump: 'Track Pump',
  outdoor_school: 'School',
  school: 'School',
  aerialway_gondola: 'Gondola lift',
  aerialway_lift: 'Lift',
  aerialway_station: 'Lift Station',
  historical_point: 'Historical point',
  panorama: 'Viewpoint',
  alpine_hut: 'Mountain hut',
  alpine_hut_sponsored: 'Top mountain hut',
  food: 'Food',
  restaurant: 'Restaurant',
  my_hotel: 'MyHotel',
  bicycle_repair: 'Bike repair',
  bicycle_wash: 'Bike wash',
  rent: 'Rental',
  sell: 'Shop',
  shop: 'Shop',
  shop_bicycle: 'Bike shop',
  doctor: 'Doctor',
  tourist_attraction: 'Attraction',
  atm: 'ATM',
  bicycle_sell: 'Bike shop',
  repair_kit: 'Repair kit',
  shop_ticket: 'Ticket office',
  info_point: 'Info Point',
  info_point_outdoor: 'Info Point',
  info_point_panel: 'Info Panel',
  info_point_panel_ar: 'Info Panel AR',
  info_point_sponsored: 'Info Point',
  alpineskiing_gear: 'Ski equipment',
  andalo_life: 'Andalo Life',
  animals_no: 'No pets',
  animals_yes: 'Pets welcomed',
  baby_backpack: 'Baby carrier',
  bus: 'Bus',
  crampons: 'Traction cleats for snow and ice',
  crosscountry_skiing_gear: 'Cross-country equipment',
  disabled_instructor: 'Disability Ski Instructor',
  googles_and_mask: 'Masks and goggles',
  gym: 'Gym',
  handicap_yes: 'Suitable for disabled sportspeople',
  helmet: 'Helmet',
  ice_skating: 'Ice skating',
  instructor_meeting_point: 'Instructor meeting point',
  kart: 'Kart',
  party: 'Party',
  pedestrians_no: 'No pedestrians',
  pedestrians_yes: 'Pedestrians allowed',
  riding_school: 'Riding school',
  ski_boots: 'Ski boots',
  ski_instructor: 'Ski instructor',
  ski_storage: 'Ski depot',
  skimo_gear: 'Mountaineering equipment',
  snow_clothing: 'Snow clothing',
  snow_shoes_gear: 'Snowshoeing equipment',
  snow_tubing: 'Snow tubing',
  snowboard_gear: 'Snowboarding equipment',
  snowboard_instructor: 'Snowboard instructor',
  snowscoot: 'Snowscoot',
  snowscoot_gear: 'Snowscoot equipment',
  spa: 'Spa',
  swimming_pool: 'Swimming pool',
  train: 'Train',
};

const tagFilters = {
  aerialway: 'Lifts',
  bicycle_info: 'Info Point',
  bike_shops: 'Bike Shops',
  bicycle_facilities: 'Bike Services',
  discover: 'Discover',
  eat_sleep: 'Mountain huts',
  public_utility: 'Utility',
  trails_alert: 'Info Alert',
  skill_area: 'Skill Area',
  ski_shops: 'Ski Shops',
  ski_info: 'Info Point',
  my_hotel: 'My Hotel',
  ski_school: 'Ski School',
};

const appInit = {
  loadInit: 'Start ' + appName,
  loadFonts: 'Initialise fonts',
  loadFontsErr: 'Cannot load fonts',
  loadCache: 'Initialise cache data',
  loadAuth: 'Authentication check',
  loadAuthErr: 'Cannot authenticate device',
  loadClient: 'Create Client',
  loadClientErr: 'Cannot create client',
  loadCheckBundle: 'Data Bundle Check',
  loadCheckBundleErr: 'Update required',
  installUpdate: 'Update the app',
  loadCheckBundleAsk:
    'You need the new version of ' + appName + ' to continue!',
  loadDownloadBundle: 'Download new Data Bundle',
  loadCacheBundle: 'Loading Data Bundle from Cache',
  loadBundleErr: 'Cannot load Data Bundle',
  loadFilters: 'Set map filters',
  loadFiltersErr: 'Error Loading filters',
  loadFileServe: 'Start static file server',
  loadFileServeErr: 'Cannot start static file server',
  loadModel: 'Create map template',
  insertTrails: 'Uploading tracks on map',
  loadComplete: 'Loading completed',
  loadMapError: 'Sorry, loading map error',
};

const login: lang = {
  login: 'Login',
  logout: 'Logout',
  logoutExt: 'Log off user',
  signup: 'Sign up',
  fbBtn: 'Continue with Facebook',
  mailBtn: 'Continue with e-mail',
  googleBtn: 'Continue with Google',
  newAccount: 'Create a new Mowi Space account!',
  loginAccount: 'Sign in with your Mowi Space account!',
  passwordHint: 'Your password must contain at least 8 characters',
  passwordForgot: 'Password forgotten?',
  acceptAndregister: 'Accept and register',
  userPassNull: 'Username and Password required!',
  userPassErr: 'Wrong Username or Password',
  passShort: 'Your password is too short!',
  userInfoNull: 'Sorry, your Display Name is mandatory!',
  wrongYear: 'Wrong Year of Birth!',
  forbiddenName: 'Nice try! Change Name! ;)',
  moreThan13: 'You must be 13+ to register!',
  emailNotVerified: 'Email not verified!',
  userDataNotComplete: 'User data not complete!',
  loginSuccess: 'Signed in successful',
  verifyEmail: 'Verify Email',
  completeProfile: 'Complete your profile',
  recoverPassword: 'Reset password',
  checkEmail: 'Check your Email!',
  mailLink: 'We have sent you the link to reset your password!',
  recoverPasswordHint: 'Enter the email you signed up with',
  recoverYourPassword: 'Reset your password',
  resendVerify: "Haven't received your code? Request it here",
};

const privacyRequest: lang = {
  privacy: 'Privacy Policy',
  canContact: 'Can we contact you?',
  weUpdateYouOn: 'We would like to keep you updated on:',
  reason1: tracksName + ' status and construction of new tracks',
  reason2: 'New App Features',
  reason3: 'Area news and events',
  reason4: 'Some tips to get the most out of ' + appName,
};

const profile: lang = {
  bornWhen: 'Born in',
  male: 'Male',
  female: 'Female',
  country: 'Country',
  sex: 'Sex',
  myProfile: 'My profile',
  profilePhoto: 'Profile picture',
  photoPermissions:
    '' +
    appName +
    ' cannot access your photos, go to app settings to change permissions',
};

const friends: lang = {
  friend: 'Friend',
  myFirends: 'My friends',
  isFriend: 'You are already friends',
  revokeFriendship: 'Remove friendship',
  requestFriendship: 'Request friendship',
  searchHereFriend: 'Find a new friend here',
  addFriend: 'Add friend',
  friendSearchNull: 'No user found with this name',
  friendsRequestsNull: 'No friend request received',
  friendsNull: 'You don’t have any friends yet,\nsearch for them by name!',
  friendsNullGroup:
    'You don’t have any friends yet,\nadd some to create groups!',
  friendIsInGroup: 'Already a member of this group',
};

const group: lang = {
  groupDetail: 'Group details',
  groupNew: 'Add members',
  groupNoActives: 'No active groups',
  partecipate: 'Join',
  ongoing: 'In progress',
  scheduled: 'Scheduled',
  partecipatingNow: 'You are participating now',
  partecipatingNowHe: 'Participating',
  partecipatingNot: 'You are not participating',
  partecipatingNotHe: 'Not participating',
  partecipatingBefore: 'You joined this group',
  onlyOnePartecipation: 'YOU CAN ONLY PARTICIPATE IN ONE GROUP AT A TIME',
  components: 'Members',
  startAt: 'Started at',
  activeFor: 'Active for',
  overAt: 'Over at',
  inactive: 'Inactive',
  moving: 'Moving',
  still: 'Stationary',
  partecipants: 'Participants',
  groupList: 'Group list',
  createNew: 'Create new',
  createHang: 'Create group',
  groupNoLogin: 'Log in to ' + appName + 'to create and participate in groups!',
  noGroups: 'You are not in any group, create one now and add your friends!',
  groupNamePlaceholder: 'Group among friends, family, etc.',
  groupDescPlaceholder: 'Meeting place, group purpose, times, etc.',
  groupFriendSelect: 'Search among your friends',
  removeUser: 'Remove member',
  groupDelete: 'Delete group',
  groupDismiss: 'Leave group',
  groupPhoto: 'Group pic',
  title: 'Title',
  description: 'Description',
  promote: 'Make admin',
  addComponents: 'Add members',
  editGroup: 'Edit Group',
  groupPermissionsTitle:
    'For groups to work properly you must grant ' +
    appName +
    ' these permissions',
  groupPermissionsPosition:
    'Share location with group members even when the app is not in use: set it to “Always”',
  groupPermissionsMotion:
    'Track your movements, save battery by avoiding sending when you are stationary',
  groupPermissionsBattery:
    Platform.OS === 'ios'
      ? 'Disable energy saving so that ' +
        appName +
        ' can communicate even when you are not using the app: we will take care of preserving your battery'
      : 'We recommend disabling battery optimisation to make sure groups can work properly: we’ll take care of preserving your battery',
  groupPermissionsBackground:
    'Enable background update of the app so that ' +
    appName +
    ' can communicate even when you are not using the app',
  preciseLocation: 'Exact location',
  groupPermissionsPreciseLocation:
    'Share your exact location to be shown in a group: click “Enable” to request it temporarily or change your settings to enable it permanently',
  partecipating: 'You are participating in a group',
};

const userMenu: lang = {
  yourFriends: 'Your friends',
  newRequests: 'new requests',
  yourGroups: 'Your groups',
  showTutorialAgain: 'Review tutorial',
  credits: 'Credits',
  settings: 'Settings',
  advancedSettings: 'Advanced settings',
};

const elements: lang = {
  infoView: 'Overview',
  details: 'Details',
  locality: 'Location',
  info: 'Info',
  media: 'Media',
  reviews: 'Comments',
  notice: 'Alerts',
  cardPressDetails: 'Click on the card to find out details',
};

const tracks: lang = {
  trackInfoErr: 'Oops, no data available for this ' + trackName,
  trackMediaErr: 'No photos or videos for this ' + trackName + 'yet!',
  IMBA: 'IMBA',
  artificial: 'Artificial',
  natural: 'Natural',
  startStation: 'Start',
  endStation: 'Finish',
  difficulty: 'Difficulty',
  length: 'Length',
  duration: 'Duration',
  altimetryPlus: 'Ascent',
  altimetryMinus: 'Descent',
  maxAltitude: 'Max Altitude',
  minAltitude: 'Min Altitude',
  technicalAbility: 'Technical Difficulty',
  physicalEfford: 'Physical Effort',
  emotion: 'Emotion',
  landscape: 'Landscape',
  suggestedPeriod: 'Best time',
  isCircular: 'Circular Track',
  isFamily: 'Suitable for families and children',
  howToArrive: 'How to get there',
  trackFilterNull: 'No track matching the filters you set!',
  maxSlope: 'Max Slope',
  loginTrackHint:
    'Log in to see live track warnings and alerts or create a free Mowi Space account now!',
};

const pois: lang = {
  poiInfoErr: 'Oops, no data available for this service',
  poiMediaErr: 'No photos or videos are available for this service yet!',
  altitude: 'Altitude',
  poiFilterNull: 'No services meeting the filters you set!',
};

const transports: lang = {
  pedestrians_yes: 'Pedestrians allowed',
  pedestrians_no: 'No pedestrians',
  isForDisabled: 'Accessibility for the Disabled',
  isForSnowscoot: 'Snowscoot allowed',
  transportInfoErr: 'Oops, no data available for this facility',
  transportMediaErr: 'No photos or videos for this facility yet!',
  transportFilterNull: 'No skilifts meeting the filters you set!!',
};

const intro: lang = {
  begin: 'Start',
  nextSlide: 'Continue',
  slideToBegin: 'Scroll to the end to start',
  useTerm: 'terms of use',
  privacyPolicy: 'privacy policy',
  iAgree: 'I agree',
  iDoNotAgree: 'I do not agree',
  maybeLater: 'Maybe later',
  pressConsent: 'Press "I agree" to accept ours',
  sureDeny: 'Deny consent',
  abortDeny: 'No, go back',
  denyAlert: 'This way, you will not be able to use ' + appName,
  welcomeTitle: 'Welcome\nto ' + appName,
  welcomeP1:
    'Make the most out of the potential of our App and enjoy every moment',
  welcomeP2: 'We hope you can have a great experience with us ;)',
  privacyLockTitle: 'Your privacy\nis important',
  privacyLockP1:
    'Protecting your privacy is very important to Mowi. That’s why we want to inform you about what data we use and how we use it.',
  privacyUserTitle: 'Personal information',
  privacyUserP1:
    'While using the App, we may ask for some of your data, such as your location to allow you locate on the map within the area, or other information when you register.',
  privacyStarTitle: 'To improve\nyour experience',
  privacyStarP1:
    'We are committed to improve our services to ensure you always have the best experience possible. To this end, we collect, in a totally anonymous way, some statistical and usage data of the app.',
  privacyAlertTitle: 'Keep me\nposted',
  privacyAlertP1:
    'We’ll update you on great new Mowi features and when we have something useful or just plain cool to share with you.',
  privacyAlertP2:
    Platform.OS === 'ios'
      ? 'You will be asked for your consent to receive notifications: you can always customise your notifications by clicking on the little bell at the top'
      : 'You will always be able to customise your notifications by clicking on the little bell at the top',
  privacyGroupTitle: 'Real-time groups',
  privacyGroupP1:
    'Share your location with a group of friends or family. You can decide when to activate or deactivate the shared location clicking on the “Join/Stop” button',
  privacyGroupP2:
    'This is why we will require your consent to use location even when you are not using the App (background) as well as motion data: you will only share your location with users in the group you are a member of',
  privacyMarketingTitle: 'News from your\n' + app + ' Area',
  privacyMarketingP1:
    'Keep me posted about lift and slopes openings and closures',
  privacyMarketingP2:
    'Receive alerts, news, promotions and events from your ' + app + ' Area',
  privacyMarketingP3:
    'To send you useful and relevant communications we may need to detect your ' +
    app +
    ' Area using your location',
  privacyMarketingP4:
    'You can always change your notification settings from your profile',
  selectAreaTitle: 'Select the area\nyou are interested in',
  selectAreaP1:
    '*' +
    appName +
    ' is currently available for the Paganella' +
    app +
    ' area\nNew fantastic destinations coming soon!',
};

const find: lang = {
  whatNeed: 'What\nare you looking for?',
  trailsProgression: tracksName + ' List',
  aerialway_lifts: 'Lifts',
  alpine_huts: 'Mountain huts',
  charging_stations: 'Charging stations',
  rent_and_shops: 'Bike rentals and shops',
  repairs_mtb: 'Bike repair',
  find: 'Search',
  trailDetail: trackName + ' Detail',
  serviceDetail: 'Service Detail',
  rentAndSchoolSki: 'Rentals and ' + app + ' schools',
};

const notifications: lang = {
  notifications: 'Notifications',
  readAll: 'Read all',
  notiCallToLoginTitle:
    'Do you feel frustrated when you arrive at a lift or ' +
    trackName +
    ' and find it closed?',
  notiCallToLoginDesc:
    'If you don’t already have one, create your Mowi Space account to receive notifications about ski slopes, facilitis and lifts status in real time!',
  noNotifications: 'No notifications to read…',
  notiSettings: 'Notification settings',
  notiSettingsTitle: 'Choose which notifications to receive',
  notiSettingsRT: 'Real-Time Information',
  trailsStatus: 'Slope and trail status',
  poisStatus: 'Facilities status',
  transportsStatus: 'Lift and transport status',
  notiExpire: 'For one week',
  notiForever: 'Always',
  notiNever: 'Never',
  notiInitTitle:
    'Do you want to receive push notifications about slopes and lifts status in real-time?',
  notiInitHint: 'YYou can always change this choice in your profile settings',
  notiPermission:
    'You have not activated notifications! In order to receive alerts and notifications you must change the notification permissions in the app settings',
  rtStatus: 'Real-time status',
  rtStatusP:
    'Receive notifications about new alerts or update on tracks, facility or activity status in the area.',
  newsAndAlerts: 'News and Alerts',
  newsAndAlertsP:
    'Receive notifications about new useful or safety measures and alerts from the area or Mowi',
  promoAndEvents: 'Events and promo',
  promoAndEventsP:
    'Receive notifications about events, communications and promos from the area, activities or Mowi',
  notiFromArea: 'You will receive notifications from this area',
  yourActualArea: 'Your current area',
  smartNoti: 'Smart Selection',
  smartNotiP:
    'Allow Mowi to select your notifications so you only receive the ones you are really interested in, e.g. by only sending you alerts, events and trail/slope/skilifts status when you are actually within the area',
};

const chat: lang = {
  chatCallToLoginTitle: 'Looking for friends or someone to give you advice?',
  chatCallToLoginDesc:
    'Create your ' +
    appName +
    ' account to access the area chat and connect to our community!',
};

const location: lang = {
  locationDisactive: 'Location off',
  locationPermissions:
    'Activate your location from settings to be able to see where you are on the map',
};

const other: lang = {
  debug: 'DEBUG',
  ComingSoon: 'Soon available',
  avalancheRisk: 'Avalanche\nRisk',
  openLifts: 'Lift open',
  openTracks: 'Trails open',
};

const promots: lang = {
  remain: 'Stay here',
  changeArea: 'Change area',
  chooseArea: 'Choose this area',
};

const notices: lang = {
  newNotice: 'New notice',
  noticesList: 'Notices List',
  newWarning: 'Create Alert',
  noticeProblemsTracks:
    'Report to us any problems you have encountered on this track',
  noticeProblemHint:
    'Help other users by letting us know about the problem! We will try to fix it as soon as possible, Thank you!',
  noticeProblemLogin:
    'Sign in or create a Mowi Space account to submit a report!',
  send: 'Send',
  sendNotice: 'Send report',
  thanksForNotice: 'Thank you for your report!',
  weResolveSoon: 'We will do our best to fix the problem as soon as possible!',
  noticeProblem: 'Report a problem',
  compileCamps: 'Fill in the following fields',
  wichProblem: 'What is the problem?',
  selectRightProblem:
    'Select the problem type closest to the one you are experiencing',
  whereProblem: 'Where is the problem located?',
  problem_all: 'On the whole path',
  problem_start: 'At the beginning',
  problem_center: 'In the middle',
  problem_end: 'At the end',
  selectRightWhere:
    'Select the point on the path that is closest to the problem you found',
  inOtherArea: 'From another area',
};

export const t: lang = {
  ...general,
  ...genericErrors,
  ...localities,
  ...categories,
  ...trackTypologies,
  ...difficulties,
  ...tagServices,
  ...tagFilters,
  ...appInit,
  ...login,
  ...privacyRequest,
  ...profile,
  ...friends,
  ...group,
  ...userMenu,
  ...elements,
  ...tracks,
  ...pois,
  ...transports,
  ...intro,
  ...find,
  ...notifications,
  ...chat,
  ...location,
  ...other,
  ...promots,
  ...notices,
  ...other,
};
