import React, { ChangeEvent, CSSProperties } from 'react';

interface TextBoxProps {
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  id: string;
  type: 'password' | 'email' | 'text';
  required: boolean;
  placeholder?: string;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextBox = (props: TextBoxProps) => {
  return (
    <>
      {props.label && (
        <label
          className="block uppercase text-gray-600 text-xs font-bold mb-2"
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <input
        required={props.required}
        id={props.id}
        type={props.type}
        onChange={props.onChange || undefined}
        className="shadow px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
        placeholder={props.placeholder}
      />
    </>
  );
};

export { TextBox };
