import { CardMessages } from "../../../components/Cards/CardMessages";
import { CardNotices } from "../../../components/Cards/CardNotices";
import { CardNotifications } from "../../../components/Cards/CardNotifications";
import { CardStats } from "../../../components/Analytics/CardStats";
import { ACTUAL_AUTH } from "../../../stores/db/auth";
import { LineMetric } from "../../../components/Analytics/LineMetric";
import { PieDimension } from "../../../components/Analytics/PieDimension";
import { gql, useLazyQuery } from "@apollo/client";
import csvDownload from "json-to-csv-export";

import ImgStats from "../../../assets/img/stats.jpg";
import { GET_USERS_DATA, User } from "../../../stores/queries/users";
import { useGlobalState } from "../../../stores/appState";

interface DashViewProps {
  route?: any;
}

const DashView = (props: DashViewProps) => {
  const [actualArea, setActualArea] = useGlobalState("actualArea");
  const [getUserData, { called, loading, data }] = useLazyQuery(
    GET_USERS_DATA,
    {
      variables: { areaId: ACTUAL_AUTH.area },
      onCompleted(data) {
        if (data?.adminGetUsersByArea?.length) {
          let res: User[] = data.adminGetUsersByArea;
          let users = [];

          for (let i = 0; i < res.length; i++) {
            const user = res[i];
            users.push({
              name: user.displayName,
              email: user.email,
              anno: user.basicInfo?.birthYear,
              paese: user.basicInfo?.country,
              sesso: user.basicInfo?.sex,
            });
          }

          const dataToConvert = {
            data: users,
            filename:
              actualArea.nameRef +
              "_users_data_" +
              new Date().toLocaleDateString(),
            delimiter: ",",
            headers: ["Nome", "Email", "Anno", "Paese", "Sesso"],
          };

          csvDownload(dataToConvert);
        }
      },
    }
  );

  const lastWeek = () => {
    const today = new Date();
    const weekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    return weekAgo.toISOString().split("T")[0];
  };

  const lastMonth = () => {
    const today = new Date();
    const monthAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    return monthAgo.toISOString().split("T")[0];
  };

  return (
    <>
      <>
        <div className="relative md:pt-5 pb-5 pt-5">
          <div className="mx-auto w-full">
            <div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 xl:w-4s/12 px-4">
                  <CardStats
                    areaId={ACTUAL_AUTH.area}
                    areaApp={ACTUAL_AUTH.app}
                    from={"2022-05-01"}
                    to={"today"}
                    metric={"totalUsers"}
                    title="Totale Utenti"
                    statDescripiron="Utenti unici dell'area"
                    statIconName="fas fa-user"
                    statIconColor="bg-red-500"
                  />
                </div>
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <CardStats
                    areaId={ACTUAL_AUTH.area}
                    areaApp={ACTUAL_AUTH.app}
                    from={"2022-05-01"}
                    to={"today"}
                    metric={"sessions"}
                    title="Accessi totali"
                    statDescripiron="Totale accessi in area"
                    statIconName="fas fa-users"
                    statIconColor="bg-blue-400"
                  />
                </div>
                <div className="w-full lg:w-4/12 xl:w-4/12 px-4">
                  <CardStats
                    areaId={ACTUAL_AUTH.area}
                    areaApp={ACTUAL_AUTH.app}
                    from={lastWeek()}
                    to={"today"}
                    metric={"sessions"}
                    title="Nuovi accessi"
                    statDescripiron="Aperture area questa settimana"
                    statIconName="far fa-chart-bar"
                    statIconColor="bg-purple-500"
                  />
                </div>
                {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  metric={"activeUsers"}
                  title="Utenti area"
                  statDescripiron="Utenti loggati nell'area"
                  statIconName="fas fa-street-view"
                  statIconColor="bg-pink-500"
                />
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <LineMetric
              areaId={ACTUAL_AUTH.area}
              areaApp={ACTUAL_AUTH.app}
              from={lastMonth()}
              to={"yesterday"}
              id="line-metric-base-access"
              metric="sessions"
              title="Andamento accessi ultimi 30 giorni"
              //unit="Accessi Totali"
            />
          </div>
          <div className="w-full xl:w-4/12 px-4">
            <PieDimension
              dimension="customUser:USER_PROFILATION"
              areaId={ACTUAL_AUTH.area}
              areaApp={ACTUAL_AUTH.app}
              from={"2022-05-01"}
              to={"yesterday"}
              title="Profilazione utenti"
              id="pie-chart_profilation"
            />
          </div>
        </div>
      </>

      {ACTUAL_AUTH.app === "bike" ? (
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <CardNotifications />
          </div>
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <CardNotices />
          </div>
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <CardMessages />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <CardNotifications />
          </div>
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <CardMessages />
          </div>
          <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl pb-2">
              <div className="flex flex-wrap items-center justify-center w-full p-2 pt-4">
                <button
                  className="bg-purple-400 text-white hover:bg-purple-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (!loading) getUserData();
                  }}
                >
                  {loading ? "Attendi..." : "Scarica dati utenti"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { DashView };
