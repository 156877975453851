import { gql } from '@apollo/client';

interface Problem {
  problem: string;
  description: string;
}

interface Notice {
  id: string;
  object: string;
  objectName: string;
  problem: string;
  description: { it: string; en: string; de: string };
  location: 'start' | 'center' | 'end' | 'all';
  type: 'track' | 'poi' | 'transport';
  creator: {
    user: string;
    displayName: string;
  };
  status:
    | 'waiting'
    | 'takenOver'
    | 'closed_isWarning'
    | 'closed_fixed'
    | 'closed_cancel';
  createdAt: string;
  updatedAt: string;
  takenOver: {
    createdAt: string;
    patrol: { user: string; displayName: string };
  };
  counter: number;
  area: string;
}

const NOTICE_FRAGMENT = gql`
  fragment noticeFragment on Notice {
    id
    object
    objectName
    creator {
      user
      displayName
    }
    problem
    location
    type
    status
    createdAt
    updatedAt
    takenOver {
      createdAt
      patrol {
        user
        displayName
      }
    }
    counter
    area
    description {
      it
      en
      de
    }
  }
`;

const PROBLEM_LIST_NOTICE = gql`
  query PROBLEM_LIST_NOTICE($language: Language!, $type: NoticeType!) {
    configProblemListNotice(language: $language, type: $type) {
      problem
      description
    }
  }
`;

const PATROL_NOTICES = gql`
  query PATROL_NOTICES {
    patrolActiveNotices {
      ...noticeFragment
    }
  }
  ${NOTICE_FRAGMENT}
`;

const USER_CREATE_NOTICE = gql`
  mutation userCreateNotice(
    $object: ID!
    $problem: NoticeProblem!
    $location: NoticeLocation!
    $type: NoticeType!
  ) {
    userCreateNotice(
      object: $object
      problem: $problem
      location: $location
      type: $type
    ) {
      object
      problem
      location
      type
      status
    }
  }
`;

const PATROL_TAKE_NOTICE = gql`
  mutation patrolTakeOverNotice($id: ID!) {
    patrolTakeOverNotice(id: $id) {
      ...noticeFragment
    }
  }
  ${NOTICE_FRAGMENT}
`;

const PATROL_CHANGESTATUS_NOTICE = gql`
  mutation patrolChangeStatusNotice($id: ID!, $newStatus: NoticeStatus!) {
    patrolChangeStatusNotice(id: $id, newStatus: $newStatus) {
      ...noticeFragment
    }
  }
  ${NOTICE_FRAGMENT}
`;

export {
  PROBLEM_LIST_NOTICE,
  USER_CREATE_NOTICE,
  PATROL_NOTICES,
  PATROL_TAKE_NOTICE,
  PATROL_CHANGESTATUS_NOTICE,
};
export type { Problem, Notice };
