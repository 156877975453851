import { Popover } from "@headlessui/react";
import React, { useState } from "react";
//@ts-ignore
import { DateRangePicker } from "react-date-range";
// @ts-ignore
import Moment from "moment/min/moment-with-locales";
import { usePopper } from "react-popper";
import { ENV } from "../config";

type DateRangeType = {
  startDate: Date;
  endDate: Date;
};

interface DateRangeProps {
  initStartDate?: string;
  initEndDate?: string;
  onChange?: (date: any) => void;
}

const DateRange = (props: DateRangeProps) => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: new Date(props.initStartDate || "2020-01-01"),
    endDate: props.initEndDate ? new Date(props.initEndDate) : new Date(),
  });

  const [rangeLabel, setRangeLabel] = useState<DateRangeType>({
    startDate: new Date(props.initStartDate || "2020-01-01"),
    endDate: props.initEndDate ? new Date(props.initEndDate) : new Date(),
  });

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -5],
        },
      },
    ],
  });

  const handleSelect = (ranges: { range: DateRangeType }) => {
    setDateRange(ranges.range);
  };

  return (
    <>
      <Popover>
        <div className="flex w-fulljustify-between items-center flex-col">
          <Popover.Button
            ref={setReferenceElement as any}
            className="flex items-center justify-center"
          >
            <div className="flex p-2 px-4 flex-row bg-white rounded-2xl justify-between items-center hover:shadow-md ease-linear transition-all duration-150 cursor-pointer text-gray-800 text-xl">
              <i>Da </i>
              <b className="ml-2">
                {Moment(rangeLabel.startDate)
                  .locale(ENV.locale, "en")
                  .format("dddd DD MMMM YYYY")}
              </b>
              <i className="ml-2">a</i>
              <b className="ml-2">
                {Moment(rangeLabel.endDate)
                  .locale(ENV.locale, "en")
                  .format("dddd DD MMMM YYYY")}
              </b>
            </div>
          </Popover.Button>
        </div>

        <Popover.Panel
          ref={setPopperElement as any}
          style={{ ...styles.popper, zIndex: 9999 }}
          {...attributes.popper}
        >
          <div
            className={
              "bg-white text-base px-2 rounded-lg shadow-lg flex flex-col p-2 justify-center items-end"
            }
            style={{ border: "1px solid #ddd", zIndex: 99999 }}
          >
            <div>
              <DateRangePicker
                ranges={[{ ...dateRange, key: "range" }]}
                onChange={handleSelect}
                maxDate={new Date()}
                minDate={new Date("2022-05-01")}
              />
            </div>

            <Popover.Button>
              <div
                className={`bg-blue-400 active:bg-blue-500 text-white text-sm px-6 py-3 text-center font-bold uppercase rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 cursor-pointer`}
                onClick={() => {
                  setRangeLabel(dateRange);
                  props.onChange && props.onChange(dateRange);
                }}
              >
                Conferma
              </div>
            </Popover.Button>
          </div>
        </Popover.Panel>
      </Popover>
    </>
  );
};

export { DateRange };
