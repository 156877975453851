import React, { useState } from "react";
import { IamAdd, IamPreview, IamsList } from "../../../components";
import { Message } from "../../../stores/queries/iams";

interface IamsViewProps {}

const IamsView = (props: IamsViewProps) => {
  const [message, setMessage] = useState<Message>();
  const [iamsIndex, setIamsIndex] = useState<number>(0);
  const [iamsCount, setIamsCount] = useState<number>(0);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12">
          <IamAdd iamsCount={iamsCount} setMessage={setMessage} />
        </div>
        <div className="w-full lg:w-4/12 px-2">
          <IamPreview iamsIndex={iamsIndex} message={message} />
        </div>
        <div className="w-full lg:w-4/12">
          <IamsList
            iamsIndex={iamsIndex}
            setIamsIndex={setIamsIndex}
            setIamsCount={setIamsCount}
            setMessage={setMessage}
          />
        </div>
      </div>
    </>
  );
};

export { IamsView };
