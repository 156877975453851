import React, { CSSProperties, MouseEvent, ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  color?: "white" | "purple" | "gray" | "grayLight" | "red" | "yellow" | "blue";
  disabled?: boolean;
  full?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
}

const Button = (props: ButtonProps) => {
  let colorClasses = "";
  switch (props.color) {
    case "white":
      colorClasses = "bg-white active:bg-gray-100 text-gray-800";
      break;
    case "purple":
      colorClasses = "bg-purple-400 active:bg-purple-500 text-white";
      break;
    case "gray":
      colorClasses = "bg-gray-600 active:bg-gray-700 text-white";
      break;
    case "grayLight":
      colorClasses = "bg-gray-400 active:bg-gray-500 text-white";
      break;
    case "red":
      colorClasses = "bg-red-400 active:bg-red-500 text-white";
      break;
    case "yellow":
      colorClasses = "bg-yellow-300 active:bg-yellow-400 text-gray-800";
      break;
    case "blue":
      colorClasses = "bg-blue-400 active:bg-blue-500 text-white";
      break;
    default:
      colorClasses = "bg-white active:bg-gray-100 text-gray-800";
      break;
  }

  let sizeClasses = "";
  switch (props.size) {
    case "xs": {
      sizeClasses = "text-xs px-2 py-1";
      break;
    }
    case "sm":
      sizeClasses = "text-xs px-4 py-2";
      break;
    case "md":
      sizeClasses = "text-sm px-6 py-3";
      break;
    case "lg":
      sizeClasses = "text-md px-6 py-3";
      break;
    default:
      sizeClasses = "text-xs px-4 py-2";
      break;
  }

  return (
    <button
      type="button"
      className={`${colorClasses} ${sizeClasses} text-center font-bold uppercase rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${
        props.className
      } ${props.full ? "w-full" : ""} ${props.disabled ? "opacity-50" : ""}`}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export { Button };
