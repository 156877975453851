import gql from "graphql-tag";

interface Message {
  id?: string;
  scope: "commercial" | "alert" | "info" | "auth" | "noauth";
  title: {
    it: string;
    en: string;
    de: string;
  };
  description?: {
    it: string;
    en: string;
    de: string;
  };
  image?: {
    it: string;
    en: string;
    de: string;
  };
  link?: {
    it: string;
    en: string;
    de: string;
  };
  repeat?: "day" | "week" | "month";
  appMinVersion?: string;
  // appMaxVersion?: string;
  area?: string;
}

const IAMS_FRAGMENT = gql`
  fragment iamsFragment on InAppMessage {
    id
    scope
    title {
      it
      en
      de
    }
    description {
      it
      en
      de
    }
    image {
      it
      en
      de
    }
    link {
      it
      en
      de
    }
    repeat
    appMinVersion
    area
  }
`;

const AREA_IAMS = gql`
  query AREA_IAMS {
    areaInAppMessages {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;

const AREA_CREATE_IAMS = gql`
  mutation areaCreateInAppMessage(
    $scope: AreaInAppMessageScopes!
    $title: DescriptionInput!
    $repeat: AreaInAppMessageRepeat
    $description: DescriptionInput
    $image: ImageInput
  ) {
    areaCreateInAppMessage(
      scope: $scope
      title: $title
      repeat: $repeat
      description: $description
      image: $image
    ) {
      ...iamsFragment
    }
  }
  ${IAMS_FRAGMENT}
`;

const AREA_DELETE_IAMS = gql`
  mutation areaDeleteInAppMessage($id: ID!) {
    areaDeleteInAppMessage(id: $id)
  }
`;

export { AREA_CREATE_IAMS, AREA_IAMS, AREA_DELETE_IAMS };
export type { Message };
