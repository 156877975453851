import { createGlobalState } from "react-hooks-global-state";

export const { setGlobalState, getGlobalState, useGlobalState } =
  createGlobalState<{
    auth: {
      user: string | null;
      area: string | null;
    };
    locale: "it" | "en" | "de";
    actualArea: {
      bundle: { version: string } | null;
      id: string | null;
      name: string | null;
      description: string | null;
      nameRef: string | null;
      locality: string | null;
      isDisabled: boolean | null;
      isCoomingSoon: string | null;
      assets: {
        coverUrl: string;
        logoUrl: string;
        tailsPath: string;
      } | null;
      map: {
        version: string;
        limits: {
          geo: [number, number, number, number];
          height: [number, number];
        };
        coordsCenter: [number, number];
      } | null;
      app: "ski" | "bike" | null;
    };
  }>({
    auth: {
      user: null,
      area: null,
    },
    locale: "it",
    actualArea: {
      bundle: null,
      id: null,
      name: null,
      description: null,
      nameRef: null,
      locality: null,
      isDisabled: null,
      isCoomingSoon: null,
      assets: null,
      map: null,
      app: null,
    },
  });
