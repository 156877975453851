import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import { ACTUAL_AUTH } from "../../stores/db/auth";
import { Button, Loading } from "../../ui";
import { ENV, LOG, theme } from "../../config";
import {
  AreaData,
  AREA_COMPLETE,
  DEMOTE_PATROL,
} from "../../stores/queries/areas";

const UserItem = ({
  role,
  user,
}: {
  role: string;
  user: {
    _id: string;
    displayName: string;
  };
}) => {
  const [demotePatrol, { error, loading }] = useMutation(DEMOTE_PATROL, {
    refetchQueries: [
      {
        query: AREA_COMPLETE,
        variables: {
          id: ACTUAL_AUTH.area,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const demote = () => {
    demotePatrol({
      variables: { areaId: ACTUAL_AUTH.area, userId: user._id },
    });
  };

  if (error) LOG.error(error);

  if (role === "admin") return null;

  return (
    <div className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center shadow transition-all rounded-2xl bg-gray-100 hover:bg-gray-200 text-white">
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginRight: 10,
        }}
      >
        <img
          alt={"AvatarUser"}
          src={
            ENV.assets.userAvatar.uri +
            user._id +
            "_low?t=" +
            ENV.auth.sessionId
          }
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: theme.vars.colors.grey,
            borderColor: theme.vars.colors.grey,
            borderWidth: 1,
          }}
        />
      </div>
      <div style={{ flex: 1, justifyContent: "center" }}>
        <div style={{ flexDirection: "row", alignItems: "center" }}>
          <span className="text-base text-gray-600 mt-2 text-center font-bold">
            {user.displayName}
          </span>
        </div>
        <span className="text-base text-gray-600 mt-2 text-center">{role}</span>
      </div>
      <Button
        onClick={() => {
          demote && demote();
        }}
        color="red"
      >
        Revoca
      </Button>
      {loading ? (
        <div
          className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center"
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: 5000,
          }}
        >
          <Loading color={theme.vars.colors.light} />
        </div>
      ) : null}
    </div>
  );
};

const PatrolsList = () => {
  const { error, data } = useQuery<{
    Area: AreaData;
  }>(AREA_COMPLETE, {
    variables: {
      id: ACTUAL_AUTH.area,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    LOG.error(error);
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className={
          "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white p-2"
        }
      >
        <div className="h-full w-full overflow-y-auto">
          {data?.Area.admins.map((item: any, index: any) => {
            return (
              <UserItem
                key={"list_" + item.user._id}
                user={item.user}
                role={item.role}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export { PatrolsList };
