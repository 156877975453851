import React from "react";

import { ENV, theme } from "../../config";

interface IamPreviewProps {
  message?: any;
  iamsIndex?: number;
}

const IamPreview = (props: IamPreviewProps) => {
  return (
    <div
      style={{ height: "calc(100vh - 110px)" }}
      className={
        "relative p-4 flex flex-1 flex-col min-w-0 break-words w-full text-center justify-center items-center"
      }
    >
      <div
        className="text-base w-full m-2 p-2 mb-4 shadow-xl rounded-2xl bg-white"
        style={
          {
            //backgroundColor: theme.vars.colors.greyDark,
          }
        }
      >
        Anteprima - <i className="uppercase">{ENV.locale}</i>:{" "}
        {!props.message?.id ? (
          <b>Nuovo</b>
        ) : (
          <b>#{(props.iamsIndex && props.iamsIndex + 1) || 1}</b>
        )}
      </div>

      <div
        className="flex flex-col w-full shadow-xl justify-start items-start"
        style={{ ...styles.page, position: "relative" }}
      >
        {props.message?.image &&
        (props.message.image[ENV.locale] || props.message.image["en"]) ? (
          <div
            style={{
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              height: "50%",
              position: "relative",
            }}
          >
            <img
              alt="immagine"
              src={props.message.image[ENV.locale] || props.message.image["en"]}
              style={{ ...styles.img, width: "100%", objectFit: "cover" }}
            />
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                height: "80%",
                width: "100%",
                backgroundImage:
                  "linear-gradient(to bottom, rgba(0,0,0,0), " +
                  theme.vars.colors.dark +
                  ")",
              }}
            />
          </div>
        ) : null}
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            paddingBottom: 20,
            top: 0,
            left: 0,
            zIndex: 100,
          }}
          className="flex flex-col w-full shadow-xl justify-end items-start"
        >
          {!props.message?.title ||
          !(props.message.title[ENV.locale] || props.message.title["en"]) ? (
            <h6
              className="uppercase flex flex-col justify-center items-center"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                height: "100%",
                width: "100%",
                color: theme.vars.colors.grey,
                fontSize: 24,
              }}
            >
              Anteprima
            </h6>
          ) : (
            <h6
              className="uppercase"
              style={{
                ...styles.title,
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {props.message.title[ENV.locale] || props.message.title["en"]}
            </h6>
          )}

          {props.message?.description ? (
            <h6
              style={{
                ...styles.description,
                textAlign: "left",
                whiteSpace: "break-spaces",
              }}
            >
              {props.message.description[ENV.locale] ||
                props.message.description["en"]}
            </h6>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    padding: 0,
    margin: 0,
    borderRadius: 30,
    backgroundColor: theme.vars.colors.dark,
  },
  page: {
    overflow: "hidden",
    width: "20vw",
    height: "32vw",
    backgroundColor: theme.vars.colors.dark,
    borderRadius: 30,
  },
  img: {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
  },
  description: {
    color: theme.vars.colors.light,
    margin: 15,
    fontSize: "1vw",
    lineHeight: 1,
    marginTop: 15,
    marginBottom: 0,
  },
  title: {
    fontSize: "1.5vw",
    lineHeight: 1,
    margin: 15,
    marginTop: 0,
    marginBottom: 0,
    color: theme.vars.colors.light,
    //textTransform: 'uppercase',
  },
};

export { IamPreview };
