import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import Masonry from "react-masonry-css";
import { useDropzone } from "react-dropzone";
//@ts-ignore
import { Lightbox } from "react-modal-image";

import { LOG, T, theme } from "../../config";
import {
  TRANSPORT,
  Transport,
  TRANSPORT_ADD_MEDIA,
  TRANSPORT_REMOVE_MEDIA,
} from "../../stores/queries/transports";
import { Button, Loading } from "../../ui";

const breakpointColsColumnsObj = {
  default: 3,
  500: 1,
};

interface TransportImageProps {
  transport: string;
  id: string;
  url: string;
  onClick?: (id: string, url: string) => void;
}

const TransportImage = (props: TransportImageProps) => {
  const [transportRemoveMedia, { loading: removeLoading, error: removeError }] =
    useMutation(TRANSPORT_REMOVE_MEDIA);

  const removeMedia = () => {
    transportRemoveMedia({
      variables: {
        id: props.transport,
        mediaId: props.id,
        type: "picture",
      },
    });
  };

  if (removeError) {
    LOG.error(removeError);
  }

  return (
    <div className="relative hover:shadow-lg bg-gray-200 transition-all rounded">
      <img src={props.url} alt="transport" className="w-full rounded" />
      {!removeLoading ? (
        <div className="absolute w-full h-full top-0 left-0 opacity-0 hover:opacity-100 transition-all">
          <div
            className="absolute w-full h-full top-0 left-0 flex flex-row justify-center items-center p-4 cursor-transportnter"
            onClick={(event) => {
              event.stopPropagation();
              props.onClick && props.onClick(props.id, props.url);
            }}
          >
            <i className="fa fa-search-plus text-white fa-2x opacity-80" />
            <Button
              className={"absolute top-2 right-2"}
              color={"purple"}
              onClick={(event) => {
                event.stopPropagation();
                removeMedia();
              }}
            >
              <i className="fa fa-trash" />
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center rounded"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <Loading color={theme.vars.colors.light} />
        </div>
      )}
    </div>
  );
};

interface TransportMediaProps {
  id: string;
}

const TransportMedia = (props: TransportMediaProps) => {
  const { error, data } = useQuery<{
    userTransport: Transport;
  }>(TRANSPORT, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [transportAddMedia, { error: uploadError, loading: uploadLoading }] =
    useMutation(TRANSPORT_ADD_MEDIA);

  const onDrop = useCallback((acceptedFiles: any) => {
    addMedia(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
  });

  const FileErrors = () => {
    if (uploadError) {
      <div className=" w-full top-0 left-0 flex flex-col justify-center items-center text-white bg-red-400 py-2">
        {uploadError.message}
      </div>;
    }
    if (fileRejections && fileRejections.length > 0) {
      return (
        <div className="text-xs w-full top-0 left-0 flex flex-col justify-center items-center text-white bg-red-400 py-2">
          {fileRejections.map(({ file, errors }) => (
            <div key={file.name}>
              {file.name}:{" "}
              {errors.map((e) => (
                <small key={e.code}>{e.message}</small>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const [showImage, setShowImage] = useState<string | null>(null);

  if (uploadError) {
    LOG.error(uploadError);
  }

  const addMedia = (files: any) => {
    transportAddMedia({
      variables: {
        id: props.id,
        medias: files,
        type: "picture",
      },
    });
  };

  if (error) {
    LOG.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error.message}
        </div>
      </div>
    );
  }

  if (!data?.userTransport) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
        {...getRootProps()}
      >
        <div
          className="rounded-t-2xl w-full bg-white mb-0 px-6 py-4"
          style={{
            backgroundColor: theme.helpers.getTagServiceColor(
              data?.userTransport.primaryService
            ),
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center"
                style={{ width: 30, height: 30 }}
              >
                <i
                  className={
                    "text-2xl icon-" + data?.userTransport.primaryService
                  }
                />
              </span>
              <h6 className="text-white text-xl font-bold my-2">
                {data?.userTransport.name}
              </h6>
            </div>
            <Button
              color={"purple"}
              onClick={() => {
                open();
              }}
            >
              <i className="fa fa-plus" /> PHOTO
            </Button>
          </div>
        </div>
        <FileErrors />
        <div
          className="relative flex-auto px-4 lg:px-10 py-10"
          style={{ overflow: "auto" }}
        >
          <form>
            <input {...getInputProps()} />
            {!data.userTransport.media.pictures ||
            data.userTransport.media.pictures.length < 1 ? (
              <div className="font-bold w-full text-center text-gray-500">
                {T("transportMediaErr")}
              </div>
            ) : null}
            <Masonry
              breakpointCols={breakpointColsColumnsObj}
              className="gallery-grid"
              columnClassName="gallery-grid_column"
            >
              {data.userTransport.media.pictures.map((item) => (
                <TransportImage
                  key={item._id}
                  url={item.url}
                  id={item._id}
                  transport={props.id}
                  onClick={(id, url) => setShowImage(url)}
                />
              ))}
            </Masonry>
          </form>
        </div>

        {isDragActive && (
          <div
            style={{
              border: "4px dashed #e1e1e1",
              backgroundColor: "rgba(0,0,0,0.2)",
              overflow: "hidden",
            }}
            className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center rounded-2xl"
          >
            <h1 className="text-white text-2xl font-bold">
              Rilascia i file qui ...
            </h1>
          </div>
        )}
        {uploadLoading && (
          <div
            style={{
              border: "4px dashed #e1e1e1",
              backgroundColor: "#e1e1e1",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              overflow: "hidden",
            }}
            className="absolute w-full bottom-0 left-0 flex flex-col justify-center items-center rounded-2xl"
          >
            <Loading color={theme.vars.colors.grey} />
            <div className="flex flex-row my-2">
              {acceptedFiles.map((file, index) => {
                return (
                  <img
                    className="shadow-md"
                    style={{
                      maxHeight: 60,
                      maxWidth: 60,
                      borderRadius: 10,
                      margin: 5,
                    }}
                    src={URL.createObjectURL(file)}
                    alt={"img_" + index}
                  />
                );
              })}
            </div>
            <h1 className="text-gray-500 text-lg">
              Caricamento di <b>{acceptedFiles.length}</b> immagini in corso...
            </h1>
          </div>
        )}
      </div>
      {showImage && (
        <Lightbox
          large={showImage}
          hideZoom
          hideDownload
          alt={data.userTransport.name}
          onClose={() => {
            setShowImage(null);
          }}
        />
      )}
    </>
  );
};

export { TransportMedia };
