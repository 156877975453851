import jwtDecode from "jwt-decode";

import { ENV, LOG, getAppLabel } from "../../config";
import { setGlobalState } from "../../stores/appState";
import { DB_AUTH, ACTUAL_AUTH } from "../../stores/db/auth";
import { AreaData } from "../../stores/queries/areas";
//import { ENV_STATE, USER_STATE } from '../../stores/appState';
import { getApolloClient } from "./createClient";

const log = LOG.extend("AUTHLIB");

type FetchNewAccessToken = (
  refreshToken: string
) => Promise<{ accessToken: string; refreshToken: string } | undefined>;

const defaultError = "Undefined error, sorry!";

// Funzione che controlla la validità di un Token
const isTokenValid = (token: string | null | undefined): boolean => {
  if (!token) {
    return false;
  }
  const decodedToken: { [key: string]: any } | undefined = decodeToken(token);
  if (!decodedToken) {
    return false;
  }
  const now = new Date();
  return now.getTime() < decodedToken.exp * 1000;
};

// Funzione che decodifica un Token
const decodeToken = (token: string) => {
  if (!token) {
    return undefined;
  }

  let decodedToken: { [key: string]: any };

  try {
    decodedToken = jwtDecode<{ [key: string]: any }>(token);
  } catch ({ message }) {
    log.error(`Decode Access Token ERROR: ${message}`);
    return undefined;
  }

  if (!decodedToken) {
    log.warn("Token decode error (=null) | " + token);
    return undefined;
  }

  return decodedToken;
};

// Funzione che recupera un nuovo AccessToken dato il RefreshToken
const fetchNewAccessToken: FetchNewAccessToken = async (refreshToken) => {
  if (!ENV.auth.uri) {
    throw new Error("ENV.auth.uri must be set to use refresh token link");
  }
  if (!ACTUAL_AUTH.area) {
    throw new Error("ACTUAL_AUTH.area must be set to use refresh token link");
  }
  if (!ACTUAL_AUTH.app) {
    throw new Error("ACTUAL_AUTH.app must be set to use refresh token link");
  }

  try {
    const query = JSON.stringify({
      query: `mutation {
        refreshToken(
            area:  "${ACTUAL_AUTH.area}",
            refreshToken: "${refreshToken}",
            application: ${ACTUAL_AUTH.app}
          ) { 
            accessToken
            refreshToken
          }
      }
      `,
    });

    const response = await fetch(ENV.auth.uri, {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: query,
    });

    const refreshResponse = await response.json();

    if (
      !refreshResponse ||
      !refreshResponse.data ||
      !refreshResponse.data.refreshToken ||
      !refreshResponse.data.refreshToken.accessToken
    ) {
      return undefined;
    }

    return {
      accessToken: refreshResponse.data.refreshToken.accessToken,
      refreshToken: refreshResponse.data.refreshToken.refreshToken,
    };
  } catch ({ message }) {
    log.error(`Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

// Funzione che recupera un nuovo AccessToken dato il RefreshToken
const changeArea = async (
  refreshToken: string,
  newArea: string,
  app: "bike" | "ski"
) => {
  if (!ENV.auth.uri) {
    throw new Error(
      "ENV.auth.uri must be set to use changeArea refresh token link"
    );
  }
  if (!newArea) {
    throw new Error("newArea must be set to use changeArea refresh token link");
  }
  if (!app) {
    throw new Error("app must be set to use changeArea refresh token link");
  }

  try {
    const query = JSON.stringify({
      query: `mutation {
        refreshToken(
            area:  "${newArea}",
            refreshToken: "${refreshToken}",
            application: ${getAppLabel(app)}
          ) { 
            info
            accessToken
            refreshToken
            user {
              id
              auth {
                createdAt
                createdWith
                isActive
                isComplete
                isEmailVerified
                lastLogin {
                  area
                  updatedAt
                }
                permissions {
                  area
                  application
                  scopes
                }
              }
            }
          }
      }
      `,
    });

    const response = await fetch(ENV.auth.uri, {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: query,
    });

    const loginResponse = await handleLoginResponse(response, "refreshToken");

    return loginResponse;
  } catch ({ message }) {
    log.error(`changeArea with Fetch New Access Token ERROR: ${message}`);
    return undefined;
  }
};

const handleLoginResponse = async (response: any, mutation: string) => {
  const loginResponse = await response.json();

  if (loginResponse.errors) {
    log.error(loginResponse.errors);
    return {
      success: false,
      msg: defaultError,
    };
  }

  if (!loginResponse || !loginResponse.data) {
    log.error("User login error on: " + mutation);
    return {
      success: false,
      msg: defaultError,
    };
  }

  if (
    !loginResponse.data[mutation] ||
    !loginResponse.data[mutation].accessToken ||
    !loginResponse.data[mutation].refreshToken ||
    !loginResponse.data[mutation].user ||
    !loginResponse.data[mutation].user.auth
  ) {
    log.debug("Login Request Faild, NO TOKENS");
    return {
      success: false,
      msg:
        loginResponse.data[mutation] && loginResponse.data[mutation].info
          ? loginResponse.data[mutation].info
          : defaultError,
    };
  }

  const tokens = {
    accessToken: loginResponse.data[mutation].accessToken,
    refreshToken: loginResponse.data[mutation].refreshToken,
    atDecoded: decodeToken(loginResponse.data[mutation].accessToken),
  };

  if (
    !tokens.atDecoded ||
    !tokens.atDecoded.user?.id ||
    !tokens.atDecoded.auth?.permission
  ) {
    log.debug("Login Request Faild, UNUBLE TO DECODE TOKENS");
    return {
      success: false,
      msg:
        loginResponse.data[mutation] && loginResponse.data[mutation].info
          ? loginResponse.data[mutation].info
          : defaultError,
    };
  }

  DB_AUTH.set({
    user: tokens.atDecoded.user.id,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    area: tokens.atDecoded.auth.permission.area || null,
    app: getAppLabel(tokens.atDecoded.auth.permission.application),
    scopes: tokens.atDecoded.auth.permission.scopes || [],
  });

  return {
    success: true,
    msg: "Login effettuato con successo",
    tokens,
    auth: loginResponse.data[mutation].user.auth,
  };
};

// Login user con email e password
const loginUser = async (
  email: string,
  password: string,
  app?: "bike" | "ski",
  area?: string
) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to loginUser");
    throw new Error("ENV.auth.uri must be set to loginUser");
  }

  if (!email || !password) {
    log.debug("Login Request Faild, NO EMAIL OR PASSWORD");
    return { success: false, msg: "Campi email e password obbligatori" };
  }

  if (password.length < 8) {
    log.debug("Login Request Faild, Password small");
    return {
      success: false,
      msg: "Password troppo corta, utilizza almeno 8 caratteri",
    };
  }

  let mutation = "loginUser";

  try {
    const query = JSON.stringify({
      query: `mutation {
        ${mutation}(
            email: "${email}",
            password: "${password}",
            ${area ? 'area: "' + area + '"' : ""}
            ${app ? "application: " + getAppLabel(app) : ""}
          ) {
            info
            accessToken
            refreshToken
            user {
              id
              auth {
                createdAt
                createdWith
                isActive
                isComplete
                isEmailVerified
                lastLogin {
                  area
                  updatedAt
                }
                permissions {
                  area
                  application
                  scopes
                }
              }
            }
          }
      }
      `,
    });

    const response = await fetch(ENV.auth.uri, {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: query,
    });

    const loginResponse = await handleLoginResponse(response, mutation);

    return loginResponse;
  } catch ({ message }) {
    log.error(`${mutation} ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

/*const loginUserFb = async () => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to use refresh token link');
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }

  try {
    await Facebook.initializeAsync({
      appId: EXT_CONFIG.facebookId,
    });
    const fbRes = await Facebook.logInWithReadPermissionsAsync({
      permissions: ['public_profile', 'email'],
    });
    if (fbRes.type === 'success' && fbRes.token) {
      const query = JSON.stringify({
        query: `mutation {
          loginFacebook(
              token: "${fbRes.token}",
              area: "${ENV_STATE.value.area}"
              application: ${EXT_CONFIG.appId}
            ) {
              info
              accessToken
              refreshToken
              user {
                id
                auth {
                  createdAt
                  createdWith
                  isActive
                  isComplete
                  isEmailVerified
                }
                privacy {
                  ${EXT_CONFIG.app} {
                    marketing {
                      isAccepted
                      updatedAt
                      version
                    }
                  }
                }
              }
            }
        }
        `,
      });

      const response = await fetch(ENV.auth.uri, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: query,
      });

      const loginResponse = await handleLoginResponse(response, 'loginFacebook');

      return loginResponse;
    } else {
      log.warn('LOGIN FACEBOOK: aborted');
      return { success: false, msg: null };
    }
  } catch ({ message }) {
    log.error(`LOGIN FACEBOOK: Error: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

const loginUserApple = async () => {
  if (!ENV.auth.uri) {
    log.error('ENV.auth.uri must be set to use refresh token link');
    throw new Error('ENV.auth.uri must be set to use refresh token link');
  }

  try {
    const appleRes = await AppleAuthentication.signInAsync({
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
    });

    if (appleRes.identityToken && appleRes.user) {
      const query = JSON.stringify({
        query: `mutation {
          loginApple(
              token: "${appleRes.identityToken}",
              area: "${ENV_STATE.value.area}"
              application: ${EXT_CONFIG.appId}
            ) {
              info 
              accessToken
              refreshToken
              user {
                id
                auth {
                  createdAt
                  createdWith
                  isActive
                  isComplete
                  isEmailVerified
                }
                privacy {
                  ${EXT_CONFIG.app} {
                    marketing {
                      isAccepted
                      updatedAt
                      version
                    }
                  }
                }
              }
            }
        }
        `,
      });

      const response = await fetch(ENV.auth.uri, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: query,
      });

      const loginResponse = await handleLoginResponse(response, 'loginApple');

      if (
        loginResponse &&
        loginResponse.user &&
        !loginResponse.user.displayName &&
        appleRes &&
        appleRes.fullName
      ) {
        if (appleRes.fullName.familyName && !appleRes.fullName.givenName) {
          loginResponse.user.displayName = appleRes.fullName.familyName;
        } else if (!appleRes.fullName.familyName && appleRes.fullName.givenName) {
          loginResponse.user.displayName = appleRes.fullName.givenName;
        } else if (appleRes.fullName.familyName && appleRes.fullName.givenName) {
          loginResponse.user.displayName =
            appleRes.fullName.familyName + ' ' + appleRes.fullName.givenName;
        }
      }

      return loginResponse;
    } else {
      log.error(`LOGIN APPLE: Error: No user or token`);
      return { success: false, msg: defaultError };
    }
  } catch (e) {
    if (e.code === 'ERR_CANCELED') {
      log.warn('LOGIN APPLE: User cancel login');
      return {
        success: false,
        msg: null,
      };
    } else {
      log.error(`LOGIN APPLE: Error: ${e.message}`);
      return {
        success: false,
        msg: defaultError,
      };
    }
  }
};*/

const recoverPassword = async (email: string) => {
  if (!ENV.auth.uri) {
    log.error("ENV.auth.uri must be set to use refresh token link");
    throw new Error("ENV.auth.uri must be set to use refresh token link");
  }

  if (!email) {
    log.debug("Recover Password Request Faild, NO email");
    return { success: false, msg: "Email obbligatoria!" };
  }

  try {
    const query = JSON.stringify({
      query: `mutation {sendChangePasswordEmail(email: "${email}")}`,
    });

    const response = await fetch(ENV.auth.uri, {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: query,
    });

    const resetResponse = await response.json();

    if (!resetResponse || !resetResponse.data) {
      log.debug("Recover Password Request Faild, NO DATA");
      return { success: false, msg: defaultError };
    }

    if (!resetResponse.data.sendChangePasswordEmail) {
      return { success: false, msg: "Wrong Email" };
    }

    return { success: true };
  } catch ({ message }) {
    log.error(`recoverPassword ERROR: ${message}`);
    return {
      success: false,
      msg: defaultError,
    };
  }
};

// Logout user
const logout = async () => {
  log.warn("LOGOUT ACTION");
  log.debug("LOGOUT: clear auth");
  DB_AUTH.clear();
  let apolloClient = await getApolloClient();
  if (apolloClient) {
    log.debug("LOGOUT: clear cache");
    await apolloClient.clearStore();
  }
  log.debug("LOGOUT: clear user state");
  setGlobalState("auth", {
    user: null,
    area: null,
  });
  return true;
};

// controllo che scope sia nell'array scopes
const checkScope = (scopes: string[], scope: string) => {
  return scopes && scopes.includes(scope);
};

const getPermittedAreas = (permissions: any) => {
  let permittedAreas: string[] = [];
  for (let index = 0; index < permissions.length; index++) {
    const permission = permissions[index];
    if (
      checkScope(permission.scopes, "root") ||
      checkScope(permission.scopes, "area:edit") ||
      checkScope(permission.scopes, "notice:edit")
    ) {
      permittedAreas.push(permission.area);
    }
  }
  return permittedAreas;
};

const getAvailableAreas = (permittedAreas: string[], areas: AreaData[]) => {
  let availableAreas: AreaData[] = [];
  for (let index = 0; index < areas.length; index++) {
    if (permittedAreas.includes(areas[index].id)) {
      availableAreas.push(areas[index]);
    }
  }
  return availableAreas;
};

export {
  isTokenValid,
  fetchNewAccessToken,
  decodeToken,
  loginUser,
  getAppLabel,
  //loginUserFb,
  //loginUserApple,
  logout,
  recoverPassword,
  checkScope,
  changeArea,
  getPermittedAreas,
  getAvailableAreas,
};
