import {
  logger,
  configLoggerType,
  transportFunctionType,
  consoleTransport,
} from "react-native-logs";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { getTransaltion, LOCALE } from "./locale";
import { theme } from "./theme";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels
);

const APPVERSION: string = "1.3.27";
const __DEV__: boolean = false; //process.env.NODE_ENV === 'development' ? true : false;

// utilities
const getAppLabel = (key: string) => {
  switch (key) {
    case "mowiBike":
      return "bike";
    case "mowiSki":
      return "ski";
    default:
      return null;
  }
};

const getAppKey = (label: string) => {
  switch (label) {
    case "bike":
      return "mowiBike";
    case "ski":
      return "mowiSki";
    default:
      return null;
  }
};

const sessionId =
  new Date().getTime().toString() +
  "-" +
  Math.round(100000 + Math.random() * (999999 - 100000)).toString();

const ENV_PATH = {
  production: {
    auth: {
      sessionId,
      uri: "https://auth.mowi.space/graphql",
      refreshUri: "https://auth.mowi.space/refreshToken",
    },
  },
  test: {
    auth: {
      sessionId,
      uri: "https://test.auth.mowi.space/graphql",
      refreshUri: "https://test.auth.mowi.space/refreshToken",
    },
  },
  analytics: {
    uri: "https://mowi-analytics.fly.dev",
  },
  ski: {
    production: {
      backend: {
        uri: "https://backend.mowi.ski/graphql",
        config: "https://config.mowi.ski",
        areas: "https://backend.mowi.ski/global/areas/gold",
      },
      assets: {
        userAvatar: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/Avatars/",
        },
        groupCover: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/MowiBikeGroup/",
        },
      },
    },
    test: {
      backend: {
        uri: "https://test.backend.mowi.ski/graphql",
        config: "https://test.config.mowi.ski",
        areas: "https://test.backend.mowi.ski/global/areas",
      },
      assets: {
        userAvatar: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/AvatarsTest/",
        },
        groupCover: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/MowiBikeGroupTest/",
        },
      },
    },
  },
  bike: {
    production: {
      backend: {
        uri: "https://backend.mowi.bike/graphql",
        config: "https://config.mowi.bike",
        areas: "https://backend.mowi.bike/global/areas",
      },
      assets: {
        userAvatar: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/Avatars/",
        },
        groupCover: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/MowiBikeGroup/",
        },
      },
    },
    test: {
      backend: {
        uri: "https://test.backend.mowi.bike/graphql",
        config: "https://test.config.mowi.bike",
        areas: "https://test.backend.mowi.bike/global/areas",
      },
      assets: {
        userAvatar: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/AvatarsTest/",
        },
        groupCover: {
          uri: "https://storage.de.cloud.ovh.net/v1/AUTH_85ee11da17b54ddfad6308e2130b047a/MowiBikeGroupTest/",
        },
      },
    },
  },
};

const ENV: {
  app: {
    version: string;
    name: string | null;
    key: "mowiBike" | "mowiSki" | null;
    basename: string;
  };
  locale: "it" | "en" | "de";
  mode: "test" | "production";
  backend: {
    uri: string | null;
    config: string | null;
    areas: string | null;
  };
  auth: {
    sessionId: string;
    uri: string;
    refreshUri: string;
  };
  analytics: string;
  assets: {
    userAvatar: { uri: string | null };
    groupCover: { uri: string | null };
  };
  db: {
    cacheKey: string;
    authKey: string;
    areasKey: string;
  };
  logs: {
    destination: "local" | "remote";
  };
  rootEmails: string[];
} = {
  app: {
    version: APPVERSION,
    name: "Mowi Business Dashboard",
    key: null,
    basename: "",
  },
  locale: LOCALE,
  mode: __DEV__ ? "test" : "production",
  backend: {
    uri: null,
    config: null,
    areas: null,
  },
  auth: __DEV__ ? ENV_PATH.test.auth : ENV_PATH.production.auth,
  analytics: ENV_PATH.analytics.uri,
  assets: {
    userAvatar: {
      uri: null,
    },
    groupCover: {
      uri: null,
    },
  },
  db: {
    cacheKey: __DEV__ ? "TEST_MOWIBUSINESS_CACHE" : "MOWIBUSINESS_CACHE",
    authKey: __DEV__ ? "TEST_MOWIBUSINESS_AUTH" : "MOWIBUSINESS_AUTH",
    areasKey: __DEV__ ? "TEST_MOWIBUSINESS_AREAS" : "MOWIBUSINESS_AREAS",
  },
  logs: {
    destination: __DEV__ ? "local" : "remote",
  },
  rootEmails: [
    "a.bottamedi@mowispace.com",
    "f.geiser@mowispace.com",
    "k.miggiano@mowispace.com",
    "f.frizzera@mowispace.com",
    "m.frizzera@mowispace.com",
  ],
};

const SET_ENV = (settings: {
  mode?: "test" | "production";
  app?: "ski" | "bike";
}) => {
  if (!settings) return false;
  if (settings.mode === "test") {
    ENV.mode = "test";
    ENV.logs.destination = "local";
    LOG.setSeverity("debug");
  }
  if (settings.mode === "production") {
    ENV.mode = "production";
    ENV.logs.destination = "remote";
    LOG.setSeverity("error");
  }
  if (settings.app) {
    ENV.backend = ENV_PATH[settings.app][ENV.mode].backend;
    ENV.auth = ENV_PATH[ENV.mode].auth;
    ENV.assets = ENV_PATH[settings.app][ENV.mode].assets;
    ENV.app.key = getAppKey(settings.app);
  }
  LOG.info("MODE CHANGED: " + settings.mode + " | " + settings.app);
};

const mowiTransport: transportFunctionType = (props) => {
  if (ENV.logs.destination === "local") {
    consoleTransport(props);
  }
};

let loggerConfig: configLoggerType = {
  transport: mowiTransport,
  severity: __DEV__ ? "debug" : "error",
  transportOptions: {
    colors: "web",
  },
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: ["STORAGES", "DB", "APOLLOCLIENT", "AUTHLIB"],
};

const LOG = logger.createLogger(loggerConfig);

LOG.debug("LOGGER CREATED");

// Gestione Lingue
const translation = getTransaltion(ENV.locale);
const translationFallback = getTransaltion("en");
const T = (txt: string | undefined): string => {
  if (!txt) txt = "";
  if (translation.t[txt]) {
    return translation.t[txt];
  } else if (translationFallback.t[txt]) {
    return translationFallback.t[txt];
  } else {
    if (__DEV__) {
      return "|" + txt + "|";
    } else {
      return txt;
    }
  }
};

interface ProfileType {
  key: string;
  icon: string;
  title: string;
  desc: string;
}

const PROFILES: {
  bike: ProfileType[];
  ski: ProfileType[];
} = {
  bike: [
    {
      key: "gravity",
      icon: "tutorial_bike_park",
      title: T("profileTitle1"),
      desc: T("profileDesc1"),
    },
    {
      key: "pedaled",
      icon: "tutorial_cross_country",
      title: T("profileTitle2"),
      desc: T("profileDesc2"),
    },
    {
      key: "family",
      icon: "tutorial_family",
      title: T("profileTitle3"),
      desc: T("profileDesc3"),
    },
  ],
  ski: [
    {
      key: "ski",
      icon: "tutorial_ski",
      title: T("profileTitle4"),
      desc: T("profileDesc4"),
    },
    {
      key: "snow",
      icon: "tutorial_outdoor",
      title: T("profileTitle5"),
      desc: T("profileDesc5"),
    },
    {
      key: "family",
      icon: "tutorial_family_snow",
      title: T("profileTitle6"),
      desc: T("profileDesc6"),
    },
  ],
};

if (__DEV__) {
  LOG.debug("Mowi Business Dashboard started in Development " + LOCALE);
}

export {
  __DEV__,
  ENV,
  ENV_PATH,
  LOG,
  T,
  theme,
  SET_ENV,
  PROFILES,
  getAppLabel,
  getAppKey,
};
