import { ENV, LOG, SET_ENV } from '../../config';
import { envStorage } from '../../libs/storages';
import { setGlobalState } from '../appState';

const log = LOG.extend('DB');
interface AuthParams {
  user: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  area: string | null;
  app: 'bike' | 'ski' | null;
  scopes: string[];
}

let ACTUAL_AUTH_INIT: AuthParams = {
  user: null,
  refreshToken: null,
  accessToken: null,
  area: null,
  app: null,
  scopes: [],
};

let ACTUAL_AUTH: AuthParams = ACTUAL_AUTH_INIT;

const DB_AUTH = {
  get: () => {
    let authSaved = null;
    try {
      authSaved = envStorage.getObject(ENV.db.authKey);
    } catch ({ message }) {
      log.error(`Get DB_AUTH ERROR: ${message}`);
    }
    if (authSaved) {
      ACTUAL_AUTH = authSaved;
    } else {
      log.warn('No DB_AUTH found');
      ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    }
    if (ACTUAL_AUTH.app) {
      SET_ENV({
        app: ACTUAL_AUTH.app,
      });
    }
    setGlobalState('auth', {
      user: ACTUAL_AUTH.user,
      area: ACTUAL_AUTH.area,
    });
    return ACTUAL_AUTH;
  },
  set: (params: AuthParams) => {
    ACTUAL_AUTH = {
      ...ACTUAL_AUTH,
      ...params,
    };
    try {
      envStorage.setObject(ENV.db.authKey, ACTUAL_AUTH);
    } catch ({ message }) {
      log.error(`Set DB_AUTH ERROR: ${message}`);
    }
    if (ACTUAL_AUTH.app) {
      SET_ENV({
        app: ACTUAL_AUTH.app,
      });
    }
    setGlobalState('auth', {
      user: ACTUAL_AUTH.user,
      area: ACTUAL_AUTH.area,
    });
    return ACTUAL_AUTH;
  },
  clear: () => {
    log.debug('CLEAR AUTHPARAM');
    ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    try {
      envStorage.remove(ENV.db.authKey);
    } catch ({ message }) {
      log.error(`Remove DB_AUTH ERROR: ${message}`);
    }
    if (ACTUAL_AUTH.app) {
      SET_ENV({
        app: ACTUAL_AUTH.app,
      });
    }
    setGlobalState('auth', {
      user: ACTUAL_AUTH.user,
      area: ACTUAL_AUTH.area,
    });
    return ACTUAL_AUTH;
  },
};

export { DB_AUTH, ACTUAL_AUTH };
export type { AuthParams };
