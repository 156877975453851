import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { LOG, T, theme } from "../../config";
import {
  TRACK,
  TRACK_UPDATE,
  Track,
  TRACK_UPDATE_OSTATUS,
} from "../../stores/queries/tracks";
import { Button, EditBox, RatingDots, StatusLabel, Loading } from "../../ui";
import { transformApolloData } from "../../libs/utils";
import { useGlobalState } from "../../stores/appState";
import { checkScope } from "../../libs/apollo/auth";
import { ACTUAL_AUTH } from "../../stores/db/auth";

const monthTxt: string[] = [
  "GEN",
  "FEB",
  "MAR",
  "APR",
  "MAG",
  "GIU",
  "LUG",
  "AGO",
  "SET",
  "OTT",
  "NOV",
  "DIC",
];

interface TrackDetailsProps {
  id: string;
}

const TrackDetails = (props: TrackDetailsProps) => {
  const { error, data } = useQuery<{
    userTrack: Track;
  }>(TRACK, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const [trackUpdate, { error: updateError }] = useMutation(TRACK_UPDATE);
  const [trackUpdateOStatus, { error: tuosError }] = useMutation(
    TRACK_UPDATE_OSTATUS,
    {
      refetchQueries: [
        {
          query: TRACK,
          variables: {
            id: props.id,
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [locale, setLocale] = useGlobalState("locale");

  const actualTrack = useRef<{
    name: Track["name"];
    description: Track["description"];
  }>({
    name: "",
    description: {
      howToStart: {
        it: "",
        en: "",
        de: "",
      },
      long: {
        it: "",
        en: "",
        de: "",
      },
      short: {
        it: "",
        en: "",
        de: "",
      },
    },
  });

  const [activeEdit, setActiveEdit] = useState<boolean>(false);
  const [manualState, setManualState] = useState<boolean | null | undefined>(
    null
  );

  const isRoot = useRef<boolean>(checkScope(ACTUAL_AUTH.scopes, "root"));

  const editPermission = useRef<boolean>(
    isRoot.current || checkScope(ACTUAL_AUTH.scopes, "area:edit")
  );
  useEffect(() => {
    if (data?.userTrack) {
      actualTrack.current = transformApolloData({
        name: data.userTrack.name,
        description: data.userTrack.description,
      });
      setManualState(data.userTrack.originalStatus);
    }
  }, [data]);

  const update = () => {
    trackUpdate({
      variables: {
        id: props.id,
        trackInput: actualTrack.current,
      },
    });
  };

  const updateOStatus = () => {
    trackUpdateOStatus({
      variables: {
        ids: [props.id],
        newStatus: manualState,
      },
    });
  };

  if (updateError) {
    LOG.error(updateError);
  }

  if (tuosError) {
    LOG.error(tuosError);
  }

  if (error) {
    LOG.error(error);
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center p-4"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <div className="p-4 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
          {error.message}
        </div>
      </div>
    );
  }

  if (!data?.userTrack) {
    return (
      <div
        className="relative break-words w-full bg-gray-50 shadow-lg rounded-2xl border-0 flex flex-col justify-center items-center"
        style={{ height: "calc(100vh - 170px)" }}
      >
        <Loading color={theme.vars.colors.greyDark} />
      </div>
    );
  }

  let statusText = "nessun valore";
  let statusAuto = false;
  if (typeof data?.userTrack.statusFromExternalSource === "boolean") {
    statusAuto = true;
    statusText = data.userTrack.statusFromExternalSource
      ? T("open")
      : T("closed");
  } else {
    if (typeof data?.userTrack.originalStatus === "boolean") {
      statusText = data.userTrack.originalStatus ? T("open") : T("closed");
    }
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 170px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
      >
        <div
          className="rounded-t-2xl w-full bg-white mb-0 px-6 py-4"
          style={{
            backgroundColor: theme.helpers.getTypologyColor(
              data?.userTrack.typology || "default"
            ),
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <span
                className="bg-gray-600 rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center"
                style={{ width: 30, height: 30 }}
              >
                {data?.userTrack.detailedInfo.trackNumber}
              </span>
              <h6 className="text-white text-xl font-bold my-2">
                {data?.userTrack.name}
              </h6>
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="flex flex-col items-center justify-center mx-2">
                <span
                  className={
                    "rounded-full text-white font-bold text-xs flex items-center justify-center"
                  }
                >
                  Stato Attuale
                </span>
                {typeof data?.userTrack.status === "boolean" ? (
                  <StatusLabel status={data?.userTrack.status} />
                ) : (
                  <StatusLabel status={data?.userTrack.originalStatus} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ overflow: "auto" }}
        >
          <form>
            {updateError && (
              <div className="p-2 mt-2 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
                {updateError.message}
              </div>
            )}
            <hr className="mt-6 border-b-1 border-gray-200" />
            <h6 className="text-gray-400 text-base mt-3 mb-4 font-bold uppercase flex flex-row justify-between">
              {T("panoramica")}
              <div>
                <Button
                  color={locale === "it" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("it");
                  }}
                >
                  IT
                </Button>{" "}
                <Button
                  color={locale === "en" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("en");
                  }}
                >
                  EN
                </Button>{" "}
                <Button
                  color={locale === "de" ? "purple" : "white"}
                  onClick={() => {
                    setLocale("de");
                  }}
                >
                  DE
                </Button>
              </div>
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={editPermission.current}
                value={data?.userTrack.name}
                label={T("name")}
                maxChar={35}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTrack.current.name = value || "";
                  update();
                }}
              />
              <EditBox
                editable={false}
                value={T(data?.userTrack.typology)}
                label={T("typology")}
                halfCol={false}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userTrack.description.short[locale]}
                label={"Sottotitolo"}
                maxChar={250}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTrack.current.description.short[locale] = value || "";
                  update();
                }}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userTrack.description.long[locale]}
                label={"Descrizione"}
                maxChar={800}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTrack.current.description.long[locale] = value || "";
                  update();
                }}
              />
              <EditBox
                editable={editPermission.current}
                value={data?.userTrack.description.howToStart[locale]}
                label={T("howToArrive")}
                maxChar={400}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  actualTrack.current.description.howToStart[locale] =
                    value || "";
                  update();
                }}
              />

              <EditBox
                editable={statusAuto ? false : true}
                value={statusText}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                customView={
                  <StatusLabel
                    status={
                      statusAuto
                        ? data?.userTrack.statusFromExternalSource
                        : data?.userTrack.originalStatus
                    }
                  />
                }
                onPressCancel={() => {
                  setManualState(data?.userTrack.originalStatus);
                }}
                onPressSave={() => {
                  updateOStatus();
                }}
                customEdit={
                  <div className="flex flex-1 flex-row justify-start items-center">
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={manualState ? "purple" : "white"}
                      onClick={() => {
                        setManualState(true);
                      }}
                    >
                      {T("open")}
                    </Button>{" "}
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={
                        typeof manualState !== "boolean" ? "purple" : "white"
                      }
                      onClick={() => {
                        setManualState(null);
                      }}
                    >
                      Nessuno
                    </Button>{" "}
                    <Button
                      style={{ marginLeft: 4, marginRight: 4 }}
                      color={
                        typeof manualState === "boolean" && !manualState
                          ? "purple"
                          : "white"
                      }
                      onClick={() => {
                        setManualState(false);
                      }}
                    >
                      {T("closed")}
                    </Button>
                  </div>
                }
                label={
                  statusAuto ? "Stato da sorgente esterna" : "Stato manuale"
                }
                halfCol={false}
                hideCharCounter={true}
              />
            </div>

            <hr className="mt-6 border-b-1 border-gray-200" />

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("dettagli")}
            </h6>
            <div className="flex flex-wrap">
              <EditBox
                editable={false}
                value={
                  data?.userTrack.detailedInfo.origin
                    ? T(data?.userTrack.detailedInfo.origin)
                    : undefined
                }
                label={T("origine")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTrack.detailedInfo.isForPedestrians
                    ? T("yes")
                    : T("no")
                }
                label={"Shared Trail"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.zone.name}
                label={"Area Zone"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.locality[locale]}
                label={T("locality")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.stations.start.name[locale]}
                label={T("startStation")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.stations.end.name[locale]}
                label={T("endStation")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.level}
                label={T("difficulty")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.length}
                label={T("length")}
                unit={"m"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.timeAvarage}
                label={T("duration")}
                unit={"min"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.maxSlope}
                label={T("maxSlope")}
                unit={"°"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.altimetry.differenceMinus}
                label={T("altimetryMinus")}
                unit={"m"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.altimetry.differencePlus}
                label={T("altimetryPlus")}
                unit={"m"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.altimetry.highestPoint}
                label={T("maxAltitude")}
                unit={"m"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.technicalInfo.altimetry.lowestPoint}
                label={T("minAltitude")}
                unit={"m"}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.physicalEfford}
                label={T("physicalEfford")}
                halfCol={true}
                customView={
                  <RatingDots
                    tot={6}
                    on={data?.userTrack.detailedInfo.physicalEfford || 0}
                  />
                }
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.emotion}
                label={T("emotion")}
                halfCol={true}
                customView={
                  <RatingDots
                    tot={6}
                    on={data?.userTrack.detailedInfo.emotion || 0}
                  />
                }
              />
              <EditBox
                editable={false}
                value={data?.userTrack.detailedInfo.landscape}
                label={T("landscape")}
                halfCol={true}
                customView={
                  <RatingDots
                    tot={6}
                    on={data?.userTrack.detailedInfo.landscape || 0}
                  />
                }
              />
              <EditBox
                editable={false}
                value={
                  monthTxt[
                    data?.userTrack.detailedInfo.suggestedPeriod[0] || 0
                  ] +
                  " - " +
                  monthTxt[
                    data?.userTrack.detailedInfo.suggestedPeriod[
                      data?.userTrack.detailedInfo.suggestedPeriod.length - 1
                    ] || 11
                  ]
                }
                label={T("suggestedPeriod")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTrack.detailedInfo.isCircular ? T("yes") : T("no")
                }
                label={T("isCircular")}
                halfCol={true}
              />
              <EditBox
                editable={false}
                value={
                  data?.userTrack.detailedInfo.isForFamily ? T("yes") : T("no")
                }
                label={T("isFamily")}
                halfCol={true}
              />
            </div>
          </form>
          {activeEdit && (
            <div
              className="w-full h-full absolute top-0 left-0 rounded-2xl"
              style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export { TrackDetails };
