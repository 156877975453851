import { CachePersistor } from 'apollo3-cache-persist';
import { ApolloClient, from, InMemoryCache } from '@apollo/client';
// @ts-ignore
import { createUploadLink } from 'apollo-upload-client';

import { __DEV__, ENV, LOG } from '../../config';
import { cacheStorage } from '../storages';
import { authLink } from './authLink';
import { onErrorRefreshTokenLink } from './onErrorRefreshTokenLink';

const log = LOG.extend('APOLLOCLIENT');

let oertl: any = onErrorRefreshTokenLink;
let client: any = null;

const createApolloClient = async () => {
  log.debug('CREATE CLIENT ON URI: ' + ENV.backend.uri);

  try {
    const uploadLink = createUploadLink({ uri: ENV.backend.uri });

    const cache = new InMemoryCache();
    const persistor = new CachePersistor({
      // trigger: 'background',
      storage: cacheStorage,
      cache,
      key: ENV.db.cacheKey,
      debug: __DEV__,
    });

    try {
      await persistor.restore();
    } catch ({ message }) {
      log.error(`Unable to Restore Cache: ${message}`);
    }

    client = new ApolloClient({
      cache,
      link: from([authLink, oertl, uploadLink]),
      resolvers: {},
    });

    client.onClearStore(async () => {
      await persistor.purge();
      log.warn('Offline Cache Purged');
    });
  } catch ({ message }) {
    log.error(`Create client ERROR: ${message}`);
  }
  return client;
};

const getApolloClient = () => {
  return client;
};

export { createApolloClient, getApolloClient };
