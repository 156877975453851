//@

import { gql } from "@apollo/client";
import { ENV, LOG } from "../../config";
import { ACTUAL_AUTH } from "../db/auth";
const { ReactNativeFile } = require("apollo-upload-client");

interface UserBasic {
  id: string;
  displayName: string;
}

interface UserCommon {
  user: UserBasic;
  createdAt: string;
}

interface UserGeolocation {
  latitude: number;
  longitude: number;
  accuracy: number;
  timestamp: number;
  isMoving?: boolean;
  altitude?: number;
  odometer?: number;
  speed?: number;
  batteryLevel?: number;
  activityConfidance?: number;
  activityType?: string;
  event?: string;
  heading?: number;
}

interface UserNotification {
  id: string;
  refId: string;
  createdAt: string;
  type: string;
  head: {
    it: string;
    de: string;
    en: string;
  };
  content: {
    it: string;
    de: string;
    en: string;
  };
  more: {
    link: {
      it: string;
      de: string;
      en: string;
    };
    description: {
      it: string;
      de: string;
      en: string;
    };
    dataId: string;
  };
  isReaded: boolean;
  area: string;
}

interface User {
  id: string;
  email: string;
  displayName: string;
  lastUpdateAvatar: number;
  basicInfo: {
    country: string;
    birthYear: string;
    sex: string;
  };
  auth: {
    createdAt: string;
    lastLogin: string;
    permissions: {
      area: string;
      scopes: string[];
    }[];
    bike: {
      registeredAt: string;
    };
    ski: {
      registeredAt: string;
    };
  };
  friends: {
    pending: UserCommon[];
    approved: UserCommon[];
    requests: UserCommon[];
  };
  ski: {
    activeGroup: {
      id: string;
      startedAt: string;
    };
    notifications: {
      lastUpdate: string;
      list: UserNotification[];
      hasUnreaded: number;
    };
  };
  privacy: {
    ski: {
      marketing: {
        isAccepted: boolean;
        updatedAt: string;
        version: string;
      };
    };
    bike: {
      marketing: {
        isAccepted: boolean;
        updatedAt: string;
        version: string;
      };
    };
  };
}

const USER_ME_FREQUENT = gql`
  query USER_ME_BASIC {
    userMe {
      id
      displayName
      ski {
        notifications {
          hasUnreaded
          lastUpdate
        }
      }
      bike {
        notifications {
          hasUnreaded
          lastUpdate
        }
      }
    }
  }
`;

const USER_ME_INFO_FRAGMENT = gql`
  fragment userMeInfoFragment on UserWithAuth {
    id
    email
    displayName
    lastUpdateAvatar
    basicInfo {
      country
      birthYear
      sex
    }
    auth {
      createdAt
      lastLogin
      permissions {
        area
        scopes
      }
      bike {
        registeredAt
      }
      ski {
        registeredAt
      }
    }
    friends {
      pending {
        user {
          id
          displayName
        }
        createdAt
      }
      approved {
        user {
          id
          displayName
        }
        createdAt
      }
      requests {
        user {
          id
          displayName
        }
        createdAt
      }
    }
  }
`;

const USER_ME = {
  ski: gql`
    query USER_ME_SKI {
      userMe {
        ...userMeInfoFragment
        ski {
          notifications {
            lastUpdate
            list {
              id
              refId
              createdAt
              type
              head {
                it
                en
                de
              }
              content {
                it
                en
                de
              }
              more {
                link {
                  it
                  en
                  de
                }
                description {
                  it
                  en
                  de
                }
                dataId
              }
              isReaded
              area
            }
            hasUnreaded
          }
        }
      }
    }
    ${USER_ME_INFO_FRAGMENT}
  `,
  bike: gql`
    query USER_ME_BIKE {
      userMe {
        ...userMeInfoFragment
        bike {
          notifications {
            lastUpdate
            list {
              id
              refId
              createdAt
              type
              head {
                it
                en
                de
              }
              content {
                it
                en
                de
              }
              more {
                link {
                  it
                  en
                  de
                }
                description {
                  it
                  en
                  de
                }
                dataId
              }
              isReaded
              area
            }
            hasUnreaded
          }
        }
      }
    }
    ${USER_ME_INFO_FRAGMENT}
  `,
};

const USER_SEARCHFRIENDS = gql`
  query userSearchFriends($searchName: String) {
    userSearchFriends(searchName: $searchName) {
      id
      displayName
      lastUpdateAvatar
    }
  }
`;

const USER_NOTIFICATIONREAD = gql`
  mutation userNotificationRead($notificationId: ID!) {
    userNotificationRead(notificationId: $notificationId) {
      id
    }
  }
`;

const USER_NOTIFICATIONREADALL = gql`
  mutation userNotificationReadAll {
    userNotificationReadAll {
      id
    }
  }
`;

const uploadAvatar = async (file: any) => {
  if (!ENV.auth.uri) {
    throw new Error("ENV.auth.uri must be set to use upload user Avatar");
  }

  try {
    let formData = new FormData();

    let uriParts = file.uri.split(".");
    let fileType = uriParts[uriParts.length - 1];
    const image = new ReactNativeFile({
      uri: file.uri,
      name: `userAvatar.${fileType}`,
      type: `image/${fileType}`,
    });

    const operations = `{ "query": "mutation ($accessToken: String!, $file: Upload!) { uploadAvatar(accessToken: $accessToken, file: $file) }", "variables": { "file": null, "accessToken": "${ACTUAL_AUTH.accessToken}" } }`;
    formData.append("operations", operations);

    const map = `{"0": ["variables.file"]}`;
    formData.append("map", map);
    formData.append("0", image);

    const response = await fetch(ENV.auth.uri, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      body: formData,
    });

    const uploadAvataresponse = await response.json();

    if (!uploadAvataresponse) {
      return false;
    }

    return uploadAvataresponse.data;
  } catch (error: any) {
    LOG.error(`Upload Avatar ERROR: ${error?.message}`);
    return false;
  }
};

interface TrackedEvent {
  _id: string;
  area: string;
  appVersion: string;
  profilation: string;
  event: string;
  platform: string;
  isInArea: boolean;
  sessionRef: string;
  deviceRef: string;
  timestamp: string;
  geoLocation: {
    timestamp: string;
    event: string;
    is_moving: boolean;
    coords: {
      latitude: number;
      longitude: number;
      accuracy: number;
      speed: number;
      heading: number;
      altitude: number;
    };
    activity: {
      activity: string;
      confidence: number;
    };
    battery: {
      level: number;
    };
    odometer: number;
  };
}

const USER_TRACKED_EVENTS = gql`
  query USER_TRACKED_EVENTS($limit: Int) {
    userTrackedEventsByArea(limit: $limit) {
      _id
      profilation
      event
      platform
      isInArea
      deviceRef
      timestamp
      geoLocation {
        coords {
          latitude
          longitude
        }
      }
    }
  }
`;

const GET_USERS_DATA = gql`
  query GET_USERS_DATA($areaId: ID) {
    adminGetUsersByArea(areaId: $areaId) {
      displayName
      email
      id
      basicInfo {
        birthYear
        country
        sex
      }
    }
  }
`;

export {
  USER_ME,
  USER_ME_FREQUENT,
  USER_SEARCHFRIENDS,
  USER_NOTIFICATIONREAD,
  USER_NOTIFICATIONREADALL,
  USER_TRACKED_EVENTS,
  GET_USERS_DATA,
  uploadAvatar,
};
export type {
  User,
  UserCommon,
  UserBasic,
  UserNotification,
  UserGeolocation,
  TrackedEvent,
};
