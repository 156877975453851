import React, { ChangeEvent, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

import { theme } from "../../../config";
import { useGlobalState } from "../../../stores/appState";
import { Button, Loading } from "../../../ui";
import { copyTextToClipboard } from "../../../libs/utils";

const shadowCss =
  "-webkit-box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.75);-moz-box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.75);box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.75);";

const WebAppIframe = () => {
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [actualArea] = useGlobalState("actualArea");
  return (
    <div
      className="relative flex flex-1 flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white"
      style={{
        overflow: "hidden",
        width: "100%",
        maxWidth: 1300,
        height: "100%",
        marginTop: 10,
      }}
    >
      {loadingStats || !actualArea?.nameRef ? (
        <div
          className="absolute break-words w-full border-0 flex flex-col justify-center items-center"
          style={{ height: "100%", width: "100%", top: 0, left: 0 }}
        >
          <Loading color={theme.vars.colors.greyDark} />
        </div>
      ) : null}
      {actualArea?.nameRef && (
        <iframe
          title="Mowi Webapp"
          allowFullScreen={true}
          onLoad={() => {
            setLoadingStats(false);
          }}
          style={{ width: "100%", height: "100%" }}
          src={"https://mowi.space/area/" + actualArea.nameRef}
        ></iframe>
      )}
    </div>
  );
};

const WebAppCode = () => {
  const [frameStyle, setFrameStyle] = useState({
    border: true,
    shadow: true,
  });
  const [actualArea] = useGlobalState("actualArea");
  const [code, setCode] = useState<string>("");

  useEffect(() => {
    if (frameStyle) {
      setCode(
        '<iframe frameborder="0" allowfullscreen style="width: 100%;max-width: 1300px;height: 600px;' +
          (frameStyle.border ? "border-radius:20px;" : "") +
          (frameStyle.shadow ? shadowCss : "") +
          'border-width: 0px;" src="' +
          "https://mowi.space/area/" +
          actualArea.nameRef +
          '"></iframe>'
      );
    }
  }, [frameStyle, actualArea.nameRef]);

  return (
    <div
      className="relative p-4 flex flex-1 flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-gray-100"
      style={{
        overflow: "hidden",
        width: "100%",
        maxWidth: 1300,
        height: "100%",
        marginTop: 10,
      }}
    >
      <ul className="list-disc">
        <li className="flex flex-row items-center justify-between mt-2 bg-white p-2">
          <b>LINK PER SITI WEB</b>{" "}
          <div>
            <Button
              style={{ marginLeft: 8, textTransform: "lowercase" }}
              onClick={() => {
                copyTextToClipboard(
                  "https://mowi.space/area/" + actualArea.nameRef + "/web"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              <small>
                {" "}
                {"https://mowi.space/area/" + actualArea.nameRef + "/web"}
              </small>{" "}
              <i className="fa fa-copy"></i>
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => {
                window.open(
                  "https://mowi.space/area/" + actualArea.nameRef + "/web/qr"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              Qr-Code
            </Button>
          </div>
        </li>
        <li className="flex flex-row items-center justify-between mt-2 bg-white p-2">
          <b>LINK PER SOCIAL</b>{" "}
          <div>
            <Button
              style={{ marginLeft: 8, textTransform: "lowercase" }}
              onClick={() => {
                copyTextToClipboard(
                  "https://mowi.space/area/" + actualArea.nameRef + "/social"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              <small>
                {" "}
                {"https://mowi.space/area/" + actualArea.nameRef + "/social"}
              </small>{" "}
              <i className="fa fa-copy"></i>
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => {
                window.open(
                  "https://mowi.space/area/" + actualArea.nameRef + "/social/qr"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              Qr-Code
            </Button>
          </div>
        </li>
        <li className="flex flex-row items-center justify-between mt-2 bg-white p-2">
          <b>LINK PER STAMPATI</b>{" "}
          <div>
            <Button
              style={{ marginLeft: 8, textTransform: "lowercase" }}
              onClick={() => {
                copyTextToClipboard(
                  "https://mowi.space/area/" + actualArea.nameRef + "/print"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              <small>
                {" "}
                {"https://mowi.space/area/" + actualArea.nameRef + "/print"}
              </small>{" "}
              <i className="fa fa-copy"></i>
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={() => {
                window.open(
                  "https://mowi.space/area/" + actualArea.nameRef + "/print/qr"
                );
              }}
              color={"gray"}
              size={"xs"}
            >
              Qr-Code
            </Button>
          </div>
        </li>
      </ul>
      <div className="w-full justify-center items-center p-2 bg-white mt-4">
        <b>CODICE IFRAME PER INCORPORO IN SITI WEB</b>
      </div>

      <div className="flex flex-row w-full justify-between items-center">
        <div
          className="flex flex-1 w-full justify-center items-center p-2 m-2 bg-white"
          style={{ height: 60 }}
        >
          <Switch.Group>
            <div className="flex items-center relative w-full px-3 text-left">
              <Switch.Label className="flex flex-1 mr-4 text-sm text-gray-600 text-left">
                Bordo arrotondato
              </Switch.Label>
              <Switch
                checked={frameStyle.border}
                onChange={(value: boolean) => {
                  setFrameStyle({
                    ...frameStyle,
                    border: value,
                  });
                }}
                style={{ width: 45 }}
                className={`${
                  frameStyle.border ? "bg-purple-400" : "bg-gray-300"
                } relative inline-flex items-center h-6 rounded-full transition-colors focus:outline-none`}
              >
                <span
                  className={`${
                    frameStyle.border ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
        <div
          className="flex flex-1 w-full justify-center items-center p-2 m-2 bg-white"
          style={{ height: 60 }}
        >
          <Switch.Group>
            <div className="flex items-center relative w-full px-3 text-left">
              <Switch.Label className="flex flex-1 mr-4 text-sm text-gray-600 text-left">
                Ombreggiatura
              </Switch.Label>
              <Switch
                checked={frameStyle.shadow}
                onChange={(value: boolean) => {
                  setFrameStyle({
                    ...frameStyle,
                    shadow: value,
                  });
                }}
                style={{ width: 45 }}
                className={`${
                  frameStyle.shadow ? "bg-purple-400" : "bg-gray-300"
                } relative inline-flex items-center h-6 rounded-full transition-colors focus:outline-none`}
              >
                <span
                  className={`${
                    frameStyle.shadow ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
        <div
          className="flex flex-1 w-full justify-center items-center p-2 m-2 bg-white"
          style={{ height: 60 }}
        >
          <Button
            style={{ marginLeft: 8 }}
            onClick={() => {
              copyTextToClipboard(code);
            }}
            color={"gray"}
            size={"sm"}
          >
            Copia Codice <i className="fa fa-copy"></i>
          </Button>
        </div>
      </div>

      <textarea
        className="flex flex-1 shadow px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded-lg text-sm border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-400 focus:border-transparent w-full ease-linear transition-all duration-150"
        rows={1}
        value={code}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          setCode(event.target.value);
        }}
      />
    </div>
  );
};

interface AppViewProps {
  route?: any;
}

const AppView = (props: AppViewProps) => {
  const [page, setPage] = useState<"app" | "iframe">("app");
  const renderPage = () => {
    switch (page) {
      case "app":
        return <WebAppIframe />;
      case "iframe":
        return <WebAppCode />;
      default:
        return <WebAppIframe />;
    }
  };
  return (
    <div
      className="w-full h-full flex flex-col justify-center items-center"
      style={{ height: "calc(100vh - 115px)" }}
    >
      <div className="flex flex-wrap p-2">
        <Button
          className="mr-2"
          color={page === "app" ? "purple" : "white"}
          onClick={() => {
            setPage("app");
          }}
        >
          ANTEPRIMA WEBAPP
        </Button>{" "}
        <Button
          className="mx-2"
          color={page === "iframe" ? "purple" : "white"}
          onClick={() => {
            setPage("iframe");
          }}
        >
          LINKS ED IFRAME
        </Button>{" "}
      </div>
      {renderPage()}
    </div>
  );
};

export { AppView };
