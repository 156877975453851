import React, { CSSProperties, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { Navbar, Sidebar } from "../components";
import { LOG, theme } from "../config";
import { useGlobalState } from "../stores/appState";

// views
import { DashView } from "../views/admin/home/DashView";
import { createApolloClient } from "../libs/apollo/createClient";
import { TracksView } from "../views/admin/area/TracksView";
import { TransportsView } from "../views/admin/area/TransportsView";
import { TrackView } from "../views/admin/area/TrackView";
import { TransportView } from "../views/admin/area/TransportView";
import { PoiView } from "../views/admin/area/PoiView";
import { PoisView } from "../views/admin/area/PoisView";
import { NoticesView } from "../views/admin/area/NoticesView";
import { PatrolsView } from "../views/admin/area/PatrolsView";
import { BrandView } from "../views/admin/area/BrandView";
import { AppView } from "../views/admin/home/AppView";
import { NotificationsView } from "../views/admin/messages/NotificationsView";
import { IamsView } from "../views/admin/messages/IamsView";
import { EventsView } from "../views/admin/analytics/EventsView";
import { AquisitionView } from "../views/admin/analytics/AquisitionView";
import { BehaviorView } from "../views/admin/analytics/BehaviorView";
import { PublicView } from "../views/admin/analytics/PublicView";

const log = LOG.extend("ADMIN");

interface AdminProps {}

const Admin = (props: AdminProps) => {
  const [auth] = useGlobalState("auth");

  const [client, setClient] = useState<any>(undefined);

  useEffect(() => {
    if (!client) {
      createApolloClient().then((apolloClient) => {
        if (apolloClient) {
          setClient(apolloClient);
        } else {
          log.error("Unable to initialize client");
        }
      });
    }
    return () => {};
  }, [client]);

  if (!auth.area || !auth.user) {
    return <Redirect to="/auth" />;
  }

  return (
    <>
      <div
        className="absolute top-0 w-full h-full bg-gray-700 bg-no-repeat bg-full"
        style={styles.container}
      ></div>
      <Sidebar />
      <div
        className="relative md:ml-60 min-h-screen"
        style={{ backgroundColor: "#e4e6e9" }}
      >
        {client ? (
          <ApolloProvider client={client}>
            <Navbar />
            <div className="px-4 md:px-10 mx-auto w-full pt-4 md:pt-20">
              <Switch>
                <Route
                  path="/admin/home/dashboard"
                  exact
                  component={DashView}
                />

                <Route
                  path="/admin/analytics/events"
                  exact
                  component={EventsView}
                />
                <Route
                  path="/admin/analytics/acquisition"
                  exact
                  component={AquisitionView}
                />
                <Route
                  path="/admin/analytics/behavior"
                  exact
                  component={BehaviorView}
                />
                <Route
                  path="/admin/analytics/public"
                  exact
                  component={PublicView}
                />

                <Route path="/admin/area/tracks" exact component={TracksView} />
                <Route
                  path="/admin/area/tracks/:id"
                  exact
                  component={TrackView}
                />
                <Route
                  path="/admin/area/tracks/:id/:view"
                  exact
                  component={TrackView}
                />

                <Route
                  path="/admin/area/transports"
                  exact
                  component={TransportsView}
                />
                <Route
                  path="/admin/area/transports/:id"
                  exact
                  component={TransportView}
                />
                <Route
                  path="/admin/area/transports/:id/:view"
                  exact
                  component={TransportView}
                />

                <Route path="/admin/area/pois" exact component={PoisView} />
                <Route path="/admin/area/pois/:id" exact component={PoiView} />

                <Route
                  path="/admin/area/notices"
                  exact
                  component={NoticesView}
                />

                <Route
                  path="/admin/area/patrols"
                  exact
                  component={PatrolsView}
                />

                <Route path="/admin/area/brand" exact component={BrandView} />

                <Route
                  path="/admin/messages/notifications"
                  exact
                  component={NotificationsView}
                />
                <Route
                  path="/admin/messages/inappmessages"
                  exact
                  component={IamsView}
                />

                <Route path="/admin/home/app" exact component={AppView} />
                <Redirect from="/admin" to="/admin/home/dashboard" />
              </Switch>
            </div>
          </ApolloProvider>
        ) : null}
      </div>
    </>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    backgroundColor: theme.vars.colors.grey,
  },
};

export { Admin };
