import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import { LineChart } from "../Charts/LineChart";

type baseMetricDate = {
  name: string;
  rows: [{ date: string; value: number }];
  total: number;
  maximum: number;
  minimum: number;
  count: number;
};

interface LineMetricProps {
  metric: string;
  id: string;
  areaId?: string | null;
  areaRef?: string | null;
  areaApp?: string | null;
  platform?: string | null;
  from?: string;
  to?: string;
  title?: string;
  subTitle?: string;
  unit?: string;
  height?: number;
}

const LineMetric = (props: LineMetricProps) => {
  const [loading, setLoading] = useState(true);
  //const [total, setTotal] = useState(0);

  const [chartData, setChartData] = useState<{
    labels: string[];
    data: string[] | number[];
  }>({ labels: [], data: [] });

  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await axios.post<baseMetricDate>(
          ENV.analytics + "/base/metric_date",
          {
            metric: props.metric,
            area_app: props.areaApp,
            area_id: props.areaId,
            area_ref: props.areaRef,
            platform: props.platform,
            from: props.from,
            to: props.to,
          }
        );

        if (response?.data) {
          let data = response.data.rows.map((row: any, index: number) => {
            return row.value;
          });

          let labels = response.data.rows.map((row: any, index: number) => {
            return row.date.replace(/(\d{4})(\d{2})(\d{2})/g, "$3/$2");
          });

          //setTotal(response.data.total);
          setChartData({
            labels: labels,
            data: data,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    setLoading(true);
    getReport();
  }, [props]);

  if (!props.metric) return null;

  return (
    <>
      <div className="overflow-hidden relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-white">
        <div className="rounded-xl mb-0 px-4 py-3 bg-white">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                {props.subTitle}
              </h6>
              <h2 className="text-xl font-semibold">{props.title}</h2>
              {/* <h3 style={{ position: "absolute" }} className="text-sm">
                {total || ""} {props.unit}
              </h3> */}
            </div>
          </div>
        </div>
        <div className="flex-auto" style={{ height: 388, padding: 10 }}>
          <div
            style={{
              height: "100%",
              /* marginLeft: -25,
              marginRight: -25, */
              marginTop: 0,
            }}
            className="relative h-full"
          >
            {chartData.data.length > 0 && !loading && (
              <LineChart
                id={props.id}
                data={chartData.data}
                labels={chartData.labels}
              />
            )}
          </div>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

LineMetric.defaultProps = {
  from: "2020-01-01",
  to: "today",
  title: "Values",
  subTitle: "Overview",
};

export { LineMetric };
