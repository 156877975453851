import { gql } from '@apollo/client';
import { Warning } from './warnings';

interface Track {
  id: string;
  name: string;
  level: string;
  category: string;
  area: string;
  typology: string;
  localities: string[];
  tagFilters: string[];
  status: boolean;
  originalStatus: boolean;
  statusFromExternalSource: boolean;
  statusClosedUntil: boolean;
  geoLocation: {
    coordinatesMultiLineString?: number[][][];
    coordinatesLineString?: number[][];
    coordinatesPoint?: number[];
  };
  media: {
    pictures: [
      {
        _id: string;
        url: string;
      }
    ];
    videos: [
      {
        _id: string;
        url: string;
      }
    ];
    cover: {
      _id: string;
      url: string;
    };
  };
  technicalInfo: {
    length: number;
    altimetry: {
      highestPoint: number;
      lowestPoint: number;
      differencePlus: number;
      differenceMinus: number;
    };
    maxSlope: number;
  };
  description: {
    short: {
      it: string;
      en: string;
      de: string;
    };
    long: {
      it: string;
      en: string;
      de: string;
    };
    howToStart: {
      it: string;
      en: string;
      de: string;
    };
  };
  detailedInfo: {
    trackNumber: string;
    isCircular: boolean;
    stations: {
      start: {
        name: {
          it: string;
          en: string;
          de: string;
        };
        altitude: number;
      };
      end: {
        name: {
          it: string;
          en: string;
          de: string;
        };
        altitude: number;
      };
    };
    timeAvarage: number;
    physicalEfford: number;
    technicalAbility: number;
    emotion: number;
    landscape: number;
    locality: {
      it: string;
      en: string;
      de: string;
    };
    level: string;
    suggestedPeriod: [number];
    origin: string;
    zone: {
      name: string;
      icon: string;
    };
    isForPedestrians: boolean;
    isForFamily: boolean;
  };
  warnings: Warning[];
}

const TRACK_FRAGMENT = gql`
  fragment trackFragment on UserTrack {
    id
    status
    originalStatus
    statusFromExternalSource
    statusClosedUntil
    name
    technicalInfo {
      length
      altimetry {
        highestPoint
        lowestPoint
        differencePlus
        differenceMinus
      }
      maxSlope
    }
    level
    category
    area
    typology
    localities
    description {
      short {
        it
        en
        de
      }
      long {
        it
        en
        de
      }
      howToStart {
        it
        en
        de
      }
    }
    detailedInfo {
      trackNumber
      isCircular
      stations {
        start {
          name {
            it
            en
            de
          }
          altitude
        }
        end {
          name {
            it
            en
            de
          }
          altitude
        }
      }
      timeAvarage
      physicalEfford
      technicalAbility
      emotion
      landscape
      locality {
        it
        en
        de
      }
      level
      suggestedPeriod
      origin
      zone {
        name
        icon
      }
      isForPedestrians
      isForFamily
    }
    warnings {
      _id
      location
      creator {
        user
        displayName
      }
      problem
      ifClosed
      description {
        it
        en
        de
      }
      refId
    }
  }
`;

const TRACK_GEO_FRAGMENT = gql`
  fragment trackGeoFragment on UserTrack {
    geoLocation {
      coordinatesMultiLineString
      coordinatesLineString
      coordinatesPoint
    }
  }
`;

const TRACK_MEDIA_FRAGMENT = gql`
  fragment trackMediaFragment on UserTrack {
    media {
      pictures {
        _id
        url
      }
      videos {
        _id
        url
      }
      cover {
        _id
        url
      }
    }
  }
`;

const TRACK = gql`
  query TRACK($id: ID!) {
    userTrack(id: $id) {
      ...trackFragment
      ...trackMediaFragment
      ...trackGeoFragment
    }
  }
  ${TRACK_FRAGMENT}
  ${TRACK_MEDIA_FRAGMENT}
  ${TRACK_GEO_FRAGMENT}
`;

const TRACKS = gql`
  query TRACKS($area: ID!) {
    userTracks(area: $area) {
      ...trackFragment
    }
  }
  ${TRACK_FRAGMENT}
`;

const TRACK_UPDATE = gql`
  mutation TRACK_UPDATE($id: ID!, $trackInput: TrackInput) {
    userUpdateTrack(id: $id, trackInput: $trackInput) {
      ...trackFragment
    }
  }
  ${TRACK_FRAGMENT}
`;

const TRACK_ADD_MEDIA = gql`
  mutation TRACK_ADD_MEDIA($id: ID!, $medias: [Upload], $type: TrackMediaType) {
    userAddMediasToTrack(id: $id, medias: $medias, type: $type) {
      id
      ...trackMediaFragment
    }
  }
  ${TRACK_MEDIA_FRAGMENT}
`;

const TRACK_REMOVE_MEDIA = gql`
  mutation TRACK_REMOVE_MEDIA($id: ID!, $mediaId: ID!, $type: TrackMediaType) {
    userRemoveMediaFromTrack(id: $id, mediaId: $mediaId, type: $type) {
      id
      ...trackMediaFragment
    }
  }
  ${TRACK_MEDIA_FRAGMENT}
`;

const TRACK_UPDATE_OSTATUS = gql`
  mutation TRACK_UPDATE_OSTATUS($ids: [ID]!, $newStatus: Boolean) {
    patrolTrackChangeOriginalStatus(ids: $ids, newStatus: $newStatus)
  }
`;

export {
  TRACK,
  TRACKS,
  TRACK_UPDATE,
  TRACK_ADD_MEDIA,
  TRACK_REMOVE_MEDIA,
  TRACK_UPDATE_OSTATUS,
};
export type { Track };
