import React from 'react';
import { T, theme } from '../config';

interface StatusLabelProps {
  status?: boolean | null;
}

const StatusLabel = (props: StatusLabelProps) => {
  let color = theme.vars.colors.greyLight;
  let text = 'Nessuno';

  if (typeof props.status === 'boolean') {
    color = props.status
      ? theme.vars.colors.greenLight
      : theme.vars.colors.redLight;
    text = props.status ? T('open') : T('closed');
  }

  return (
    <span
      className="flex flex-row bg-gray-600 rounded-full text-white font-bold p-1 px-2 items-center justify-center uppercase"
      style={{ fontSize: 11, maxWidth: 100 }}
    >
      <span
        className={
          'rounded-full text-white font-bold p-1 mr-2 text-xs flex items-center justify-center'
        }
        style={{
          width: 12,
          height: 12,
          backgroundColor: color,
        }}
      ></span>
      {text}
    </span>
  );
};

export { StatusLabel };
