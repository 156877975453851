import { DateRange } from "../../../ui/DateRange";
import { useState } from "react";
import { PieDimension } from "../../../components/Analytics/PieDimension";
import { ACTUAL_AUTH } from "../../../stores/db/auth";

import ImgStats from "../../../assets/img/stats.jpg";

interface PublicViewProps {
  route?: any;
}

const PublicView = (props: PublicViewProps) => {
  const [selRange, setSelRange] = useState({
    startDate: "2022-06-01",
    endDate: undefined,
  });

  return (
    <>
      <div className="relative pt-5 mb-4">
        <div className="mx-auto w-full">
          <div className="pr-2 pl-2">
            <DateRange
              initStartDate={selRange.startDate}
              initEndDate={selRange.endDate}
              onChange={(range) => {
                let startDate = range.startDate.toISOString().split("T")[0];
                let endDate = range.endDate.toISOString().split("T")[0];
                setSelRange({ startDate, endDate });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="customUser:USER_PROFILATION"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Profilazione utenti"
            id="pie-chart_profilation"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="deviceCategory"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Tipo dispositivo"
            id="pie-chart_devices"
          />
        </div>

        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="platform"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Piattaforma"
            id="pie-chart_platform"
          />
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="language"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Lingua"
            id="pie-chart_language"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="country"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Paese"
            id="pie-chart_country"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <PieDimension
            dimension="region"
            areaId={ACTUAL_AUTH.area}
            areaApp={ACTUAL_AUTH.app}
            from={selRange.startDate}
            to={selRange.endDate}
            title="Regione"
            id="pie-chart_region"
          />
        </div>
      </div>
    </>
  );
};

export { PublicView };
