import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import { PoiDetails, PoiMap, PoiMedia } from '../../../components';
import { Button } from '../../../ui';

interface PoiViewProps {}

const PoiView = (props: PoiViewProps) => {
  const match = useRouteMatch('/admin/area/pois/:id');
  const params: any = match?.params;

  const [page, setPage] = useState<'info' | 'media' | 'warnings'>('info');

  const renderPage = () => {
    switch (page) {
      case 'info':
        return <PoiDetails id={params?.id} />;
      case 'media':
        return <PoiMedia id={params?.id} />;
      default:
        return <PoiDetails id={params?.id} />;
    }
  };

  return (
    <>
      <div className="flex flex-wrap p-2 w-full">
        <Button
          className="mr-2"
          color={page === 'info' ? 'purple' : 'white'}
          onClick={() => {
            setPage('info');
          }}
        >
          SCHEDA
        </Button>{' '}
        <Button
          className="mx-2"
          color={page === 'media' ? 'purple' : 'white'}
          onClick={() => {
            setPage('media');
          }}
        >
          MEDIA
        </Button>{' '}
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-7/12 p-2">{renderPage()}</div>
        <div className="w-full lg:w-5/12 p-2">
          <PoiMap id={params?.id} />
        </div>
      </div>
    </>
  );
};

export { PoiView };
