import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQuery } from "@apollo/client";

import { ENV, LOG, T, theme } from "../../config";

import { Button, Loading } from "../../ui";

import {
  AREA_DELETE_IAMS,
  AREA_IAMS,
  Message,
} from "../../stores/queries/iams";

const MessageItem = ({
  message,
  index,
  onClickPreview,
  onClickDelete,
}: {
  message: Message;
  index: number;
  onClickDelete?: (item: Message) => void;
  onClickPreview?: (item: Message) => void;
}) => {
  if (!message) return null;
  return (
    <div className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center rounded-2xl bg-gray-100 text-white">
      <div
        className="flex justify-center items-center bg-blue-400 text-white font-bold text-lg"
        style={{ ...styles.iamsLeft }}
      >
        #{index + 1}
      </div>
      <div
        className="flex"
        style={{ alignItems: "center", justifyContent: "center" }}
      ></div>
      <div className="flex flex-1 ">
        <div className="flex flex-col flex-1 justify-center">
          {message.title && (
            <div className="flex text-base text-gray-600 font-bold text-2lines">
              {message.title[ENV.locale] || message.title.en}
            </div>
          )}
          <div className="flex flex-row text-base text-gray-600 font-bold">
            <div
              className="flex justify-center items-center bg-purple-400 text-white font-bold"
              style={{ ...styles.iamsLabel }}
            >
              {message.scope === "alert" ? (
                <i className="fa fa-bell text-xs" />
              ) : null}
              {message.scope === "info" ? (
                <i className="fa fa-info-circle text-xs" />
              ) : null}
              {message.scope === "commercial" ? (
                <i className="fa fa-ad text-xs" />
              ) : null}
            </div>
            <div
              className="flex justify-center items-center bg-purple-400 text-white font-bold"
              style={{ ...styles.iamsLabel }}
            >
              {!message.repeat || message.repeat === null ? (
                <i className="fa fa-calendar-times text-xs" />
              ) : null}
              {message.repeat === "week" ? (
                <i className="fa fa-calendar-day text-xs" />
              ) : null}
              {message.repeat === "month" ? (
                <i className="fa fa-calendar-week text-xs" />
              ) : null}
            </div>
            {message.image?.it || message.image?.en || message.image?.de ? (
              <div
                className="flex justify-center items-center bg-purple-400 text-white font-bold"
                style={{ ...styles.iamsLabel }}
              >
                <i className="fa fa-image text-xs" />
              </div>
            ) : null}
          </div>
        </div>

        <div
          className="flex flex-1"
          style={{ flexDirection: "column", justifyContent: "center" }}
        >
          <Button
            color={"blue"}
            className="m-1"
            onClick={() => {
              onClickPreview && onClickPreview(message);
            }}
          >
            <i className="fa fa-chevron-left"></i> Anteprima
          </Button>
          <Button
            color={"gray"}
            className="m-1"
            onClick={() => {
              onClickDelete && onClickDelete(message);
            }}
          >
            Elimina
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IamsListProps {
  setMessage: Function;
  iamsIndex: number;
  setIamsIndex: Function;
  setIamsCount: Function;
}

const IamsList = (props: IamsListProps) => {
  const { loading, error, data } = useQuery<{
    areaInAppMessages: Message[];
  }>(AREA_IAMS, {
    fetchPolicy: "cache-and-network",
  });

  const [iamsDelete, { loading: idLoading, error: idError }] = useMutation(
    AREA_DELETE_IAMS,
    {
      refetchQueries: [
        {
          query: AREA_IAMS,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const [openMessage, setOpenMessage] = useState<Message | null>(null);

  const deleteMessage = (id: string) => {
    if (id) {
      iamsDelete({
        variables: {
          id,
        },
      });
    }
  };

  useEffect(() => {
    if (data?.areaInAppMessages) {
      props.setIamsCount && props.setIamsCount(data?.areaInAppMessages?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) LOG.error(error);
  if (idError) LOG.error(idError);

  if (error && !data?.areaInAppMessages) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg text-gray-600 mt-2 text-center">
          Ops, scusa c'è statu un errore!
        </p>
      </div>
    );
  }

  return (
    <div
      style={{ height: "calc(100vh - 110px)" }}
      className={
        "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white"
      }
    >
      <div className="flex flex-col w-full items-center justify-between overflow-auto">
        <div className="rounded-t-2xl w-full my-1 px-6 py-4 flex flex-row justify-between">
          <h3 className={"font-semibold text-2xl text-gray-600"}>Attuali</h3>
          <h3 className="text-lg font-bold">
            {data?.areaInAppMessages?.length}/2
          </h3>
        </div>
        <div className="w-full h-full overflow-y-auto px-4">
          {!data?.areaInAppMessages || data.areaInAppMessages.length < 1 ? (
            <div className="font-bold w-full text-center text-gray-500">
              Non sono presenti messaggi in app
            </div>
          ) : null}
          {data?.areaInAppMessages.map((item, index) => {
            return (
              <MessageItem
                key={item.id}
                message={item}
                index={index}
                onClickPreview={(item) => {
                  props.setIamsIndex && props.setIamsIndex(index);
                  props.setMessage(item);
                }}
                onClickDelete={(item) => {
                  setOpenMessage(item);
                }}
              />
            );
          })}
        </div>

        {!data && loading ? (
          <div
            className="absolute rounded-2xl top-0 left-0 flex w-full h-full items-center justify-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              zIndex: 5000,
            }}
          >
            <Loading color={theme.vars.colors.light} />
          </div>
        ) : null}
      </div>

      <Transition.Root show={openMessage ? true : false} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-y-auto"
          style={{ zIndex: 99999 }}
          open={openMessage ? true : false}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-12 sm:align-middle sm:max-w-sm w-full">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-auto">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-xl leading-6 font-medium text-gray-600 mb-2"
                      >
                        <p
                          style={{
                            borderBottom: "1px solid #ddd",
                            paddingBottom: 5,
                          }}
                          className="text-center"
                        >
                          Attenzione!
                        </p>
                      </Dialog.Title>
                      <p>
                        Sicuro di voler eliminare il messaggio in app:{" "}
                        <b>
                          {openMessage?.title[ENV.locale] ||
                            openMessage?.title["en"]}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="px-4 flex mb-4 justify-between">
                  <Button
                    size="md"
                    color="white"
                    className={"lg:min-w-140-px mb-4 m-2"}
                    onClick={() => {
                      if (openMessage) {
                        setOpenMessage(null);
                      }
                    }}
                  >
                    {" "}
                    {T("cancel")}
                  </Button>
                  <Button
                    size="md"
                    color="red"
                    className={"lg:min-w-140-px mb-4 m-2"}
                    onClick={() => {
                      openMessage?.id && deleteMessage(openMessage.id);
                      if (openMessage) {
                        setOpenMessage(null);
                      }
                    }}
                  >
                    {" "}
                    {T("yes")}
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {idLoading && (
        <div
          className="absolute top-0 left-0 flex w-full h-full items-center justify-center rounded-2xl"
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: 5000,
          }}
        >
          <Loading color={theme.vars.colors.light} />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  iamsLeft: {
    width: 40,
    height: 80,
    borderRadius: 16,
    marginRight: 10,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  iamsLabel: {
    height: 26,
    width: 26,
    borderRadius: 13,
    marginRight: 5,
  },
  iamsIcon: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: 3,
  },
  voidList: {
    width: "100%",
    padding: 20,
    alignItems: "center",
  },
  modalBtn: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    borderRadius: 20,
    paddingVertical: 10,
    marginVertical: 5,
    paddingRight: 10,
    minHeight: 70,
  },
};

export { IamsList };
