import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import {
  AREA_NOTIFICATIONS,
  Notification,
} from "../../stores/queries/notifications";
import { useQuery } from "@apollo/client";
// @ts-ignore
import Moment from "moment/min/moment-with-locales";
import { Link } from "react-router-dom";

const NotiStatus = ({ noti }: { noti: Notification }) => {
  if (!noti) return null;

  let color: any = "white";
  let icon: any = "";
  let text: string = "";

  switch (noti.status) {
    case "sent":
      color = theme.vars.colors.greenLight;
      icon = "paper-plane";
      text = "inviata";
      break;
    case "waiting":
      icon = "clock";
      color = theme.vars.colors.grey;
      text = "in verifica";
      break;
    case "approved":
      icon = "check-circle";
      color = theme.vars.colors.cyan;
      text = "approvata";
      break;
    case "rejected":
      icon = "times-circle";
      color = theme.vars.colors.redLight;
      text = "rifiutata";
      break;
    case "cancel":
      icon = "times";
      color = theme.vars.colors.greyLight;
      text = "annullata";
      break;
    default:
      break;
  }

  return (
    <div
      className="flex w-full flex-row justify-center items-center rounded-2xl bg-gray-100 text-white px-2 py-1"
      style={{
        backgroundColor: color,
      }}
    >
      <div className="mr-2">
        <i className={"text-md fa fa-" + icon} />
      </div>

      <div className="flex flex-row justify-center items-center">
        <div className="font-bold uppercase text-xs">{text}</div>

        <div className="text-xs">
          {
            // @ts-ignore
            noti.info[ENV.locale] || noti.info["en"]
          }
        </div>
      </div>
    </div>
  );
};

const NotiHead = ({ noti }: { noti: Notification }) => {
  let man = false;

  if (noti?.creator?._id) {
    man = true;
  }

  return (
    <div className="flex flex-1 flex-row items-center">
      <div
        className="text-white text-xs font-bold flex justify-center items-center"
        style={{
          marginRight: 4,
          width: 20,
          height: 26,
          borderRadius: 13,
          backgroundColor: man
            ? theme.vars.colors.cyan
            : theme.vars.colors.greyLight,
        }}
      >
        {man ? "M" : "A"}
      </div>
      <div className="flex flex-1 flex-col items-start">
        <div
          style={{ width: 170 }}
          className="flex-1 text-gray-800 font-bold truncate text-xs text-left"
        >
          {
            // @ts-ignore
            noti.head[ENV.locale] || noti.head["en"]
          }
        </div>
        {noti.sentOnDate && (
          <div
            style={{ width: 170, fontWeight: 400 }}
            className="text-xs truncate text-left"
          >
            {Moment(new Date(noti.sentOnDate))
              .locale(ENV.locale, "en")
              .format("dddd, DD/MM/YY, HH:mm")}
          </div>
        )}
      </div>
    </div>
  );
};

const CardNotifications = () => {
  const { data, loading } = useQuery<{
    areaAllNotifications: Notification[];
  }>(AREA_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 10000,
  });

  let notificationList = [];

  if (data?.areaAllNotifications) {
    notificationList = [...(data?.areaAllNotifications as any)].reverse() || [];
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl pb-2">
        <div className="rounded-t mb-0 px-1 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h2 className="text-xl font-semibold">Notifiche</h2>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-purple-400 text-white hover:bg-purple-500 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                <Link to={"/admin/messages/notifications"}> Vedi Tutte</Link>
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <tbody>
              {notificationList?.map((notification, index) => {
                if (index < 5) {
                  return (
                    <tr key={notification.id} style={{ height: 40 }}>
                      <th className="border-t-0 px-2 align-middle border-l-0 border-r-0">
                        <NotiHead noti={notification} />
                      </th>

                      <td className="border-t-0 px-2 align-middle border-l-0 border-r-0">
                        <NotiStatus noti={notification} />
                      </td>
                    </tr>
                  );
                } else return null;
              })}
            </tbody>
          </table>
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

export { CardNotifications };
