import React from "react";
import Loader from "react-loader-spinner";

interface LoadingProps {
  color?: string;
  width?: number;
  height?: number;
  type?: any;
}

const Loading = (props: LoadingProps) => {
  return (
    <Loader
      type={props.type || "Oval"}
      color={props.color || "#fff"}
      height={props.height || 30}
      width={props.width || 30}
    />
  );
};

export { Loading };
