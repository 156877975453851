import React from 'react';
import { TransportsList } from '../../../components';

interface TransportsViewProps {}

const TransportsView = (props: TransportsViewProps) => {
  return (
    <>
      <TransportsList />
    </>
  );
};

export { TransportsView };
