import React, { useState } from 'react';
import { useParams } from 'react-router';

import {
  TransportDetails,
  TransportMap,
  TransportMedia,
  TransportWarnings,
} from '../../../components';
import { Button } from '../../../ui';

interface TransportViewProps {}

const TransportView = (props: TransportViewProps) => {
  const params: any = useParams();

  const [page, setPage] = useState<'info' | 'media' | 'warnings'>(
    params?.view || 'info'
  );

  const renderPage = () => {
    switch (page) {
      case 'info':
        return <TransportDetails id={params?.id} />;
      case 'media':
        return <TransportMedia id={params?.id} />;
      case 'warnings':
        return <TransportWarnings id={params?.id} />;
      default:
        return <TransportDetails id={params?.id} />;
    }
  };

  return (
    <>
      <div className="flex flex-wrap p-2 w-full">
        <Button
          className="mr-2"
          color={page === 'info' ? 'purple' : 'white'}
          onClick={() => {
            setPage('info');
          }}
        >
          SCHEDA
        </Button>{' '}
        <Button
          className="mx-2"
          color={page === 'media' ? 'purple' : 'white'}
          onClick={() => {
            setPage('media');
          }}
        >
          MEDIA
        </Button>{' '}
        <Button
          className="ml-2"
          color={page === 'warnings' ? 'purple' : 'white'}
          onClick={() => {
            setPage('warnings');
          }}
        >
          AVVISI
        </Button>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-7/12 p-2">{renderPage()}</div>
        <div className="w-full lg:w-5/12 p-2">
          <TransportMap id={params?.id} />
        </div>
      </div>
    </>
  );
};

export { TransportView };
