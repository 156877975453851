import React, { CSSProperties } from 'react';

import { theme } from '../config';

interface RatingDotsProps {
  on: number;
  tot: number;
  size?: number;
  symbol?: string;
  style?: CSSProperties;
  onStyle?: CSSProperties;
  offStyle?: CSSProperties;
}

const RatingDots = (props: RatingDotsProps) => {
  let size = 13;
  if (props.size) size = props.size;
  let symbol = '\u2B24';
  if (props.symbol) symbol = props.symbol;
  let tot = 0;
  if (props.tot >= 0) tot = props.tot;
  let on = tot;
  if (props.on <= tot) on = props.on;

  let onText = '';
  let offText = '';
  for (let index = 0; index < on; index++) {
    onText += symbol;
  }
  for (let index = 0; index < tot - on; index++) {
    offText += symbol;
  }

  return (
    <div className="flex flex-row" style={props.style}>
      <span style={{ ...styles.on, fontSize: size, ...props.onStyle }}>
        {onText}
      </span>
      <span style={{ ...styles.off, fontSize: size, ...props.offStyle }}>
        {offText}
      </span>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  on: {
    color: theme.vars.colors.greyLight,
  },
  off: {
    color: theme.vars.colors.light,
  },
};

export { RatingDots };
