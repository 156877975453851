import React from "react";
import { useQuery } from "@apollo/client";

import { ACTUAL_AUTH } from "../../stores/db/auth";
import { Poi, POIS } from "../../stores/queries/pois";
import { Table } from "../../ui";
import { LOG, T, theme } from "../../config";
import { useHistory } from "react-router";

const columns: any = [
  {
    name: T("name"),
    selector: "name",
    sortable: true,
    minWidth: "20%",
    style: { fontWeight: 600 },
  },
  {
    name: T("typology"),
    selector: "primaryService",
    sortable: true,
    cell: (row: Poi) => T(row.primaryService),
    hide: "sm",
  },
  {
    name: T("locality"),
    selector: "localities",
    sortable: true,
    cell: (row: Poi) => T(row.localities[0]),
    hide: "sm",
  },
  {
    hide: "md",
    name: (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <h1>STATO</h1>
        <h4 style={{ fontSize: "80%" }}>Sorgente</h4>
      </div>
    ),
    cell: (row: Poi) => (
      <div>
        {typeof row?.statusFromExternalSource === "boolean" ? (
          <i
            className="fa fa-circle"
            style={{
              color: row?.statusFromExternalSource
                ? theme.vars.colors.greenLight
                : theme.vars.colors.redLight,
              opacity: 0.5,
            }}
          />
        ) : (
          <i
            className="fa fa-minus"
            style={{
              color: theme.vars.colors.greyLight,
            }}
          />
        )}
      </div>
    ),
    width: "60px",
    sortable: false,
    center: true,
    style: { borderLeft: "1px solid #eee" },
  },
  {
    name: (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <h1>STATO</h1>
        <h4 style={{ fontSize: "80%" }}>Attuale</h4>
      </div>
    ),
    cell: (row: Poi) => (
      <div>
        {typeof row?.status === "boolean" ? (
          <i
            className="fa fa-circle"
            style={{
              color: row?.status
                ? theme.vars.colors.greenLight
                : theme.vars.colors.redLight,
            }}
          />
        ) : (
          <i
            className="fa fa-minus"
            style={{
              color: theme.vars.colors.greyLight,
            }}
          />
        )}
      </div>
    ),
    width: "60px",
    sortable: false,
    center: true,
    style: { borderLeft: "1px solid #eee" },
  },
];

const PoisList = () => {
  const history = useHistory();
  const { error, data } = useQuery<{
    userPois: Poi[];
  }>(POIS, {
    variables: {
      area: ACTUAL_AUTH.area,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    LOG.error(error);
  }

  return (
    <>
      <div
        className={
          "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white"
        }
      >
        <Table
          columns={columns}
          defaultSortField={"primaryService"}
          data={data?.userPois || []}
          height={"calc(100vh - 240px)"}
          title={"Lista Servizi"}
          loading={data ? false : true}
          filterSelector={"name"}
          onRowClick={(row: Poi, event) => {
            history.push("/admin/area/pois/" + row.id);
          }}
        />
      </div>
    </>
  );
};

export { PoisList };
