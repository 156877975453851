import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loading } from "../../ui";
import { ENV, theme } from "../../config";
import { HBarChart } from "../Charts/HBarChart";

type AnalyticsRow = {
  name: string;
  category: string;
  typology: string;
  value: number;
};

interface ElementViewsProps {
  id: string;
  areaId?: string | null;
  areaRef?: string | null;
  areaApp?: string | null;
  type?: string;
  from?: string;
  platform?: string;
  to?: string;
  title?: string;
  subTitle?: string;
  unit?: string;
  height?: number;
  lineHeight?: number;
}

const ElementViews = (props: ElementViewsProps) => {
  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState<{
    labels: string[];
    data: string[] | number[];
  }>({ labels: [], data: [] });

  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await axios.post<AnalyticsRow[]>(
          ENV.analytics + "/behaviour/element_views",
          {
            type: props.type,
            area_app: props.areaApp,
            area_id: props.areaId,
            area_ref: props.areaRef,
            platform: props.platform,
            from: props.from,
            to: props.to,
          }
        );

        if (response?.data) {
          let data = response.data.map((row: any, index: number) => {
            return row.value;
          });

          let labels = response.data.map((row: any, index: number) => {
            return row.name;
          });

          setChartData({
            labels: labels,
            data: data,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    setLoading(true);
    getReport();
  }, [props]);

  return (
    <>
      <div className="overflow-hidden relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-xl bg-white">
        <div className="rounded-xl mb-0 px-4 pt-3 bg-white">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                {props.subTitle}
              </h6>
              <h2 className="text-xl font-semibold">{props.title}</h2>
            </div>
          </div>
        </div>
        <div
          className="pb-2 px-4"
          style={{ borderTopWidth: 0, overflow: "auto" }}
        >
          {chartData.data.length > 0 && !loading && (
            <HBarChart
              id={props.id}
              data={chartData.data}
              labels={chartData.labels}
              lineHeight={props.lineHeight}
              height={props.height}
            />
          )}
        </div>
        {loading && (
          <div className="w-full h-full absolute flex items-center justify-center py-8 bg-white rounded-lg">
            <Loading color={theme.vars.colors.greyDark} />
          </div>
        )}
      </div>
    </>
  );
};

ElementViews.defaultProps = {
  from: "2020-01-01",
  to: "today",
  title: "Values",
  subTitle: "Overview",
  lineHeight: 10,
};

export { ElementViews };
