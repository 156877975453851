import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import { LOG, T, theme } from "../../config";
import { Button, EditBox, EditImage, Loading } from "../../ui";
import { useGlobalState } from "../../stores/appState";
import { AREA_CREATE_IAMS, AREA_IAMS } from "../../stores/queries/iams";

interface IamsAddProps {
  setMessage: Function;
  iamsCount: number;
}

const IamAdd = (props: IamsAddProps) => {
  const [areaCreateInAppMessage, { error, loading }] = useMutation(
    AREA_CREATE_IAMS,
    {
      refetchQueries: [
        {
          query: AREA_IAMS,
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (res: any) => {
        if (res?.areaCreateInAppMessage) {
          setSuccess(true);
        }
      },
    }
  );

  const [locale, setLocale] = useGlobalState("locale");
  const [scope, setScope] = useState<"commercial" | "info" | "alert">("info");
  const [repeat, setRepeat] = useState<"week" | "month" | null>("month");
  const [imageUrl, setImageUrl] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setLocale("en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeEdit, setActiveEdit] = useState<boolean>(false);

  const actualMessage = useRef<{
    title: {
      it?: string;
      en?: string;
      de?: string;
    };
    description: {
      it?: string;
      en?: string;
      de?: string;
    };
    image: {
      imageIt: any;
      imageDe: any;
      imageEn: any;
    };
  }>({
    title: {
      it: undefined,
      en: undefined,
      de: undefined,
    },
    description: {
      it: undefined,
      en: undefined,
      de: undefined,
    },
    image: {
      imageIt: undefined,
      imageDe: undefined,
      imageEn: undefined,
    },
  });

  const create = () => {
    areaCreateInAppMessage({
      variables: { scope, repeat, ...actualMessage.current },
    });
  };

  if (error) {
    LOG.error(error);
  }

  return (
    <>
      <div
        style={{ height: "calc(100vh - 113px)" }}
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-gray-50 border-0"
      >
        <div className="rounded-t-2xl w-full my-1 px-6 py-4 flex flex-row justify-between bg-white">
          <h3 className={"font-semibold text-2xl text-gray-600"}>Nuovo</h3>
          <div>
            <Button
              onClick={() => {
                props.setMessage &&
                  props.setMessage({
                    ...actualMessage.current,
                    image: { en: imageUrl },
                  });
              }}
              disabled={
                actualMessage.current?.title?.en &&
                actualMessage.current.title.en !== ""
                  ? false
                  : true
              }
              color="blue"
              style={{ marginRight: 5 }}
            >
              Anteprima <i className="fa fa-chevron-right"></i>
            </Button>
            <Button
              onClick={() => {
                create();
              }}
              color="gray"
              disabled={
                actualMessage.current?.title?.en &&
                actualMessage.current.title.en !== "" &&
                props.iamsCount < 2
                  ? false
                  : true
              }
            >
              Crea <i className="fa fa-chevron-right"></i>
            </Button>
          </div>
        </div>
        <div
          className="flex-auto px-4 lg:px-4 py-4 pt-0"
          style={{ overflow: "auto" }}
        >
          <form>
            {error?.message && (
              <div className="p-2 mt-2 text-white bg-red-400 rounded-2xl flex flex-col justify-center items-center">
                {error.message}
              </div>
            )}
            {props.iamsCount > 1 ? (
              <div className="p-2 my-2 text-xs text-gray-600 text-center w-full bg-yellow-200 rounded-lg">
                Numero massimo di messaggi inApp raggiunto (2/2), eliminane uno
                per poterne creare un'altro
              </div>
            ) : null}
            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("Tipologia")}
            </h6>
            <div className="flex flex-1 flex-row w-full">
              {/* <div
                onClick={() => {
                  setScope("alert");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (scope === "alert"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-bell"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Avviso
                </h6>
              </div> */}
              <div
                onClick={() => {
                  setScope("info");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (scope === "info"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-info-circle"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Informativo
                </h6>
              </div>
              <div
                onClick={() => {
                  setScope("commercial");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (scope === "commercial"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-ad"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Commerciale
                </h6>
              </div>
            </div>

            {scope === "commercial" ? (
              <div className="p-2 my-2 text-xs text-gray-600 text-center w-full bg-yellow-200 rounded-lg">
                I messaggi inApp di tipo <b>commerciale</b> arriveranno
                solamente agli utenti registrati che hanno accettato di ricevere
                promozioni da parte dell'area
              </div>
            ) : null}

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("Ripeti")}
            </h6>
            <div className="flex flex-1 flex-row w-full">
              <div
                onClick={() => {
                  setRepeat(null);
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (!repeat
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-calendar-times"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Una Volta
                </h6>
              </div>
              <div
                onClick={() => {
                  setRepeat("week");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (repeat === "week"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-calendar-day"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Settimanale
                </h6>
              </div>
              <div
                onClick={() => {
                  setRepeat("month");
                }}
                className={
                  "cursor-pointer p-2 flex flex-1 flex-col m-2 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 justify-center items-center " +
                  (repeat === "month"
                    ? "bg-purple-400 text-white"
                    : "bg-white text-gray-700")
                }
              >
                <i className="fa fa-calendar-week"></i>
                <h6 className=" text-xs font-bold w-full mt-2 text-center">
                  Mensile
                </h6>
              </div>
            </div>

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("lingue")}
            </h6>
            <div className="text-gray-400 text-base mt-3 mb-4 font-bold uppercase flex flex-1 flex-row ">
              <Button
                color={locale === "en" ? "purple" : "white"}
                onClick={() => {
                  setLocale("en");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                EN*
              </Button>{" "}
              <Button
                color={locale === "it" ? "purple" : "white"}
                onClick={() => {
                  setLocale("it");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                IT
              </Button>{" "}
              <Button
                color={locale === "de" ? "purple" : "white"}
                onClick={() => {
                  setLocale("de");
                }}
                className={
                  "flex flex-1 m-2 text-center justify-center items-center"
                }
              >
                DE
              </Button>
            </div>

            <h6 className="text-gray-400 text-sm mt-3 mb-4 font-bold uppercase">
              {T("contenuto")}
            </h6>

            <div className="flex flex-wrap">
              <EditBox
                editable={true}
                value={actualMessage.current.title[locale]}
                label={T("title") + (locale === "en" ? "*" : "")}
                maxChar={35}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  if (value && value.trim() !== "") {
                    actualMessage.current.title[locale] = value;
                  } else {
                    actualMessage.current.title[locale] = undefined;
                  }
                  props.setMessage &&
                    props.setMessage({
                      ...actualMessage.current,
                      image: { en: imageUrl },
                    });
                }}
              />
              <EditBox
                editable={true}
                value={actualMessage.current.description[locale]}
                label={"Descrizione"}
                maxChar={400}
                onEnterEdit={() => {
                  setActiveEdit(true);
                }}
                onExitEdit={() => {
                  setActiveEdit(false);
                }}
                onPressSave={(value) => {
                  if (value && value.trim() !== "") {
                    actualMessage.current.description[locale] = value;
                  } else {
                    actualMessage.current.description[locale] = undefined;
                  }
                  props.setMessage &&
                    props.setMessage({
                      ...actualMessage.current,
                      image: { en: imageUrl },
                    });
                }}
              />
              <EditImage
                value={imageUrl || undefined}
                id={"1"}
                loading={false}
                onChange={(file) => {
                  if (file) {
                    let url = URL.createObjectURL(file[0]);
                    actualMessage.current.image.imageEn = file[0];
                    setImageUrl(url);
                    props.setMessage &&
                      props.setMessage({
                        ...actualMessage.current,
                        image: { en: url },
                      });
                  } else {
                    actualMessage.current.image.imageEn = undefined;
                    setImageUrl(undefined);
                    props.setMessage &&
                      props.setMessage({
                        ...actualMessage.current,
                        image: { en: undefined },
                      });
                  }
                }}
                onPressRemove={(id) => {
                  actualMessage.current.image.imageEn = undefined;
                  setImageUrl(undefined);
                  props.setMessage &&
                    props.setMessage({
                      ...actualMessage.current,
                      image: { en: undefined },
                    });
                }}
                label={"Immagine"}
                editable={true}
              />
            </div>
          </form>
          {activeEdit && (
            <div
              className="w-full h-full absolute top-0 left-0 rounded-2xl"
              style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
            ></div>
          )}
          {loading ? (
            <div
              className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center"
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 5000,
              }}
            >
              <Loading color={theme.vars.colors.light} />
            </div>
          ) : null}
          {success ? (
            <div
              className="absolute rounded-2xl top-0 left-0 flex flex-col w-full h-full items-center justify-center bg-white"
              style={{
                zIndex: 5000,
              }}
            >
              <div
                className="flex flex-1 flex-col w-full justify-center items-center p-4"
                style={{
                  width: "100%",
                  height: 500,
                  borderTopWidth: 2,
                  borderColor: theme.vars.colors.light,
                }}
              >
                <i
                  style={{
                    fontSize: 80,
                    color: theme.vars.colors.greenLight,
                  }}
                  className="icon-check_encircled_thin"
                />
                <p className="text-lg text-gray-800 text-center">
                  Messaggio inApp creato!
                </p>
                <Button
                  style={{ marginTop: 20 }}
                  color="gray"
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  Indietro
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { IamAdd };
