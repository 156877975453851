import React from 'react';
import { NoticesList } from '../../../components';

interface NoticesViewProps {}

const NoticesView = (props: NoticesViewProps) => {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-6/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-4">
            <h6 className="text-gray-500 text-xl font-bold">
              Tutte le segnalazioni
            </h6>
          </div>
          <NoticesList />
        </div>
        <div className="w-full lg:w-6/12 p-2">
          <div className="rounded-t-2xl w-full px-6 py-4">
            <h6 className="text-gray-500 text-xl font-bold">
              Prese in carico da te
            </h6>
          </div>
          <NoticesList onlyTakeOver={true} />
        </div>
      </div>
    </>
  );
};

export { NoticesView };
