import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

import { theme } from "../../config";

interface LineChartProps {
  labels: string[];
  data: string[] | number[];
  id: string;
  height?: number;
}

const LineChart = (props: LineChartProps) => {
  const CHART = useRef<Chart<"line", any[], string>>();
  const CANVAS = useRef<any>();

  const initChart = async () => {
    if (!props.data) {
      return;
    }

    CANVAS.current = (document.getElementById(props.id) as any).getContext(
      "2d"
    );

    var gradient =
      CANVAS.current && CANVAS.current.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, theme.vars.colors.blueLight);
    gradient.addColorStop(1, "rgba(50,250,50,0)");

    CHART.current = new Chart(CANVAS.current, {
      type: "line",

      data: {
        labels: props.labels,
        datasets: [
          {
            label: "",
            data: props.data,
            borderColor: theme.vars.colors.blueLight,
            //backgroundColor: "rgba(0, 0, 0, 0.1)",
            backgroundColor: gradient,
            fill: true,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.5,
          },
        },

        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
            color: "#999",
            anchor: "end",
            align: "end",
            offset: -2,
            formatter: Math.round,
            font: {
              weight: "bold",
            },
          },
        },

        layout: {
          padding: 0,
        },

        scales: {
          x: {
            //beginAtZero: true,
            offset: true,
            //display: false,
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              drawTicks: false,
            },
            /* afterFit: (scale) => {
              scale.paddingLeft -= 3;
              scale.paddingRight -= 3;
            }, */
            /* ticks: {
              mirror: true,
            }, */
          },
          y: {
            //beginAtZero: true,
            offset: true,
            display: false,
            grid: {
              display: false,
              drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            /* ticks: {
              mirror: true,
            }, */
          },
        },
      },
    });
  };

  useEffect(() => {
    if (CHART.current) {
      CHART.current.destroy();
      initChart();
    } else {
      initChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div
      style={{
        height: "100%",
      }}
      className="relative h-full"
    >
      <canvas id={props.id || "line-chart"}></canvas>
    </div>
  );
};

LineChart.defaultProps = {
  from: "2020-01-01",
  to: "today",
  title: "Values",
  subTitle: "Overview",
};

export { LineChart };
