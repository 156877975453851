import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQuery } from "@apollo/client";
// @ts-ignore
import Moment from "moment/min/moment-with-locales";

import { ENV, LOG, T, theme } from "../../config";

import {
  Notice,
  PATROL_NOTICES,
  PATROL_TAKE_NOTICE,
  PATROL_CHANGESTATUS_NOTICE,
} from "../../stores/queries/notices";
import { Warning } from "../../stores/queries/warnings";
import { Button, Loading } from "../../ui";
import { useGlobalState } from "../../stores/appState";
import { WarningCreate } from "..";

const NoticeIcon = ({ status }: any) => {
  let icon: any = "patrol_management_section_standby";
  let color: any = theme.vars.colors.redLight;
  if (status === "takenOver") {
    icon = "patrol_top_bar_tacking_on";
    color = theme.vars.colors.cyan;
  }
  return (
    <div
      className="flex justify-center items-center"
      style={{ ...styles.noticeLeft, backgroundColor: color }}
    >
      <i className={"icon-" + icon} style={{ fontSize: 40 }} />
    </div>
  );
};

const NoticeItem = ({
  notice,
  active,
  onClick,
}: {
  notice: Notice;
  active?: boolean;
  onClick?: (item: Notice) => void;
}) => {
  return (
    <div
      className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl bg-gray-100 hover:bg-gray-200 text-white cursor-pointer"
      onClick={() => {
        console.log(notice);
        onClick && onClick(notice);
      }}
    >
      <NoticeIcon status={notice.status} />
      <div style={{ alignItems: "center", justifyContent: "center" }}></div>
      <div style={{ flex: 1, justifyContent: "center" }}>
        <div style={{ flexDirection: "row", alignItems: "center" }}>
          <span className="text-base text-gray-600 mt-2 text-center font-bold">
            {notice.objectName}
          </span>
        </div>
        <span className="text-base text-gray-600 mt-2 text-center">
          {notice.description[ENV.locale as "it" | "en" | "de"]}
        </span>

        <div style={{ flexDirection: "row" }}>
          <span className="text-base text-gray-600 mt-2 text-center">
            Posizione{": "}
          </span>
          <span className="text-base text-gray-600 mt-2 text-center">
            {notice.location}
          </span>
        </div>
        {notice.takenOver.patrol.user ? (
          <span className="text-sm text-gray-600 mt-2 text-center">
            IN CARICO da {notice.takenOver.createdAt}
            {" | "}
            {notice.takenOver.patrol.displayName}
          </span>
        ) : (
          <span className="text-sm text-gray-600 mt-2 text-center">
            IN ATTESA {T("from")}{" "}
            {Moment(new Date(notice.createdAt))
              .locale([ENV.locale, "en"])
              .calendar()}
            {" | "}
            {notice.creator.displayName}
          </span>
        )}
      </div>

      <div className="flex flex-col justify-center items-center">
        <i className={"icon-search_trail text-gray-600"} />
        <p className="text-sm text-gray-600 mt-2 text-center font-bold">
          {notice.counter}
        </p>
      </div>
    </div>
  );
};

interface NoticesListProps {
  onlyTakeOver?: boolean;
}

interface WarningCreateProps {
  id: string;
  type: Notice["type"];
  name: string;
  noticeId: string;
  problem: Warning["problem"];
  location: Warning["location"];
}

const NoticesList = (props: NoticesListProps) => {
  const [auth] = useGlobalState("auth");

  const { loading, error, data } = useQuery<{
    patrolActiveNotices: Notice[];
  }>(PATROL_NOTICES, {
    fetchPolicy: "cache-and-network",
  });

  const [openNotice, setOpenNotice] = useState<Notice | null>(null);
  const [openCreateWarning, setOpenCreateWarning] =
    useState<WarningCreateProps | null>(null);

  const [noticeTake, { loading: ntLoading, error: ntError }] =
    useMutation(PATROL_TAKE_NOTICE);

  const [noticeChangeStatus, { loading: ncsLoading, error: ncsError }] =
    useMutation(PATROL_CHANGESTATUS_NOTICE);

  const take = (id: string) => {
    if (id) {
      noticeTake({
        variables: {
          id,
        },
      });
    }
  };

  const changeStatus = (id: string, newStatus: Notice["status"]) => {
    if (id && newStatus) {
      noticeChangeStatus({
        variables: {
          id,
          newStatus,
        },
      });
    }
  };

  if (error) LOG.error(error);
  if (ntError) LOG.error(ntError);
  if (ncsError) LOG.error(ncsError);

  if (error && !data?.patrolActiveNotices) {
    return (
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="text-lg text-gray-600 mt-2 text-center">
          Ops, scusa c'è statu un errore!
        </p>
      </div>
    );
  }

  return (
    <div
      style={{ height: "calc(100vh - 180px)" }}
      className={
        "relative flex flex-1 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-2xl bg-white"
      }
    >
      <div className="flex flex-col w-full px-4 py-4 items-center justify-between overflow-auto">
        {!data?.patrolActiveNotices || data.patrolActiveNotices.length < 1 ? (
          <div className="font-bold w-full text-center text-gray-500">
            Non sono presenti segnalazioni
          </div>
        ) : null}
        {data?.patrolActiveNotices.map((item, index) => {
          let iTakenOver = item.takenOver?.patrol?.user === auth.user;
          if (!iTakenOver && props.onlyTakeOver) return null;
          return (
            <NoticeItem
              notice={item}
              active={iTakenOver}
              onClick={(item) => {
                setOpenNotice(item);
              }}
            />
          );
        })}
        {!data && loading ? (
          <div
            className="absolute rounded-2xl top-0 left-0 flex w-full h-full items-center justify-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.3)",
              zIndex: 5000,
            }}
          >
            <Loading color={theme.vars.colors.light} />
          </div>
        ) : null}
      </div>

      <Transition.Root show={openNotice ? true : false} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-y-auto"
          style={{ zIndex: 99999 }}
          open={openNotice ? true : false}
          onClose={() => {}}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-12 sm:align-middle sm:max-w-2xl w-full">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-auto">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl leading-6 font-medium text-gray-600 mb-4"
                      >
                        Come vuoi procedere?
                        <small>
                          <br />
                          {openNotice?.objectName}
                          <br />
                          <small>
                            {openNotice?.description[ENV.locale]} -{" "}
                            {openNotice?.location}
                          </small>
                        </small>
                      </Dialog.Title>
                      <div className="flex flex-1 flex-col">
                        {openNotice?.status !== "waiting" ? (
                          <div
                            className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl bg-gray-100 hover:bg-gray-200 text-white cursor-pointer"
                            style={{
                              backgroundColor: theme.vars.colors.redLight,
                            }}
                            onClick={() => {
                              openNotice?.id &&
                                changeStatus(openNotice.id, "waiting");
                              setOpenNotice(null);
                            }}
                          >
                            <div className="px-2">
                              <i className="text-3xl icon-patrol_management_section_standby" />
                            </div>
                            <div style={{ flexDirection: "column", flex: 1 }}>
                              <span className="text-base mt-2 text-center font-bold">
                                Rimetti in attesa
                              </span>
                              <br />
                              <span className="text-sm mt-2 text-center">
                                Reimposta la segnalazione in attesa cosi che
                                possa prenderla in carico qualcun'altro
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl text-white cursor-pointer"
                            style={{ backgroundColor: theme.vars.colors.cyan }}
                            onClick={() => {
                              take(openNotice?.id);
                              setOpenNotice(null);
                            }}
                          >
                            <div className="px-2">
                              <i className="text-3xl icon-patrol_top_bar_tacking_on" />
                            </div>
                            <div style={{ flexDirection: "column", flex: 1 }}>
                              <span className="text-base  mt-2 text-center font-bold">
                                Prendi in carico
                              </span>
                              <br />
                              <span className="text-sm  mt-2 text-center">
                                Incaricati di andare a controllare se la
                                segnalazione è vera e poi torna qui per
                                pubblicare un avviso o cancellarla
                              </span>
                            </div>
                          </div>
                        )}

                        <div
                          className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl text-gray-600 cursor-pointer"
                          style={{ backgroundColor: theme.vars.colors.yellow }}
                          onClick={() => {
                            if (!openNotice?.object) {
                              setOpenNotice(null);
                              return null;
                            }
                            setOpenNotice(null);
                            setOpenCreateWarning({
                              id: openNotice.object,
                              name: openNotice.objectName,
                              noticeId: openNotice.id,
                              location: openNotice.location,
                              problem: openNotice.problem,
                              type: openNotice.type,
                            });
                          }}
                        >
                          <div className="px-2">
                            <i className="text-3xl icon-attention" />
                          </div>
                          <div style={{ flexDirection: "column", flex: 1 }}>
                            <span className="text-base  mt-2 text-center font-bold">
                              Pubblica un avviso
                            </span>
                            <br />
                            <span className="text-sm  mt-2 text-center">
                              Crea un avviso di attenzione sul tracciato e
                              scegli se chiuderlo temporaneamente mentre sistemi
                              il problema
                            </span>
                          </div>
                        </div>

                        <div
                          className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl text-white cursor-pointer"
                          style={{ backgroundColor: theme.vars.colors.green }}
                          onClick={() => {
                            openNotice?.id &&
                              changeStatus(openNotice.id, "closed_fixed");
                            setOpenNotice(null);
                          }}
                        >
                          <div className="px-2">
                            <i className="text-3xl icon-patrol_management_section_trail_check" />
                          </div>
                          <div style={{ flexDirection: "column", flex: 1 }}>
                            <span className="text-base  mt-2 text-center font-bold">
                              Ho già risolto
                            </span>
                            <br />
                            <span className="text-sm  mt-2 text-center">
                              Non c'è la necessità di pubblicare un avviso
                            </span>
                          </div>
                        </div>

                        <div
                          className="flex w-full flex-row py-2 my-2 px-2 justify-center items-center hover:shadow-lg transition-all rounded-2xl text-white cursor-pointer"
                          style={{
                            backgroundColor: theme.vars.colors.greyLight,
                          }}
                          onClick={() => {
                            openNotice?.id &&
                              changeStatus(openNotice.id, "closed_cancel");
                            setOpenNotice(null);
                          }}
                        >
                          <div className="px-2">
                            <i className="text-3xl icon-attention" />
                          </div>
                          <div style={{ flexDirection: "column", flex: 1 }}>
                            <span className="text-base  mt-2 text-center font-bold">
                              Elimina segnalazione
                            </span>
                            <br />
                            <span className="text-sm  mt-2 text-center">
                              La segnalazione risulta errata o inesistente
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 flex justify-center">
                  <>
                    <Button
                      size="md"
                      color="white"
                      className={"lg:min-w-140-px"}
                      onClick={() => {
                        if (openNotice) {
                          setOpenNotice(null);
                        }
                      }}
                    >
                      {" "}
                      {T("cancel")}
                    </Button>
                  </>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <WarningCreate
        open={openCreateWarning ? true : false}
        type={"track"}
        elemId={openCreateWarning?.id || ""}
        name={openCreateWarning?.name || ""}
        onCancel={() => setOpenCreateWarning(null)}
        onClose={() => setOpenCreateWarning(null)}
        noticeId={openCreateWarning?.noticeId}
        problem={openCreateWarning?.problem}
        location={openCreateWarning?.location}
      />
      {ntLoading || ncsLoading ? (
        <div
          className="absolute top-0 left-0 flex w-full h-full items-center justify-center rounded-2xl"
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: 5000,
          }}
        >
          <Loading color={theme.vars.colors.light} />
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  noticeLeft: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: theme.vars.colors.blue,
    marginRight: 10,
  },
  noticeIcon: {
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginRight: 3,
  },
  voidList: {
    width: "100%",
    padding: 20,
    alignItems: "center",
  },
  modalBtn: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    borderRadius: 20,
    paddingVertical: 10,
    marginVertical: 5,
    paddingRight: 10,
    minHeight: 70,
  },
};

export { NoticesList };
