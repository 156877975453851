import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useGlobalState } from '../stores/appState';

// views
import { Login } from '../views/auth/Login';

interface AuthProps {}

const Auth = (props: AuthProps) => {
  const [auth] = useGlobalState('auth');

  if (auth.area && auth.user) {
    return <Redirect to="/admin" />;
  }

  return (
    <>
      <section className="relative w-full h-full py-7">
        <Switch>
          <Route path="/auth/login" exact component={Login} />
          <Redirect from="/auth" to="/auth/login" />
        </Switch>
      </section>
    </>
  );
};

export { Auth };
